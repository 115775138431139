import React, { ChangeEvent, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import IconWithButton from "../IconWithButton/IconWithButton";
import './alertPopup.scss';


function AssessmentFeedbackPopUp(props: any) {
    const { setShowAddFeedback, entityId, entityType, feedbackTypes } = props;

    const [feedbackType, setFeedbackType] = useState(feedbackTypes.find(fb => fb.name == "Not Present"));

    const onClickCancel = () => {
        setShowAddFeedback(false);
    }

    const onClickOk = () => {
        setShowAddFeedback(false);
        props.addFeedback(entityId, feedbackType.id);
    }

    const onChange = (ev: ChangeEvent<HTMLSelectElement>) => {
        if (ev.target.name == "feedback") {
            setFeedbackType(feedbackTypes.find(fb => fb.id == ev.target.value));
        }
    }

    return(
        <div>
            <Modal className="cancel-dimension complete-transaction-modal" show={true}>
                <div className="alert-container">
                    <Modal.Title className="cancel-header">
                        <span className="info-icon"></span>
                        <div className="cancel-header-text">Provide Feedback</div>
                    </Modal.Title>
                    <Modal.Body className="px-5">
                        <Row>
                            <p>What is wrong with the AI's assessment?</p>
                        </Row>
                        <Row>
                            <select
                                className="input-field form-control"
                                name="feedback"
                                value={feedbackType.id}
                                onChange={onChange}
                            >
                                {feedbackTypes.filter(fb => fb.name != "Correct" && fb.entityTypes.includes(entityType)).map(fb =>
                                    (<>
                                        <option value={fb.id}>
                                            {fb.name}
                                        </option>
                                    </>)
                                )}
                            </select>
                        </Row>
                        <Row>
                            <p>{feedbackType.description}</p>
                        </Row>
                        <Row className="button-align">
                            <Col md={4} className="button-position-ok">
                                <IconWithButton
                                    buttonText={"Submit"}
                                    onButtonClick={onClickOk}
                                    isSave={true}
                                    hideArrowIcon={true}
                                />
                            </Col>
                            <Col md={6} className="button-position-cancel">
                                <a href="#" onClick={onClickCancel} className="modal-cancel">
                                    Cancel
                                </a>
                            </Col>
                        </Row>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}

export default AssessmentFeedbackPopUp;
