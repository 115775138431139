import React, { useState, useEffect } from "react";
import './QAPDIssuesExistsModal.scss';
import IconWithButton from "../IconWithButton/IconWithButton";
import { useHistory } from "react-router-dom";
import {
    Modal,
    Row,
    Col
  } from "react-bootstrap";


interface IProps {
    isOpen: boolean;
    handleClose: any,
    handleOk: any;
    qaPDIssues: any;
    transactionId: any;
    setWait: any,
    clearPreviousTransactionOnComplete?: any,
    clearUserLocks?: any,
}

function QAPDIssusExistsModal(props: IProps) {
    const {handleClose, handleOk} = props;
    const history = useHistory();

    const onClickCancel = () => {
        handleClose(false);
    }

    const onClickOk = () => {
        handleClose(false);
        handleOk(props.transactionId);
        props.setWait(true);
        setTimeout(()=>{
            props.setWait(false);
            props.clearPreviousTransactionOnComplete && props.clearPreviousTransactionOnComplete()
            props.clearUserLocks && props.clearUserLocks()
            history?.push("/home")
        }, 1000)
    }

    return (
        <Modal className="qaPD_Issues_modal modal-dialog" show={true} backdrop="static" animation={false}>
            <Modal.Title className="qaPD-Issues-header modal-title">
                <span className="info-icon"></span>
                <div className="cancel-header-text">Correction Required</div>
            </Modal.Title>
            <Modal.Body className="qaPD-Issue-body modal-body">
                <p>The following issues must be corrected by an ROIS specialist.</p>
                <div className="qaPD_Issue_Table">
                    {props.qaPDIssues && Array.isArray(props.qaPDIssues) && props.qaPDIssues.map((row) =>
                        (
                         <Row className="qaPD_Issue_Info">
                            <Col md={4} className="first-col nonlink" style={{
                                textDecoration: row.active ? "" : "line-through",
                                color: row.resolved && row.active ? "#a0a0a0" : ""
                              }}>
                                ({row.qaErrorTypeName}) {row.qaErrorName}
                            </Col>
                            <Col md={4} className="middle-col nonlink" style={{
                                textDecoration: row.active ? "" : "line-through",
                                color: row.resolved && row.active ? "#a0a0a0" : ""
                              }}>
                                {row.firstName} {row.lastName}
                            </Col> 
                            <Col md={4} className="middle-col nonlink" style={{
                                textDecoration: row.active ? "" : "line-through",
                                color: row.resolved && row.active ? "#a0a0a0" : ""
                              }}>
                                Page {row.pageNumber}
                            </Col> 
                         </Row>                           
                        ) 
                    )}
                </div>
                <p>Proceeding will set the transaction to <b>Facility Action Required</b>.  Whould you like to proceed?</p>
            </Modal.Body>
            <Modal.Footer className="qaPD-Issue-footer modal-footer">
                <Row xs={12} className="button-align">
                    <Col xs={4} md={4} className="button-position-cancel">
                        <a href="#!" onClick={onClickCancel} >
                            Cancel
                        </a>
                    </Col>
                    <Col  xs={8} md={8} className="button-position-ok">
                        <IconWithButton 
                            buttonText={"Set to Facility Action Required"}
                            onButtonClick={onClickOk} 
                            isSave={false}                            
                        />
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default QAPDIssusExistsModal;