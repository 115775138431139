import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { Accordion, Col, Modal, Row, Form } from "react-bootstrap";
import CloseModal from "../../../../src/assets/images/close_modal_icon.png";
import ReduxFormDropdownField from "../../reduxFormFields/ReduxFormDropDownField";
import { reduxForm, Field } from 'redux-form';
import { RejectModalConstants } from '../../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants'
import infoIcon from "../../../../src/assets/images/Info_icon.png";
import IconWithButton from "../../IconWithButton/IconWithButton";
import "./RejectModal.scss";
import localValidations from '../../../components/dataEntryDetails/dataEntryComponents/validations/localValidations'
import ReduxFormTextField from "../../reduxFormFields/ReduxFormTextField";
import { API_SUCCESS, DEFAULT_DROPDOWN_VALUE } from "../../constants/ApiConstants";
import { Radio } from "@material-ui/core";
import {
  pickerHelperFunction
} from "../../../common/utils/appUtils";
import{
  nextTransactionIdActionCreator
} from "../../../components/dataEntryDetails/DataEntryDetailsDucks"
import{
  getOutboundTemplateFieldsRequest, 
  changeDelayReasonTemplate, 
  changeOutBoundFieldValue, 
  sendOutboundTemplateFieldsActionCreator,
  getLogoUrlActionCreator
} from "../../../components/landingPage/LandingPageDucks"
import {DelayModal} from '../delayModal/DelayModal'
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";

interface IProps {
  show: Function;
  handleClose: any;
  goLandingPage: Function;
  rejectOptions?: any;
  dispatch?: Function;
  formState?: any;
  rejectLetterData?: any;
  transactionId?: any;
  resetForm?: Function;
  resetFormDataEntryState?: Function,
  definedOutboundTemplates?: any,
  getOutboundTemplateFieldsSaga?: Function,
  outboundTemplatesFields?: any,
  outboundTemplateFieldsResponseStatusCode?: any,
  changeDelayReasonTemplate?: Function,
  changeOutBoundFieldValue?: Function,
  logoUrlStatusCode?: any,
  logoUrl?: any,
  getLogoUrlActionCreator?: Function,
  facilityId?: any,
  phone?: string,
  sendOutboundTemplateFields?: Function,
  readonly?: boolean
}

function RejectModal(props: IProps) {
  const [activeId, setActiveId] = useState("");
  const [emailErrMsg, setEmailErrMsg] = useState("")
  const [faxErrMsg, setFaxErrMsg] = useState("")
  const [radioSelect , setRadioSelect] = useState("")
  const [buttonText, setButtonText] = useState("")
  const [includeAuthForm, setIncudeAuthForm] = useState(false)
  const [dropDownTemplatesNames, setDropDownTemplatesNames] = useState(pickerHelperFunction([{definedTemplateId: DEFAULT_DROPDOWN_VALUE, templateName: "Reject Template"}].concat(props?.definedOutboundTemplates), "templateName", "definedTemplateId"))
  const [selectedTemplate, setSelectedTemplate] = useState(DEFAULT_DROPDOWN_VALUE)

  const [rejectDescPlaceholder, setRejectDescPlaceholder] = useState("");

  const[rName, setRName] = useState(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RName'): {});
  const[rCity, setRCity] = useState(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RCity'): {});
  const[rState, setRState] = useState(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RState'): {});
  const[rZipCode, setRZipCode] = useState(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RZipCode'): {});
  const[rAddress1, setRAddress1] = useState(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RAddress1'): {});
  const[rAddress2, setRAddress2] = useState(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RAddress2'): {});

  const { values } = props.formState || {};

  const descCharLimit = 700;
  const [descCharCount, setDescCharCount] = useState(values && RejectModalConstants.REJECT_MODAL_DESCRIPTION && values[RejectModalConstants.REJECT_MODAL_DESCRIPTION]?.length || 0);
  const [descError, setDescError] = useState('');
  
  useEffect(() => {
    props.getLogoUrlActionCreator && props.getLogoUrlActionCreator(props.facilityId);
  }, [])

  useEffect(() => { 
    if(descCharCount <= descCharLimit)
      setDescError("");
  }, [descCharCount])

  const changeFieldValue = (fieldName: string, value: any) => {
    props.dispatch && props.dispatch({
      type: "@@redux-form/CHANGE", payload: value, meta: {
        field: fieldName,
        touch: true, persistentSubmitErrors: false, form: RejectModalConstants.REJECT_MODAL_FORM
      }
    })
  }

  let curDate = new Date();
  React.useEffect(() => {
    if(radioSelect === "")
    setDefaultValues();
  });
  function onChangeEmail(value: String){
   if(radioSelect === "Email") { 
     value && value.length > 0 ? setEmailErrMsg("") : setEmailErrMsg("Email address is required")
    }
  }
  function onChangeFax(value: String){
    if(radioSelect === "Fax Number") { 
      value && value.length > 0 ? setFaxErrMsg("") : setFaxErrMsg("Fax number is required")
     }
   }

  function onChangeRejectOption(value: any) {
    if (value === DEFAULT_DROPDOWN_VALUE) {
      changeFieldValue(RejectModalConstants.REJECT_MODAL_DESCRIPTION, "")
      return
    }

    if (value.toString().startsWith("<") && value.toString().endsWith(">")){
      setRejectDescPlaceholder(value);
    }else{
      setRejectDescPlaceholder("");
      let currentValue = values && values[RejectModalConstants.REJECT_MODAL_DESCRIPTION]
      let updatedValue = currentValue ? currentValue.trim() + "\r\n\r\n" + value.trim() : value
      let option = props.rejectOptions.find(o => o.value === value);
      let currentRid = values && values[RejectModalConstants.REJECT_REASON_IDS];
      let updatedRID = currentRid ? currentRid + ',' + option?.rid : option?.rid;
      changeFieldValue(RejectModalConstants.REJECT_REASON_IDS, updatedRID)
      changeFieldValue(RejectModalConstants.REJECT_MODAL_DESCRIPTION, updatedValue)
      setDescCharCount(updatedValue.length);
    }
  }

  function patientNameFormat(){
    return props.rejectLetterData?.patientLastName.substr(0, 1) + ", " + props.rejectLetterData?.patientFirstName;
  }

  function createModal(){
    if(selectedTemplate == '11'){
      return(
        <DelayModal
          fields= {props.outboundTemplatesFields}
          patientName={patientNameFormat()}
          templateId = {selectedTemplate}
          handleSubmit = {() => {}}
          handleChangeReason = {props.changeDelayReasonTemplate? props.changeDelayReasonTemplate: (data) => {} }
          logoUrlStatusCode = {props.logoUrlStatusCode}
          logoUrl = {props.logoUrl}
          phone={props.phone}
        />
      );
    }

    return <div>test</div>
  }

  function onClickDone () { 
    if(descCharCount > descCharLimit){
      setDescError("Description length cannot exceed " + descCharLimit + " characters.");
      document.getElementsByClassName("description-input")[0].scrollIntoView();
      return '';
    }else{
      setDescError("");
    }

    if((radioSelect === "Email" && values && !values[RejectModalConstants.REJECT_MODAL_EMAIL]?.length) || (radioSelect === "Email" && !values)){
        setEmailErrMsg("Email address is required")
        return ''
    }
    if((radioSelect === "Fax Number" && values && !values[RejectModalConstants.PHONE_FAX_NUMBER]?.length) || (radioSelect === "Fax Number" && !values)){
      setFaxErrMsg("Fax number is required")
      return ''
    }
    if (selectedTemplate == "-9999"){
      if(props.resetForm){
        props.resetForm();
      }

      if(props.resetFormDataEntryState){
        props.resetFormDataEntryState();
      }

    } else {
      const contact = radioSelect == "Email" ? values[RejectModalConstants.REJECT_MODAL_EMAIL] : values[RejectModalConstants.PHONE_FAX_NUMBER];
    }

    props.handleClose();
    props.goLandingPage(values, radioSelect, selectedTemplate, props.outboundTemplatesFields) 
  }

  function onChangeRadio(value: any) {
    setRadioSelect(value)
    setEmailErrMsg("")
    setFaxErrMsg("")
    if(value === "Email"){
      setButtonText("Email Notification");
    }
    else if(value === "Fax Number"){
      setButtonText("Fax Notification")
    }
    else{
      setButtonText("Print Notification")
    }
  }

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId("");
    } else {
      setActiveId(id);

      setRName(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RName'): {})
      changeFieldValue("Requestor Name", rName?.userValue)

      setRCity(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RCity'): {})
      changeFieldValue("Requestor City", rCity?.userValue)

      setRState(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RState'): {})
      changeFieldValue("Requestor State", rState?.userValue)

      setRZipCode(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RZipCode'): {})
      changeFieldValue("Requestor zipcode", rZipCode?.userValue)

      setRAddress1(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RAddress1'): {})
      changeFieldValue("Requestor Address1", rAddress1?.userValue)

      setRAddress2(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RAddress2'): {})
      changeFieldValue("Requestor Address2", rAddress2?.userValue)
    }
  }

  function setDefaultValues() {
    if(props.rejectLetterData?.requestorEmail !== undefined){
      if(props.rejectLetterData?.requestorEmail){
        setRadioSelect("Email");
        setButtonText("Email Notification");
        changeFieldValue(RejectModalConstants.REJECT_MODAL_EMAIL, props.rejectLetterData?.requestorEmail)
        return;
      } 
      if(props.rejectLetterData?.requestorFax && !props.rejectLetterData?.requestorEmail){
        setRadioSelect("Fax Number");
        setButtonText("Fax Notification");
        changeFieldValue(RejectModalConstants.PHONE_FAX_NUMBER, props.rejectLetterData?.requestorFax )
        return;
      }
  
      if(!props.rejectLetterData?.requestorFax && !props.rejectLetterData?.requestorEmail){
        setRadioSelect("Send to Print");
        setButtonText("Print Notification");
        return;
      }
    }
  }

  function handleChange(value: any) {
    setIncudeAuthForm(value);
    changeFieldValue(RejectModalConstants.REJECT_INCLUDE_AUTH_FORM, value)
  }

  const onChangeTemplateDropDown = (value: any) => {
    if (value === DEFAULT_DROPDOWN_VALUE) {
      setSelectedTemplate(DEFAULT_DROPDOWN_VALUE)
      return
    }
    
    props.getOutboundTemplateFieldsSaga && props.getOutboundTemplateFieldsSaga(value, props.transactionId)
    setSelectedTemplate(value)
  }

  useEffect(() => {
    setRName(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RName'): {})
    changeFieldValue("Requestor Name", rName?.userValue)

    setRCity(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RCity'): {})
    changeFieldValue("Requestor City", rCity?.userValue)

    setRState(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RState'): {})
    changeFieldValue("Requestor State", rState?.userValue)

    setRZipCode(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RZipCode'): {})
    changeFieldValue("Requestor zipcode", rZipCode?.userValue)

    setRAddress1(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RAddress1'): {})
    changeFieldValue("Requestor Address1", rAddress1?.userValue)

    setRAddress2(props.outboundTemplatesFields.length > 0 ? props.outboundTemplatesFields.find(x => x.pdfName == 'RAddress2'): {})
    changeFieldValue("Requestor Address2", rAddress2?.userValue)
  }, [props?.outboundTemplatesFields, props.outboundTemplateFieldsResponseStatusCode === 200])

  const onChangePrintAddress = (fieldName: any, value: any) => {
    props.changeOutBoundFieldValue && props.changeOutBoundFieldValue(fieldName, value)
  }

  return (
    <Modal className="reject-model-dimension" show={true}>
      <Modal.Title className="reject-modal-header">
        <img
          src={infoIcon}
          alt={"info Icon"}
        />
        <span style={{ marginLeft: "5px" }}>Requestor Communication ({props.transactionId})</span>
        <img
          src={CloseModal}
          alt={"Close Modal"}
          style={{ float: "right", marginRight: "16px", marginTop: "5px" }}
          onClick={props.handleClose}
        />
      </Modal.Title>
      <Row className="phrase-section" style={{"marginLeft":"10px"}}>
          <Col>
            <label className="phrase-section-one">Select an Outbound Template:</label>
            <div className="phrase-dropdown">
              <Field
                name={"Select an Outbound Template"}
                component={ReduxFormDropdownField}
                props={{
                  required: false,
                  options: dropDownTemplatesNames,
                  onChange: onChangeTemplateDropDown
                }}
              />
            </div>
            </Col>
      </Row>
     
      <div className="reject-modal-content">
     
          
          {selectedTemplate == DEFAULT_DROPDOWN_VALUE?
          <>
            <div className="reject-logo-section">
            <div className="reject-main">
            <Row >
              <Col>
                <div className="reject-logo">
                  <div>
                    <div className='modal-text-style-org' style={{ fontWeight: 'bold' }}>
                      {props.rejectLetterData?.address1 ? <div>{props.rejectLetterData?.address1}<br /></div> : null}
                      {props.rejectLetterData?.address2 ? <div>{props.rejectLetterData?.address2}<br /></div> : null}
                      {props.rejectLetterData?.city + "," + props.rejectLetterData?.state + " " + props.rejectLetterData?.zipcode}
                      {props.rejectLetterData?.phone ? <div>{props.rejectLetterData?.phone}<br /></div> : null}
                      {props.rejectLetterData?.fax ? <div>{props.rejectLetterData?.fax}<br /></div> : null}
                      {props.rejectLetterData?.organizationWebsite ? <div>{props.rejectLetterData?.organizationWebsite}</div> : null}
                    </div>
                    {props.logoUrlStatusCode == API_SUCCESS? 
                      <div className="reject-logo-left-section"><img src={props.logoUrl} alt="logo"></img></div>  
                      :null}              
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                    <div className='modal-text-style-requestor'>
                        {props.rejectLetterData?.fullname ? <div>{props.rejectLetterData?.fullname}<br /></div> : null}
                        {props.rejectLetterData?.requestorFirstname + " " + props.rejectLetterData?.requestorLastname}
                        {props.rejectLetterData?.requestorAddress1 ? <div>{props.rejectLetterData?.requestorAddress1}<br /></div> : null}
                        {props.rejectLetterData?.requestorAddress2 ? <div>{props.rejectLetterData?.requestorAddress2}<br /></div> : null}
                        {props.rejectLetterData?.requestorCity + " " + props.rejectLetterData?.requestorState + " " + props.rejectLetterData?.requestorZipCode}
                      </div>
                      <div className='modal-text-style-date'>
                        Date:{` ${curDate.getMonth() + 1}/${curDate.getDate()}/${curDate.getFullYear()}`}<br />
                        <br />
                      Invoice #: {props.rejectLetterData?.invoiceNumber ? <span>{props.rejectLetterData?.invoiceNumber}</span> : null}
                      </div>
              </Col>
            </Row>
            </div>
            </div>
            
            <Row className="reject-descriptive-section">
              <Col>
                <span className="heading">
                  WE ARE UNABLE TO FULLFILL YOUR REQUEST FOR MEDICAL RECORDS
                  <br />
                  <br />

                </span>
                <span >
                  Patient name: {patientNameFormat()} <br />
                  Case #: {props.rejectLetterData?.caseNumber ? <span>{props.rejectLetterData?.caseNumber}<br /></span> : <span><br /></span>}
                  Medical Provider: {props.rejectLetterData?.medicalProviderName ? <span>{props.rejectLetterData?.medicalProviderName}<br /></span> : null}
                  <br />
                  <br />
                </span>
                <p>
                  Dear Medical Records Requestor: <br />
                  {/* props.rejectLetterData?.verismaName */} Verisma Systems, Inc. has contracted with the medical provider noted above to 
                  provide HIPAA compliance review and distribute medical records on its behalf. An issue 
                  concerning the attached request has been detected. 
                  Your request will not be processed due to the following issue(s):
                </p>
              </Col>
            </Row>
            <Row className="phrase-section">
              <Col>
                <label className="phrase-section-one">Select a Phrase</label>
                <div className="phrase-dropdown">
                  <Field
                    name={RejectModalConstants.SELECT_PHRASE}
                    component={ReduxFormDropdownField}
                    props={{
                      required: false,
                      options: props.rejectOptions,
                      onChange: onChangeRejectOption
                    }}
                  />
                </div>

                <span style={{ fontWeight: 600 }}>
                  Select standard phrases from above and/or type a description of
                  the problem here ({descCharLimit} characters max) <br />
                  <br />
                </span>
                <Row>
                  <Col>
                    <Field
                      name={RejectModalConstants.REJECT_MODAL_DESCRIPTION}
                      component={ReduxFormTextField}
                      placeholder={rejectDescPlaceholder}
                      props={{
                        errorMessage: descError,
                        onChange:(e) => {setDescCharCount(e.length)},
                        className: "description-input",
                        multiline: true
                      }}
                    />
                    <span style={descCharCount > descCharLimit ? {"color": "Red"} : {}}>{descCharCount + "/" + descCharLimit}</span>
                  </Col>
                </Row>
                <div style={{ paddingTop: "15px" }}>
                  <input
                    type="checkbox"
                    id="valid_Authorization"
                    name="valid_Authorization"
                    checked={includeAuthForm}
                    onChange={e => handleChange(e.target.checked)}
                  />
                  <label className='' style={{ marginLeft: "5px" }}>
                    {" "}
                    Include a valid Authorization attachment with this notice
                  </label>
                  <br />
                </div>
                <div className='' style={{ fontWeight: 900 }}>
                  In order to process your request for medical records, a new
                  request/authorization addressing the issue(s) must be <br />
                  provided to the medical facility from where you are requesting
                  records
                </div>
                <br /> <br />
                <span className=''>
                  Please contact our Customer Service Team with questions:
                </span>
                <br />
                <span className=''>Telephone: {props.phone}</span>
                <br /> <br />
                <div style={{ fontSize: "9px" }}>
                  STATEMENT OF CONFIDENTIALITY
                  <br />
                  THIS INFORMATION IS INTENDED FOR THE EXCLUSIVE USE OF THE
                  ADDRESSEE AND MAY CONTAIN CONFEDENTIAL OR PRIVILEGED
                  INFORMATION.IF YOU ARE NOT THE
                  INTENDED RECIPIENT, YOU ARE HEREBY NOTIFIED THAT ANY RETENTION,
                  COPYING, DISSEMINATION, OR USE OF THIS COMMUNICATION IS STRICTLY
                  PROHIBITED. IF THIS WAS SENT IN ERROR, PLEASE NOTIFY ROIS App
                  Health Service Org, AT {props.phone} AND DESTROY THIS COMMUNICATION
                  AND ANY ATTACHMENTS.
                </div>
              </Col>
            </Row>
        </>:
         props.outboundTemplateFieldsResponseStatusCode === 200 && props.outboundTemplatesFields.length > 0? 
         createModal()
         : 
         <div style={{'textAlign': 'center'}}>
            <Row>
              <Col xs={12} md={12}>
                <LoadingSpinner/>
              </Col>
            </Row>
          </div>
         }
        <Row className="accordion-section">
          <Col className="accordion-main-section">
            <Accordion >
              <div>
                <div className="panel-header">
                  <Accordion.Toggle
                    onClick={() => toggleActive("0")}
                    className="panel-toggle"
                    eventKey="0"
                  >
                    <span className="accordion-item__icon">
                      <ExpandMoreIcon
                        className={activeId ? "rotate" : "rotate-down"}
                      />
                    </span>
                    Notification Method Overrides
                  </Accordion.Toggle>
                </div>
               
                <Accordion.Collapse eventKey="0">
                  <div className="panel-body">
                  <Row className="override-email-row">
                      <Col xs={4} md={4} className="pl-0 override-form">
                        <Row>
                          <Col xs={12} md={12} className="override-label">
                            <Radio
                              checked={radioSelect === "Email"}
                              onChange={e => onChangeRadio(e.target.value)}

                              value="Email"
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': 'Yes' }}
                            />
                            <Form.Label className="radio-label">Email</Form.Label>
                          </Col>
                        </Row>
                        <div className="reject-override placeholder-style">
                          <Field
                            name={RejectModalConstants.REJECT_MODAL_EMAIL}
                            component={ReduxFormTextField}
                            placeholder="example@mail.com"
                            props={{
                              maxLength: 100,
                              required: false,
                              errorMessage:emailErrMsg,
                              radioSelected:{radioSelect},
                              label: "",
                              onChange: onChangeEmail
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={4} md={4} className="pl-0 override-form">
                      <Row>
                          <Col xs={12} md={12} className="override-label">
                            <Radio
                              checked={radioSelect === "Fax Number"}
                              onChange={e => onChangeRadio(e.target.value)}
                              value="Fax Number"
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': 'Yes' }}
                            />
                            <Form.Label className="radio-label">Fax Number</Form.Label>
                          </Col>
                        </Row>
                        <div className="reject-override placeholder-style">
                          <Field
                            name={RejectModalConstants.PHONE_FAX_NUMBER}
                            component={ReduxFormTextField}
                            placeholder="xxx-xxx-xxxx"
                            props={{
                              maxLength: 100,
                              required: false,
                              errorMessage:faxErrMsg,
                              radioSelected:{radioSelect},
                              label: "",
                              onChange: onChangeFax
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={1.5} md={1.5} className="pl-0 override-form">
                      </Col>
                      {selectedTemplate === DEFAULT_DROPDOWN_VALUE && props.outboundTemplateFieldsResponseStatusCode === 200 ? 
                      <Col xs={2.5} md={2.5} className="pl-0 override-form">
                        <Row className="override-print-row">
                          <Col xs={12} md={12} >
                            <Radio
                              checked={radioSelect === "Send to Print"}
                              onChange={e => onChangeRadio(e.target.value)}
                              value="Send to Print"
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': 'Yes' }}
                            />
                            <Form.Label className="radio-label">Send to Print</Form.Label>
                          </Col>
                        </Row>
                      </Col>:

                        <Row className="override-print-row">
                        <Col xs={12} md={12}>
                          <Radio  style={{"marginLeft": "-4px"}}
                            checked={radioSelect === "Send to Print"}
                            onChange={e => onChangeRadio(e.target.value)}
                            value="Send to Print"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'Yes' }}
                          />
                          <Form.Label className="radio-label">Send to Print</Form.Label>
                          <div style={{"marginRight": "20px"}}>
                          <Row style={{"marginLeft": "-29px"}}>
                            <Col xs={12} md={12}>
                            <Field
                              name={"Requestor Name"}
                              component={ReduxFormTextField}
                              placeholder="Requestor Name"
                              props={{
                                maxLength: 100,
                                required: false,
                                label: "Requestor Name",
                                onBlur: (e) => onChangePrintAddress(rName.pdfName, e)
                              }}
                            />
                            </Col>
                        </Row>
                        <div style={{"marginBottom": "-50px"}}>
                        <Row style={{"marginLeft": "-29px"}}>
                        <Col xs={12} md={12}>
                              <Field
                                name={"Requestor Address1"}
                                component={ReduxFormTextField}
                                placeholder="123 main st"
                                props={{
                                  maxLength: 100,
                                  required: false,
                                  label: "Requestor Address",
                                  onBlur: (e) => onChangePrintAddress(rAddress1.pdfName, e)
                                }}
                              />
                          </Col>
                        </Row>
                        </div>
                        <div style={{"marginBottom": "-20px"}}>
                        <Row style={{"marginLeft": "-29px"}}>
                        <Col xs={12} md={12}>
                              <Field
                                name={"Requestor Address2"}
                                component={ReduxFormTextField}
                                placeholder="apt 3A"
                                props={{
                                  maxLength: 100,
                                  required: false,
                                  onBlur: (e) => onChangePrintAddress(rAddress2.pdfName, e)
                                }}
                              />
                        </Col>
                        </Row>
                        </div>
                        </div>
                        <Row>
                          <Col xs={4} md={4}  style={{"marginLeft": "-13px"}}>
                            
                              <Field
                                name={"Requestor City"}
                                component={ReduxFormTextField}
                                placeholder="New York"
                                props={{
                                  maxLength: 100,
                                  required: false,
                                  label: "City",
                                  onBlur: (e) => onChangePrintAddress(rCity.pdfName, e)
                                }}
                              />
                          
                          </Col>
                          <Col xs={4} md={4} >
                              <Field
                                name={"Requestor State"}
                                component={ReduxFormTextField}
                                placeholder="NY"
                                props={{
                                  maxLength: 2,
                                  required: false,
                                  label: "State",
                                  onBlur: (e) => onChangePrintAddress(rState.pdfName, e)
                                }}
                              />
                          </Col>
                          <Col xs={4} md={4} >
                              <Field
                                name={"Requestor zipcode"}
                                component={ReduxFormTextField}
                                placeholder="12345"
                                props={{
                                  maxLength: 10,
                                  required: false,
                                  label: "Zip Code",
                                  onBlur: (e) => onChangePrintAddress(rZipCode.pdfName, e)
                                }}
                              />
                          </Col>
                        </Row>
                        </Col>
                      </Row>
                      }
                    </Row>
                  </div>
                </Accordion.Collapse>
              </div>
            </Accordion>
          </Col>
        </Row>
      </div>
       

      <Row className="save-cancel-row">

      <Col xs={6} md={6} className="button-position-cancel">
        <a href="#!" onClick={props.handleClose} className="modal-cancel">
            Cancel
          </a>
        </Col>

        <Col xs={6} md={6} className="button-position-save">
        <IconWithButton
            buttonText={buttonText}
            onButtonClick={onClickDone}
            hideArrowIcon={true}
            isSave={true}
          />
        </Col>
      </Row>
    </Modal>
  );
}
const RejectModalReduxForm = reduxForm<{}, IProps>({
  form: RejectModalConstants.REJECT_MODAL_FORM,
  validate: localValidations,
  enableReinitialize: true,
  touchOnChange: true
})(RejectModal)

function mapStateToProps(state: any, props: any) {
  
  const transactionId = props.readonly ? state.landingPageState.readOnlyTransactionDetails.transactionId : state.landingPageState?.transactionId;
  const facility = props.readonly ? state.landingPageState.readOnlyTransactionDetails.attributes.facility : state.landingPageState.selectedFacilityDetails.facility;
  const rejectOptions = props.readonly ? state.landingPageState.rejectOptions : state.dataEntryState.rejectOptions;

  return {
    formState: state.form && state.form[RejectModalConstants.REJECT_MODAL_FORM],
    rejectOptions: rejectOptions,
    transactionId: transactionId,
    definedOutboundTemplates: state.landingPageState.definedOutboundTemplates,
    outboundTemplatesFields: state.landingPageState.outboundTemplateFields,
    outboundTemplateFieldsResponseStatusCode: state.landingPageState.outboundTemplateFieldsResponseStatusCode,
    logoUrlStatusCode: state.landingPageState.logoUrlStatusCode,
    logoUrl: state.landingPageState.logoUrl,
    facilityId: facility.id,
    phone: (facility?.address?.phoneNumber ?? "").trim() == "" ? 
      "866-390-7404" : facility?.address?.phoneNumber
  }
}

function mapDispatchToProps(dispatch: Function, props: any){
  return {
    getOutboundTemplateFieldsSaga: (templateId: string, transactionId: string) => dispatch(getOutboundTemplateFieldsRequest(templateId, transactionId)),
    changeDelayReasonTemplate: (data: any) => dispatch(changeDelayReasonTemplate(data)),
    changeOutBoundFieldValue: (fieldName: any, value: any) => dispatch(changeOutBoundFieldValue(fieldName, value)),
    getLogoUrlActionCreator : (facilityId: any) => dispatch(getLogoUrlActionCreator(facilityId)),
    sendOutboundTemplateFields: (templateId: any, transactionId: any, action: any, templateFields: any, facilityId: any, context: any, contact: any) => {
        dispatch(sendOutboundTemplateFieldsActionCreator(templateId, transactionId, action, templateFields, facilityId, context, contact))
        if(!props.readonly && templateId === "-9999" && nextTransactionIdActionCreator){
          dispatch(nextTransactionIdActionCreator(facilityId))
        }
    }
  }
}

export default connect<{}, {}, IProps>(mapStateToProps, mapDispatchToProps)(RejectModalReduxForm);