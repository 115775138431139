import React from 'react';
import './TabComponents.scss';
import { Card, Figure } from 'react-bootstrap';
import QAIcon from '../../assets/images/tabImages/QA-Icon.png';

interface QAReviewProps {
  qaReview: number
}

function MyTasks(props: QAReviewProps) {
  return (
    <Card className="cardBorderStyle">
      <Card.Body className='border-none'>
        <Figure className='mb-0'>
          <Figure.Image
            src={QAIcon}
            alt='QAReviewLogo'
            className='mr-2 float-left'
            style={{maxWidth: "32px", maxHeight: "32px"}}
          />
          <Figure.Caption className='figure-caption-custom mt-1'>
            QA Review
        </Figure.Caption>
        </Figure>
        <span className='card-data'>
          <span className="totalRecordCount">{props.qaReview}</span>
        </span>
      </Card.Body>
    </Card>
  );
}
export default MyTasks;
