import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import React, { useState, useEffect, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import moment from 'moment';
import { change, Field, InjectedFormProps, reduxForm, untouch, stopSubmit } from 'redux-form';
import { API_FAILURE, API_SUCCESS, API_FETCHING, DEFAULT_DROPDOWN_NAME, DEFAULT_DROPDOWN_VALUE, DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, INVALID_DATE, API_INITIAL } from '../../../common/constants/ApiConstants';
import AccordionComponent from "../../../common/formFields/Accordion";
import DropdownField from "../../../common/formFields/DropdownField";
import IconWithButton from "../../../common/IconWithButton/IconWithButton";
import ReduxFormcheckboxField from "../../../common/reduxFormFields/ReduxFormCheckboxField";
import ReduxFormDateField from "../../../common/reduxFormFields/ReduxFormDateField";
import ReduxFormDropdownField from "../../../common/reduxFormFields/ReduxFormDropDownField";
import ReduxFormDropdownSearchField from "../../../common/reduxFormFields/ReduxFormDropDownSearchField";
import ReduxFormRadioField from "../../../common/reduxFormFields/ReduxFormRadioField";
import ReduxFormTextField from "../../../common/reduxFormFields/ReduxFormTextField";
import ReduxFormValidationError from '../../../common/reduxFormFields/ReduxFormValidationError';
import CustomTable from "../../../common/table/CustomTable";
import CompleteTransactionPopUp, { CompleteTransactionWaiting } from "../../../common/alertPopup/CompleteTransactionPopUp";
import CompleteReviewPopUp from "../../../common/alertPopup/CompleteReviewPopUp";
import QAPDIssuePopUp from "../../../common/modal/QAPDIssuesExistsModel";
import DuplicateTXPopup from "../../../common/alertPopup/DuplicateTXPopup";
import SensitiveInfo from "../dataEntryComponents/SensitiveInfo";
import InfoToRelease from "../dataEntryComponents/InfoToRelease";
import MultilineTextboxButtons from '../../../common/formFields/MultilineTextboxButtons';

import {
  generatePickerOptions,
  generatePurposeOfRequestOptions,
  isValid,
  normalizeData,
  pickerHelperFunction,
  mirrorData,
  generateUpdateBillToRequestorObject,
  generateSendToRequestorObject,
  generateUpdateSendToRequestorObject,
  generateSendToRequestorContactObject,
  generateSendToContactObject,
  generateUpdateDisclosureInformationObject,
  getMomentDate,
  _,
  LightTooltip
} from "../../../common/utils/appUtils";
import "../DataEntryDetails.scss";
import { tabItems } from "../DataEntryTabConstants";
import { BillingFormconstants, FormConstants, RequestorFormConstants } from './DataEntryFieldsConstants';
import localValidations, { ignoreFields, DISCLOSURE_EVENT_ID, isAuthDateRequired, isDateSqlOverflow, infoToReleaseValidiation } from './validations/localValidations';
import submitValidations from './validations/submitValidations';
import {
  updateBillToRequestorDetailsRequest,
  sendToRequestorDataActionCreator,
  updateSendToRequestorDataActionCreator,
  sendToRequestorDataProps,
  sendToContactDataProps,
  getRequestorSearchDetailsActionCreator,
  getContactSearchDetailsActionCreator,
  patchSendToRequestorContactActionCreators,
  sendToContactDataActionCreator,
  updateBillingPaneFields,
  getPatientSearchDetailsActionCreator,
  createPatientRecordRequest,
  updateFormSyncErrorsActionCreator,
  updateDisclosureInformationActionCreator,
  completeTransactionTaskActionCreator,
  createPatientRecordSuccess,
  billToRequestorDetailsActionCreator,
  nextTransactionIdActionCreator,
  requestorCompleteActionCreator,
  billingCompleteActionCreator,
  deleteDocumentActionCreator,
  detailsCompleteActionCreator,
  patchPatientDetailsCreator,
  getAuditTypesRequest,
  getOBOsRequest,
  getValidateRequest,
  checkDuplicateTransactions,
  setLastUsedRequestorInfo,
  clearPreviousTransactionOnCompleteCreator,
  readBarCodeDDSRequest,
  updateRequestorDetailsAction,
  saveInformationToReleaseActionCreator,
  getTransactionAttributesActionCreator,
  putDigitalFaxValidationCreator,
  putAuditTransactionSensitiveInfoCreator,
  updateRequestorContactInfo,
  resetRequestorContactList,
  getReleaseMethodOptionsData,
  setConfirmFaxNumberAction,
  getFeeScheduleData,
  returnToQueueTaskActionCreator,
  getQAPDIssuesActionCreator
} from "../DataEntryDetailsDucks";
import { createTransaction, clearUserLocksActionRequest, resetRequestContext, getNextQATransaction } from '../../landingPage/LandingPageDucks';
import { isNullOrUndefined } from 'util';
import { id } from 'date-fns/locale';
import { ProceedIcon } from '../../../assets/images/proceedIcon'
import { validate } from '@material-ui/pickers';
import { max, min } from 'date-fns';
import toast from 'react-hot-toast';
import { store } from '../../../redux';

interface IpropType {
  activeTabId: number;
  onChangeDataEntryTab: Function;
  proceedBtnClick: boolean;
  dispatch?: Function,
  requestTypes?: any,
  purposeOfRequests?: any,
  createPatientRecordSuccess?: Function,
  formState?: any,
  selectedPurposeFromAPI?: number,
  discloseInstitutions?: any,
  transactionDetails?: any,
  requestorContactDetails?: any,
  feeSchedules?: any,
  releaseMethods?: any,
  shippingServices?: any,
  billingTypes?: any,
  sendToRequestorData?: any,
  sendToContactData?: any,
  updateSendToRequestorDetails?: any,
  updateBillToRequestorData?: any,
  patchSendToRequestorContactData?: any,
  requestorSearchDetails?: any,
  patientSearchDetails?: any,
  selectedFacilityDetails?: any,
  globalUser?: number
  transactionId?: any,
  contactSearchDetails?: any,
  updateBillingPaneFields?: Function,
  retrieveProtocol: any,
  getPatientListSearchDetails?: any,
  phiCodes?: any,
  createPatientRecordRequest?: Function,
  updateFormSyncErrors?: Function,
  formSyncErrors?: any,
  updateDisclosureInformationDetails?: any,
  touch?: any,
  completeTransaction?: any,
  returnToQueue?: any,
  getPatientSearchResultsAPIStatus?: any,
  getRequestorSearchDetailsAPIStatus?: any,
  getContactSearchDetailsAPIStatus?: any,
  getBillToRequestorDetails?: Function,
  facilityId?: any,
  getNextTransactionId?: any,
  selectedPHICodes?: any,
  patchDetailsComplete?: Function,
  patchRequestorComplete?: Function,
  patchBillingComplete?: Function,
  additionalChargeRecords?: any,
  canEditPatient?: Boolean,
  updatedPatientDetailsAttributes?: any,
  patchPatientDetailsCreator?: Function,
  patientDetailsUpdated?: false,
  getAuditTypesRequest?: Function,
  getOBOsRequest?: Function,
  auditTypes?: any,
  onBehalfOf?: any,
  getFacilityFeeSchedules: Function,
  getFacilityShippingServices: Function,
  getFacilityReleaseMethods: Function,
  validationPassed?: boolean,
  checkValidation?: Function,
  checkDuplicates?: Function,
  previousRequestor?: any,
  previousTransactionId?: any,
  setLastUsedRequestorInfo?: any,
  transactionIDAttributes?: any,
  lastUsedRequestorContact?: any,
  requestorDetails?: any,
  createTransaction?: Function,
  clearUserLocks?: Function,
  clearPreviousTransactionOnComplete?: Function,
  transactionDDS?: any,
  readBarCodeDDSRequest?: Function,
  documentsList?: any,
  updateRequestorDetails?: Function,
  intiaTxlSagaCallCount?: any,
  isNewTransaction?: boolean,
  facilityAdditionalFields?: any,
  saveInformationToRelease?: Function,
  requestContext?: any,
  resetRequestContext?: Function,
  updateDeliveryInstructionsStatus?: any,
  getTransactionAttributes?: Function,
  putDigitalFaxValidation?: Function,
  putAuditTransactionSensitiveInfo?: Function,
  transactionStatus?: any,
  readBarCodeDDSAPIStatus?: any,
  updateRequestorContactInfo?: Function,
  resetRequestorContactList?: Function,
  requestorComplete?: boolean,
  userHasMadeChanged: Function,
  setConfirmFaxNumberAction?: Function,
  getQAPDIssues?: any,
  qaPDIssues?:any,
  getNextQATransactionCall?: Function,
}

interface InormalizedType {
  [key: string]: any;
}

enum InputFieldLength {
  NAME_MAX_LENGTH = 40,
  MEDICAL_RECORD_NUMBER_MAX_LENGTH = 30,
  REQUEST_ID_MAX_LENGTH = 30,
  EMPI_MAX_LENGTH = 30,
  CASE_NUMBER_MAX_LENGTH = 40,
}
enum TypeOfRequestorID {
  INTERNAL = 5005
}
// call for requestor search
const DataEntryDetailsRightPane: React.FC<IpropType & InjectedFormProps<{}, IpropType>> = (props: IpropType) => {

  function fetchAllSearchRecords(facilityId: string) {
    const phoneOrFax = values ? values[RequestorFormConstants.PHONE_FAX_NUMBER] : '';
    const emailAddress = values ? values[RequestorFormConstants.EMAIL_ADDRESS] : '';
    const address = values ? values[RequestorFormConstants.SEARCH_ADDRESS] : '';
    const zip = values ? values[RequestorFormConstants.SEARCH_ZIP] : '';
    const rqSiteCode = values ? values[RequestorFormConstants.RQ_SITE_CODE] : '';
    const internalRequestor = props && props.transactionDetails?.transactionRequest?.isInternalRequest && searchName ? searchName : '';
    const typeOfRequestorID = props && props.transactionDetails?.transactionRequest?.isInternalRequest ? TypeOfRequestorID.INTERNAL : null;
    const globalUserId = props.globalUser
    const requestorName = values ? values[RequestorFormConstants.SEARCH_REQUESTOR_NAME] : '';
    const contactName = values ? values[RequestorFormConstants.SEARCH_CONTACT_NAME] : '';

    props.dispatch && props.dispatch(getRequestorSearchDetailsActionCreator({ phoneOrFax, emailAddress, address, zip, rqSiteCode, internalRequestor, typeOfRequestorID, requestorName, contactName }, facilityId, globalUserId))

  }


  const onChangeFaxOrPhoneOrEmail = (value) => {
    if (value.length) { setErrorMessage("") }
    setSearchPhone(value)
  }


  const onClickSearch = () => {
    if (FormConstants && props.formState.values[FormConstants.REQUEST_TYPE] === '31') {
      fetchAllSearchRecords(props.selectedFacilityDetails.facility.id);
      setRequestorGrid(!showRequestorGrid);
      setShowAddNewRequestor(false);
    }
    else if (props && props.transactionDetails?.transactionRequest?.isInternalRequest) {
      fetchAllSearchRecords(props.selectedFacilityDetails.facility.id);
      setRequestorGrid(!showRequestorGrid);
      setShowAddNewRequestor(false);
    }
    else {
      //advanceSearchCounter checks if any of the following search fields are empty
      let advanceSearchCounter = 0;
      advanceSearchCounter = values[RequestorFormConstants.SEARCH_ADDRESS] ? advanceSearchCounter + 0 : advanceSearchCounter + 1;
      advanceSearchCounter = values[RequestorFormConstants.SEARCH_ZIP] ? advanceSearchCounter + 0 : advanceSearchCounter + 1;
      advanceSearchCounter = values[RequestorFormConstants.SEARCH_CONTACT_NAME] ? advanceSearchCounter + 0 : advanceSearchCounter + 1;
      advanceSearchCounter = values[RequestorFormConstants.SEARCH_REQUESTOR_NAME] ? advanceSearchCounter + 0 : advanceSearchCounter + 1;

      if (advanceSearchCounter > 2 && showAdvanceRequestorSearch) {
        setErrorMessage("At least two values are required to search.")
      }
      else if (isValid(values[RequestorFormConstants.PHONE_FAX_NUMBER]).display_error && isValid(values[RequestorFormConstants.EMAIL_ADDRESS]).display_error && !showAdvanceRequestorSearch) {
        setErrorMessage("At least one value is required to search.")
      }
      else {
        fetchAllSearchRecords(props.selectedFacilityDetails.facility.id);
        setRequestorGrid(!showRequestorGrid);
      }
      setShowAddNewRequestor(true);
    }


  }

  const onClickPatientSerach = () => {
    if (isValid(values[FormConstants.SEARCH_LAST_NAME]).display_error) {
      setLastNameError('Last Name or MRN is required.');
    }
    if (isValid(values[FormConstants.SEARCH_DOB]).display_error) {
      setDOBError("DOB is required.")
    }
    const searchDOB = values[FormConstants.SEARCH_DOB]
    let isValidDob = true
    if (searchDOB === INVALID_DATE || (searchDOB && typeof searchDOB === "object" && isNaN(searchDOB.getTime()))) {
      isValidDob = false
    } else if (searchDOB.getTime && searchDOB.getTime() > futureDate.getTime()) {
      isValidDob = false
    } else if (searchDOB.getTime && searchDOB.getTime() < patientMinDOB.getTime()) {
      isValidDob = false
    }
    if (!isValid(values[FormConstants.SEARCH_LAST_NAME]).display_error && !isValid(searchDOB).display_error && isValidDob) {
      setPatientGrid(true)
      let lastName = values[FormConstants.SEARCH_LAST_NAME];
      let dob = moment(new Date(values[FormConstants.SEARCH_DOB])).format("MM/DD/YYYY")
      props && props.getPatientListSearchDetails(props.selectedFacilityDetails.facility.id, lastName, dob)
    }
  }

  const headCellsRequestor = [
    { id: 'comments', label: '', commentField:true },
    { id: 'en', label: 'E/N' },
    { id: 'requestorName', label: 'REQUESTOR NAME' },
    { id: 'address', label: 'Address' },
    { id: 'city', label: 'City' },
    { id: 'state', label: 'State' },
    { id: 'zip', label: 'Zip' },
  ];

  const headCellsContact = [
    { id: 'firstName', label: 'FIRST NAME' },
    { id: 'lastName', label: 'LAST NAME' },
    { id: 'phone', label: 'PHONE' },
    { id: 'fax', label: 'FAX' },
    { id: 'email', label: 'EMAIL' },
  ];

  const headCellsPatient = [
    { id: 'firstName', label: 'FIRST NAME' },
    { id: 'lastName', label: 'LAST NAME' },
    { id: 'dob', label: 'DOB' },
    { id: 'empi', label: 'EMPI' },
  ];

  const normalizedTabItems: InormalizedType = normalizeData(tabItems, "id");
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisableField, setDisableField] = useState(true);
  const [isRequestorFieldEnable, setEnableRequestorField] = useState(false)
  const [selectedPatientId, setSelectedPatientId] = useState(0)

  const setRtrError = (errMsg?: string) => 
    props.updateFormSyncErrors && 
    props.updateFormSyncErrors({ 
      [FormConstants.RETRIEVE_RECORD_TYPE]: 
      !_.isNil(errMsg) ? 
      errMsg : 'At least one of the records and date range must be selected.' 
    })
    
  const setSensitiveAccordionErrMsg = (errMsg?: string) => 
    props.updateFormSyncErrors && 
    props.updateFormSyncErrors({ 
      [FormConstants.SENSITIVE_INFORMATION]: !_.isNil(errMsg) 
        ? errMsg 
        : 'Sensitive information selection must be made.' 
    })
  const setAdditionalChargesErr = (errMsg?: string) => props.updateFormSyncErrors && props.updateFormSyncErrors({ [BillingFormconstants.ADDITIONAL_CHARGES]: !_.isNil(errMsg) ? errMsg : "Required" })
  const [showRequestorGrid, setRequestorGrid] = useState(false);
  const [showContactGrid, setContactGrid] = useState(false);
  const [showPatientGrid, setPatientGrid] = useState(false);

  const [contactRecords, setContactRecords] = useState([]);

  const [scrollTo, setScrollTo] = useState("");
  const { activeTabId, onChangeDataEntryTab } = props;

  const [values, setValues] = useState({})
  useEffect(() => {
    setValues(props.formState.values || {});
  }, [props.formState?.values]);
  
  const [addNewContact, setAddNewContact] = useState(false);
  const [addNewPatient, setAddNewPatient] = useState(false);
  const [lastNameError, setLastNameError] = useState("");
  const [DOBError, setDOBError] = useState("");
  const [editContactRequestorID, setEditContactRequestorID] = useState(0);
  const [showCompleteTransactionPopUp, setCompleteTransactionPopUp] = useState(false);
  const [showCompleteReviewPopUp, setShowCompleteReviewPopUp] = useState(false);
  const [showQAPDIssuePopUp, setShowQAPDIssuePopUp] = useState(false);
  const [wait, setWait] = useState(false)
  const [createNewTransactionComplete, setCreateNewTransactionComplete] = useState(false)
  const [closeTransactionComplete, setCloseNewTransactionComplete] = useState(false)

  const [searchPhone, setSearchPhone] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchZip, setSearchZip] = useState('');
  const [searchAddress, setSearchAddress] = useState('');
  const [searchName, setSearchName] = useState('');
  const [auditTypesOptions, setAuditTypesOptions] = useState([] as any);
  const [onBehalfOfOptions, setOnBehalfOfOptions] = useState([] as any);

  const requestTypeValue = values ? values[FormConstants.REQUEST_TYPE] : ''
  const purposeTypeValue = values ? values[FormConstants.PURPOSE_TYPE] : ''
  const billingAdditionalChargeValue = values ? values[BillingFormconstants.ADDITIONAL_CHARGES] : ''

  const [sensitiveInfo, setSensitiveInfo] = useState<any>(null);
  const prevSensitiveInfo = useRef<any>(null);

  const [releaseInfo, setReleaseInfo] = useState<any>(null);
  const [searchRequestorName, setSearchRequestorName] = useState('');
  const [searchContactName, setSearchContactName] = useState('');

  const [enableEditReqContact, setEnableEditReqContact] = useState(false);

  const [requiredContactError, setRequiredContactError] = useState("");

  useEffect(() => {
    if (props.transactionDetails?.releaseInfo){
      let release = [...props.transactionDetails.releaseInfo]
      if (release.some(i => i.id === 31) && props.transactionDetails?.requestComments){
        release = [...release.filter(i => i.id !== 31),
          {...release.find(i => i.id === 31),
            freeText: props.transactionDetails?.requestComments
          }
        ]
      }

      setReleaseInfo(release);
    }
  }, [props.transactionDetails?.releaseInfo, props.transactionDetails?.requestComments]);


  const disclosesensitiveInfo = values ? values[FormConstants.DISCLOSE_ALL_INFO] : '';
  const doNotDisclosesensitiveInfo = values ? values[FormConstants.DONT_DISCLOSE_ANY_INFO] : '';
  const rtrFromDate = values ? values[FormConstants.RTR_FROM] : '';
  const rtrToDate = values ? values[FormConstants.RTR_TO] : '';
  const allDates = values ? values[FormConstants.RTR_ALL] : '';
  const retrieveRecord = values ? values[FormConstants.RETRIEVE_RECORD_TYPE] : {};
  const additionalCharges = values ? values[BillingFormconstants.ADDITIONAL_CHARGES] : {}
  const [showAdvanceRequestorSearch, setShowAdvanceRequestorSearch] = useState(false)

  //dds field error messages
  const [rqidErrorMessage, setRqidErrorMessage] = useState("");
  const [ssnErrorMessage, setSsnErrorMessage] = useState("");
  const [rqSTxiteCodeErrorMessage, setRqSiteCodeErrorMessage] = useState("");
  const [docTypeErrorMessage, setDocTypedErrorMessage] = useState("");
  const [rfErrorMessage, setRfErrorMessage] = useState("");
  const [drErrorMessage, setDrErrorMessage] = useState("");
  const [noBarcodeErrorMessage, setnoBarcodeErrorMessage] = useState("");
  const [isDisableDDSField, setDisableDDSField] = useState(false);
  const [showAddNewRequestor, setShowAddNewRequestor] = useState(true);
  const [reqDocId, setReqDocId] = useState(null);
  const [disableFeeScheduleDropDown, setDisableFeeScheduleDropdown] = useState(false);
  const [disableRealeaseMethodDropDown, setDisableRealeaseMethodDropdown] = useState(false);
  const [disableShippingDropDown, setDisableShippingDropdown] = useState(false);
  const checkIfReqDocumentIsUploaded = () => {
    props.documentsList && props.documentsList
      .forEach((el) => el.id.includes('REQ') ? setReqDocId(el.id) : setReqDocId(null))
    console.log(props.documentsList)
  }

  const [sensitiveInfoLabels, setSensitiveInfoLabels] = useState<string[]>([])
  const [showDigitalFax, setShowDisgitalFax] = useState(props.transactionDetails?.releaseMethod?.id === 3 ? true : false);
  const [sensitiveInfoOkHandlerValue, setSensitiveInfoOkHandlerValue] = useState("");

  const [contactsLoaded, setContactsLoaded] = useState(false);
  
  useEffect(() => {
    untouch(RequestorFormConstants.REQUESTOR_CONTACT);
  },[])

  useEffect(() => {
    if (FormConstants)
      setSensitiveInfoLabels([
        FormConstants.GENETIC_INFORMATION,
        FormConstants.HIV,
        FormConstants.MENTAL_HEALTH,
        FormConstants.STD,
        FormConstants.SUBSTANCE_ABUSE
      ]);
  }, [FormConstants]);

  useEffect(() => {
    if (props.auditTypes.length === 0) {
      props.getAuditTypesRequest && props.getAuditTypesRequest();
    } else {
      const ato = pickerHelperFunction(props.auditTypes, "titleText", "auditTypeID");
      ato.unshift({ value: DEFAULT_DROPDOWN_VALUE, label: DEFAULT_DROPDOWN_NAME })
      setAuditTypesOptions(ato);
    }
  }, [props.auditTypes]);

  useEffect(() => {
    if (props.onBehalfOf.length === 0) {
      props.getOBOsRequest && props.getOBOsRequest();
    } else {
      const oboo = pickerHelperFunction(props.onBehalfOf, "titleText", "oboid");
      oboo.unshift({ value: DEFAULT_DROPDOWN_VALUE, label: DEFAULT_DROPDOWN_NAME })
      setOnBehalfOfOptions(oboo);
    }
  }, [props.onBehalfOf]);

  useEffect(() => {
    if (props.selectedPHICodes.length) {
      let obj = {};
      if (props.selectedPHICodes) {
        props.selectedPHICodes.map(PHIData => {
          if (PHIData.isRetrieved) {
            obj = { ...obj, [PHIData.requestedItemID]: { code: PHIData.code, description: PHIData.description } }

          }
          return null
        })
      }
      changeFieldValue(FormConstants.PHI, obj)
    }
  }, [props.selectedPHICodes])

  useEffect(() => {
    if (Object.keys(props.additionalChargeRecords).length) {
      changeFieldValue(BillingFormconstants.ADDITIONAL_CHARGES, props.additionalChargeRecords)
    }
  }, [props.additionalChargeRecords])

  useEffect(() => {
    changeFieldValue(BillingFormconstants.RELEASE_METHODS, props.transactionDetails?.releaseMethod?.id);
    if(props && props.transactionDetails?.releaseMethod?.id === 2){
      setDisableShippingDropdown(true);
    }
    else{
      setDisableShippingDropdown(false);
    }
    if(props && props.transactionDetails?.releaseMethod?.id === 3){
      setShowDisgitalFax(true);
    }
    else{
      setShowDisgitalFax(false);
    }
  }, [props.transactionDetails?.releaseMethod?.id])
  
  useEffect(() => {
    changeFieldValue(BillingFormconstants.FEE_SCHEDULES, props.transactionDetails?.feeSchedule?.id);
  }, [props.transactionDetails?.feeSchedule?.id])

  useEffect(() => {
    if (props.transactionDetails?.shippingService != undefined && Object.keys(props.transactionDetails?.shippingService).length) {
      changeFieldValue(BillingFormconstants.SHIPPING_SERVICES, props.transactionDetails?.shippingService)
    }
  }, [props.transactionDetails?.shippingService])

  useEffect(() => {
    if(props.transactionDetails?.transactionRequest?.isInternalRequest)
    {
      setDisableFeeScheduleDropdown(true);
      setDisableRealeaseMethodDropdown(true);
      setDisableShippingDropdown(true);
    }
    else{
      setDisableFeeScheduleDropdown(false);
      setDisableRealeaseMethodDropdown(false);
      if(props && props.transactionDetails?.releaseMethod?.id === 2){
        setDisableShippingDropdown(true);
      }
      else{
        setDisableShippingDropdown(false);
      }
    }
  }, [props.transactionDetails?.transactionRequest?.isInternalRequest])

  useEffect(() => {
    if (props.canEditPatient) {
      setDisableField(false)
    }
    else {
      setDisableField(true)
    }
  }, [props.canEditPatient])

  // useEffect(() => {
  //   if (props.canEditPatient && props.patientDetailsUpdated && isDisableField) {
  //     let patientDataid = selectedPatientId || props.transactionDetails?.patient?.id || null
  //     let patientName = values[FormConstants.PATIENT_NAME];
  //     let patientLastName = values[FormConstants.PATIENT_LAST_NAME];
  //     if (patientName && patientLastName) {
  //       let patientDetails = {
  //         patientDataid: patientDataid,
  //         transactionId: props.transactionId,
  //         [FormConstants.PATIENT_NAME]: patientName,
  //         [FormConstants.PATIENT_MIDDLE_NAME]: values[FormConstants.PATIENT_MIDDLE_NAME] || "",
  //         [FormConstants.PATIENT_LAST_NAME]: patientLastName,
  //         [FormConstants.DOB]: getMomentDate(values[FormConstants.DOB]),
  //         [FormConstants.EMPI_NUMBER]: values[FormConstants.EMPI_NUMBER] || "",
  //         [FormConstants.MRN_ID]: values[FormConstants.MRN_ID] || ""
  //       }
  //       props.patchPatientDetailsCreator && props.patchPatientDetailsCreator(patientDetails);
  //     }
  //   }
  // })

  useEffect(() => {
    if (values && (values[RequestorFormConstants.NO_BARCODE] === undefined || !values[RequestorFormConstants.NO_BARCODE])) {
      if (props.transactionDetails?.transactionDds?.ssn !== undefined && props.transactionDetails?.transactionDds?.ssn.length) {
        const ssn = values ? values[RequestorFormConstants.SSN] : '';
        maskSSNInput(ssn);
      }
    }

  }, [values !== undefined && props.activeTabId === 2 && props.formState.values && FormConstants && props.formState.values[FormConstants.REQUEST_TYPE] === '31']);

  useEffect(() => {
    setErrorMessage("")
    setDOBError("")
    setLastNameError("")
    if (activeTabId === 3) {
      props.checkDuplicates && props.checkDuplicates(props.transactionId, values[FormConstants.CASE_NUMBER])
    }
    if(activeTabId !== 1){
      setEnableEditReqContact(false)
    }
  }, [activeTabId])

  useEffect(() => {
    if(props.requestorDetails?.requestorInfo?.id){
      props.setLastUsedRequestorInfo(
        {
          requestorName: props.requestorDetails?.fullName,
          address1: props.requestorDetails?.requestorAddress?.address1,
          address2: props.requestorDetails?.requestorAddress?.address2,
          city: props.requestorDetails?.requestorAddress?.city,
          state: props.requestorDetails?.requestorAddress?.state,
          zip: props.requestorDetails?.requestorAddress?.zipCode,
          firstName: props.requestorDetails?.requestorInfo?.firstName,
          lastName: props.requestorDetails?.requestorInfo?.lastName,
          email: props.requestorDetails?.requestorInfo?.email,
          phone: props.requestorDetails?.requestorInfo?.phone,
          fax: props.requestorDetails?.requestorInfo?.fax,
          requestorContactId: props.requestorDetails?.requestorInfo?.id,
          requestorId: props.requestorDetails?.requestorID,
          requestPurpose: props.transactionDetails?.requestPurpose,
          org: props.selectedFacilityDetails.facility?.id,
          transactionId: props.transactionId
        });
    }
  },
  [props.requestorDetails?.requestorInfo?.id])

  useEffect(() => {
    if (props.validationPassed) {
      if (props.transactionStatus?.id >= 130 && props.transactionStatus?.id <= 141) {
        props.getQAPDIssues(props.transactionId);
        if (props.qaPDIssues && props.qaPDIssues.length > 0) {
          setShowQAPDIssuePopUp(true);
        } else {
          setShowCompleteReviewPopUp(true);
        }
      } else {
        setCompleteTransactionPopUp(true);
      }
    } else {
      setCompleteTransactionPopUp(false);
      setShowCompleteReviewPopUp(false);
      setShowQAPDIssuePopUp(false);
    }
  }, [props.validationPassed])

  useEffect(() => {
    if (requestTypeValue && requestTypeValue !== '66' && sensitiveInfoLabels.length > 0 && props.transactionDetails?.sensitiveInfo){
      if (props.transactionDetails?.sensitiveInfo.every(i => i.stateID === 2))
        return;

      const newSensitiveInfo = {};
      sensitiveInfoLabels.forEach(l => {
          const item = props.transactionDetails.sensitiveInfo.find(i => i.name === l);
          if(item)
            newSensitiveInfo[l] = item.stateID === 1 ? {recordType: l} : null;
        }
      );

      if ((prevSensitiveInfo?.current === null) !== (newSensitiveInfo === null) ||  
          !sensitiveInfoLabels.every(l => ((prevSensitiveInfo[l] === null) === (newSensitiveInfo[l] === null)))
      ){

        if (!props.isNewTransaction ||
          (props?.formState && FormConstants && props.formState[FormConstants.DONT_DISCLOSE_ANY_INFO]) ||
          props.transactionDetails.sensitiveInfo.some(i => i.stateID) ||
          Object.keys(newSensitiveInfo).some(i => newSensitiveInfo[i] !== null)
        ){
          saveSensitiveInfo(newSensitiveInfo)
      

          prevSensitiveInfo.current = newSensitiveInfo;
        }
      }
    }
  },[sensitiveInfoLabels, props.transactionDetails?.sensitiveInfo, props.intiaTxlSagaCallCount >= 20]);

  useEffect(() => { 
    if (props.updateDeliveryInstructionsStatus === API_SUCCESS){
      toast.success("Delivery Instructions Saved");
    } if (props.updateDeliveryInstructionsStatus === API_FAILURE) {
      toast.error("Delivery Instructions Not Saved")
    }
  }, [props.updateDeliveryInstructionsStatus])
  // // //barcode dds reader call
  useEffect(() => {
    console.log("update")
    if (props.transactionDDS?.rqid) {
      changeFieldValue(RequestorFormConstants.RQID, props.transactionDDS?.rqid)
    }

    if (props.transactionDDS?.txSiteCode) {
      changeFieldValue(RequestorFormConstants.TX_SITE_CODE, props.transactionDDS?.txSiteCode)
      changeFieldValue(RequestorFormConstants.RQ_SITE_CODE, props.transactionDDS?.txSiteCode)
    }

    if (props.transactionDDS?.docReturnCode) {
      changeFieldValue(RequestorFormConstants.DOC_RETURN_CODE,props.transactionDDS?.docReturnCode)
    }

    if (props.transactionDDS?.ssn) {
      changeFieldValue(RequestorFormConstants.SSN, '***-**-****')
    }

    if (props.transactionDDS?.docType) {
      changeFieldValue(RequestorFormConstants.DOC_TYPE, props.transactionDDS?.docType)
    }

    if (props.transactionDDS?.routingField) {
      changeFieldValue(RequestorFormConstants.ROUTING_FIELD, props.transactionDDS?.routingField)
    }

  }, [props.transactionDDS, props?.readBarCodeDDSAPIStatus == API_SUCCESS]);
  
  useEffect(() => { 
    setDisableDDSField(!!values[RequestorFormConstants.NO_BARCODE])
  }, [values[RequestorFormConstants.NO_BARCODE]])

  useEffect(() => {
    if(scrollTo){
      setTimeout(() => {
        const element = document.getElementById(scrollTo);
        element && element.scrollIntoView(true);
        setScrollTo("");
      });
    }
  },[props.activeTabId])


  useEffect(() => {
    const sensitiveInfo = values[FormConstants.SENSITIVE_INFORMATION];

    if (sensitiveInfo && Object.keys(sensitiveInfo).length > 0) {
      const total = Object.keys(sensitiveInfo).reduce((acc, cur) => {
        return acc + (sensitiveInfo[cur] === null ? 0 : 1)
      }, 0);

      props.formState.values[FormConstants.DISCLOSE_ALL_INFO] = (total === 5);
      if (props.isNewTransaction && props.formState.values[FormConstants.REQUEST_TYPE] === "-9999") {
        props.formState.values[FormConstants.DONT_DISCLOSE_ANY_INFO] = false;
      } else {
        props.formState.values[FormConstants.DONT_DISCLOSE_ANY_INFO] = (total === 0);
      }

      changeFieldValue(FormConstants.DISCLOSE_ALL_INFO, total === 5);
      changeFieldValue(FormConstants.DONT_DISCLOSE_ANY_INFO, total === 0);


    }

  }, [values[FormConstants.SENSITIVE_INFORMATION]]);

  useEffect(() => { 
    props.getFacilityReleaseMethods && props.transactionDetails?.facility?.id && props.transactionId && 
    props.getFacilityReleaseMethods(props.transactionDetails.facility.id, props.transactionId)
  }, [editContactRequestorID, props.transactionDetails?.sendToContact?.id])

  useEffect(() => {
    setContactsLoaded(props.getContactSearchDetailsAPIStatus === API_SUCCESS && props.requestorContactDetails !== null)
  }, [props.getContactSearchDetailsAPIStatus, props.requestorContactDetails])


  useEffect(() => {
    const vals = { contactsLoaded, showContactGrid }
  }, [contactsLoaded, showContactGrid])

  useEffect(() => {
    const formSyncErrors = props.formSyncErrors || {}
    if (props.transactionDetails?.releaseInfo?.length && retrieveRecord && (((rtrFromDate && rtrFromDate !== "Invalid Date") && (rtrToDate && rtrToDate !== "Invalid Date")) || allDates)) {
      formSyncErrors[FormConstants.RETRIEVE_RECORD_TYPE] && formSyncErrors[FormConstants.RETRIEVE_RECORD_TYPE] !== "" && setRtrError("")
    }
    if (sensitiveInfo || disclosesensitiveInfo || doNotDisclosesensitiveInfo) {
      formSyncErrors[FormConstants.SENSITIVE_INFORMATION] && formSyncErrors[FormConstants.SENSITIVE_INFORMATION] !== "" && setSensitiveAccordionErrMsg("")
    }
    if (additionalCharges && Object.keys(additionalCharges).length) {
      let unfilledColumns = Object.keys(additionalCharges).filter((item: any) => {
        const rowData = additionalCharges[item]
        return (isValid(rowData.glCodeID).display_error || rowData.glCodeID === DEFAULT_DROPDOWN_VALUE || isValid(rowData.chargeAmount).display_error || isValid(rowData.description).display_error)
      })
      if (unfilledColumns.length === 0) {
        formSyncErrors[BillingFormconstants.ADDITIONAL_CHARGES] && formSyncErrors[BillingFormconstants.ADDITIONAL_CHARGES] !== "" && setAdditionalChargesErr("")
      }
    }
  }, [sensitiveInfo && disclosesensitiveInfo && doNotDisclosesensitiveInfo &&
    retrieveRecord && rtrFromDate && rtrToDate && allDates, ...Object.keys(sensitiveInfo ?? {}).map(s => sensitiveInfo[s])])

  

  const onCheck = (value: boolean) => {
    if (value) {
      const requestorName = values[RequestorFormConstants.REQUESTOR_NAME]
      const address1 = values[RequestorFormConstants.ADDRESS],
        city = values[RequestorFormConstants.CITY],
        state = values[RequestorFormConstants.STATE],
        zip = values[RequestorFormConstants.ZIP],
        email = values[RequestorFormConstants.EMAIL],
        phone = values[RequestorFormConstants.PHONE_NUMBER],
        fax = values[RequestorFormConstants.FAX_NUMBER],
        updatedValues = {
          [BillingFormconstants.BILL_TO_REQUESTOR_NAME]: requestorName,
          [BillingFormconstants.BILL_TO_ADDRESS_LINE1]: address1,
          [BillingFormconstants.BILL_TO_STATE]: state,
          [BillingFormconstants.BILL_TO_ZIP]: zip,
          [BillingFormconstants.BILL_TO_CITY]: city,
          [BillingFormconstants.BILL_TO_FIRST_NAME]: values[RequestorFormConstants.FIRST_NAME] ? values[RequestorFormConstants.FIRST_NAME] : null,
          [BillingFormconstants.BILL_TO_LAST_NAME]: values[RequestorFormConstants.LAST_NAME] ? values[RequestorFormConstants.LAST_NAME] : null,
          [BillingFormconstants.BILL_TO_ADDRESS_LINE2]: values[RequestorFormConstants.ADDRESS2],
          [BillingFormconstants.BILL_TO_EMAIL_ADDRESS]: email ? email : null,
          [BillingFormconstants.BILL_TO_FAX]: fax ? fax: null,
          [BillingFormconstants.BILL_TO_PHONE]: phone ? phone : null,
          [BillingFormconstants.REQUIRES_PREPAYMENT]: values[BillingFormconstants.REQUIRES_PREPAYMENT],
          requestorNameId: props.requestorDetails.requestorID
        }
      changeFieldValue(BillingFormconstants.BILL_TO_REQUESTOR_NAME, requestorName)
      changeFieldValue(BillingFormconstants.BILL_TO_FIRST_NAME, values[RequestorFormConstants.FIRST_NAME])
      changeFieldValue(BillingFormconstants.BILL_TO_LAST_NAME, values[RequestorFormConstants.LAST_NAME])
      changeFieldValue(BillingFormconstants.BILL_TO_ADDRESS_LINE1, address1)
      changeFieldValue(BillingFormconstants.BILL_TO_ADDRESS_LINE2, values[RequestorFormConstants.ADDRESS2])
      changeFieldValue(BillingFormconstants.BILL_TO_CITY, city)
      changeFieldValue(BillingFormconstants.BILL_TO_STATE, state)
      changeFieldValue(BillingFormconstants.BILL_TO_EMAIL_ADDRESS, values[RequestorFormConstants.EMAIL])
      changeFieldValue(BillingFormconstants.BILL_TO_FAX, values[RequestorFormConstants.FAX_NUMBER])
      changeFieldValue(BillingFormconstants.BILL_TO_PHONE, values[RequestorFormConstants.PHONE_NUMBER])
      changeFieldValue(BillingFormconstants.BILL_TO_ZIP, zip)
      if (requestorName?.length && address1?.length && city?.length && state?.length && zip?.length) {
        /* Make api call */
        let data = {
          requestorbillingcontactid: props.transactionDetails.billToContact?.id || null,
          data: generateUpdateBillToRequestorObject(updatedValues)
        }
        props && props.updateBillToRequestorData(data)
      }
    }
    else {
      changeFieldValue(BillingFormconstants.BILL_TO_REQUESTOR_NAME, '')
      changeFieldValue(BillingFormconstants.BILL_TO_FIRST_NAME, '')
      changeFieldValue(BillingFormconstants.BILL_TO_LAST_NAME, '')
      changeFieldValue(BillingFormconstants.BILL_TO_ADDRESS_LINE1, '')
      changeFieldValue(BillingFormconstants.BILL_TO_ADDRESS_LINE2, '')
      changeFieldValue(BillingFormconstants.BILL_TO_CITY, '')
      changeFieldValue(BillingFormconstants.BILL_TO_STATE, '')
      changeFieldValue(BillingFormconstants.BILL_TO_EMAIL_ADDRESS, '')
      changeFieldValue(BillingFormconstants.BILL_TO_FAX, '')
      changeFieldValue(BillingFormconstants.BILL_TO_PHONE, '')
      changeFieldValue(BillingFormconstants.BILL_TO_ZIP, '')
    }
  }

  const allDatesCheck = () => {
    if (!values[FormConstants.RTR_ALL]) {
      changeFieldValue(FormConstants.RTR_FROM, null)
      changeFieldValue(FormConstants.RTR_TO, null)
    }
  }

  const goToNextTransaction = () => {
    if (props.requestContext == 8) {
      props.getNextQATransactionCall?.();
    } else {
      props.getNextTransactionId(props.facilityId, props.requestContext ?? 1);
    }
  };

  const onBlurRequestorTabFields = (value: string, fieldName: string, eventObj) => {
    console.log(`from onBlurRequestorTabFields, add new contact ${addNewContact}, value ${value}, fieldname ${fieldName}, ${eventObj}`)
    if(value.length < 1 && fieldName !== RequestorFormConstants.FAX_NUMBER){
      return;
    }

    const requestorName = values[RequestorFormConstants.REQUESTOR_NAME]
    const address1 = values[RequestorFormConstants.ADDRESS]
    const city = values[RequestorFormConstants.CITY]
    const state = values[RequestorFormConstants.STATE]
    const zip = values[RequestorFormConstants.ZIP]
    const firstName = values[RequestorFormConstants.FIRST_NAME]
    const lastName = values[RequestorFormConstants.LAST_NAME]
    const phone = values[RequestorFormConstants.PHONE_NUMBER]
    const email = values[RequestorFormConstants.EMAIL];
    const fax = values[RequestorFormConstants.FAX_NUMBER]

    if (isRequestorFieldEnable && requestorName?.length && address1?.length && city?.length && state?.length && zip?.length) {
      let data = {
        requestType: requestTypeValue,
        transactionID: props.transactionId,
        data: generateSendToRequestorObject(values) //put
      }
      props && props.sendToRequestorData(data)
      setEnableRequestorField(false)  
    } else if (!addNewContact) {
      let data = {
        requestType: requestTypeValue,
        data: generateSendToRequestorContactObject(values, props.transactionId)
      }
      data.data['requestorcontactid'] = props.requestorDetails.requestorInfo.id || null
      props.updateRequestorContactInfo && props.updateRequestorContactInfo(data)
      props && props.patchSendToRequestorContactData(data)
    }

    if (addNewContact) {
      
      if(firstName?.length || lastName?.length || phone?.length || email?.length || fax?.length){
        let data = {
          requestType: requestTypeValue,
          data: generateSendToContactObject(values),
          requestorID: props.requestorDetails.requestorID|| null,
          transactionID: props.transactionId,
          requestorDetails: { ...generateUpdateSendToRequestorObject(values), requestorcontactid: props.requestorDetails.requestorInfo.id || null },
        }
        data.data['transactionid'] = props.transactionId
        data.requestorID && props && props.sendToContactData(data)
        props.updateRequestorContactInfo && props.updateRequestorContactInfo(data)
        setAddNewContact(false)
        onChangeDetailsOrBillingPaneField(2, BillingFormconstants.RELEASE_METHODS, true)
      }
    }

  }

  const onBlurDisclosureInformationFields = (value, fieldName) => {
    let updatedValues = { ...values }
    updatedValues[fieldName] = value
    const disclosureReason = updatedValues[FormConstants.DISCLOSURE_REASON]
    const disclosureDate = updatedValues[FormConstants.DISCLOSURE_DATE]
    const disclosureDateFormatted = new Date(updatedValues[FormConstants.DISCLOSURE_DATE])

    if (disclosureReason && disclosureReason !== DEFAULT_DROPDOWN_VALUE && disclosureDate && disclosureDate !== INVALID_DATE && !isNaN(disclosureDateFormatted.getTime()) && isDateSqlOverflow(disclosureDate)) {
      let data = {
        transactionID: props.transactionId,
        data: generateUpdateDisclosureInformationObject(updatedValues)
      }
      props && props.updateDisclosureInformationDetails(data)
    }
  }

  const onBlurBillingPangeFields = (value: any, fieldName: string) => {
    const requestorName = values[BillingFormconstants.BILL_TO_REQUESTOR_NAME]
    const address1 = values[BillingFormconstants.BILL_TO_ADDRESS_LINE1]
    const city = values[BillingFormconstants.BILL_TO_CITY]
    const state = values[BillingFormconstants.BILL_TO_STATE]
    const zip = values[BillingFormconstants.BILL_TO_ZIP]
    let updatedValues = {
      ...values,
      [fieldName]: value,
      requestorNameId: props.requestorDetails.requestorID
    }
    if ((requestorName?.length && address1?.length && city?.length && state?.length && zip?.length) || 
      fieldName === BillingFormconstants.REQUIRES_PREPAYMENT || fieldName === BillingFormconstants.DELIVERY_INSTRUCTIONS) {
      /* Make api call */
      let data = {
        requestorbillingcontactid: props.transactionDetails.billToContact?.id || null,
        data: generateUpdateBillToRequestorObject(updatedValues)
      }
      if (fieldName === BillingFormconstants.DELIVERY_INSTRUCTIONS) {
        data.data["deliveryInstructions"] = encodeURI(value)
      }
      props && props.updateBillToRequestorData(data)
    }
  }


  const updateRequestAndPurposeType = (value: string) => {
    const { requestTypes } = props
    let requestTypeId = DEFAULT_DROPDOWN_VALUE
    value !== requestTypeId && Object.keys(requestTypes).map((key) => {
      if (requestTypeId === DEFAULT_DROPDOWN_VALUE && requestTypes[key]["requestPurpose"].indexOf(parseInt(value)) > -1) {
        requestTypeId = requestTypes[key]["id"]
      }
      return null
    })
    changeFieldValue(FormConstants.REQUEST_TYPE, requestTypeId.toString())
    changeFieldValue(FormConstants.PURPOSE_TYPE, value)
    onChangeDetailsOrBillingPaneField(value, FormConstants.PURPOSE_TYPE)
  }

  const changeFieldValue = (fieldName: string, value: any) => {
    if(value === undefined){
      value = '';
    }
    props.dispatch && props.dispatch({
      type: "@@redux-form/CHANGE", payload: value, meta: {
        field: fieldName,
        touch: true, persistentSubmitErrors: false, form: FormConstants.DATA_ENTRY_FIELDS_FORM
      }
    })
  }

  const onChangeRequestorName = (value: any) => {
    changeFieldValue(BillingFormconstants.SEND_TO_REQUESTOR_NAME, value)
  }
  const onChangeFirstName = (value: any, copyToBilling = false) => {
    changeFieldValue(BillingFormconstants.SEND_TO_FIRST_NAME, value);
    copyToBilling && changeFieldValue(BillingFormconstants.BILL_TO_FIRST_NAME, value);
  }
  const onChangeLastName = (value: any, copyToBilling = false) => {
    changeFieldValue(BillingFormconstants.SEND_TO_LAST_NAME, value)
    copyToBilling && changeFieldValue(BillingFormconstants.BILL_TO_LAST_NAME, value);
  }
  const onChangeAddress1 = (value: any) => {
    changeFieldValue(BillingFormconstants.SEND_TO_ADDRESS_LINE1, value)
  }
  const onChangeAddress2 = (value: any) => {
    changeFieldValue(BillingFormconstants.SEND_TO_ADDRESS_LINE2, value)
  }
  const onChangeCity = (value: any) => {
    changeFieldValue(BillingFormconstants.SEND_TO_CITY, value)
  }
  const onChangeState = (value: any) => {
    changeFieldValue(BillingFormconstants.SEND_TO_STATE, value)
  }
  const onChangeZip = (value: any) => {
    changeFieldValue(BillingFormconstants.SEND_TO_ZIP, value)
  }
  const onChangePhone = (value: any, copyToBilling = false) => {
    changeFieldValue(BillingFormconstants.SEND_TO_PHONE, value)
    copyToBilling && changeFieldValue(BillingFormconstants.BILL_TO_PHONE, value);
  }
  const onChangeFax = (value: any, copyToBilling = false) => {
    changeFieldValue(BillingFormconstants.SEND_TO_FAX, value)
    copyToBilling && changeFieldValue(BillingFormconstants.BILL_TO_FAX, value);
  }
  const onChangeEmail = (value: any, copyToBilling = false) => {
    changeFieldValue(BillingFormconstants.SEND_TO_EMAIL_ADDRESS, value)
    copyToBilling && changeFieldValue(BillingFormconstants.BILL_TO_EMAIL_ADDRESS, value);
  }

  //dds fields
  const onChangeRQID = (value: any) => {
    changeFieldValue(RequestorFormConstants.RQID, value)


  }

  const onBlurRQID = (value: any) => {
    if (values[RequestorFormConstants.NO_BARCODE] === undefined || !values[RequestorFormConstants.NO_BARCODE]) {
      const reqObj = {
        transactionId: props.transactionId,
        userID: global.userID,
        RQID: value

      }
      props.updateBillingPaneFields && props.updateBillingPaneFields(reqObj)

    }
  }

  const onChangeRQSiteCode = (value: any) => {

    changeFieldValue(RequestorFormConstants.RQ_SITE_CODE, value)
  }

  const onChangeTXSiteCode = (value: any) => {

    changeFieldValue(RequestorFormConstants.TX_SITE_CODE, value)
  }

  const onBlurTXSiteCode = (value: any) => {
    const reqObj = {
      transactionId: props.transactionId,
      userID: global.userID,
      txSiteCode: value,
      rqSiteCode: value

    }
    if (values[RequestorFormConstants.NO_BARCODE] === undefined || !values[RequestorFormConstants.NO_BARCODE]) {
      props.updateBillingPaneFields && props.updateBillingPaneFields(reqObj)

    }
  }


  const onChangeSSN = (value: any) => {
    // if (value.length > 0 && !/^\d+$/.test(value)) {
    //   setSsnErrorMessage("SSN must be only numerical characters")
    // } else {
    //   setSsnErrorMessage("")

    // }


    changeFieldValue(RequestorFormConstants.SSN, value)
  }

  const onBlurSSN = (value: any) => {
    if (value.length > 0 && value !== '***-**-***') {
      if (values[RequestorFormConstants.NO_BARCODE] === undefined || !values[RequestorFormConstants.NO_BARCODE]) {
        const reqObj = {
          transactionId: props.transactionId,
          userID: global.userID,
          ssn: value

        }
        props.updateBillingPaneFields && props.updateBillingPaneFields(reqObj)
        maskSSNInput(value);
      }
    }
  }

  const onChangeDocType = (value: any) => {
    if (value.length !== 4) {
      setDocTypedErrorMessage("Doctype must be exactly 4 characters long")
    } else {
      setDocTypedErrorMessage("")

    }



    changeFieldValue(RequestorFormConstants.DOC_TYPE, value)
  }

  const onBlurDocType = (value: any) => {
    if (value.length === 4) {
      if (values[RequestorFormConstants.NO_BARCODE] === undefined || !values[RequestorFormConstants.NO_BARCODE]) {
        const reqObj = {
          transactionId: props.transactionId,
          userID: global.userID,
          docType: value
        }
        props.updateBillingPaneFields && props.updateBillingPaneFields(reqObj)
      }
    }
  }

  const onChangeRF = (value: any) => {
    if (!(value.toLowerCase() === "p" || value.toLowerCase() === "d" || value.toLowerCase() === "no rf")) {
      setRfErrorMessage("Routing Field must be either P, D, or No RF")
    } else {
      setRfErrorMessage("")

    }

    changeFieldValue(RequestorFormConstants.ROUTING_FIELD, value)
  }

  const onBlurRF = (value: any) => {
    if (value.toLowerCase() === "p" || value.toLowerCase() === "d" || value.toLowerCase() === "no rf") {
      if (values[RequestorFormConstants.NO_BARCODE] === undefined || !values[RequestorFormConstants.NO_BARCODE]) {
        const reqObj = {
          transactionId: props.transactionId,
          userID: global.userID,
          routingField: value,
        }
        props.updateBillingPaneFields && props.updateBillingPaneFields(reqObj)
      }
    }
  }

  const onChangeDR = (value: any) => {
    if (!(value.toLowerCase() === "f" || value.toLowerCase() === "s" || value.toLowerCase() === "no dr")) {
      setDrErrorMessage("DocReturnCode must be either F, S, or No DR");

    } else {
      setDrErrorMessage("")
    }
    changeFieldValue(RequestorFormConstants.DOC_RETURN_CODE, value)
  }

  const onBlurDR = (value: any) => {
    if (value.toLowerCase() === "f" || value.toLowerCase() === "s" || value.toLowerCase() === "no dr") {
      if (values[RequestorFormConstants.NO_BARCODE] === undefined || !values[RequestorFormConstants.NO_BARCODE]) {
        const reqObj = {
          transactionId: props.transactionId,
          userID: global.userID,
          docReturnCode: value
        }
        props.updateBillingPaneFields && props.updateBillingPaneFields(reqObj)
      }
    }
  }

  const onChangeNoBarcode = (value: any) => {
    // add blank fields for update 
    const reqObj = {
      transactionId: props.transactionId,
      userID: global.userID,
      txSiteCode: "",
      rqSiteCode: "",
      docReturnCode: "",
      routingField: "",
      RQID: "",
      docType: "",
      ssn: "",
      noBarcode: value

    }
    props.updateBillingPaneFields && props.updateBillingPaneFields(reqObj)

    if (value) {
      changeFieldValue(RequestorFormConstants.DOC_RETURN_CODE, "")
      changeFieldValue(RequestorFormConstants.ROUTING_FIELD, "")
      changeFieldValue(RequestorFormConstants.DOC_TYPE, "")
      changeFieldValue(RequestorFormConstants.SSN, "")
      changeFieldValue(RequestorFormConstants.RQID, "")
      changeFieldValue(RequestorFormConstants.TX_SITE_CODE, "")
      changeFieldValue(RequestorFormConstants.RQ_SITE_CODE, "")
      
      changeFieldValue(BillingFormconstants.RELEASE_METHODS, 5)
      onChangeDetailsOrBillingPaneField(5, BillingFormconstants.RELEASE_METHODS)
    }
    else {
      changeFieldValue(RequestorFormConstants.DOC_TYPE, "0001")
    }
    changeFieldValue(RequestorFormConstants.NO_BARCODE, value)
  }


  const resetFieldValue = (fieldName: string) => {
    props.dispatch && props.dispatch(change(FormConstants.DATA_ENTRY_FIELDS_FORM, fieldName, null))
    props.dispatch && props.dispatch(untouch(FormConstants.DATA_ENTRY_FIELDS_FORM, fieldName))
  }

  const onChangeRequestType = (value, prevValue) => {
    changeFieldValue(FormConstants.REQUEST_TYPE, value.target.value);
    if ((value.target.value === '66')) {
      resetFieldValue(FormConstants.PHI)
      changeFieldValue(FormConstants.PURPOSE_TYPE, '43')
      onChangeDetailsOrBillingPaneField("43", FormConstants.PURPOSE_TYPE, true)
    } else {
      changeFieldValue(FormConstants.PURPOSE_TYPE, DEFAULT_DROPDOWN_VALUE)
    }

    let reqDoc = props.documentsList?.filter(el => el.id.includes('REQ'))
    if (reqDoc.length === 1 && value.target.value === '31') {
      console.log(reqDoc[0].id);
      props.readBarCodeDDSRequest && props.readBarCodeDDSRequest(reqDoc[0].id);
    }

    const isAudit = value.target.value === '53';

    if (isAudit) {
      const allSensitiveInfo = {}

      sensitiveInfoLabels.forEach(s => {
        allSensitiveInfo[s] = { recordType: s }
      });

      saveSensitiveInfo(allSensitiveInfo);
    }
  }

  const onChangePurposeOfRequest = (event) => {
    let value = event.target.value
    if (value === DEFAULT_DROPDOWN_VALUE || (values && values[FormConstants.REQUEST_TYPE] !== DEFAULT_DROPDOWN_VALUE)) {
      changeFieldValue(FormConstants.PURPOSE_TYPE, value)
      onChangeDetailsOrBillingPaneField(value, FormConstants.PURPOSE_TYPE, true)
      return null
    }
    updateRequestAndPurposeType(value);
  }

  const onBtnClick = () => {
    let isValidForm = true
    if (Object.keys(normalizedTabItems).length > activeTabId) {
      // if (requestTypeValue !== DISCLOSURE_EVENT_ID && 
      //     props.transactionDetails?.releaseInfo?.length && 
      //     ((retrieveRecord && (
      //         Object.keys(retrieveRecord).length === 0)) || 
      //         (((!rtrFromDate || rtrFromDate === "Invalid Date") || (!rtrToDate || rtrToDate === "Invalid Date")) && !allDates)
      //       )
      //     ) {
      //   setRtrError()
      //   isValidForm = false
      // }
     
      if (activeTabId === 3 && additionalCharges && Object.keys(additionalCharges).length) {

        let unfilledColumns = Object.keys(additionalCharges).filter((item: any) => {
          const rowData = additionalCharges[item]
          return (isValid(rowData.glCodeID).display_error || rowData.glCodeID === DEFAULT_DROPDOWN_VALUE || isValid(rowData.chargeAmount).display_error || isValid(rowData.description).display_error)
        })
        if (unfilledColumns.length) {
          setAdditionalChargesErr()
          isValidForm = false
        }
      }
      let editFormLocalValidations = localValidations(values, props);
    
      ignoreFields.map(item => delete editFormLocalValidations[item])

      if (values[RequestorFormConstants.REQUESTOR_NAME] && 
          (!values[RequestorFormConstants.FIRST_NAME] &&
          !values[RequestorFormConstants.LAST_NAME] &&
          !values[RequestorFormConstants.EMAIL] &&
          !values[RequestorFormConstants.PHONE_NUMBER] &&
          !values[RequestorFormConstants.FAX_NUMBER])){
        setRequiredContactError("A contact selection must be made.");
        props.touch && props.touch(RequestorFormConstants.REQUESTOR_CONTACT);
      }else{
        delete editFormLocalValidations[RequestorFormConstants.REQUESTOR_CONTACT]
      }

      if (isValidForm && !Object.keys(editFormLocalValidations).length) {
        console.log(`form valid ${isValidForm}`)
        if ((requestTypeValue === DISCLOSURE_EVENT_ID && activeTabId === 2) || activeTabId === 4) { return null }

        if (activeTabId === 1) {
          props.getFacilityFeeSchedules(props.selectedFacilityDetails?.facility?.id, props.transactionId)
          props.getFacilityShippingServices(props.selectedFacilityDetails?.facility?.id);
          props.getFacilityReleaseMethods(props.selectedFacilityDetails?.facility?.id, props.transactionId);
          props.patchDetailsComplete && props.patchDetailsComplete(props.transactionId);
          document.getElementsByClassName('right-pane')[0].scrollTo(0,0);
        }
        else if (activeTabId === 2) {
          changeFieldValue(BillingFormconstants.RELEASE_METHODS, props.transactionDetails?.releaseMethod?.id);
          props.patchRequestorComplete && props.patchRequestorComplete(props.transactionId);
          props.getTransactionAttributes && props.getTransactionAttributes(props.transactionId) //if this is only being done to refresh the status, please delete
          document.getElementsByClassName('right-pane')[0].scrollTo(0,0);
        }
        else if (activeTabId === 3) {
          props.patchBillingComplete && props.patchBillingComplete(props.transactionId);

         
        }
        onChangeDataEntryTab(activeTabId + 1, false)


      }else{
        console.log(`form not valid ${isValidForm}`)
        if(activeTabId == 3 && (editFormLocalValidations[FormConstants.RTR_FROM] || editFormLocalValidations[FormConstants.RTR_TO] || editFormLocalValidations[FormConstants.RETRIEVE_RECORD_TYPE] || editFormLocalValidations[FormConstants.SENSITIVE_INFORMATION])){
          if (requestTypeValue !== '66'&& (!sensitiveInfo || 
            (((Object.keys(sensitiveInfo).length === 0)) && !disclosesensitiveInfo && !doNotDisclosesensitiveInfo) || 
            (Object.keys(sensitiveInfo).every(k => sensitiveInfo[k] === null) && !doNotDisclosesensitiveInfo))) {
            setSensitiveAccordionErrMsg()
            isValidForm = false
          }

          if(editFormLocalValidations[FormConstants.RTR_FROM]) props.updateFormSyncErrors && props.updateFormSyncErrors({ [FormConstants.RTR_FROM]: editFormLocalValidations[FormConstants.RTR_FROM] })

          if(editFormLocalValidations[FormConstants.RTR_TO]) props.updateFormSyncErrors && props.updateFormSyncErrors({ [FormConstants.RTR_TO]: editFormLocalValidations[FormConstants.RTR_TO] })

          if(editFormLocalValidations[FormConstants.RETRIEVE_RECORD_TYPE]) props.updateFormSyncErrors && props.updateFormSyncErrors({ [FormConstants.RETRIEVE_RECORD_TYPE]: editFormLocalValidations[FormConstants.RETRIEVE_RECORD_TYPE] })

          if(editFormLocalValidations[FormConstants.SENSITIVE_INFORMATION]) props.updateFormSyncErrors && props.updateFormSyncErrors({ [FormConstants.SENSITIVE_INFORMATION]: editFormLocalValidations[FormConstants.SENSITIVE_INFORMATION] })


          if (editFormLocalValidations[FormConstants.RTR_FROM] || editFormLocalValidations[FormConstants.RTR_TO] || editFormLocalValidations[FormConstants.RETRIEVE_RECORD_TYPE]) {
            setScrollTo("infotorelease");
          } else if (editFormLocalValidations[FormConstants.SENSITIVE_INFORMATION]) {
            setScrollTo("sensitiveinfo");
          }

          onChangeDataEntryTab(1, false)
        }

      }
      
      props.touch && props.touch(...Object.keys(editFormLocalValidations))
      props.dispatch && props.dispatch(stopSubmit(FormConstants.DATA_ENTRY_FIELDS_FORM, editFormLocalValidations))

    }
    
    sessionStorage.setItem("formValues", JSON.stringify(values))
  };

  const onCompleteTransaction = () => {
    props.setLastUsedRequestorInfo({
      requestorName: props.requestorDetails?.fullName,
      address1: props.requestorDetails?.requestorAddress?.address1,
      address2: props.requestorDetails?.requestorAddress?.address2,
      city: props.requestorDetails?.requestorAddress?.city,
      state: props.requestorDetails?.requestorAddress?.state,
      zip: props.requestorDetails?.requestorAddress?.zipCode,
      firstName: props.requestorDetails?.requestorInfo?.firstName,
      lastName: props.requestorDetails?.requestorInfo?.lastName,
      email: props.requestorDetails?.requestorInfo?.email,
      phone: props.requestorDetails?.requestorInfo?.phone,
      fax: props.requestorDetails?.requestorInfo?.fax,
      requestorContactId: props.requestorDetails?.requestorInfo?.id,
      requestorId: props.requestorDetails?.requestorID,
      requestPurpose: props.transactionDetails?.requestPurpose,
      org: props.selectedFacilityDetails.facility?.id,
      transactionId: props.transactionId
    });
    props.getQAPDIssues(props.transactionId);
    props?.checkValidation && props.checkValidation(props.transactionId)
  }
  const onCompleteTransactionNewRequest = () => {
    setCreateNewTransactionComplete(true);
    onCompleteTransaction();
  }

  const clearPreviousTransactionOnComplete = () => {
    props.clearPreviousTransactionOnComplete && props.clearPreviousTransactionOnComplete();
  }
  const clearUserLocks = () => {
    props.clearUserLocks && props.clearUserLocks();
  };
  const createTransaction = (data) => {
    props.createTransaction && props.createTransaction(data);
  }
  const getConfirmation = (value) => {
  }

  const onCloseCompleteTransaction = () =>{
    setCloseNewTransactionComplete(true);
    onCompleteTransaction();
  }

  const onRequestorTableRowClickHandler = (rowData) => {
    console.log("selected req \n", rowData)
    props.resetRequestorContactList && props.resetRequestorContactList()
    setTimeout(() => {
      if (!values[RequestorFormConstants.PHONE_FAX_NUMBER])
        setSearchPhone("");
      if (!values[RequestorFormConstants.EMAIL_ADDRESS])
        setSearchEmail("");
      if (!values[RequestorFormConstants.SEARCH_ADDRESS])
        setSearchAddress("");
      if (!values[RequestorFormConstants.SEARCH_ZIP])
        setSearchZip("");
      if (!values[RequestorFormConstants.SEARCH_REQUESTOR_NAME])
        setSearchRequestorName("");
      if (!values[RequestorFormConstants.SEARCH_CONTACT_NAME])
        setSearchContactName("");

      /* set the fields here */
      rowData = {
        ...rowData.requestorAddress,
        ...rowData

      }
      props.updateRequestorDetails && props.updateRequestorDetails({
        requestorID: rowData.requestorId,
        requestorInfo: {
          id: rowData.requestorContactId
        }
      });

      if (rowData.requestorId) {
        const requestObject = {
          transactionId: props.transactionId,
          userID: global.userID,
          sendToRequestorid: rowData.requestorId || null,
        }

        if(rowData.requestorContactId){
          requestObject['sendToRequestorContactid'] = rowData.requestorContactId;
        }

        props.updateBillingPaneFields && props.updateBillingPaneFields(requestObject, true)

        toast.loading("Loading Contacts. Please Wait.", {position: 'bottom-right', duration: 5000});
      } else {
        mirrorData("requestorNotes", "")
      }
      
      rowData.requestorId && setEditContactRequestorID(rowData.requestorId);
      setContactRecords(contactRecords);
      
      rowData.firstName ? changeFieldValue(RequestorFormConstants.FIRST_NAME, rowData.firstName) : resetFieldValue(RequestorFormConstants.FIRST_NAME)
      rowData.lastName ? changeFieldValue(RequestorFormConstants.LAST_NAME, rowData.lastName) : resetFieldValue(RequestorFormConstants.LAST_NAME)
      rowData.phone ? changeFieldValue(RequestorFormConstants.PHONE_NUMBER, rowData.phone) : resetFieldValue(RequestorFormConstants.PHONE_NUMBER)
      rowData.fax ? changeFieldValue(RequestorFormConstants.FAX_NUMBER, rowData.fax) : resetFieldValue(RequestorFormConstants.FAX_NUMBER)
      rowData.email ? changeFieldValue(RequestorFormConstants.EMAIL, rowData.email) : resetFieldValue(RequestorFormConstants.EMAIL)

      if (rowData.requestorName) {
        changeFieldValue(RequestorFormConstants.REQUESTOR_NAME, rowData.requestorName)
        changeFieldValue(BillingFormconstants.BILL_TO_REQUESTOR_NAME, rowData.requestorName);
      } else {
        resetFieldValue(RequestorFormConstants.REQUESTOR_NAME);
        resetFieldValue(BillingFormconstants.BILL_TO_REQUESTOR_NAME)
      }

      if (rowData.city) {
        changeFieldValue(RequestorFormConstants.CITY, rowData.city)
        changeFieldValue(BillingFormconstants.BILL_TO_CITY, rowData.city);
      } else {
        resetFieldValue(RequestorFormConstants.CITY);
        resetFieldValue(BillingFormconstants.BILL_TO_CITY)
      }

      if (rowData.zip) {
        changeFieldValue(RequestorFormConstants.ZIP, rowData.zip)
        changeFieldValue(BillingFormconstants.BILL_TO_ZIP, rowData.zip);
      } else {
        resetFieldValue(RequestorFormConstants.ZIP);
        resetFieldValue(BillingFormconstants.BILL_TO_ZIP)
      }

      if (rowData.state) {
        changeFieldValue(RequestorFormConstants.STATE, rowData.state)
        changeFieldValue(BillingFormconstants.BILL_TO_STATE, rowData.state);
      } else {
        resetFieldValue(RequestorFormConstants.STATE);
        resetFieldValue(BillingFormconstants.BILL_TO_STATE)
      }

      if (rowData.address1) {
        changeFieldValue(RequestorFormConstants.ADDRESS, rowData.address1)
        changeFieldValue(BillingFormconstants.BILL_TO_ADDRESS_LINE1, rowData.stataddress1e);
      } else {
        resetFieldValue(RequestorFormConstants.ADDRESS);
        resetFieldValue(BillingFormconstants.BILL_TO_ADDRESS_LINE1)
      }

      if (rowData.address1) {
        changeFieldValue(RequestorFormConstants.ADDRESS, rowData.address1)
        changeFieldValue(BillingFormconstants.BILL_TO_ADDRESS_LINE1, rowData.address1);
      } else {
        resetFieldValue(RequestorFormConstants.ADDRESS);
        resetFieldValue(BillingFormconstants.BILL_TO_ADDRESS_LINE1)
      }

      if (rowData.address2) {
        changeFieldValue(RequestorFormConstants.ADDRESS2, rowData.address2)
        changeFieldValue(BillingFormconstants.BILL_TO_ADDRESS_LINE2, rowData.address2);
      } else {
        resetFieldValue(RequestorFormConstants.ADDRESS2);
        resetFieldValue(BillingFormconstants.BILL_TO_ADDRESS_LINE2)
      }

      if (rowData.prePay != null) {
        changeFieldValue(BillingFormconstants.REQUIRES_PREPAYMENT, rowData.prePay);
      } else {
        resetFieldValue(BillingFormconstants.REQUIRES_PREPAYMENT)
      }

      mirrorData(RequestorFormConstants.REQUESTOR_NAME, rowData.requestorName)
      mirrorData(RequestorFormConstants.CITY, rowData.city)
      mirrorData(RequestorFormConstants.STATE, rowData.state)
      mirrorData(RequestorFormConstants.ZIP, rowData.zip)
      mirrorData(RequestorFormConstants.FIRST_NAME, rowData.firstName)
      mirrorData(RequestorFormConstants.LAST_NAME, rowData.lastName)
      mirrorData(RequestorFormConstants.PHONE_NUMBER, rowData.phone)
      mirrorData(RequestorFormConstants.FAX_NUMBER, rowData.fax)
      mirrorData(RequestorFormConstants.ADDRESS, rowData.address1)
      mirrorData(RequestorFormConstants.ADDRESS2, rowData.address2)
      mirrorData(RequestorFormConstants.EMAIL, rowData.email)
      mirrorData(BillingFormconstants.REQUIRES_PREPAYMENT, rowData.prePay)

      if (rowData.requestorId) {
        setEnableRequestorField(false);
      }else{
        props.resetRequestorContactList && props.resetRequestorContactList();
      }
      resetFieldValue(RequestorFormConstants.PHONE_FAX_NUMBER)
      resetFieldValue(RequestorFormConstants.EMAIL_ADDRESS)
      resetFieldValue(RequestorFormConstants.SEARCH_ADDRESS)
      resetFieldValue(RequestorFormConstants.SEARCH_ZIP)
      resetFieldValue(RequestorFormConstants.SEARCH_REQUESTOR_NAME)
      resetFieldValue(RequestorFormConstants.SEARCH_CONTACT_NAME)

      setRequiredContactError("");
      untouch(RequestorFormConstants.REQUESTOR_CONTACT);
      
    });
  }

  const isInvalidDate = (date) => {
    return date === INVALID_DATE || date.length === 0
  }

  const onChangeDetailsOrBillingPaneField = (value: any, fieldName: string, updateData: any = false) => {
    let keyName = ""
    let updatedValue = value
    let extraProps = {}
    
    changeFieldValue(fieldName, value)
    switch (fieldName) {
      case BillingFormconstants.FEE_SCHEDULES:
        keyName = "feeScheduleId"
        break
      case BillingFormconstants.CERTIFICATION_REQUESTED:
        keyName = "certRequired"
        break
      case BillingFormconstants.CHARGE_FOR_POSTAGE:
        keyName = "postageIsApplied"
        break
      case BillingFormconstants.RELEASE_METHODS:
        keyName = "releaseMethodId"
        break
      case BillingFormconstants.SHIPPING_SERVICES:
        keyName = "shippingService"
        break
      case FormConstants.CLINIC_RESPONISBLE:
        changeFieldValue(fieldName, value)
        keyName = "clinicdepartmentid"
        break
      case FormConstants.PURPOSE_TYPE:
        keyName = "requestpurposeid"
        break
      case FormConstants.MRN_ID:
        keyName = "mrn"
        break
      case FormConstants.AUTHORIZATION_DATE:
        keyName = "authdate"
        updatedValue = getMomentDate(value)
        if (isInvalidDate(updatedValue) || !isDateSqlOverflow(updatedValue)) {
          return
        }
        break
      case FormConstants.REQUEST_DATE:
        updatedValue = getMomentDate(value)
        keyName = "requestDate"
        if (isInvalidDate(updatedValue) || !isDateSqlOverflow(updatedValue)) {
          return
        }
        break
      case FormConstants.RECEIVED_DATE:
        updatedValue = getMomentDate(value)
        keyName = "receiveddate"
        if (isInvalidDate(updatedValue) || !isDateSqlOverflow(updatedValue)) {
          return
        }
        break
      case FormConstants.DUE_DATE:
        updatedValue = getMomentDate(value)
        keyName = "duedate"
        if (isInvalidDate(updatedValue) || !isDateSqlOverflow(updatedValue)) {
          return
        }
        break
      case FormConstants.CASE_NUMBER:
        keyName = "caseNumber"
        break
      case FormConstants.REQUEST_ID:
        keyName = "requestid"
        break
      case "patientDataid":
        keyName = "patientDataid"
        break
      case FormConstants.AUDIT_TYPE:
        keyName = "auditType"
        break
      case FormConstants.ON_BEHALF_OF:
        keyName = "onBehalfOf"
        break
    }
    let patientDataid = selectedPatientId || props.transactionDetails?.patient?.id || null
    let billToRequestorContactId = props.transactionDetails?.billToContact?.id || null
    if (keyName === "patientDataid") {
      patientDataid = value || null
    }
    if (keyName === "feeScheduleId" && value !== "98") {
      if (billToRequestorContactId) {
        props.getBillToRequestorDetails && props.getBillToRequestorDetails(billToRequestorContactId)
      }
    }
    if (keyName === "releaseMethodId" && value === "2") {
        setDisableShippingDropdown(true);
    }
    else {
      setDisableShippingDropdown(false);
    }
    if (keyName === "releaseMethodId") {
      setShowDisgitalFax(value === "3");
    }
    if (keyName.length) {
      const requestObject = {
        patientDataid: patientDataid || null,
        [keyName]: updatedValue,
        ...extraProps,
        transactionId: props.transactionId,
        userID: global.userID
      }

      props.userHasMadeChanged()
      props.updateBillingPaneFields && props.updateBillingPaneFields(requestObject, updateData)
    }
  }

  const onContactTableRowClickHandler = (rowData) => {
    setAddNewContact(false)
    setEnableEditReqContact(false)
    /* set the fields here */
    /* set the fields here */
    if (rowData.firstName) {
      changeFieldValue(RequestorFormConstants.FIRST_NAME, rowData.firstName);
      changeFieldValue(BillingFormconstants.BILL_TO_FIRST_NAME, rowData.firstName);
    } else {
      resetFieldValue(RequestorFormConstants.FIRST_NAME);
      resetFieldValue(BillingFormconstants.BILL_TO_FIRST_NAME)
    }
    if (rowData.lastName) {
      changeFieldValue(RequestorFormConstants.LAST_NAME, rowData.lastName);
      changeFieldValue(BillingFormconstants.BILL_TO_LAST_NAME, rowData.lastName);
    } else {
      resetFieldValue(RequestorFormConstants.LAST_NAME)
      resetFieldValue(BillingFormconstants.BILL_TO_LAST_NAME)
    }
    if (rowData.email) {
      changeFieldValue(RequestorFormConstants.EMAIL, rowData.email);
      changeFieldValue(BillingFormconstants.BILL_TO_EMAIL_ADDRESS, rowData.email);
    } else {
      resetFieldValue(RequestorFormConstants.EMAIL)
      resetFieldValue(BillingFormconstants.BILL_TO_EMAIL_ADDRESS)
    }
    if (rowData.phone) {
      changeFieldValue(RequestorFormConstants.PHONE_NUMBER, rowData.phone);
      changeFieldValue(BillingFormconstants.BILL_TO_PHONE, rowData.phone);
    } else {
      resetFieldValue(RequestorFormConstants.PHONE_NUMBER)
      resetFieldValue(BillingFormconstants.BILL_TO_PHONE)
    }
    if (rowData.fax) {
      changeFieldValue(RequestorFormConstants.FAX_NUMBER, rowData.fax);
      changeFieldValue(BillingFormconstants.BILL_TO_FAX, rowData.fax);
    } else {
      resetFieldValue(RequestorFormConstants.FAX_NUMBER)
      resetFieldValue(BillingFormconstants.BILL_TO_FAX)
    }

    mirrorData(RequestorFormConstants.FIRST_NAME, rowData.firstName)
    mirrorData(BillingFormconstants.BILL_TO_FIRST_NAME, rowData.firstName)
    mirrorData(RequestorFormConstants.LAST_NAME, rowData.lastName)
    mirrorData(BillingFormconstants.BILL_TO_LAST_NAME, rowData.lastName)
    mirrorData(RequestorFormConstants.EMAIL, rowData.email)
    mirrorData(BillingFormconstants.BILL_TO_EMAIL_ADDRESS, rowData.email)
    mirrorData(RequestorFormConstants.PHONE_NUMBER, rowData.phone)
    mirrorData(BillingFormconstants.BILL_TO_PHONE, rowData.phone)
    mirrorData(RequestorFormConstants.FAX_NUMBER, rowData.fax)
    mirrorData(BillingFormconstants.BILL_TO_FAX, rowData.fax)

    if(!addNewContact && rowData.id){
      const reqObj = {
        transactionId: props.transactionId,
        userID: global.userID,
        sendToRequestorContactid: rowData.id

      }
      props.updateBillingPaneFields && props.updateBillingPaneFields(reqObj, true)

      const updatedValues = {
        [BillingFormconstants.BILL_TO_REQUESTOR_NAME]: values[BillingFormconstants.BILL_TO_REQUESTOR_NAME],
        [BillingFormconstants.BILL_TO_ADDRESS_LINE1]: values[BillingFormconstants.BILL_TO_ADDRESS_LINE1],
        [BillingFormconstants.BILL_TO_STATE]: values[BillingFormconstants.BILL_TO_STATE],
        [BillingFormconstants.BILL_TO_ZIP]: values[BillingFormconstants.BILL_TO_ZIP],
        [BillingFormconstants.BILL_TO_CITY]: values[BillingFormconstants.BILL_TO_CITY],
        [BillingFormconstants.BILL_TO_FIRST_NAME]: rowData.firstName,
        [BillingFormconstants.BILL_TO_LAST_NAME]: rowData.lastName,
        [BillingFormconstants.BILL_TO_ADDRESS_LINE2]: values[RequestorFormConstants.ADDRESS2],
        [BillingFormconstants.BILL_TO_EMAIL_ADDRESS]: rowData.email,
        [BillingFormconstants.BILL_TO_FAX]: rowData.fax,
        [BillingFormconstants.BILL_TO_PHONE]: rowData.phone,
        [BillingFormconstants.REQUIRES_PREPAYMENT]: values[BillingFormconstants.REQUIRES_PREPAYMENT],
        requestorNameId: props.requestorDetails.requestorID
      }
      let data = {
        requestorbillingcontactid: props.transactionDetails.billToContact?.id || null,
        data: generateUpdateBillToRequestorObject(updatedValues)
      }
      props && props.updateBillToRequestorData(data)
    }
  }


  function onChangeSearchLastName(value: String) {
    if(value){
      if (value.length === 0 && lastNameError === "")
        setLastNameError("Last Name or MRN is required.")
      else if(value.length > 0 && lastNameError !== "")
        setLastNameError("") 
    }
  }

  function onChangeSearchDOB(value: String) {
    stopSubmit();
    value && value.toString() != "Invalid Date" ? setDOBError("") : setDOBError("DOB is required.")
  }

  const onPatientTableRowClickHandler = (rowData) => {
    mirrorData(FormConstants.PATIENT_NAME, rowData.firstName)
    mirrorData(FormConstants.PATIENT_LAST_NAME, rowData.lastName)
    mirrorData(FormConstants.PATIENT_MIDDLE_NAME, rowData.middleName)
    mirrorData(FormConstants.DOB, rowData.dob)
    rowData.firstName ? changeFieldValue(FormConstants.PATIENT_NAME, rowData.firstName) : resetFieldValue(FormConstants.PATIENT_NAME)
    rowData.lastName ? changeFieldValue(FormConstants.PATIENT_LAST_NAME, rowData.lastName) : resetFieldValue(FormConstants.PATIENT_LAST_NAME)
    rowData.middleName ? changeFieldValue(FormConstants.PATIENT_MIDDLE_NAME, rowData.middleName) : resetFieldValue(FormConstants.PATIENT_MIDDLE_NAME)
    rowData.dob ? changeFieldValue(FormConstants.DOB, rowData.dob ? new Date(rowData.dob) : null) : resetFieldValue(FormConstants.DOB)
    rowData.mrn ? changeFieldValue(FormConstants.MRN_ID, rowData.mrn) : resetFieldValue(FormConstants.MRN_ID)
    resetFieldValue(FormConstants.EMPI_NUMBER)
    resetFieldValue(FormConstants.SEARCH_LAST_NAME)
    resetFieldValue(FormConstants.SEARCH_DOB)
   
    if (!rowData.firstName) {
      setDisableField(false)
      setAddNewPatient(true)
      mirrorData("notes", "")
      setSelectedPatientId(0)
    } else {
      setDisableField(true)
      setAddNewPatient(false)
      setSelectedPatientId(rowData.id)

      onChangeDetailsOrBillingPaneField(rowData.id, "patientDataid", true)
      props.createPatientRecordSuccess && props.createPatientRecordSuccess({ id: rowData.id })
    }
  }


  const onClickEdit = () => {
    setRequiredContactError("");
    untouch(RequestorFormConstants.REQUESTOR_CONTACT);
    props.transactionDetails.requestorNameID === 0 ? setContactGrid(false) : setContactGrid(true)
  }

  let patientMinDOB = new Date(1900,0,1);


  let futureDate = new Date();
  /* add a day to get any future dates */
  futureDate.setDate(futureDate.getDate());

  const onEditPatientDetails = (value: string, fieldName: string) => {
    let patientDetails = {
      transactionId: props.transactionId,
      [FormConstants.PATIENT_NAME]: values[FormConstants.PATIENT_NAME],
      [FormConstants.PATIENT_MIDDLE_NAME]: values[FormConstants.PATIENT_MIDDLE_NAME] || "",
      [FormConstants.PATIENT_LAST_NAME]: values[FormConstants.PATIENT_LAST_NAME],
      [FormConstants.DOB]: values[FormConstants.DOB],
      [FormConstants.EMPI_NUMBER]: values[FormConstants.EMPI_NUMBER] || "",
      [FormConstants.MRN_ID]: values[FormConstants.MRN_ID] || "",
      [fieldName]: value
    }


    if (patientDetails[FormConstants.DOB] !== null && !isNaN(patientDetails[FormConstants.DOB].getTime()) && patientDetails[FormConstants.DOB] < new Date("1/1/1900"))
        return;


    if (patientDetails[FormConstants.PATIENT_NAME] !== props.updatedPatientDetailsAttributes[FormConstants.PATIENT_NAME] ||
      patientDetails[FormConstants.PATIENT_MIDDLE_NAME] !== props.updatedPatientDetailsAttributes[FormConstants.PATIENT_MIDDLE_NAME] ||
      patientDetails[FormConstants.PATIENT_LAST_NAME] !== props.updatedPatientDetailsAttributes[FormConstants.PATIENT_LAST_NAME] ||
      patientDetails[FormConstants.DOB] !== props.updatedPatientDetailsAttributes[FormConstants.DOB] ||
      patientDetails[FormConstants.EMPI_NUMBER] !== props.updatedPatientDetailsAttributes[FormConstants.EMPI_NUMBER] ||
      patientDetails[FormConstants.MRN_ID] !== props.updatedPatientDetailsAttributes[FormConstants.MRN_ID]){

      const dob = patientDetails[FormConstants.DOB]
      if (patientDetails[FormConstants.PATIENT_NAME]?.length && patientDetails[FormConstants.PATIENT_LAST_NAME]?.length && dob && dob !== INVALID_DATE && !isNaN(dob.getTime())) {
        if (addNewPatient) { //CREATE A PATIENT
          props.createPatientRecordRequest && props.createPatientRecordRequest(patientDetails, () => { setDisableField(true) })
          setAddNewPatient(false)
        } else { //EDIT PATIENT
          patientDetails.patientDataid = selectedPatientId || props.transactionDetails?.patient?.id || null
          props.patchPatientDetailsCreator && props.patchPatientDetailsCreator(patientDetails)
        }
      }
    }
  }

  const updatedActiveTabId =
    activeTabId + 1 <= Object.keys(normalizedTabItems).length
      ? activeTabId + 1
      : activeTabId;

  const getPurposeOfRequestOptions = () => {
    if (!values) { return [] }
    else if (values[FormConstants.REQUEST_TYPE] === DEFAULT_DROPDOWN_VALUE) {
      return generatePurposeOfRequestOptions(props.purposeOfRequests)
    } else {
      let options = pickerHelperFunction(props.purposeOfRequests[values[FormConstants.REQUEST_TYPE]], "name", "id")
      options.unshift({ value: DEFAULT_DROPDOWN_VALUE, label: DEFAULT_DROPDOWN_NAME })
      return options
    }
  }
  const requestTypePickerOptions = generatePickerOptions(props.requestTypes, "name", "id")
  const purposeOfRequestOptions = getPurposeOfRequestOptions()
  let clinicOptions = generatePickerOptions(props.discloseInstitutions, "name", "id")
  //clinicOptions.unshift({ value: DEFAULT_DROPDOWN_VALUE, label: DEFAULT_DROPDOWN_NAME })
  const getBillingTypeId = () => {
    let billingTypeId = props.feeSchedules?.filter(item => values && Number(item.value) === Number(values[BillingFormconstants.FEE_SCHEDULES]))[0]
    return billingTypeId?.billingType ? billingTypeId.billingType.toString() : ""
  }
  const maskSSNInput = (value: string) => {
    if (value !== '' && value !== undefined) {
   
      changeFieldValue(RequestorFormConstants.SSN, '***-**-***')
    }
  }

  const getDuplicateCompare = () => {
    return {
      transactionID: props.transactionId,
      paymentStatus: "",
      resDataEntryDate: new Date(),
      receivedDate: values == null ? "" : values[FormConstants.RECEIVED_DATE],
      stdDate: null,
      releaseDate: null,
      patientFirstName: values == null ? "" : values[FormConstants.PATIENT_NAME],
      patientLastName: values == null ? "" : values[FormConstants.PATIENT_LAST_NAME],
      patientDOB: values == null ? "" : values[FormConstants.DOB],
      mrn: values == null ? "" : values[FormConstants.MRN_ID],
      empi: values == null ? "" : values[FormConstants.EMPI_NUMBER],
      caseNumber: values == null ? "" : values[FormConstants.CASE_NUMBER],
      requestorName: values == null ? "" : values[RequestorFormConstants.REQUESTOR_NAME],
      status: values == null ? "" : props.transactionStatus?.name,
      requestorAddress: values == null ? {} : {
        address1: values[RequestorFormConstants.ADDRESS],
        address2: values[RequestorFormConstants.ADDRESS2],
        city: values[RequestorFormConstants.CITY],
        state: values[RequestorFormConstants.STATE],
        zipCode: values[RequestorFormConstants.ZIP],
        phoneNumber: values[RequestorFormConstants.PHONE_NUMBER],
        faxNumber: values[RequestorFormConstants.FAX_NUMBER]
      },
      requestorEmail: values == null ? "" : values[RequestorFormConstants.EMAIL],
      phone: values == null ? "" : values[RequestorFormConstants.PHONE_NUMBER],
      requestDocument: ""
    }
  }

  const saveSensitiveInfo = (data) => {
    const state = Object.keys(data).reduce((acc, cur, i, arr) => {
      const val = acc + (data[cur] === null ? 0 : 1);
      if (i === arr.length - 1) {
        if (val === 0) return -1;
        if (val === arr.length) return 1;
        return 0;
      } else {
        return val;
      }
    }, 0)
    if (state === 0) {
      changeFieldValue(FormConstants.DISCLOSE_ALL_INFO, false);
      changeFieldValue(FormConstants.DONT_DISCLOSE_ANY_INFO, false);
    } else if (state === 1) {
      changeFieldValue(FormConstants.DISCLOSE_ALL_INFO, true);
      changeFieldValue(FormConstants.DONT_DISCLOSE_ANY_INFO, false);
    } else if (state === -1) {
      changeFieldValue(FormConstants.DONT_DISCLOSE_ANY_INFO, true);
      changeFieldValue(FormConstants.DISCLOSE_ALL_INFO, false);
    }

    setSensitiveInfo(data);
    changeFieldValue(FormConstants.SENSITIVE_INFORMATION, data)

    props.updateBillingPaneFields && props.updateBillingPaneFields({
      //patientDataid: props.transactionDetails?.patient?.id,
      transactionId: props.transactionId,
      userID: global.userID,
      geneticSensitiveInfo: data[FormConstants.GENETIC_INFORMATION] === null ? 0 : 1,
      hivAidsSensitiveInfo: data[FormConstants.HIV] === null ? 0 : 1,
      mentalHealthSensitiveInfo: data[FormConstants.MENTAL_HEALTH] === null ? 0 : 1,
      substanceAbuseSensitiveInfo: data[FormConstants.SUBSTANCE_ABUSE] === null ? 0 : 1,
      sexuallyTransmittedDiseaseSensitiveInfo: data[FormConstants.STD] === null ? 0 : 1
    })
  }

  const saveInfoToRelease = (rtrData) => {
    {
      changeFieldValue(FormConstants.RTR_FROM, rtrData.fromDate ? moment(rtrData.fromDate).format("MM/DD/YYYY") : "")
      changeFieldValue(FormConstants.RTR_TO, rtrData.toDate ? moment(rtrData.toDate).format("MM/DD/YYYY") : "")
      changeFieldValue(FormConstants.RTR_ALL, rtrData.allDates)
      changeFieldValue(FormConstants.RETRIEVE_RECORD_TYPE, rtrData.recordTypes.filter(t => t.state).reduce((acc, cur) => {
        return { ...acc, [cur.code]: { recordType: cur.code, text: cur.text } }
      }, {}))

      let infoErrors = infoToReleaseValidiation(props, rtrData)
      if(props.requestorComplete){
        if(Object.keys(infoErrors).length < 1){
          [FormConstants.RTR_FROM, FormConstants.RTR_TO, FormConstants.RETRIEVE_RECORD_TYPE].forEach(e => {
            props.updateFormSyncErrors && props.updateFormSyncErrors({ [e]: undefined })
          });
        }else{
          props.updateFormSyncErrors && props.updateFormSyncErrors(infoErrors)
  
        }
      }

      if(!isDateSqlOverflow(rtrData.fromDate)){
        [FormConstants.RTR_FROM].forEach(e => {
          props.updateFormSyncErrors && props.updateFormSyncErrors({ [e]: "Invalid Date" })
        });
      }else if(!isDateSqlOverflow(rtrData.toDate)){
        [FormConstants.RTR_TO].forEach(e => {
          props.updateFormSyncErrors && props.updateFormSyncErrors({ [e]: "Invalid Date" })
        });
      }else{
        rtrData && props.saveInformationToRelease && props.saveInformationToRelease(rtrData);
        // [FormConstants.RTR_FROM, FormConstants.RTR_TO, FormConstants.RETRIEVE_RECORD_TYPE].forEach(e => {
        //   props.updateFormSyncErrors && props.updateFormSyncErrors({ [e]: undefined })
        // });
      }
      
      localValidations(values, props);
    }
  }

  const sortedRequestTypeOptions = requestTypePickerOptions.sort((a, b) => {
    if (a.label < b.label) { return -1; }
    if (a.label > b.label) { return 1; }
    return 0;
  })
  const onChangeSearchRequestorName = (value) => {
    if (value.length) { setErrorMessage("") }
    setSearchRequestorName(value)
  }
  const onChangeSearchContactName = (value) => {
    if (value.length) { setErrorMessage("") }
    setSearchContactName(value)
  }
  const onChangeSearchAddress = (value) => {
    if (value.length) { setErrorMessage("") }
    setSearchAddress(value)
  }
  const onChangeSearchZip = (value) => {
    if (value.length) { setErrorMessage("") }
    setSearchZip(value)
  }
  const onChangeShowAdvanceRequestorSearch = () =>{
    setShowAdvanceRequestorSearch(!showAdvanceRequestorSearch);
    changeFieldValue(RequestorFormConstants.PHONE_FAX_NUMBER, "");
    changeFieldValue(RequestorFormConstants.EMAIL_ADDRESS, "");
    changeFieldValue(RequestorFormConstants.SEARCH_REQUESTOR_NAME, "");
    changeFieldValue(RequestorFormConstants.SEARCH_CONTACT_NAME, "");
    changeFieldValue(RequestorFormConstants.SEARCH_ADDRESS, "");
    changeFieldValue(RequestorFormConstants.SEARCH_ZIP, "");
  }
  const onChangeSearchName = (value) =>{
    setSearchName(value);
  }

  const onBlurDigitalFaxField = (value: string) =>{
    if(value.length){
      changeFieldValue(BillingFormconstants.DIGITAL_FAX, value)
      props.setConfirmFaxNumberAction && props.setConfirmFaxNumberAction(value)
      const faxNumber = values[RequestorFormConstants.FAX_NUMBER]
      if(faxNumber.length && value !== faxNumber){
        setErrorMessage("Fax number entered does not match the assigned requestor contact fax number")
      }
      else{
        setErrorMessage("");
        props.putDigitalFaxValidation && props.putDigitalFaxValidation(props.transactionId, faxNumber, true);
      }
    } else{
      setErrorMessage("")
    }
  }
 
  const sensitiveInfoOkHandler = (value: string) =>{
    setSensitiveInfoOkHandlerValue(value);
    props.putAuditTransactionSensitiveInfo && props.putAuditTransactionSensitiveInfo(props.transactionId, value);
  }


  const onClickAddNewReqContact = () => {
    let reqObj = {
      requestType: requestTypeValue,
      data: generateSendToContactObject({}),
      requestorID: props.requestorDetails.requestorID|| null,
      transactionID: props.transactionId,
      requestorDetails: { ...generateUpdateSendToRequestorObject(values), requestorcontactid: props.requestorDetails.requestorInfo.id || null },
    }
    reqObj.data['transactionid'] = props.transactionId

    props && props.sendToContactData(reqObj)
  }

  const apiString = (val:any) => {
    if(val === API_INITIAL)  return "API_INITIAL"
    else if(val === API_FETCHING) return "API_FETCHING"
    else if(val === API_SUCCESS) return "API_SUCCESS"
    else if (val === API_FAILURE) return "API_FAILURE"
    else return "ERROR"
  }

  return (

    <div className="right-pane" onKeyDown={() => props.userHasMadeChanged()}>
      <DuplicateTXPopup compareTransaction={getDuplicateCompare()} userId={global.userID}></DuplicateTXPopup>
      {wait ? <CompleteTransactionWaiting /> : <></>}

      <div style={{position: "relative", flex: "1 1 auto"}}>
      {/* Details Entry Right panel population Tab 1 Details*/}
      {activeTabId === 1 ?

        <div className="details-tab-right-pane">

          <div className="data-entry request-type">
            <Field
              name={FormConstants.REQUEST_TYPE}
              component={DropdownField}
              props={{
                label: "What is the request type?",
                required: true,
                options: sortedRequestTypeOptions,
                onChange: onChangeRequestType
              }}
            />
          </div>

          <div className="data-entry purpose-type">
            <Field
              name={FormConstants.PURPOSE_TYPE}
              component={DropdownField}
              props={{
                label: "What is the purpose of the request?",
                required: true,
                options: purposeOfRequestOptions,
                onChange: onChangePurposeOfRequest,
                disabled: props.formState.values && FormConstants && (props.formState.values[FormConstants.REQUEST_TYPE] == -9999)
              }}
            />
          </div>

          <div className='requestor-info-container'>
            <div className="new-panel-header section-headers" style={{ width: '100%', marginLeft: 12, marginTop: 50 }}> {'Patient Information'} </div>
            <div className='filter-text'><span className='requestor-filter-text' style={{ marginBottom: 23 }}>Search and select a patient</span></div>
            <div className="data-entry requestor-search-Phone placeholder-style">
              <Field
                name={FormConstants.SEARCH_LAST_NAME}
                component={ReduxFormTextField}
                props={{
                  label: "Last Name/MRN",
                  placeholder: "last name or mrn",
                  required: true,
                  maxLength: InputFieldLength.NAME_MAX_LENGTH,
                  errorMessage: lastNameError,
                  onBlur: onChangeSearchLastName,
                  suppressEnterSubmit: true
                }}
              />
            </div>
            <div className="data-entry requestor-search-email  placeholder-style">
              <Field
                name={FormConstants.SEARCH_DOB}
                component={ReduxFormDateField}
                props={{
                  label: "Date of Birth",
                  placeholder: "mm/dd/yyyy",
                  required: true,
                  maxDate: new Date(),
                  minDate: patientMinDOB,
                  errorMessage: DOBError,
                  onChange: onChangeSearchDOB
                }}
              />
            </div>

            <div className="data-entry requestor-search-icon">
              <IconWithButton
                onButtonClick={onClickPatientSerach}
                buttonText='Search'
                rotateArrow={showPatientGrid ? 90 : 0}
              />
            </div>
          </div>
          <span className='horizontal-line' style={{ marginTop: '4%' }}></span>

          {showPatientGrid ?
            <CustomTable
              onTableRowClickHandler={onPatientTableRowClickHandler}
              setGrid={setPatientGrid}
              rows={props.patientSearchDetails ? props.patientSearchDetails : []}
              headCells={headCellsPatient}
              addNewText="Add new Patient"
              defaultSortCol="lastName"
              setAddNewRequestor={setAddNewPatient}
              apiStatus={props.getPatientSearchResultsAPIStatus}
              showAddNewRequestor={true}
            />
            : null}

          <div className="data-entry patient-firstName placeholder-style">
            <Field
              name={FormConstants.PATIENT_NAME}
              component={ReduxFormTextField}
              props={{
                label: "Patient name",
                placeholder: "first name",
                maxLength: InputFieldLength.NAME_MAX_LENGTH,
                required: true,
                disabled: isDisableField,
                onBlur: onEditPatientDetails,
                suppressEnterSubmit: true
              }}
            />
          </div>
          <div className="data-entry patient-middleName  placeholder-style">
            <Field
              name={FormConstants.PATIENT_MIDDLE_NAME}
              component={ReduxFormTextField}
              props={{
                label: " ",
                placeholder: "M",
                disabled: isDisableField,
                suppressEnterSubmit: true
              }}
            />
          </div>
          <div className="data-entry patient-lastName placeholder-style">
            <Field
              name={FormConstants.PATIENT_LAST_NAME}
              component={ReduxFormTextField}
              props={{
                label: " ",
                placeholder: "last name",
                maxLength: InputFieldLength.NAME_MAX_LENGTH,
                disabled: isDisableField,
                onBlur: onEditPatientDetails,
                suppressEnterSubmit: true
              }}
            />
          </div>
          
          <div style={{"height":"20px", "flexBasis":"100%"}}>&nbsp;</div>

          <div className="data-entry patient-dob">
            <Field
              name={FormConstants.DOB}
              component={ReduxFormDateField}
              props={{
                label: "Patient date of birth",
                placeholder: "mm/dd/yyyy",
                maxDate: new Date(),
                minDate: patientMinDOB,
                required: true,
                disabled: isDisableField,
                onChange: onEditPatientDetails
              }}
            />
          </div>

          <div className="data-entry mrn-number  placeholder-style">
            <Field
              name={FormConstants.MRN_ID}
              component={ReduxFormTextField}
              props={{
                label: "Medical Record Number (MRN)",
                placeholder: "MRN12346789",
                maxLength: InputFieldLength.MEDICAL_RECORD_NUMBER_MAX_LENGTH,
                onBlur: onEditPatientDetails,
                suppressEnterSubmit: true
              }}
            />
          </div>

          {props.transactionDetails.facility?.useRequestID ?
            <div className="data-entry placeholder-style">
              <Field
                name={FormConstants.REQUEST_ID}
                component={ReduxFormTextField}
                props={{
                  label: "Request ID",
                  placeholder: "XXXXXX-XXXX",
                  maxLength: InputFieldLength.REQUEST_ID_MAX_LENGTH,
                  onBlur: onChangeDetailsOrBillingPaneField,
                  suppressEnterSubmit: true
                }}
              />
            </div> : <div className="data-entry placeholder-style"></div>}

          {props.transactionDetails.facility?.useEMPI ? <div className="data-entry empi-number  placeholder-style">
            <Field
              name={FormConstants.EMPI_NUMBER}
              component={ReduxFormTextField}
              props={{
                label: "EMPI",
                placeholder: "123456789",
                maxLength: InputFieldLength.EMPI_MAX_LENGTH,
                onChange: onChangeDetailsOrBillingPaneField,
                suppressEnterSubmit: true
              }}
            />
          </div> : <div className="data-entry empi-number placeholder-style" style={{ display: 'none' }} />}

          <span className='horizontal-line' style={{ marginTop: '4%' }}></span>
          {requestTypeValue !== '66' ? <div className="data-entry authorization-date">
            <Field
              name={FormConstants.AUTHORIZATION_DATE}
              component={ReduxFormDateField}
              props={{
                label: "Authorization date",
                placeholder: "mm/dd/yyyy",
                minDate: DEFAULT_MIN_DATE,
                maxDate: new Date(),
                required: isAuthDateRequired(purposeTypeValue),
                onChange: onChangeDetailsOrBillingPaneField
              }}
            />
          </div> : null}

          {requestTypeValue !== '66' ? <div className="data-entry request-date">
            <Field
              name={FormConstants.REQUEST_DATE}
              component={ReduxFormDateField}
              props={{
                label: "Request date",
                placeholder: "mm/dd/yyyy",
                maxDate: new Date(),
                minDate: DEFAULT_MIN_DATE,
                required: true,
                onChange: onChangeDetailsOrBillingPaneField
              }}
            />
          </div> : null}

          <div className="data-entry received-date">
            <Field
              name={FormConstants.RECEIVED_DATE}
              component={ReduxFormDateField}
              props={{
                label: "Received date",
                placeholder: "mm/dd/yyyy",
                maxDate: new Date(),
                minDate: new Date((new Date()).setHours(0,0,0,0) - (365 * 24 * 60 * 60 * 1000)),
                required: requestTypeValue !== '66' || props?.facilityAdditionalFields?.enableNonHIMReceivedDateRequired,
                onChange: onChangeDetailsOrBillingPaneField
              }}
            />
          </div>

          {requestTypeValue !== '66' ? <div className="data-entry due-date">
            <Field
              name={FormConstants.DUE_DATE}
              component={ReduxFormDateField}
              props={{
                label: "Due date",
                placeholder: "mm/dd/yyyy",
                minDate: DEFAULT_MIN_DATE,
                maxDate: DEFAULT_MAX_DATE,
                onChange: onChangeDetailsOrBillingPaneField
              }}
            />
          </div> : null}

          {props.transactionDetails.facility?.useClinics | props.transactionDetails.facility?.useDepartments ? <div className="data-entry clinic-responsible">
            <Field
              name={FormConstants.CLINIC_RESPONISBLE}
              component={ReduxFormDropdownField}
              placeholder={DEFAULT_DROPDOWN_NAME}
              props={{
                label: props.transactionDetails.facility?.useClinics ? "What clinic is responsible for this request?" : "Department responsible for this request:",
                required: true,
                options: clinicOptions,
                onChange: onChangeDetailsOrBillingPaneField
              }}
            />
          </div> : null}

          {!props.transactionDetails.facility?.useDepartments && !props.transactionDetails.facility?.useClinics ? <div className="data-entry clinic-responsible" />
            : null
          }

          {requestTypeValue !== '31' &&
            <div className="data-entry case-number placeholder-style">
              <Field
                name={FormConstants.CASE_NUMBER}
                component={ReduxFormTextField}
                props={{
                  label: "Case number",
                  placeholder: "123456789",
                  maxLength: InputFieldLength.CASE_NUMBER_MAX_LENGTH,
                  required: props.formState.values && FormConstants && ['3','53'].includes(props.formState.values[FormConstants.REQUEST_TYPE]),
                  onBlur: onChangeDetailsOrBillingPaneField,
                  suppressEnterSubmit: true
                }}
              />
            </div>
          }

          {requestTypeValue === '53' ? <>
            <div className="data-entry request-type placeholder-style">
              <Field
                name={FormConstants.AUDIT_TYPE}
                component={ReduxFormDropdownField}
                props={{
                  label: "Audit Type",
                  required: true,
                  options: auditTypesOptions,
                  onChange: onChangeDetailsOrBillingPaneField

                }}
              />
            </div>
            <div className="data-entry request-type placeholder-style">
              <Field
                name={FormConstants.ON_BEHALF_OF}
                component={ReduxFormDropdownField}
                props={{
                  label: "On Behalf Of",
                  required: true,
                  options: onBehalfOfOptions,
                  onChange: onChangeDetailsOrBillingPaneField

                }}
              />
            </div></> : null}

          {requestTypeValue !== '66' ? <div className='disclosure-accordion' onMouseUp={() => props.userHasMadeChanged()}>
            <InfoToRelease
              labels={releaseInfo?.map(i => ({ id: i.id, name: i.name, code: i.code }))}
              data={releaseInfo}
              onChange={saveInfoToRelease}
              transactionId={props.transactionId}
              activeTabId={activeTabId}
              fromErrorMsg={props.formSyncErrors[FormConstants.RTR_FROM]}
              toErrorMsg={props.formSyncErrors[FormConstants.RTR_TO]}
              errorMsg={props.formSyncErrors[FormConstants.RETRIEVE_RECORD_TYPE]}
              minDate={props.formState.values && FormConstants && new Date(props.formState.values[FormConstants.DOB])}
              maxDate={new Date()}
              
            />
          </div>
            : null}

          {requestTypeValue !== '66' ? <div className='disclosure-accordion' onMouseUp={() => props.userHasMadeChanged()}>
            <SensitiveInfo
              labels={sensitiveInfoLabels}
              data={sensitiveInfo}
              onChange={saveSensitiveInfo}
              errorMsg={props.formSyncErrors && props.formSyncErrors[FormConstants.SENSITIVE_INFORMATION]}
            />
          </div> : null}


          {requestTypeValue === '66' ? <div className='disclosure-accordion' onMouseUp={() => props.userHasMadeChanged()}>
            <AccordionComponent
              activeTabId={activeTabId}
              requestTypeValue={'66'}
              heading='Disclosure Information'
              phiCodes={props.selectedPHICodes}
              onBlur={onBlurDisclosureInformationFields}
            />
          </div> : null}

        </div> : null}

      {/* requestor tab */}

      {activeTabId === 2 ?

        /* Form fields UI mockup for requestor tab */
        <div id='requestor-tab-right-pane' className='details-tab-right-pane'>



          <div style={{ flexDirection: 'column', flexWrap: 'nowrap' }} className='requestor-info-container'>
            <div className="new-panel-header section-headers" style={{ width: '98%', marginLeft: 12 }}> {'Requestor Information'} </div>
            {/* If Request Type is Disability/Social Security */}
            {
              FormConstants && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] !== undefined && props.formState.values[FormConstants.REQUEST_TYPE] === '31' &&
              (
                <>
                  <div style={{ marginLeft: "0", marginTop: 12 }} className="row dds-row-1">
                    <div style={{ flexBasis: "33%" }}>
                      <Field
                        name={RequestorFormConstants.RQID}
                        component={ReduxFormTextField}
                        props={{
                          label: "RQID",
                          placeholder: "Enter RQID",
                          maxLength: 50,
                          onChange: onChangeRQID,
                          required: props.formState.values[RequestorFormConstants.NO_BARCODE] !== undefined ? !props.formState.values[RequestorFormConstants.NO_BARCODE] : true,
                          errorMessage: rqidErrorMessage,
                          onBlur: onBlurRQID,
                          disabled: isDisableDDSField,
                          suppressEnterSubmit: true
                        }}
                      />
                    </div>
                    <div style={{ flexBasis: "33%" }}>
                      <Field
                        name={RequestorFormConstants.TX_SITE_CODE}
                        component={ReduxFormTextField}
                        props={{
                          label: "TX Site Code",
                          placeholder: "Site Code",
                          maxLength: 10,
                          onChange: onChangeTXSiteCode,
                          required: props.formState.values[RequestorFormConstants.NO_BARCODE] !== undefined ? !props.formState.values[RequestorFormConstants.NO_BARCODE] : true,
                          errorMessage: rqSTxiteCodeErrorMessage,
                          onBlur: onBlurTXSiteCode,
                          disabled: isDisableDDSField,
                          suppressEnterSubmit: true
                        }}
                      />
                    </div>
                    <div style={{ flexBasis: "33%" }}>
                      <Field
                        name={RequestorFormConstants.DOC_RETURN_CODE}
                        component={ReduxFormTextField}
                        props={{
                          label: "Doc Return Code",
                          placeholder: "Doc Return Code",
                          maxLength: 50,
                          onChange: onChangeDR,
                          required: props.formState.values[RequestorFormConstants.NO_BARCODE] !== undefined ? !props.formState.values[RequestorFormConstants.NO_BARCODE] : true,
                          errorMessage: drErrorMessage,
                          onBlur: onBlurDR,
                          disabled: isDisableDDSField,
                          suppressEnterSubmit: true
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginLeft: "0" }} className="row dds-row-2">
                    <div style={{ flexBasis: "33%" }}>
                      <Field
                        name={RequestorFormConstants.SSN}
                        component={ReduxFormTextField}
                        props={{
                          label: "SSN",
                          placeholder: "SSN",
                          maxLength: 9,
                          onChange: onChangeSSN,
                          required: props.formState.values[RequestorFormConstants.NO_BARCODE] !== undefined ? !props.formState.values[RequestorFormConstants.NO_BARCODE] : true,
                          errorMessage: ssnErrorMessage,
                          onBlur: onBlurSSN,
                          disabled: isDisableDDSField,
                          suppressEnterSubmit: true
                        }}
                      />
                    </div>
                    <div style={{ flexBasis: "33%" }}>
                      <Field
                        name={RequestorFormConstants.DOC_TYPE}
                        component={ReduxFormTextField}
                        props={{
                          label: "Doc Type",
                          placeholder: "Doc Type",
                          maxLength: 4,
                          onChange: onChangeDocType,
                          required: props.formState.values[RequestorFormConstants.NO_BARCODE] !== undefined ? !props.formState.values[RequestorFormConstants.NO_BARCODE] : true,
                          errorMessage: docTypeErrorMessage,
                          onBlur: onBlurDocType,
                          disabled: isDisableDDSField,
                          suppressEnterSubmit: true
                        }}
                      />
                    </div>
                    <div style={{ flexBasis: "33%" }}>
                      <Field
                        name={RequestorFormConstants.ROUTING_FIELD}
                        component={ReduxFormTextField}
                        props={{
                          label: "Routing Field",
                          placeholder: "Routing Field",
                          maxLength: 50,
                          onChange: onChangeRF,
                          required: props.formState.values[RequestorFormConstants.NO_BARCODE] !== undefined ? !props.formState.values[RequestorFormConstants.NO_BARCODE] : true,
                          errorMessage: rfErrorMessage,
                          onBlur: onBlurRF,
                          disabled: isDisableDDSField,
                          suppressEnterSubmit: true
                        }}
                      />
                    </div>
                  </div>
                  <div className="row no-barcode">

                    <div className="select-all-check">
                      <Field
                        name={RequestorFormConstants.NO_BARCODE}
                        component={ReduxFormcheckboxField}
                        props={{
                          fieldlabel: "",
                          checkboxLabel: 'No Barcode',
                          onChange: onChangeNoBarcode,
                        }}
                      />
                    </div>
                  </div>
                  {props.formState.values[FormConstants.REQUEST_TYPE] === '31' &&
                    <div className="data-entry case-number placeholder-style" style={{ width: '33.5%', marginTop: -8 }}>
                      <Field
                        name={FormConstants.CASE_NUMBER}
                        component={ReduxFormTextField}
                        props={{
                          label: "Case number",
                          placeholder: "123456789",
                          maxLength: InputFieldLength.CASE_NUMBER_MAX_LENGTH,
                          required: true,
                          onBlur: onChangeDetailsOrBillingPaneField,
                          suppressEnterSubmit: true
                        }}
                      />
                    </div>
                  }
                  <span className='horizontal-line' style={{ marginTop: '4%' }}></span>
                </>
              )
            }

            {/* Same as option */}
            {(props.lastUsedRequestorContact && props.selectedFacilityDetails.facility.id === props.lastUsedRequestorContact.org && props.transactionId !== props.lastUsedRequestorContact.transactionId)
              &&
              <div className='same-as-option'>
                <p className='form-label same-as-label'>To use Requestor: {"  "}
                  <span className='same-as-identity'>{props.lastUsedRequestorContact.requestorName}</span>
                  - Contact:
                  <span className='same-as-identity'>{(props.lastUsedRequestorContact.firstName || props.lastUsedRequestorContact.lastName) ? `${props.lastUsedRequestorContact.firstName} ${props.lastUsedRequestorContact.lastName}` : '(None)'} </span>
                  for this transaction, click
                </p>
                <IconWithButton onButtonClick={() => onRequestorTableRowClickHandler(props.lastUsedRequestorContact)} buttonText={`Same As`} hideArrowIcon={true} isSameAs={true} />
              </div>}

            {/* Requestor Search */}
            <div className='filter-text' style={{ flexBasis: '10%', marginBottom: 13 }}><span style={{ marginLeft: 12 }} className={"requestor-filter-text" + FormConstants && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] !== undefined && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? "requestor-filter-text-adj" : ""}>Search for requestor</span></div>

            <div id='requestor-search-fields' className="row">
              <>
                {/* If Request Type is Disability/Social Security */}
                {FormConstants && props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] !== undefined && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ?
                  <div style={{ flexBasis: "33%" }}>
                    <Field
                      name={RequestorFormConstants.RQ_SITE_CODE}
                      component={ReduxFormTextField}
                      props={{
                        label: "RQ Site Code",
                        placeholder: "Site Code",
                        maxLength: 10,
                        onChange: onChangeRQSiteCode,
                        errorMessage: rqSTxiteCodeErrorMessage,
                        suppressEnterSubmit: true
                      }}
                    />
                  </div> : ""}
                {/* If Request Type is Internal */}
                {FormConstants && props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] === '21' ?
                  <div style={{ flexBasis: "25%" }}>
                    <Field
                      name={RequestorFormConstants.INTENRNAL_REQUESTOR}
                      component={ReduxFormTextField}
                      props={{
                        label: "Name",
                        placeholder: "Name",
                        maxLength: 50,
                        onChange: onChangeSearchName,
                        suppressEnterSubmit: true
                      }}
                    />
                  </div> : 
                <div id='phone/fax-search' style={FormConstants && props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? { flexBasis: "33%" } : { flexBasis: "25%" }}>
                  <Field
                    name={RequestorFormConstants.PHONE_FAX_NUMBER}
                    component={ReduxFormTextField}
                    props={{
                      label: "Phone/Fax",
                      placeholder: "XXX-XXX-XXXX",
                      maxLength: 12,
                      onChange: onChangeFaxOrPhoneOrEmail,
                      errorMessage: !showAdvanceRequestorSearch && errorMessage.length > 0 ? errorMessage : null,
                      suppressEnterSubmit: true
                    }}
                  />
                </div>}
                <div id='email-search' style={FormConstants && props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? { flexBasis: "33%" } : { flexBasis: "25%" }}>
                  <Field
                    name={RequestorFormConstants.EMAIL_ADDRESS}
                    component={ReduxFormTextField}
                    props={{
                      label: "Email",
                      placeholder: "example@mail.com",
                      maxLength: 50,
                      onChange: (e) => {setSearchEmail(e);onChangeFaxOrPhoneOrEmail(e)},
                      errorMessage: !showAdvanceRequestorSearch && props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] !== '21' && errorMessage.length > 0 ? "  " : null,
                      suppressEnterSubmit: true
                    }}
                  />
                </div>
                <div style={{ 
                        alignSelf: 'flex-start',
                        marginTop:21,
                        marginLeft: props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? 0 : 13, position: 'relative', flexBasis: props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? 'auto' : '50%', 
                        }}>
                  <IconWithButton
                    onButtonClick={onClickSearch}
                    buttonText='Search'
                    rotateArrow={showRequestorGrid ? 90 : 0}
                  />
                </div>
              </>
            </div>

            <div className='advanced-search-container' style={{ position: 'relative', display: 'flex', flexDirection: 'column-reverse', top: 0 }}>
              {showAdvanceRequestorSearch &&
              <div>
                <div id='advanced-search-content' style={{ marginLeft: "0" }} className="row">
                    <div id='address-search' style={{ flexBasis: props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? "33%" : "25%" }}>
                      <Field
                        name={RequestorFormConstants.SEARCH_ADDRESS}
                        component={ReduxFormTextField}
                        props={{
                          label: "Address",
                          placeholder: "123 main st",
                          maxLength: 70,
                          onChange: onChangeSearchAddress,
                          errorMessage,
                          suppressEnterSubmit: true
                        }}
                      />
                    </div>

                    <div id='zip-search' style={{ flexBasis: props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? "33%" : "25%" }}>
                      <Field
                        name={RequestorFormConstants.SEARCH_ZIP}
                        component={ReduxFormTextField}
                        props={{
                          label: "Zip Code",
                          placeholder: "12345",
                          maxLength: 10,
                          onChange: onChangeSearchZip,
                          errorMessage: errorMessage.length > 0 ? "  " : null,
                          suppressEnterSubmit: true
                        }}
                      />
                    </div>
              
                </div>
                <div id='advanced-search-content' style={{ marginLeft: "0" }} className="row">
                <div id='requestor-name-search' style={{ flexBasis: props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? "33%" : "25%" }}>
                  <Field
                    name={RequestorFormConstants.SEARCH_REQUESTOR_NAME}
                    component={ReduxFormTextField}
                    props={{
                      label: "Requestor Name",
                      placeholder: "Name",
                      maxLength: 50,
                      onChange: onChangeSearchRequestorName,
                      errorMessage,
                      suppressEnterSubmit: true
                    }}
                  />
                </div>

                <div id='requestor-contact-search' style={{ flexBasis: props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? "33%" : "25%" }}>
                  <Field
                    name={RequestorFormConstants.SEARCH_CONTACT_NAME}
                    component={ReduxFormTextField}
                    props={{
                      label: "Contact",
                      placeholder: "Contact",
                      maxLength: 50,
                      onChange: onChangeSearchContactName,
                      errorMessage: errorMessage.length > 0 ? "  " : null,
                      suppressEnterSubmit: true
                    }}
                  />
                </div>
          
            </div></div>
              }
            </div>
            <div id='advanced-search-toggle' className="row" style={{
                justifyContent: props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? 'flex-start' : 'space-between',
                flexDirection: props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? 'column' : 'row',
                marginRight: props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? '3px' : '-15px',
              }}>
                <>
                  {/* <div className="data-entry requestor-search-icon"
                    style={
                      {
                        flexBasis: FormConstants && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? "40%" : "40%",
                        marginTop: FormConstants && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? "-2%" : "0%",
                        //marginLeft: FormConstants && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? "0%" : "0%",
                      }
                    }
                  >

                  </div> */}
                </>
                {FormConstants && props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] !== '21' ?
                <div id='icon+label'>
                  <span className='edit-contact'
                    onClick={() => onChangeShowAdvanceRequestorSearch()}>
                    <ArrowForwardIos style={{ fontSize: 18, transform: (showAdvanceRequestorSearch ? 'rotate(90deg)' : '') }} />Advanced Search
                  </span>
                </div> : <div><span></span></div>}
                {/* top: !showAdvanceRequestorSearch ? 0 : FormConstants && props.formState.values[FormConstants.REQUEST_TYPE] === '31' ? 90 : 126  */}
                
              </div>



          </div>
          <div className='search-requestor-table'>
            {showRequestorGrid ?
              <CustomTable
                onTableRowClickHandler={onRequestorTableRowClickHandler}
                setGrid={setRequestorGrid}
                rows={props.requestorSearchDetails ? props.requestorSearchDetails : []}
                headCells={headCellsRequestor}
                addNewText="Add new Requestor"
                defaultSortCol="default"
                setAddNewRequestor={(data) => {
                  props.updateRequestorDetails && props.updateRequestorDetails({
                    requestorID: null,
                    requestorInfo: null
                  });
                  setEnableRequestorField(data); 
                  props.resetRequestorContactList && props.resetRequestorContactList(); 
                  setEnableEditReqContact(false);
                }}
                apiStatus={props.getRequestorSearchDetailsAPIStatus}
                searchTerms={{ phone: searchPhone, email: searchEmail, zip: searchZip, address: searchAddress }}
                showAddNewRequestor={showAddNewRequestor}
              />
              : null}
          </div>
          <span className="horizontal-line" style={{ height: 1, flexBasis: 'auto', marginTop: props && props.formState && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] === '31' && showAdvanceRequestorSearch ? 23 : '4%' }}></span>

          <div className='requestor-info-container requestor-input-container' style={{ marginTop: 8 }}>
            <div className='data-entry requestor-info placeholder-style'>
              <Field
                name={RequestorFormConstants.REQUESTOR_NAME}
                component={ReduxFormTextField}
                props={{
                  label: "Requestor name",
                  required: true,
                  placeholder: "Requestor Name",
                  maxLength: 100,
                  onChange: onChangeRequestorName,
                  onBlur: onBlurRequestorTabFields,
                  disabled: !isRequestorFieldEnable,
                  suppressEnterSubmit: true
                }}
              />
            </div>

            <div className='data-entry requestor-info placeholder-style'>
              <Field
                name={RequestorFormConstants.ADDRESS}
                component={ReduxFormTextField}
                props={{
                  label: "Address",
                  required: true,
                  placeholder: "Address line 1",
                  maxLength: 70,
                  onChange: onChangeAddress1,
                  onBlur: onBlurRequestorTabFields,
                  disabled: !isRequestorFieldEnable,
                  suppressEnterSubmit: true
                }}
              />
            </div>

            <div style={{}} className='data-entry requestor-addressLine2 placeholder-style'>
              <Field
                name={RequestorFormConstants.ADDRESS2}
                component={ReduxFormTextField}
                props={{
                  label: "",
                  maxLength: 70,
                  placeholder: "P.O. Box, Suite, Apt #",
                  onChange: onChangeAddress2,
                  onBlur: onBlurRequestorTabFields,
                  disabled: !isRequestorFieldEnable,
                  suppressEnterSubmit: true

                }}
              />
            </div>

            <div className='data-entry requestor-city placeholder-style'>
              <Field
                name={RequestorFormConstants.CITY}
                component={ReduxFormTextField}
                props={{
                  label: "City",
                  required: true,
                  placeholder: "City",
                  maxLength: 30,
                  onChange: onChangeCity,
                  onBlur: onBlurRequestorTabFields,
                  disabled: !isRequestorFieldEnable,
                  suppressEnterSubmit: true

                }}
              />
            </div>

            <div className='data-entry requestor-state placeholder-style'>
              <Field
                name={RequestorFormConstants.STATE}
                component={ReduxFormTextField}
                props={{
                  label: "State",
                  required: true,
                  placeholder: "State",
                  maxLength: 2,
                  onChange: onChangeState,
                  onBlur: onBlurRequestorTabFields,
                  disabled: !isRequestorFieldEnable,
                  suppressEnterSubmit: true

                }}
              />
            </div>
            <div className='data-entry requestor-zip placeholder-style'>
              <Field
                name={RequestorFormConstants.ZIP}
                component={ReduxFormTextField}
                props={{
                  label: "Zip",
                  required: true,
                  placeholder: "Zip code",
                  maxLength: 5,
                  onChange: onChangeZip,
                  onBlur: onBlurRequestorTabFields,
                  disabled: !isRequestorFieldEnable,
                  suppressEnterSubmit: true

                }}
              />
            </div>
          </div>

          {/* <span className='horizontal-line'></span> */}
          


          <div className='contact-info-container' id='requestorContactForm'>
            <div className='panel-header-and-edit'>
              <div className="new-panel-header section-headers" style={{ width: '98%', marginLeft: 12 }}> {'Contacts'} </div>
              {/* <div className='data-entry'><span className='requestor-contact'>Contacts</span></div> */}

              <div className='data-entry' style={{ position: 'absolute', right: 12 }}>

                <span className={!contactsLoaded || showContactGrid === true ? 'edit-contact edit-contact-disabled' : 'edit-contact edit-contact-enabled'}
                  onClick={contactsLoaded ? () => onClickEdit() : () => {}}>
                  <EditIcon className={!contactsLoaded || showContactGrid === true ? 'edit-icon-disabled' : 'edit-icon-enabled'} />
                  Assign Contact
                </span>
              </div>
            </div>
            <Field
              name={RequestorFormConstants.REQUESTOR_CONTACT}
              component={ReduxFormValidationError}
              props={{
                errorMessage: requiredContactError,
                suppressEnterSubmit: true
              }}
            />
            {/* <span className="horizontal-line"></span> */}
            {showContactGrid ?
              <CustomTable
                onTableRowClickHandler={onContactTableRowClickHandler}
                setGrid={setContactGrid}
                rows={editContactRequestorID ? editContactRequestorID > 0 ? props.requestorContactDetails : [] : props.requestorContactDetails}
                headCells={headCellsContact}
                addNewText="Add new Contact"
                defaultSortCol={searchEmail ? "email" : (searchPhone ? "phonefax" : "firstName")}
                defaultSortVal={searchEmail || searchPhone}
                setAddNewRequestor={(data) => {
                    setAddNewContact(true)
                    onClickAddNewReqContact()


                   setEnableEditReqContact(data)}}
                showAddNewRequestor={true}
                apiStatus={props.getContactSearchDetailsAPIStatus}
              />
              : null}


            <div className='data-entry requestor-first-name placeholder-style'>
              <Field
                name={RequestorFormConstants.FIRST_NAME}
                component={ReduxFormTextField}
                props={{
                  label: "First name",
                  placeholder: "First Name",
                  maxLength: 30,
                  onchange: onChangeFirstName,
                  onBlur: (v, f, e) => { onBlurRequestorTabFields(v,f,e); onChangeFirstName(v, true) },
                  suppressEnterSubmit: true,
                  disabled: !enableEditReqContact

                }}
              />
            </div>
            <div className='data-entry requestor-last-name placeholder-style'>
              <Field
                name={RequestorFormConstants.LAST_NAME}
                component={ReduxFormTextField}
                props={{
                  label: "Last name",
                  placeholder: "Last Name",
                  maxLength: 30,
                  onchange: onChangeLastName,
                  onBlur: (v, f, e) => { onBlurRequestorTabFields(v,f,e); onChangeLastName(v, true) },
                  suppressEnterSubmit: true,
                  disabled: !enableEditReqContact

                }}
              />
            </div>
            <div className='data-entry placeholder-style'>
              <Field
                name={RequestorFormConstants.EMAIL}
                component={ReduxFormTextField}
                props={{
                  label: "Email",
                  placeholder: "example@mail.com",
                  maxLength: 50,
                  onchange: onChangeEmail,
                  onBlur: (v, f, e) => { onBlurRequestorTabFields(v,f,e); onChangeEmail(v, true)},
                  suppressEnterSubmit: true,
                  disabled: !enableEditReqContact,
                  errorMessage : props.formState.syncErrors && props.formState.syncErrors[RequestorFormConstants.EMAIL]

                }}
              />
            </div>

            <div className='data-entry placeholder-style'>
              <Field
                name={RequestorFormConstants.PHONE_NUMBER}
                component={ReduxFormTextField}
                props={{
                  label: "Phone number",
                  placeholder: "XXX-XXX-XXXXxXXXXX",
                  maxLength: 30,
                  onchange: onChangePhone,
                  onBlur: (v, f, e) => { onBlurRequestorTabFields(v,f,e); onChangePhone(v, true) },
                  suppressEnterSubmit: true,
                  disabled: !enableEditReqContact

                }}
              />
            </div>
            <div className='data-entry placeholder-style'>
              <Field
                name={RequestorFormConstants.FAX_NUMBER}
                component={ReduxFormTextField}
                props={{
                  label: "Fax number",
                  placeholder: "XXX-XXX-XXXX",
                  maxLength: 12,
                  onchange: onChangeFax,
                  onBlur: (v, f, e) => { onBlurRequestorTabFields(v,f,e); onChangeFax(v, true) },
                  suppressEnterSubmit: true,
                  disabled: !enableEditReqContact
                }}
              />
            </div>
                
          </div>

          {/* {(requestTypeValue === '31' && purposeTypeValue !== '-9999') || (requestTypeValue === '59'&& purposeTypeValue === '19') ? <div className = 'disclosure-accordion' >
              <AccordionComponent 
                activeTabId={activeTabId}
                requestTypeValue={'31'}
                heading='Authorization Verification'
              />
                </div>: null  }    */}

        </div>

        : null}

      {activeTabId === 3 ?
        <div className="billing-right-pane">
          <Row>
            <Col>
              <p className="font-weight-bold mb-3 billing-desc">Enter any overrides to the fee schedule for this request.</p>
            </Col>
            <span className="horizontal-line"></span>
          </Row>
          <Row>
            <Col xs={6} md={6} className="pl-0">
              <div className="data-entry purpose-type">
                <Field
                  name={BillingFormconstants.FEE_SCHEDULES}
                  component={ReduxFormDropdownField}
                  props={{
                    label: "Use this Fee Schedule",
                    required: false,
                    options: props.feeSchedules,
                    disabled: disableFeeScheduleDropDown,
                    onChange: (v, f) => onChangeDetailsOrBillingPaneField(v, f, true)
                  }}
                />
              </div>
            </Col>
            <Col xs={6} md={6} className="pl-0">
              <div className="data-entry purpose-type">
                <Field
                  name={BillingFormconstants.RELEASE_METHODS}
                  component={ReduxFormDropdownField}
                  props={{
                    label: "Release Method",
                    options: props.releaseMethods,
                    disabled: disableRealeaseMethodDropDown,
                    onChange: (v, f) => onChangeDetailsOrBillingPaneField(v, f, true)
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={6} className="pl-0">
              <div className="data-entry purpose-type">
                <Field
                  name={BillingFormconstants.SHIPPING_SERVICES}
                  component={ReduxFormDropdownField}
                  props={{
                    label: "Shipping Service",
                    options: props.shippingServices,
                    disabled: disableShippingDropDown,
                    onChange: onChangeDetailsOrBillingPaneField
                  }}
                />
              </div>
            </Col>
            <Col xs={6} md={6}>
              <div className="data-entry ">
                <Field
                  name={BillingFormconstants.CHARGE_FOR_POSTAGE}
                  component={ReduxFormRadioField}
                  props={{
                    fieldlabel: "Charge for Postage?",
                    radioLabel: 'Yes',
                    defaultSelected: props.transactionDetails?.postageIsApplied,
                    disabled: disableShippingDropDown,
                    onChange: onChangeDetailsOrBillingPaneField
                  }}
                />
              </div>
            </Col>
            {showDigitalFax ? 
            <Col xs={6} md={6} className="pl-0">
              <div className="data-entry purpose-type">
                <Field
                    name={BillingFormconstants.DIGITAL_FAX}
                    component={ReduxFormTextField}
                    props={{
                      label: "Re-enter Fax Number",
                      placeholder: "XXX-XXX-XXXX",
                      maxLength: 12,
                      suppressEnterSubmit: true,
                      onBlur: onBlurDigitalFaxField,
                      errorMessage
                    }}
                  />
            </div>
            </Col> : null }
          </Row>
          <Row>
            <Col xs={6} md={6}>
              <div className="data-entry">
                <Field
                  name={BillingFormconstants.CERTIFICATION_REQUESTED}
                  component={ReduxFormcheckboxField}
                  props={{
                    fieldlabel: "Certification Requested?",
                    checkboxLabel: 'Yes',
                    defaultChecked: props.transactionDetails?.certificationRequired,
                    onChange: onChangeDetailsOrBillingPaneField
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className='delivery-instructions'>
            <Col>
              <div className="data-entry">
                <MultilineTextboxButtons
                  text={values[BillingFormconstants.DELIVERY_INSTRUCTIONS]}
                  label="Delivery Instructions:"
                  maxLength={2000}
                  className="deliveryInstructionsTextArea"
                  onSave={e => {
                    onBlurBillingPangeFields(e, BillingFormconstants.DELIVERY_INSTRUCTIONS);
                    changeFieldValue(BillingFormconstants.DELIVERY_INSTRUCTIONS, e)
                  }}
                  saveRollover="Save Instructions"
                  cancelRollover="Cancel"
                 />
              </div>
            </Col>
          </Row>
          {getBillingTypeId() === "1" ?
            <Row>
              <Col>
                <AccordionComponent data={billingAdditionalChargeValue} onBlur={onBlurBillingPangeFields}
                  activeTabId={activeTabId} onCheckSameAsSendTo={onCheck}
                  setAdditionalChargesErr={setAdditionalChargesErr}
                  dispatch={props.dispatch}
                  additionalChargesErr={props.formSyncErrors && props.formSyncErrors[BillingFormconstants.ADDITIONAL_CHARGES]} />
              </Col>
            </Row> : null}
        </div> : null}

      {activeTabId === 4 ? <div>
        <Row className="mb-5 rp-section mx-0">
          <Col>
            <div className="rp-title">Retrieval Protocol</div>
            {props.retrieveProtocol && props.retrieveProtocol.map(data => (
              <>
                <Col className="pl-0">
                  <span>{data.description}</span>
                </Col>
                <div dangerouslySetInnerHTML={{ __html: data.protocolText.replaceAll && data.protocolText.replaceAll("<a", '<a target="_blank"') }} />
              </>
            ))}
          </Col>
        </Row>
      </div> : null}
      </div>
      {showCompleteTransactionPopUp &&
        <div>
          <CompleteTransactionPopUp
            setCompleteTransactionPopUp={setCompleteTransactionPopUp}
            getConfirmation={getConfirmation}
            transactionID={props.transactionId}
            completeTransaction={props.completeTransaction}
            getNextTransactionId={props.getNextTransactionId}
            goToNextTransaction={goToNextTransaction}
            facilityId={props.facilityId}
            onChangeDataEntryTab={props.onChangeDataEntryTab}
            activeTabId={props.activeTabId}
            setWait={setWait}
            requestType={props.formState.values[FormConstants.REQUEST_TYPE]}
            setCreateNewTransactionComplete={setCreateNewTransactionComplete}
            createTransaction={createTransaction}
            clearUserLocks={clearUserLocks}
            isCreateTransaction={createNewTransactionComplete}
            clearPreviousTransactionOnComplete={clearPreviousTransactionOnComplete}
            requestContext={props.requestContext}
            resetRequestContext={() => props.resetRequestContext && props.resetRequestContext()}
            hasSensitiveData={sensitiveInfoOkHandlerValue}
            setCloseTranctionComplete = {setCloseNewTransactionComplete}
            closeTranctioncomplete = {closeTransactionComplete}
          />
        </div>
      }

      {showCompleteReviewPopUp &&         
        <div>
          <CompleteReviewPopUp
            setShowCompleteReviewPopUp={setShowCompleteReviewPopUp}
            transactionId={props.transactionId}
            completeTransaction={props.completeTransaction}
            returnToQueue={props.returnToQueue}
            setWait={setWait}
            clearPreviousTransactionOnComplete={clearPreviousTransactionOnComplete}
            goToNextTransaction={goToNextTransaction}
            facilityId={props.facilityId}
            requestContext={props.requestContext}
            onChangeDataEntryTab={props.onChangeDataEntryTab}
          />
        </div>
      }

      {showQAPDIssuePopUp &&
        <div>
          <QAPDIssuePopUp
            isOpen={showQAPDIssuePopUp}
            handleClose={setShowQAPDIssuePopUp}
            handleOk={props.returnToQueue}
            transactionId={props.transactionId}
            qaPDIssues={props.qaPDIssues}
            setWait={setWait}
            clearPreviousTransactionOnComplete={clearPreviousTransactionOnComplete}
          />
        </div>
      }
     
      <div className={`panel-footer text-center panel-footerRight ${activeTabId === 4 && 'records-footer'}`}>
        {
          activeTabId === 1 || activeTabId === 3
            ? (<span className="proceedCircle" onClick={onBtnClick}>
              <span className="text-center proceed-link">
                <ProceedIcon color={'#4d7f9d'} />
                Proceed to {normalizedTabItems[updatedActiveTabId].label}
              </span>
            </span>)
            :
            activeTabId === 2 && props.formState.values && props.formState.values[FormConstants.REQUEST_TYPE] !== '66'
              ? (<span className="proceedCircle" onClick={onBtnClick}>
                <span className="text-center proceed-link">
                  <ProceedIcon color={'#4d7f9d'} />
                  Proceed to {normalizedTabItems[updatedActiveTabId].label}
                </span>
              </span>)
              :
              (props.transactionStatus?.id >= 130 && props.transactionStatus?.id <= 141 ? (
                <button className="btn btn-primary" type="button" onClick={onCompleteTransaction}>
                  Complete Review
                </button>
              ) : (
                <div className='btn-group flex-wrap'>
                <div className = "btn-group">
                  <button type = "button" className = "btn btn-primary dropdown-toggle" data-toggle = "dropdown">
                      Complete 
                      <span className = "caret"></span>
                  </button>
                  
                  <ul className="dropdown-menu" style={{padding:"10px", whiteSpace:"nowrap"}}>
                      <li><a href = "#!" onClick={onCloseCompleteTransaction}>Complete & Close</a></li>
                      <li><a href = "#!" onClick={onCompleteTransaction}>Complete & Next</a></li>
                      <li><a href = "#!" onClick={onCompleteTransactionNewRequest}>Complete & New</a></li>
                  </ul>
                </div>
              </div>
              ))
        }
      </div>

    </div>
  );
}

function mapStateToProps(state: any, props: any) {
  const formValues = state.form && state.form[FormConstants.DATA_ENTRY_FIELDS_FORM] ?
    state.form[FormConstants.DATA_ENTRY_FIELDS_FORM].values : {}
  const patientDetails = state.dataEntryState.patientDetailsAttributes
  const transactionIDAttributes = state.dataEntryState.transactionIDAttributes
  const transactionDetails = props.transactionDetails || {}
  const selectedPHICodes = props.selectedPHICodes || []
  const additionalChargeRecords = props.additionalChargeRecords || {}
  const requestorDetails = state.dataEntryState.requestorDetails
  const requestorBillingDetails = state.dataEntryState.requestorBillingDetails

  const onChangePurpose = () => {
    let requestTypeId = DEFAULT_DROPDOWN_VALUE
    let requestTypes = state.dataEntryState.requestTypes
    let purposeTypeId = transactionIDAttributes.id
    Object.keys(requestTypes).map((key) => {
      if (requestTypeId === DEFAULT_DROPDOWN_VALUE && requestTypes[key]["requestPurpose"].indexOf(parseInt(purposeTypeId)) > -1) {
        requestTypeId = requestTypes[key]["id"]
      }
      return null
    })
    return requestTypeId.toString()
  }

  const onChangeSensitiveInfo = () => {
    let disclosureChanged = formValues && (!state.landingPageState.isNewTransaction || formValues[FormConstants.DISCLOSURE_CHANGED]);

    const requestSensitiveData = state.dataEntryState.transactionDetails.sensitiveInfo;
    const sensitiveInfo = state.form.DATA_ENTRY_FIELDS_FORM.sensitiveInfo;

    let obj = {};
    let isSelectAllValues:any = null;
    let isSelectNoValues:any = null;
    if (requestSensitiveData && sensitiveInfo && (disclosureChanged || sensitiveInfo.some(d => d) || requestSensitiveData.some(d => d.stateID === 1))) {
      

      requestSensitiveData.forEach(el => {
         obj = { ...obj, [el.name]: el.stateID === 1 ? { recordType: el.name } : null }
      })
      isSelectAllValues = Object.keys(sensitiveInfo).every(i => i != null);
      isSelectNoValues = Object.keys(sensitiveInfo).every(i => i == null);
      disclosureChanged = true;
    }
    return { isSelectAllValues, isSelectNoValues, sensitiveInfo: obj, disclosureChanged }
  }

  const onChangePHIcodes = () => {
    let obj = {};
    if (selectedPHICodes) {
      selectedPHICodes.map(PHIData => {
        if (PHIData.isRetrieved) {
          obj = { ...obj, [PHIData.requestedItemID]: { code: PHIData.code, description: PHIData.description } }

        }
        return null
      })
    }

    return obj
  }
  const getReleaseInfoDetails = () => {
    let selectedValues = {};
    let isAllDatesChecked = false;
    let fromDate = null;
    let toDate = null;

    const releaseInfo = state.dataEntryState.transactionDetails?.releaseInfo;

    const item = releaseInfo?.find(d => d.checked);

    if(item){
      isAllDatesChecked = item.allDates
      fromDate = item.dateOfService?.from || null
      toDate = item.dateOfService?.to || null
      selectedValues = {
        ...selectedValues,
        [item.code]: { 
          recordType: item.code
        }
      }

    }

    return { selectedValues, isAllDatesChecked, fromDate, toDate }
  }

  const { isSelectAllValues, isSelectNoValues, sensitiveInfo, disclosureChanged } = onChangeSensitiveInfo()
  const { selectedValues, isAllDatesChecked, fromDate, toDate } = getReleaseInfoDetails()

  return {
    formState: state.form && state.form[FormConstants.DATA_ENTRY_FIELDS_FORM],
    transactionId: state.landingPageState.transactionId,
    initialValues: {
      ...formValues,
      [FormConstants.PURPOSE_TYPE]: transactionIDAttributes.id?.toString(),
      [FormConstants.REQUEST_TYPE]: onChangePurpose(),
      [FormConstants.PATIENT_NAME]: patientDetails.contact?.firstName,
      [FormConstants.PATIENT_LAST_NAME]: patientDetails.contact?.lastName,
      [FormConstants.PATIENT_MIDDLE_NAME]: patientDetails?.contact?.middleInitial ? patientDetails?.contact?.middleInitial
        : (patientDetails?.contact?.middleName.length ? patientDetails?.contact?.middleName?.charAt(0).toUpperCase() : ''),
      [FormConstants.DOB]: patientDetails.dob ? new Date(patientDetails.dob) : null,
      [FormConstants.EMPI_NUMBER]: patientDetails?.empi?.toString(),
      [FormConstants.MRN_ID]: (patientDetails.mrn === "" ? null : patientDetails.mrn) || (transactionDetails.mrn === "" ? null : transactionDetails.mrn),
      [FormConstants.AUTHORIZATION_DATE]: transactionDetails.date?.authorization ? transactionDetails.date.authorization : null,
      [FormConstants.REQUEST_DATE]: transactionDetails.date?.request ? transactionDetails.date.request : null,
      [FormConstants.RECEIVED_DATE]: transactionDetails.date?.received ? transactionDetails.date.received : null,
      [FormConstants.DUE_DATE]: transactionDetails.date?.due ? transactionDetails.date.due : null,
      [FormConstants.REQUEST_ID]: transactionDetails.requestID,
      [FormConstants.CASE_NUMBER]: transactionDetails.caseNumber,
      [FormConstants.CLINIC_RESPONISBLE]: transactionDetails.disclosingInstitution?.id?.toString(),
      [FormConstants.SITE_CODE]: '',
      [FormConstants.SEARCH_LAST_NAME]: '',
      [FormConstants.SEARCH_DOB]: '',
      [RequestorFormConstants.PHONE_FAX_NUMBER]: "",
      [RequestorFormConstants.EMAIL_ADDRESS]: "",
      [RequestorFormConstants.EMAIL]: requestorDetails?.requestorInfo?.email,
      [RequestorFormConstants.FAX_NUMBER]: requestorDetails?.requestorInfo?.fax,
      [RequestorFormConstants.REQUESTOR_NAME]: requestorDetails?.fullName,
      [RequestorFormConstants.ADDRESS]: requestorDetails?.requestorAddress?.address1,
      [RequestorFormConstants.ADDRESS2]: requestorDetails?.requestorAddress?.address2,
      [RequestorFormConstants.CITY]: requestorDetails?.requestorAddress?.city,
      [RequestorFormConstants.STATE]: requestorDetails?.requestorAddress?.state,
      [RequestorFormConstants.ZIP]: requestorDetails?.requestorAddress?.zipCode,
      [RequestorFormConstants.FIRST_NAME]: requestorDetails?.requestorInfo?.firstName,
      [RequestorFormConstants.LAST_NAME]: requestorDetails?.requestorInfo?.lastName,
      [RequestorFormConstants.PHONE_NUMBER]: requestorDetails?.requestorInfo?.phone,
      [RequestorFormConstants.INTENRNAL_REQUESTOR]: "",
      [FormConstants.DISCLOSURE_REASON]: transactionDetails.disclosure?.disclosureReasonID,
      [FormConstants.DISCLOSURE_DATE]: transactionDetails.disclosure?.disclosureDate,
      [FormConstants.PAGES_DISCLOSED]: transactionDetails.disclosure?.numberOfPage,
      [FormConstants.PROTOCOL_NAME]: transactionDetails.disclosure?.protocolName,
      [FormConstants.PROTOCOL_NUMBER]: transactionDetails.disclosure?.protocolnumber,
      [FormConstants.SPECIAL_NOTES]: transactionDetails.disclosure?.specialNote,
      [FormConstants.PHI]: onChangePHIcodes(),
      [FormConstants.RTR_FROM]: fromDate || null,
      [FormConstants.RTR_TO]: toDate || null,
      [FormConstants.RTR_ALL]: isAllDatesChecked,
      [FormConstants.RETRIEVE_RECORD_TYPE]: selectedValues,
      [FormConstants.SENSITIVE_INFORMATION]: sensitiveInfo,
      [FormConstants.DISCLOSE_ALL_INFO]: isSelectAllValues,
      [FormConstants.DONT_DISCLOSE_ANY_INFO]: isSelectNoValues,
      [FormConstants.DISCLOSURE_CHANGED]: disclosureChanged,
      [FormConstants.GENETIC_INFORMATION]: '',
      [FormConstants.HIV]: '',
      [FormConstants.MENTAL_HEALTH]: '',
      [FormConstants.SUBSTANCE_ABUSE]: '',
      [FormConstants.STD]: '',
      [FormConstants.AUDIT_TYPE]: transactionDetails.requestForAuditType,
      [FormConstants.ON_BEHALF_OF]: transactionDetails.onBehalfOf,
      [BillingFormconstants.DELIVERY_INSTRUCTIONS]: transactionDetails?.deliveryInstructions,
      [BillingFormconstants.ADDITIONAL_CHARGES]: additionalChargeRecords,
      [BillingFormconstants.FEE_SCHEDULES]: transactionDetails?.feeSchedule?.id,
      [BillingFormconstants.BILLING_TYPES]: transactionDetails?.billingTypeID,
      [BillingFormconstants.RELEASE_METHODS]: transactionDetails?.releaseMethod?.id,
      [BillingFormconstants.SHIPPING_SERVICES]: transactionDetails?.shippingService,
      [BillingFormconstants.AUXILIARY_CHARGE]: transactionDetails?.includeAuxilliaryPages,
      [BillingFormconstants.CERTIFICATION_REQUESTED]: transactionDetails?.certificationRequired,
      [BillingFormconstants.REQUIRES_PREPAYMENT]: requestorDetails?.prePay,
      [BillingFormconstants.CHARGE_FOR_POSTAGE]: transactionDetails?.postageIsApplied,
      [BillingFormconstants.RELEASE_METHOD_CHARGE]: transactionDetails?.includeRequestPages,
      [BillingFormconstants.SEND_TO_REQUESTOR_NAME]: requestorDetails?.fullName,
      [BillingFormconstants.SEND_TO_FIRST_NAME]: requestorDetails?.requestorInfo?.firstName,
      [BillingFormconstants.SEND_TO_LAST_NAME]: requestorDetails?.requestorInfo?.lastName,
      [BillingFormconstants.SEND_TO_ADDRESS_LINE1]: requestorDetails?.requestorAddress?.address1,
      [BillingFormconstants.SEND_TO_ADDRESS_LINE2]: requestorDetails?.requestorAddress?.address2,
      [BillingFormconstants.SEND_TO_CITY]: requestorDetails?.requestorAddress?.city,
      [BillingFormconstants.SEND_TO_STATE]: requestorDetails?.requestorAddress?.state,
      [BillingFormconstants.SEND_TO_EMAIL_ADDRESS]: requestorDetails?.requestorInfo?.email,
      [BillingFormconstants.SEND_TO_ZIP]: requestorDetails?.requestorAddress?.zipCode,
      [BillingFormconstants.SEND_TO_PHONE]: requestorDetails?.requestorInfo?.phone,
      [BillingFormconstants.SEND_TO_FAX]: requestorDetails?.requestorInfo?.fax,
      [BillingFormconstants.SAME_AS_SEND_TO]: false,
      [BillingFormconstants.BILL_TO_REQUESTOR_NAME]: requestorBillingDetails?.requestorBillingLocation,
      [BillingFormconstants.BILL_TO_FIRST_NAME]: requestorBillingDetails?.firstName,
      [BillingFormconstants.BILL_TO_LAST_NAME]: requestorBillingDetails?.lastName,
      [BillingFormconstants.BILL_TO_ADDRESS_LINE1]: requestorBillingDetails?.address?.address1,
      [BillingFormconstants.BILL_TO_ADDRESS_LINE2]: requestorBillingDetails?.address?.address2,
      [BillingFormconstants.BILL_TO_CITY]: requestorBillingDetails?.address?.city,
      [BillingFormconstants.BILL_TO_STATE]: requestorBillingDetails?.address?.state,
      [BillingFormconstants.BILL_TO_EMAIL_ADDRESS]: requestorBillingDetails?.email,
      [BillingFormconstants.BILL_TO_ZIP]: requestorBillingDetails?.address?.zipCode,
      [BillingFormconstants.BILL_TO_PHONE]: requestorBillingDetails?.phone,
      [BillingFormconstants.BILL_TO_FAX]: requestorBillingDetails?.fax,
      [BillingFormconstants.REQUIRES_PREPAYMENT]: requestorBillingDetails?.prePay,
      [BillingFormconstants.DIGITAL_FAX]: state.dataEntryState.confirmFax,
      //dds fields
      [RequestorFormConstants.RQID]: transactionDetails?.transactionDds?.rqid,
      [RequestorFormConstants.RQ_SITE_CODE]: transactionDetails?.transactionDds?.txSiteCode,
      [RequestorFormConstants.TX_SITE_CODE]: transactionDetails?.transactionDds?.txSiteCode,
      [RequestorFormConstants.DOC_RETURN_CODE]: transactionDetails?.transactionDds?.docReturnCode,
      [RequestorFormConstants.DOC_TYPE]: transactionDetails?.transactionDds?.docType,
      [RequestorFormConstants.SSN]: transactionDetails?.transactionDds?.ssn,
      [RequestorFormConstants.NO_BARCODE]: transactionDetails?.transactionDds?.noBarcode,
      [RequestorFormConstants.ROUTING_FIELD]: transactionDetails?.transactionDds?.routingField,
      [FormConstants.ESCALATION]: transactionDetails?.isEscalated
    },
    requestTypes: state.dataEntryState.requestTypes,
    purposeOfRequests: state.dataEntryState.purposeOfRequests,
    discloseInstitutions: state.dataEntryState.discloseInstitutions,
    transactionDetails,
    selectedPHICodes: state.dataEntryState.selectedPHICodes,
    additionalChargeRecords,
    requestorContactDetails: state.dataEntryState.requestorContactDetails,
    feeSchedules: state.dataEntryState.feeSchedule,
    releaseMethods: state.dataEntryState.releaseMethod,
    shippingServices: state.dataEntryState.shippingService,
    billingTypes: state.dataEntryState.billingType,
    selectedFacilityDetails: state.landingPageState.selectedFacilityDetails,
    requestorSearchDetails: state.dataEntryState.requestorSearchDetails,
    globalUser: state.loginState.userID,
    contactSearchDetails: state.dataEntryState.contactSearchDetails,
    patientSearchDetails: state.dataEntryState.patientSearchDetails,
    phiCodes: state.dataEntryState.phiCodes,
    formSyncErrors: state.dataEntryState.formSyncErrors,
    getRequestorSearchDetailsAPIStatus: state.dataEntryState.getRequestorSearchDetailsAPIStatus,
    getPatientSearchResultsAPIStatus: state.dataEntryState.getPatientSearchResultsAPIStatus,
    getContactSearchDetailsAPIStatus: state.dataEntryState.getContactSearchDetailsAPIStatus,
    facilityId: state.landingPageState.selectedFacilityDetails?.facility.id,
    canEditPatient: state.dataEntryState.patientDetailsAttributes?.canEdit,
    updatedPatientDetailsAttributes: Object.keys(state.dataEntryState.rightPaneUpdatedPatientDetailsAttributes).length ? 
      state.dataEntryState.rightPaneUpdatedPatientDetailsAttributes :
      {
      transactionId: props.transactionId,
      [FormConstants.PATIENT_NAME]: patientDetails.contact?.firstName,
      [FormConstants.PATIENT_MIDDLE_NAME]: patientDetails?.contact?.middleInitial ? patientDetails?.contact?.middleInitial
        : (patientDetails?.contact?.middleName.length ? patientDetails?.contact?.middleName?.charAt(0).toUpperCase() : ''),
      [FormConstants.PATIENT_LAST_NAME]: patientDetails.contact?.lastName,
      [FormConstants.DOB]: patientDetails.dob ? new Date(patientDetails.dob) : null,
      [FormConstants.EMPI_NUMBER]: patientDetails?.empi || '',
      [FormConstants.MRN_ID]: patientDetails.mrn,
      patientDataid: props.transactionDetails?.patient?.id
    },
    patientDetailsUpdated: state.dataEntryState.patientDetailsUpdated,
    auditTypes: state.dataEntryState.auditTypes,
    onBehalfOf: state.dataEntryState.onBehalfOf,
    requestorDetails: state.dataEntryState.requestorDetails,
    previousTransactionId: state.dataEntryState.requestorDetails.previousTransactionId,
    lastUsedRequestorContact: state.dataEntryState.lastUsedRequestorInfo,
    validationPassed: state.dataEntryState.getValidation == API_SUCCESS,
    transactionDDS: state.dataEntryState.readBarCodeDDS,
    documentsList: state.dataEntryState.documentsList,
    intiaTxlSagaCallCount: state.dataEntryState.intiaTxlSagaCallCount,
    isNewTransaction: state.landingPageState.isNewTransaction,
    facilityAdditionalFields: state.dataEntryState.FacilityAdditionalFields,
    requestContext: state.landingPageState.requestContext,
    updateDeliveryInstructionsStatus: state.dataEntryState.updateDeliveryInstructionsStatus,
    transactionStatus: state.dataEntryState.leftPaneTransactionDetails?.transactionStatus,
    readBarCodeDDSAPIStatus: state.dataEntryState.readBarCodeDDSAPIStatus,
    requestorComplete: state.dataEntryState.requestorComplete,
    getQAPDIssues: state.dataEntryState.getQAPDIssues,
    qaPDIssues: state.dataEntryState.qaPDIssues,
  }
}

export function mapDispatchToProps(dispatch: Function) {
  const ret = {
    sendToRequestorData: (requestData: sendToRequestorDataProps) =>
      dispatch(sendToRequestorDataActionCreator(requestData)),
    sendToContactData: (requestContactData: sendToContactDataProps) =>
      dispatch(sendToContactDataActionCreator(requestContactData)),
    updateSendToRequestorDetails: (requestData: sendToRequestorDataProps) =>
      dispatch(updateSendToRequestorDataActionCreator(requestData)),
    updateBillToRequestorData: (data: any) =>
      dispatch(updateBillToRequestorDetailsRequest(data)),
    patchSendToRequestorContactData: (requestData: sendToRequestorDataProps) => (
      dispatch(patchSendToRequestorContactActionCreators(requestData))),
    getRequestorSearchDetailsActionCreator: (data: any, facilityId: string, globalUserId: number) => (
      dispatch(getRequestorSearchDetailsActionCreator(data, facilityId, globalUserId))),
    getPatientListSearchDetails: (facilityId: string, lastName: string, dob: any) => (
      dispatch(getPatientSearchDetailsActionCreator(facilityId, lastName, dob))),
    getContactSearchDetailsActionCreator: (requestorId: any, distinct: boolean | undefined) => (
      dispatch(getContactSearchDetailsActionCreator(requestorId, distinct))),
    updateBillingPaneFields: (data: any, updateData) => {
      return dispatch(updateBillingPaneFields(data, updateData))
    },
    createPatientRecordRequest: (data: any, onSuccess) => {
      return dispatch(createPatientRecordRequest(data, onSuccess))
    },
    updateFormSyncErrors: (data: any) => dispatch(updateFormSyncErrorsActionCreator(data)),
    updateDisclosureInformationDetails: (data: any) => dispatch(updateDisclosureInformationActionCreator(data)),
    completeTransaction: (data: any) => dispatch(completeTransactionTaskActionCreator(data)),
    returnToQueue: (data: any) => dispatch(returnToQueueTaskActionCreator(data)),
    getQAPDIssues: (transactionId: string) => dispatch(getQAPDIssuesActionCreator(transactionId)),
    getBillToRequestorDetails: (data: any) => dispatch(billToRequestorDetailsActionCreator(data)),
    createPatientRecordSuccess: (data: any) => dispatch(createPatientRecordSuccess(data)),
    getNextTransactionId: (data: string, context) =>
      dispatch(nextTransactionIdActionCreator(data, context)),
    patchRequestorComplete: (data: string) => dispatch(requestorCompleteActionCreator(data)),
    patchBillingComplete: (data: string) => dispatch(billingCompleteActionCreator(data)),
    patchDetailsComplete: (data: string) => dispatch(detailsCompleteActionCreator(data)),
    patchPatientDetailsCreator: (data: any) => dispatch(patchPatientDetailsCreator(data)),
    getAuditTypesRequest: (data: any) => dispatch(getAuditTypesRequest(data)),
    getOBOsRequest: (data: any) => dispatch(getOBOsRequest(data)),
    checkValidation: (data: any) => dispatch(getValidateRequest(data)),
    setLastUsedRequestorInfo: (data: any) => dispatch(setLastUsedRequestorInfo(data)),
    checkDuplicates: (transactionId: string, caseNumber: string) => dispatch(checkDuplicateTransactions(transactionId, caseNumber)),
    createTransaction: (data: any) => dispatch(createTransaction(data)),
    clearUserLocks: () => dispatch(clearUserLocksActionRequest()),
    clearPreviousTransactionOnComplete: () => dispatch(clearPreviousTransactionOnCompleteCreator()),
    readBarCodeDDSRequest: (documentId: string) => dispatch(readBarCodeDDSRequest(documentId)),
    updateRequestorDetails: (data: any) => dispatch(updateRequestorDetailsAction(data)),
    saveInformationToRelease: (data: any) =>  dispatch(saveInformationToReleaseActionCreator(data)),
    resetRequestContext: () => dispatch(resetRequestContext()),
    getTransactionAttributes: (transactionId: string) =>
      dispatch(getTransactionAttributesActionCreator(transactionId)),
    putDigitalFaxValidation: (transactionId: string, faxNumber: any) => dispatch(putDigitalFaxValidationCreator(transactionId, faxNumber)),
    putAuditTransactionSensitiveInfo: (transactionId: string, recordContains: any) => dispatch(putAuditTransactionSensitiveInfoCreator(transactionId, recordContains)),
    updateRequestorContactInfo : (data: any) => dispatch(updateRequestorContactInfo(data)),
    resetRequestorContactList: () => dispatch(resetRequestorContactList()),
    getFacilityReleaseMethods: (facilityId: string, transactionId: string) => dispatch(getReleaseMethodOptionsData(facilityId, transactionId)),
    setConfirmFaxNumberAction: (faxNumber: string) => dispatch(setConfirmFaxNumberAction(faxNumber)),
    getNextQATransactionCall: () => dispatch(getNextQATransaction(null)),
  };
  return ret;
}

const DataEntryDetailsReduxForm = reduxForm<{}, IpropType>({
  form: FormConstants.DATA_ENTRY_FIELDS_FORM,
  validate: localValidations,
  onSubmit: submitValidations,
  touchOnChange: true,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true
})(DataEntryDetailsRightPane)

export default connect<{}, {}, IpropType>(mapStateToProps, mapDispatchToProps)(
  DataEntryDetailsReduxForm
)
