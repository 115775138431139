import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import DropdownField from "../../formFields/DropdownField";
import editSmallIcon from '../../../assets/images/editIcons/editSmallIcon.png';
import RemoveIcon from "../../../assets/images/tabImages/RemoveIcon.png"
import '../CustomTable.scss';
import { normalizeData, areValuesIdentical } from '../../utils/appUtils';
import TextField from '../../formFields/TextField';
import DateField from '../../formFields/DateField';
import { reduxForm } from 'redux-form';
import FormConstants from '../../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants';
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from '../../../common/constants/ApiConstants';
import localValidations from '../../../components/dataEntryDetails/dataEntryComponents/validations/localValidations';
import submitValidations from '../../../components/dataEntryDetails/dataEntryComponents/validations/localValidations';
import { connect } from "react-redux";
import saveIcon from "../../../assets/images/Save_icon_small.png";
import cancelIcon from "../../../assets/images/x_cancel_icon.png";
import editGreyIcon from '../../../assets/images/editIcons/editGrey.png';
import moment from "moment";
import { isConstructorDeclaration } from 'typescript';

interface Data {
    description: string;
    source: string;
    sab: string;
    size: string;
    pageCount: number;
    actions: string;
    dateOfService: string;
}

type Order = 'asc' | 'desc';


function stableSort(array, order, orderBy) {
    const compare = (a, b) => {
        return (a[orderBy] || "").toString().localeCompare(b[orderBy]);
    }
    if (order === "asc") {
        return array.sort(compare)
    } else {
        return array.sort(compare).reverse()
    }
}

interface HeadCell {
    id: keyof Data;
    label: string;
    sort: boolean;
}

const headCells: HeadCell[] = [
    { id: 'description', label: 'RECORD NAME', sort: true },
    { id: 'dateOfService', label: 'Dates Of Service', sort: true },
    { id: 'source', label: 'SOURCE', sort: true },
    { id: 'sab', label: 'S/A/B', sort: true },
    { id: 'size', label: 'SIZE', sort: true },
    { id: 'pageCount', label: 'PAGES', sort: true },
    { id: 'actions', label: 'ACTIONS', sort: false },
];

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    hideSource?: Boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    let updatedHeadCells = [...headCells]
    if (props.hideSource) {
        updatedHeadCells.splice(1, 1)
    }
    return (
        <TableHead>
            <TableRow>
                {updatedHeadCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sort ? 
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}

                        >
                            { headCell.label }
                        </TableSortLabel> :
                            headCell.label 
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
interface IpropTypes {
    rows: any,
    getDocumentById: Function,
    setShowRecordGrid: any
    hideSource?: Boolean,
    setSelectedMedDocumentIndex?: any,
    updateDocument: Function,
    transactionId: string,
    documentSources?: any,
    deleteDocument: Function,
    getTransactionDocuments: any,
    deleteDocumentSuccess?: Boolean,
    minDate: Date,
    maxDate: Date,
    auditOnShowDoc: boolean
}
function getMomentDate(date: string, dateFormat: string = "MM/DD/YYYY") {

    if (date && moment(date, dateFormat).isValid()) {
        return moment(date).format(dateFormat);
    }
    return "";
}

function MedicalRecordsRetrieve(props: IpropTypes) {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('pageCount');
    const [documentsList, updateDocumentsList] = React.useState(props.rows);
    const [medicalRecordsFrom, setMedicalRecordsFrom] = useState({});
    const [medicalRecordsTo, setMedicalRecordsTo] = useState({});
    const [editRows, setEditRows] = useState<any>({});

    const onClickCancelButton = (index: number) => {
        let updatedListData = [...documentsList]
        updatedListData[index].isEditing = false;
        updateDocumentsList(updatedListData)
        const edit = { ...editRows };
        edit[index] = null;
        setEditRows(edit);
    }

    const updateDocumentDetails = (data: any) => {
        const requestObject = {
            documentId: data.id,
            recordSourceId: data.sourceID || 3,
            fileDescription: data.description,
            transactionId: props.transactionId,
            account: data.account,
            toDateOfService: data.dateToService,
            fromDateOfService: data.dateOfService
        }
        props.updateDocument(requestObject)
    }

    useEffect(() => {
        const currentListSorted = documentsList.slice((a, b) => (a.id > b.id) ? 1 : -1).sort();
        const updatedListSorted = props.rows.slice((a, b) => (a.id > b.id) ? 1 : -1).sort();

        if (!areValuesIdentical(currentListSorted, updatedListSorted)) {
            updateDocumentsList(props.rows)
        }
    }, [props.rows])

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const removeDocument = (id: any) => {
        if (window.confirm("Are you certain you wish to delete this file?")) {
            props.deleteDocument(id)

        }
    }

    useEffect(() => {
        if (props.deleteDocumentSuccess)
            props.getTransactionDocuments(props.transactionId)
    }, [props.deleteDocumentSuccess]);

    const changeSubRowData = (index, value, sourceID = "34000", key) => {
        let updatedItem = editRows[index]
        if (key === 'account') {
            updatedItem = {
                ...updatedItem,
                account: value,
                sourceID
            }
        } else if (key === 'to') {
            setMedicalRecordsTo({...medicalRecordsTo,
                [index]:value
            })
            updatedItem = {
                ...updatedItem,
                dateToService: getMomentDate(value),
                sourceID
            }
        } else if (key === 'from') {
            setMedicalRecordsFrom({...medicalRecordsFrom,
             [index]: value   
            })
            updatedItem = {
                ...updatedItem,
                dateOfService: getMomentDate(value),
                sourceID
            }
        } else if (key === 'file') {
            updatedItem = {
                ...updatedItem,
                description: value,
                file: {
                    ...updatedItem.file,
                    name: value
                },
                sourceID
            }
        } else if (key === "sourceID") {
            updatedItem = {
                ...updatedItem,
                sourceID: value
            }
        }
        updatedItem = {
            ...updatedItem,
            sourceID: updatedItem.sourceID || "34000"
        }

        const edit = { ...editRows };
        edit[index] = updatedItem;
        setEditRows(edit)
    }
    const medAPIFetch = (documentId) => {
        props.setShowRecordGrid(false)
        props.setSelectedMedDocumentIndex(documentId)
        props.getDocumentById(documentId, props.auditOnShowDoc)
        return ""
    }
    const getFileName = (file: any) => {
        return file.description || file.file?.name
    }

    const [errors, setErrors] = useState({});

    const onClickEditAllButton = () => {
        const editRows = documentsList.map(r => ({
            ...r,
            isEditing: true
        }))
        setEditRows(editRows);
        updateDocumentsList(editRows);
    }

    const onClickCancelAllButton = () => {
        documentsList.forEach((d, i) => {
            if (d.isEditing)
                onClickCancelButton(i)
        });
        setErrors({});
    }

    const onClickSaveAllButton = () => {
        if (Object.keys(errors).every(e => errors[e] === null)){
            let updatedListData = [...documentsList];
            updatedListData.forEach((d, i) => {
                if (d.isEditing){
                    let updatedItem = editRows[i];
                    updatedItem.isEditing = false;
                    updatedListData[i] = updatedItem;
                    updateDocumentsList(updatedListData);
                    updateDocumentDetails(updatedItem);                
                }
            });

            const edit = { ...editRows };
            Object.keys(edit).forEach(k => edit[k] == null)
            setEditRows(edit);
        }
    }

    const errorModel =  { 
        fromError: '', 
        toError: '',
        accountError: ''
    }

    const setError = (index, key, error) => {
        const e = errors[index] || { ...errorModel };
        e[key] = error;
        const newErrs = { ...errors }
        if (Object.values(e).every(x => x === ''))
            newErrs[index] = null;
        else
            newErrs[index] = e;
        setErrors(newErrs);
    }

    const accountAllowed = "ABCDEFGHIJKLMNOPQURSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890-.";

    return (
        <div className="records-table-main" style={props.hideSource ? { width: "85%" } : {}}>
            <div className='edit-all-controls'>
                <a href="#!" onClick={onClickEditAllButton}>
                    <img className='record_edit' src={editSmallIcon} alt="Edit Icon" />
                    <span className='record_edit_span' >Edit</span>
                </a>
            </div>
            <Table
                size="small" stickyHeader className='records-table'
            >
                <div className="records-table-inner">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        hideSource={props.hideSource}
                    />
                    <TableBody>
                        {stableSort(documentsList, order, orderBy)
                            .map((row: any, index) => {
                                const fileSize = row.file?.size?.bytes
                                const editRow = editRows[index];

                                const fileName = getFileName(row.isEditing ? editRow : row)
                                const setFromError = (error) => {
                                    setError(index, "fromError", error);
                                }
                                const setToError = (error) => {
                                    setError(index, "toError", error);
                                }
                                const setAccountError = (error) => {
                                    setError(index, "accountError", error);
                                }
                                return (
                                    <>
                                        <TableRow hover key={row[index]} className={row.isEditing ? 'edit-record-row' : 'noEdit-record-row'}>
                                            <TableCell className='record_name' colSpan={row.isEditing ? 2 : 1} >
                                                {row.isEditing ? 
                                                    <TextField 
                                                        className="edit-textinput-medical" 
                                                        label="" value={fileName}
                                                        onChange={(value) => { changeSubRowData(index, value, row.sourceID, 'file') }} 
                                                        errMsg="" 
                                                        placeholder=""
                                                        suppressEnterSubmit={true} />
                                                    : <>
                                                        <a href="#!" onClick={() => medAPIFetch(row.id)} style={fileName && fileName.length > 0 ? {} : { opacity: 0.5 }}>{fileName && fileName.length > 0 ? fileName : "No file name"}</a>
                                                    </>
                                                }
                                            </TableCell>
                                            {row.isEditing ? null:
                                            <TableCell className='record_dob'>
                                                    {row.dateOfService ? getMomentDate(row.dateOfService, "M/D/YYYY") : null }
                                                    {!row.dateOfService && !row.dateToService ? null : " - "}
                                                    {row.dateToService ? getMomentDate(row.dateToService, "M/D/YYYY") : null }
                                            </TableCell>
                                            }
                                            {props.hideSource ? null : <TableCell className='record_source'>
                                                {row.isEditing ? <DropdownField
                                                    required={true}
                                                    options={props.documentSources}
                                                    onChange={(event) => { changeSubRowData(index, event.target.value, event.target.value, 'sourceID') }}
                                                    input={{ value: editRow.sourceID || "34000" }}
                                                    meta='abc'
                                                />
                                                    : <>
                                                        {props.documentSources.filter(s => s.value == (row.sourceID || "34000"))[0].label}
                                                    </>}
                                            </TableCell>}
                                            <TableCell className='record_sab'>{row.sab}</TableCell>
                                            <TableCell className='record_size'>{isNaN(fileSize) ? fileSize : ((Number(fileSize) * 0.00000095367432).toFixed(2) + "MB")}</TableCell>
                                            <TableCell className='record_pages'>{row.pageCount}</TableCell>
                                            <TableCell className='record_options'>
                                                <div className='record_options_items'>
                                                    <span className='remove_doc' onClick={() => removeDocument(row.id)} style={{display:'flex', alignItems:'center', marginTop:1}}>
                                                        <svg width="15" height="15" viewBox="0 0 1200 1200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M600 0C268.629 0 0 268.629 0 600C0 931.371 268.629 1200 600 1200C931.371 1200 1200 931.371 1200 600C1200 268.629 931.371 0 600 0ZM411.475 262.5L600 451.025L788.525 262.5L937.5 411.475L748.975 600L937.5 788.525L788.525 937.5L600 748.975L411.475 937.5L262.5 788.525L451.025 600L262.5 411.475L411.475 262.5Z" fill="#CC5967" />
                                                        </svg>
                                                        <span className='record_remove_span'>Remove</span>
                                                    </span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        {row.isEditing ? <TableRow hover key={row[index]} className="secondrow">
                                            <TableCell className="medical-record-from-date">
                                                <DateField
                                                    label={FormConstants.MEDICAL_RECORDS_FROM}
                                                    name={FormConstants.MEDICAL_RECORDS_FROM}
                                                    minDate={props.minDate}
                                                    maxDate={medicalRecordsTo[index] === '' ? props.maxDate : new Date(medicalRecordsTo[index])}
                                                    placeholder="mm/dd/yyyy"
                                                    errMsg={errors[index]?.fromError || ""}
                                                    value={editRow.dateOfService}
                                                    onChange={(value) => {
                                                        const date = moment(value).startOf('day').toDate();
                                                        const maxdate = medicalRecordsTo[index] === '' ? props.maxDate : medicalRecordsTo[index];
                                                        if (date === null) {
                                                            setFromError("")
                                                        } else if (date > props.maxDate) {
                                                            setFromError("The From date can not be greater than the current date.")
                                                        } else if (date > maxdate) {
                                                            setFromError("The From date can not be greater than the To date or the current date")
                                                        } else if (date < props.minDate) {
                                                            setFromError("The From date can not be earlier than the patient DoB")
                                                        } else {
                                                            setFromError("")
                                                        }

                                                        changeSubRowData(index, date, row.sourceID, 'from')

                                                    }}

                                                />

                                            </TableCell> 
                                            <TableCell className="medical-record-to-date">
                                                <DateField
                                                    label={FormConstants.MEDICAL_RECORDS_TO}
                                                    name={FormConstants.MEDICAL_RECORDS_TO}
                                                    minDate={medicalRecordsFrom[index] === '' ? props.minDate : new Date(medicalRecordsFrom[index])}
                                                    maxDate={props.maxDate}
                                                    placeholder="mm/dd/yyyy"
                                                    errMsg={errors[index]?.toError || ""}
                                                    value={editRow.dateToService}
                                                    onChange={(value) => {
                                                        const date = moment(value).startOf('day').toDate();
                                                        const mindate = medicalRecordsFrom[index] === '' ? props.minDate : medicalRecordsFrom[index];
                                                        if (date === null) {
                                                            setToError("")
                                                        } else if (date > props.maxDate) {
                                                            setToError("The To date can not be greater than the current date.")
                                                        } else if (date < mindate) {
                                                            setToError("The To date can not be earlier than the From date or the patient DoB")
                                                        } else {
                                                            setToError("")
                                                        }

                                                        changeSubRowData(index, date, row.sourceID, 'to')
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell colSpan={4} className="account-no-text">
                                                <TextField
                                                    label={FormConstants.MEDICAL_ACCOUNT_NUMBER}
                                                    name={FormConstants.MEDICAL_ACCOUNT_NUMBER}
                                                    maxLength={100}
                                                    placeholder="123456789"
                                                    errMsg={errors[index]?.accountError || ""}
                                                    disabled={false}
                                                    onChange={(value) => { 
                                                        if([...value].some(c => !accountAllowed.includes(c))){
                                                            setAccountError("Only letters, numbers, hyphen and period characters allowed")
                                                        }else{
                                                            setAccountError("")
                                                        }
                                                        changeSubRowData(index, value, row.sourceID, 'account') }
                                                    }
                                                    value={editRow.account}
                                                    suppressEnterSubmit={true} 
                                                />
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableRow> : ''}
                                    </>
                                );
                            })}

                    </TableBody>
                </div>
            </Table>
            {documentsList.filter(d => d.isEditing).length > 0 ? 
                <div className='edit-all-controls'>
                    <a href="#!" style={{ whiteSpace: "nowrap" }} onClick={onClickSaveAllButton}>
                        <img className='record_edit' src={saveIcon} alt="Save Icon" />
                        <span className='record_edit_span'>Save</span>
                    </a>
                    <a href="#!" style={{ whiteSpace: "nowrap" }} onClick={onClickCancelAllButton}>
                        <img className='record_edit' src={cancelIcon} alt="Save Icon" />
                        <span className='record_edit_span'>Cancel</span>
                    </a>
                </div>
            : <></>}
        </div>
    );
}

const DataEntryDetailsReduxForm = reduxForm<{}, IpropTypes>({
    form: FormConstants.DATA_ENTRY_FIELDS_FORM,
    validate: localValidations,
    onSubmit: submitValidations,
    enableReinitialize: true,
    touchOnChange: true,
})(MedicalRecordsRetrieve);

function mapStateToProps(state: any) {

    return {
        deleteDocumentSuccess: state.dataEntryState.deleteDocumentAPISuccess
    };
}
export function mapDispatchToProps(dispatch: Function) {
    return {
    };
}

export default connect<{}, {}, IpropTypes>(
    mapStateToProps,
    mapDispatchToProps
)(DataEntryDetailsReduxForm);