import React, { useState, useEffect } from "react";
import { Col, Row} from "react-bootstrap";
import { connect } from "react-redux";
import CancelMarkFilled from "../../../assets/images/x_cancel_icon.png";
import IconWithButton from "../../../common/IconWithButton/IconWithButton";
import "../DataEntryDetails.scss";
import { dataEntryState, 
        getQAIssuesActionCreator,
        getQAPDIssuesActionCreator, 
        postQATransactionIssueActionCreators,
        readBarCodeDDSRequest
      } from "../DataEntryDetailsDucks";
import { } from './DataEntryFieldsConstants';

interface IpropType {
    toggleForm?: any,
    showForm?: any, 
    getQAErrors?: any, 
    userAttributes?: any, 
    postQATransactionIssue?: any,
    transactionId?: string,
    getQAIssues?: any,
    getQAPDIssues?: any,
    refreshIssues?: any
    readBarCodeDDSRequest?: Function,
    documentsList?: any,
    currentFile?: string,
    readOnlyTransactionDetails: any,
    isEditIssue?: boolean;
    editPageNumber?: number;
    editIssueType?: number;
    editIssueId?: string;
    editIssueDetails?:string;
}

const AddIssueForm = (props:IpropType) => {
    const [formValues, setFormValues] = useState({
      qaErrorID: '',
      pageNumber: '',
      issueDetails: '',
    });

    const [errors, setErrors] = useState({
      qaErrorID: '',
      pageNumber: '',
      issueDetails: '',
    });

    const releaseDocument = props.readOnlyTransactionDetails == null ? props.documentsList?.find(doc => doc.id.includes("REL")) : props.readOnlyTransactionDetails.documentList.find(doc => doc.id.includes("REL"));
    const maxPage = releaseDocument?.pageCount ? releaseDocument.pageCount : 9999;
  
    // const selectedError = props.getQAErrors?.find(errorType => errorType.qaErrorId === parseInt(formValues.qaErrorID));
    const selectedError = Array.isArray(props.getQAErrors)
    ? props.getQAErrors.find(errorType => errorType.qaErrorId === parseInt(formValues.qaErrorID))
    : undefined;

    useEffect(() =>{
      //type dropdown
      var dropdown = document.getElementById("issue-type") as HTMLSelectElement;
      if (dropdown) {
        dropdown.value = props.editIssueType ? props.editIssueType.toString() : "";
      }

      //page
      var page = document.getElementById("page-number") as HTMLInputElement;
      if (page) {
        page.value = props.editPageNumber ? props.editPageNumber.toString() : "";
      }

      //details
      var details = document.getElementById("issue-details") as HTMLTextAreaElement;
      if (details) {
        details.value = props.editIssueDetails ? props.editIssueDetails.toString() : "";
      }

      setFormValues({
        qaErrorID: dropdown.value,
        pageNumber: page.value,
        issueDetails: details.value
      });
    },[props.isEditIssue])
  
    const handleChange = (event) => {
      let { name, value } = event.target;
      if (name === 'pageNumber') {
        if (parseInt(value, 10) > maxPage) {
          value = maxPage.toString();
            setFormValues({ ...formValues, [name]: maxPage.toString() });
        } else if (parseInt(value, 10) <= 0) { 
          setFormValues({ ...formValues, [name]: 1 });
        } else {
            setFormValues({ ...formValues, [name]: value });
        }
      }
      setFormValues({ ...formValues, [name]: value });
      setErrors({...errors, [name]: ''});
    };
  
    const onClickSaveIssue = async (event) => {
      event.preventDefault();
      let newErrors = { qaErrorID: '', pageNumber: '', issueDetails: '' };
  
      let valid = true;
      if (!(parseInt(formValues.pageNumber, 10) > 0 && parseInt(formValues.pageNumber, 10) <= maxPage)) {
        newErrors.pageNumber = "Invalid page number, must be between 1-" + maxPage +".";
        valid = false;
      }
      if (!formValues.qaErrorID) {
        newErrors.qaErrorID = "Select valid Issue Type.";
        valid = false;
      }
      if (!formValues.issueDetails || formValues.issueDetails.match(/^ *$/) !== null) {
        newErrors.issueDetails = "You must enter valid details about the issue.";
        valid = false;
      }

      if (valid) {
        await props.postQATransactionIssue(
          parseInt(props.transactionId!.split("-")[0]),
          parseInt(props.transactionId!.split("-")[1]),
          parseInt(formValues.qaErrorID, 10),
          formValues.issueDetails,
          releaseDocument.file.name,
          parseInt(formValues.pageNumber, 10),
          props.editIssueId
        );

        setFormValues({
          qaErrorID: '',
          pageNumber: '',
          issueDetails: '',
        });
        props.toggleForm()
      } else {
        setErrors(newErrors);
      }
    };
  
    const validatePageNumberEvent = (e) => {
      if (["e", "E", "+", "-", "."].includes(e.key)) {
        e.preventDefault();
      }
    };
  
    const validatePastedPageNumber = (e) => {
      const clipboardData = e.clipboardData;
      const pastedData = clipboardData.getData('text');
      if (pastedData.includes('e') || pastedData.includes('E') || pastedData.includes('+') || pastedData.includes('-') || pastedData.includes('.')) {
        e.preventDefault();
      }
    };

    const [formHeaderText, setFormHeaderText] = useState("Add Issue");
    if (props.isEditIssue && formHeaderText === "Add Issue"){
      setFormHeaderText('Edit Issue')
    }
  
    return (
      <div className="add-issue-form">
        <Row>
          <Col>
            <span className="static-text" id="add-issue-header">
              {props.isEditIssue ? "Edit Issue" : "Add Issue"}
            </span>
          </Col>
          <Col className="d-flex justify-content-end">
            <a id="cancel-issue" onClick={props.toggleForm}>
              <img src={CancelMarkFilled} className="cancel-icon" alt="Cancel" />
            </a>
          </Col>
        </Row>
  
        <div className="static-text">By: {props.userAttributes?.firstName} {props.userAttributes?.lastName}</div>
  
        <br />
  
        <Row>
          <Col xs={6} md={8}>
            <span>Issue Type</span>
          </Col>
          <Col xs={6} md={4}>
            <span>Page Number</span>
          </Col>
        </Row>
  
        <Row className="type-page">
          <Col className="left-issue" xs={6} md={8}>
            <select className={`input-field form-control ${errors.qaErrorID ? 'is-invalid' : ''}`}
              id="issue-type"
              name="qaErrorID"
              onChange={handleChange}
              value={formValues.qaErrorID}
              required>
              <option id="select-placeholder" value="" disabled>Select Issue Type</option>
              {props.getQAErrors.map((errorType) => (
                <option key={errorType.qaErrorId} value={errorType.qaErrorId}>
                  ({errorType.qaErrorTypeName}) {errorType.qaErrorName}
                </option>
              ))}
            </select>
            {errors.qaErrorID && <div className="invalid-feedback">{errors.qaErrorID}</div>}
          </Col>
  
          <Col className="right-issue" xs={6} md={4}>
            <input
              className={`input-field form-control ${errors.pageNumber ? 'is-invalid' : ''}`}
              id="page-number"
              name="pageNumber"
              max={maxPage}
              type="number"
              placeholder="Page #"
              min="1"
              value={formValues.pageNumber}
              onKeyDown={validatePageNumberEvent}
              onPaste={validatePastedPageNumber}
              onChange={handleChange}
              required
            />
            {errors.pageNumber && <div className="invalid-feedback">{errors.pageNumber}</div>}
          </Col>
        </Row>
        <br />

        <div className="static-text">
          {selectedError?.qaErrorExplanation}
        </div>

        <br />

        <div>Issue Details</div>
        <textarea className={`input-field form-control ${errors.issueDetails ? 'is-invalid' : ''}`}
          id="issue-details"
          name="issueDetails"
          placeholder="Enter details here..."
          onChange={handleChange}
          value={formValues.issueDetails}
          required
        ></textarea>
        {errors.issueDetails && <div className="invalid-feedback">{errors.issueDetails}</div>}
        <br />
        <Row>
          <Col className="d-flex justify-content-end" id="save-btn">
            <IconWithButton onButtonClick={onClickSaveIssue} buttonText='Save Issue' />
          </Col>
        </Row>
      </div>
    );
  };

  function mapStateToProps(state: any) {
    return {
      readOnlyTransactionDetails: state.landingPageState.readOnlyTransactionDetails,
      getQAIssues: state.dataEntryState.getQAIssues,
      getQAPDIssues: state.dataEntryState.getQAPDIssues,
      documentsList: state.dataEntryState.documentsList,
      currentFile: state.dataEntryState.currentFile,
    };
  }
  
  function mapDispatchToProps(dispatch: Function) {
    return {
      getQAIssues: (transactionId: string) => dispatch(getQAIssuesActionCreator(transactionId)),
      getQAPDIssues: (transactionId: string) => dispatch(getQAPDIssuesActionCreator(transactionId)),
      postQATransactionIssue: (organizationId: number, requestBufferId: number, errorId: number, details: string, filename: string, pageNumber: number, editIssueId?: string) =>
        dispatch(postQATransactionIssueActionCreators(organizationId, requestBufferId, errorId, details, filename, pageNumber, editIssueId)),
      readBarCodeDDSRequest: (documentId: string) => dispatch(readBarCodeDDSRequest(documentId)),
    };
  }
  

  export default connect(mapStateToProps, mapDispatchToProps)(AddIssueForm);