import FORM_CONSTANTS, { RequestorFormConstants, RejectModalConstants, BillingFormconstants, FormConstants } from '../DataEntryFieldsConstants'


import { isValid, isPHISelected, isRecordsTypeSelected, isValidCharacters } from '../../../../common/utils/appUtils'
import { INVALID_DATE, DEFAULT_DROPDOWN_VALUE } from '../../../../common/constants/ApiConstants';

export const DISCLOSURE_EVENT_ID = "66"

const FILTERED_REQ_TYPES_FOR_AUTHDATE = ["19", "6", "7", "8", "9", "53"]

export const isAuthDateRequired = (puposeTypeValue: any) => {
    return FILTERED_REQ_TYPES_FOR_AUTHDATE.indexOf(puposeTypeValue) > -1
}

const convertStringToDate = (date: any) => {
    if (typeof date === "string") {
        return new Date(date)
    }
    return date
}

const getBillingTypeId = (feeSchedules, values) => {
    let billingTypeId = feeSchedules?.filter(item => values && Number(item.value) === Number(values[BillingFormconstants.FEE_SCHEDULES]))[0]
    return billingTypeId?.billingType ? billingTypeId.billingType.toString() : ""
}

export const isDateSqlOverflow = (dateToCheck) => {
    if (typeof dateToCheck === 'string' || dateToCheck instanceof String){
        dateToCheck = new Date(dateToCheck.toString())
    }
        

    if(dateToCheck < new Date("1/1/1753") || dateToCheck > new Date("12/31/9999")){
        return false;
    }

    return true;
}

export const ignoreFields = [RejectModalConstants.SELECT_PHRASE, RejectModalConstants.TRANSACTION_CANCEL_REASONS, RejectModalConstants.TRANSACTION_CANCEL_OTHER_REASONS_TEXT]

const localValidations = (values, props) => {
    console.log(props)
    let errors = {}
    const phraseType = values[RejectModalConstants.SELECT_PHRASE]
    const selectCancelReason = values[RejectModalConstants.TRANSACTION_CANCEL_REASONS]
    const otherCancelReasonText = values[RejectModalConstants.TRANSACTION_CANCEL_OTHER_REASONS_TEXT]
    const otherCancelReasonvalue = props?.cancellationReasons?.length > 0? props?.cancellationReasons?.filter(x => x.label === "Other"): []
    const requestType = values[FORM_CONSTANTS.REQUEST_TYPE]
    const releaseType = values[BillingFormconstants.RELEASE_METHODS];

    if (phraseType === DEFAULT_DROPDOWN_VALUE) {
        errors[RejectModalConstants.SELECT_PHRASE] = "Phrase is required."
    }
    if (selectCancelReason === DEFAULT_DROPDOWN_VALUE) {
        errors[RejectModalConstants.TRANSACTION_CANCEL_REASONS] = "Cancelation reason is required."
    }
    if(otherCancelReasonvalue.length > 0 && selectCancelReason === `${otherCancelReasonvalue[0].value}` && otherCancelReasonText.length === 0){
        console.log("testing other reason error")
        errors[RejectModalConstants.TRANSACTION_CANCEL_OTHER_REASONS_TEXT] = "Provide reason for cancelation"
    }

    let patientMinDOB = new Date(1900, 0, 1);
    let futureDate = new Date();
    // add a day to get any future dates
    futureDate.setHours(0,0,0,0);
    futureDate.setDate(futureDate.getDate() + 1);
    const patientDOB = convertStringToDate(values[FORM_CONSTANTS.DOB])

    // Details Tab validations
    if (props.activeTabId === 1) {
       
        const reqestType = values[FORM_CONSTANTS.REQUEST_TYPE]
        const purposeType = values[FORM_CONSTANTS.PURPOSE_TYPE]
        const disclosureReason = values[FORM_CONSTANTS.DISCLOSURE_REASON]
        const clinicResponsible = values[FORM_CONSTANTS.CLINIC_RESPONISBLE]
        const authorizationDate = convertStringToDate(values[FORM_CONSTANTS.AUTHORIZATION_DATE])
        const requestDate = convertStringToDate(values[FORM_CONSTANTS.REQUEST_DATE])
        const recievedDate = convertStringToDate(values[FORM_CONSTANTS.RECEIVED_DATE])
        const disclosureDate = convertStringToDate(values[FORM_CONSTANTS.DISCLOSURE_DATE])
        const dueDate = convertStringToDate(values[FORM_CONSTANTS.DUE_DATE])
        

        let isNameValid = isValid(values[FORM_CONSTANTS.PATIENT_NAME]),
            isDOBValid = isValid(patientDOB),
            isAuthorizationValid = isValid(authorizationDate),
            isDisclosureDate = isValid(disclosureDate),
            isRequestDateValid = isValid(requestDate),
            isRecievedDateValid = isValid(recievedDate),
            isLastNameValid = isValid(values[FORM_CONSTANTS.PATIENT_LAST_NAME]),
            isLastNameValidChars = isValidCharacters(values[FORM_CONSTANTS.PATIENT_LAST_NAME], "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz -1234567890'`"),
            isPHIcode = values[FORM_CONSTANTS.PHI] ? isPHISelected(values[FORM_CONSTANTS.PHI]) : null
            

        if (isNameValid.display_error) {
            errors[FORM_CONSTANTS.PATIENT_NAME] = "Patient first name is required."
        }

        if (isRecievedDateValid.display_error) {
            if (requestType && (requestType.toString() !== DISCLOSURE_EVENT_ID || props?.facilityAdditionalFields?.enableNonHIMReceivedDateRequired)){
                errors[FORM_CONSTANTS.RECEIVED_DATE] = "Received date is required."
            }
        } else if(!isDateSqlOverflow(recievedDate)){
            errors[FORM_CONSTANTS.RECEIVED_DATE] = "Date must be between 1/2/1753 and 12/30/9999"
        }else if (recievedDate === INVALID_DATE || (recievedDate && typeof recievedDate === "object" && isNaN(recievedDate.getTime()))) {
            errors[FORM_CONSTANTS.RECEIVED_DATE] = "Invalid date."
        } else if (recievedDate.getTime() >= futureDate.getTime()) {
            errors[FORM_CONSTANTS.RECEIVED_DATE] = "Received date must not be a future date."
        } else if (recievedDate.getTime() < (new Date()).setHours(0, 0, 0, 0) - (365 * 24 * 60 * 60 * 1000)) {
            errors[FORM_CONSTANTS.RECEIVED_DATE] = "Received date must not be prior to 365 days in the past."
        }

        if (requestType && requestType.toString() === DISCLOSURE_EVENT_ID) {
            if (isPHIcode?.display_error || !values[FORM_CONSTANTS.PHI]) {
                errors[FORM_CONSTANTS.PHI] = "At least one PHI code must be selected for a disclosure event."
            }
            if (disclosureReason === DEFAULT_DROPDOWN_VALUE || isValid(disclosureReason).display_error) {
                errors[FORM_CONSTANTS.DISCLOSURE_REASON] = "Reason for disclosure is required."
            }
            if (isDisclosureDate.display_error) {
                errors[FORM_CONSTANTS.DISCLOSURE_DATE] = "Disclosure date is required."
            }else if(!isDateSqlOverflow(disclosureDate)){
                errors[FORM_CONSTANTS.DISCLOSURE_DATE] = "Date must be between 1/2/1753 and 12/30/9999"
            } else if (disclosureDate === INVALID_DATE ||
                (disclosureDate && typeof disclosureDate === "object" && isNaN(disclosureDate.getTime()))) {
                errors[FORM_CONSTANTS.DISCLOSURE_DATE] = "Invalid date."
            } else if (disclosureDate.getTime() >= futureDate.getTime()) {
                errors[FORM_CONSTANTS.DISCLOSURE_DATE] = "Disclosure date must not be a future date."
            } else if (!isRecievedDateValid.display_error && recievedDate?.setHours(0, 0, 0, 0) > disclosureDate?.setHours(0, 0, 0, 0)) {
                errors[FORM_CONSTANTS.DISCLOSURE_DATE] = "Disclosure date can't be before the received date."
            }

            if (recievedDate && !isRecievedDateValid.display_error){
                if (!isRecievedDateValid.display_error && !isDisclosureDate.display_error && recievedDate?.setHours(0, 0, 0, 0) > disclosureDate?.setHours(0, 0, 0, 0)) {
                    errors[FORM_CONSTANTS.RECEIVED_DATE] = "Received date can't be after the disclosure date."
                } else if (recievedDate && disclosureDate && ((disclosureDate - recievedDate) / (1000 * 3600 * 24 * 365) > 1)) {
                    errors[FORM_CONSTANTS.RECEIVED_DATE] = "Received date can't be more than a year prior to the disclosure date."
                }
            }
        } else {
            
            if (isAuthorizationValid.display_error) {
                if (isAuthDateRequired(purposeType)) {
                    errors[FORM_CONSTANTS.AUTHORIZATION_DATE] = "Authorization date is required."
                } else if (authorizationDate !== null && (authorizationDate === INVALID_DATE ||
                    (authorizationDate && typeof authorizationDate === "object" && isNaN(authorizationDate.getTime())) || !isDateSqlOverflow(authorizationDate))) {
                    errors[FORM_CONSTANTS.AUTHORIZATION_DATE] = "Invalid date."
                }
            } else if (authorizationDate.getTime() >= futureDate.getTime()) {
                errors[FORM_CONSTANTS.AUTHORIZATION_DATE] = "Authorization date must not be a future date."
            }

            if (isRequestDateValid.display_error) {
                errors[FORM_CONSTANTS.REQUEST_DATE] = "Request date is required."
            } else if (requestDate === INVALID_DATE ||
                (requestDate && typeof requestDate === "object" && isNaN(requestDate.getTime()))) {
                errors[FORM_CONSTANTS.REQUEST_DATE] = "Invalid date."
            } else if (requestDate.getTime() >= futureDate.getTime()) {
                errors[FORM_CONSTANTS.REQUEST_DATE] = "Request date must not be a future date."
            }

            if (dueDate < new Date('1/1/1753') || dueDate > new Date('12/31/9999')) {
                errors[FORM_CONSTANTS.DUE_DATE] = "Invalid date."
            }
        }

        if (isLastNameValid.display_error) {
            errors[FORM_CONSTANTS.PATIENT_LAST_NAME] = "Patient last name is required."
        }

        if (isLastNameValidChars.display_error) {
            errors[FORM_CONSTANTS.PATIENT_LAST_NAME] = "Patient last name can not contain special characters."
        }

        if (isDOBValid.display_error) {
            errors[FORM_CONSTANTS.DOB] = "Patient date of birth is required."
        } else if (patientDOB === INVALID_DATE ||
            (patientDOB && typeof patientDOB === "object" && isNaN(patientDOB.getTime())) || !isDateSqlOverflow(patientDOB)) {
            errors[FORM_CONSTANTS.DOB] = "Invalid date."
        } else if (patientDOB.getTime() >= futureDate.getTime()) {
            errors[FORM_CONSTANTS.DOB] = "Invalid date."
        } else if (patientDOB.getTime() < patientMinDOB.getTime()) {
            errors[FORM_CONSTANTS.DOB] = "Patient’s date of birth cannot be prior to 1/1/1900."
        }



        if (requestType === DEFAULT_DROPDOWN_VALUE) {
            errors[FORM_CONSTANTS.REQUEST_TYPE] = "Request Type is required."
        }

        if (purposeType === DEFAULT_DROPDOWN_VALUE) {
            errors[FORM_CONSTANTS.PURPOSE_TYPE] = "Purpose Type is required."
        }

        if (props.transactionDetails?.facility?.useDepartments || props.transactionDetails?.facility?.useClinics) {
            if (clinicResponsible === DEFAULT_DROPDOWN_VALUE || !clinicResponsible) {
                errors[FORM_CONSTANTS.CLINIC_RESPONISBLE] = (props.transactionDetails?.facility?.useDepartments ? "Department" : "Clinic") + " is required."
            }
        }

        if (['3','53'].includes(requestType) && (values[FormConstants.CASE_NUMBER] === undefined || values[FormConstants.CASE_NUMBER].length === 0)) {
            errors[FormConstants.CASE_NUMBER] = "Case Number is required"
        }

        if (requestType === '53') {
            if (values[FormConstants.AUDIT_TYPE] === undefined || values[FormConstants.AUDIT_TYPE] === 0 || values[FormConstants.AUDIT_TYPE] === "-9999") {
                errors[FormConstants.AUDIT_TYPE] = "Audit Type is required"
            }
            if (values[FormConstants.ON_BEHALF_OF] === undefined || values[FormConstants.ON_BEHALF_OF] === 0 || values[FormConstants.ON_BEHALF_OF] === "-9999") {
                errors[FormConstants.ON_BEHALF_OF] = "On Behalf Of is required"
            }
        }
    } else if (props.activeTabId === 2) {
        const requestorName = values[RequestorFormConstants.REQUESTOR_NAME],
            address = values[RequestorFormConstants.ADDRESS],
            city = values[RequestorFormConstants.CITY],
            state = values[RequestorFormConstants.STATE],
            zip = values[RequestorFormConstants.ZIP],
            phoneNumber = values[RequestorFormConstants.PHONE_NUMBER],
            faxNumber = values[RequestorFormConstants.FAX_NUMBER],
            email = values[RequestorFormConstants.EMAIL],


            isValidRequestorName = isValid(requestorName),
            isValidAddress = isValid(address),
            isValidCity = isValid(city),
            isValidState = isValid(state),
            isValidZip = isValid(zip)

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        const isBarcodeChecked = values[RequestorFormConstants.NO_BARCODE] === undefined || values[RequestorFormConstants.NO_BARCODE];

        if (isValidRequestorName.display_error) {
            errors[RequestorFormConstants.REQUESTOR_NAME] = "Requestor name is required field."
        }

        if(values[RequestorFormConstants.REQUESTOR_NAME] &&
            !values[RequestorFormConstants.FIRST_NAME] && 
            !values[RequestorFormConstants.LAST_NAME] && 
            !values[RequestorFormConstants.EMAIL] && 
            !values[RequestorFormConstants.PHONE_NUMBER] &&
            !values[RequestorFormConstants.FAX_NUMBER] 
            ){
            errors[RequestorFormConstants.REQUESTOR_CONTACT] = "A contact selection must be made."
        }
        if (isValidAddress.display_error) {
            errors[RequestorFormConstants.ADDRESS] = "Address is required field."
        }
        if (isValidCity.display_error) {
            errors[RequestorFormConstants.CITY] = "City is required field."
        }
        if (isValidState.display_error) {
            errors[RequestorFormConstants.STATE] = "State is required field."
        } else if (state && state.length < 2) {
            errors[RequestorFormConstants.STATE] = "Enter valid state."
        }
        if (isValidZip.display_error) {
            errors[RequestorFormConstants.ZIP] = "Zip is required field."
        } else if (zip && zip.length < 5) {
            errors[RequestorFormConstants.ZIP] = "Enter valid zip code."
        }

        if(email && !emailRegex.test(email)){
            errors[RequestorFormConstants.EMAIL] = 'Invalid email address'
        }

        if (phoneNumber && phoneNumber.length < 12) {
            errors[RequestorFormConstants.PHONE_NUMBER] = "Enter valid phone number."
        }
        if (faxNumber && faxNumber.length < 12) {
            errors[RequestorFormConstants.FAX_NUMBER] = "Enter valid fax number"
        }
        if (requestType === '31' && !isBarcodeChecked && (values[RequestorFormConstants.RQID] === undefined || values[RequestorFormConstants.RQID].length === 0)) {
            errors[RequestorFormConstants.RQID] = "RQID is required"
        }

        if (requestType === '31' && !isBarcodeChecked && (values[RequestorFormConstants.TX_SITE_CODE] === undefined || values[RequestorFormConstants.TX_SITE_CODE].length === 0)) {
            errors[RequestorFormConstants.TX_SITE_CODE] = "Tx Site Code is required"
        }

        if (requestType === '31' && !isBarcodeChecked && (values[RequestorFormConstants.DOC_RETURN_CODE] === undefined || values[RequestorFormConstants.DOC_RETURN_CODE].length === 0)) {
            errors[RequestorFormConstants.DOC_RETURN_CODE] = "Doc Return Code is required"
        }

        if (requestType === '31' && !isBarcodeChecked && (values[RequestorFormConstants.SSN] === undefined || values[RequestorFormConstants.SSN].length === 0)) {
            errors[RequestorFormConstants.SSN] = "SSN is required"
        }

        if (requestType === '31' && !isBarcodeChecked && (values[RequestorFormConstants.DOC_TYPE] === undefined || values[RequestorFormConstants.DOC_TYPE].length === 0)) {
            errors[RequestorFormConstants.DOC_TYPE] = "Doc Type is required"
        }

        if (requestType === '31' && !isBarcodeChecked && (values[RequestorFormConstants.ROUTING_FIELD] === undefined || values[RequestorFormConstants.ROUTING_FIELD].length === 0)) {
            errors[RequestorFormConstants.ROUTING_FIELD] = "Routing Field is required"
        }
        if (requestType === '31' && (values[FormConstants.CASE_NUMBER] === undefined || values[FormConstants.CASE_NUMBER].length === 0)) {
            errors[FormConstants.CASE_NUMBER] = "Case Number is required"
        }



    }

    else if (props.activeTabId === 3) {


        let billTorequestorName = values[BillingFormconstants.BILL_TO_REQUESTOR_NAME],
            billAddress = values[BillingFormconstants.BILL_TO_ADDRESS_LINE1],
            billCity = values[BillingFormconstants.BILL_TO_CITY],
            billState = values[BillingFormconstants.BILL_TO_STATE],
            billZip = values[BillingFormconstants.BILL_TO_ZIP],
            additionalCharges = values[BillingFormconstants.ADDITIONAL_CHARGES],
            feeSchedule = values[BillingFormconstants.FEE_SCHEDULES],
            digitalFaxNumber = values[BillingFormconstants.DIGITAL_FAX],
            requestorFax = values[RequestorFormConstants.FAX_NUMBER],
            email = values[RequestorFormConstants.EMAIL],

            isValidBillRequestorName = isValid(billTorequestorName),
            isValidBillAddress = isValid(billAddress),
            isValidBillCity = isValid(billCity),
            isValidBillState = isValid(billState),
            isValidBillZip = isValid(billZip)

        if (getBillingTypeId(props.feeSchedules, values) === "1") {
            if (isValidBillRequestorName.display_error) {
                errors[BillingFormconstants.BILL_TO_REQUESTOR_NAME] = "Requestor name is required field."
            }
            if (isValidBillAddress.display_error) {
                errors[BillingFormconstants.BILL_TO_ADDRESS_LINE1] = "Address is required field."
            }
            if (isValidBillCity.display_error) {
                errors[BillingFormconstants.BILL_TO_CITY] = "City is required field."
            }
            if (isValidBillState.display_error) {
                errors[BillingFormconstants.BILL_TO_STATE] = "State is required field."
            }
            if (isValidBillZip.display_error) {
                errors[BillingFormconstants.BILL_TO_ZIP] = "Zip is required field."
            }
        }
        if (getBillingTypeId(props.feeSchedules, values) === "1" && additionalCharges && Object.keys(additionalCharges).length) {
            let unfilledColumns = Object.keys(additionalCharges).filter((item: any) => {
                const rowData = additionalCharges[item]
                return (isValid(rowData.glCodeID).display_error || rowData.glCodeID === DEFAULT_DROPDOWN_VALUE || isValid(rowData.chargeAmount).display_error || isValid(rowData.description).display_error)
            })
            if (unfilledColumns.length) {
                errors[BillingFormconstants.ADDITIONAL_CHARGES] = "Please fill all mandatory fields."
            }
        }


        if(releaseType === '2' || releaseType === 2){
            if (!email){
                errors[BillingFormconstants.RELEASE_METHODS] = "Requestor contact must have an email assigned for download delivery."
            }
        }

        if(releaseType === '3' || releaseType ===  3){
            if (digitalFaxNumber && digitalFaxNumber.length < 12) {
                errors[BillingFormconstants.DIGITAL_FAX] = "Enter valid fax number"
            }
            else if (digitalFaxNumber.length === 0) {
                errors[BillingFormconstants.DIGITAL_FAX] = "Enter valid fax number"
            }
            else if (requestorFax.length > 0 && digitalFaxNumber.length > 0) {
                if (requestorFax != digitalFaxNumber)
                    errors[BillingFormconstants.DIGITAL_FAX] = "Fax number entered does not match the assigned requestor contact fax number"
            }
            else if (requestorFax.length === 0 && digitalFaxNumber.length > 0) {
                errors[BillingFormconstants.DIGITAL_FAX] = "Fax number entered does not match the assigned requestor contact fax number"
            }
        }

        // sensitive info and release of info

        let isRetrieveRecordsType = values[FORM_CONSTANTS.RETRIEVE_RECORD_TYPE] ? isRecordsTypeSelected(values[FORM_CONSTANTS.RETRIEVE_RECORD_TYPE]) : null,
            sensitiveInfo = values ? values[FormConstants.SENSITIVE_INFORMATION] : '';
        const disclosesensitiveInfo = values ? values[FormConstants.DISCLOSE_ALL_INFO] : '';
        const doNotDisclosesensitiveInfo = values ? values[FormConstants.DONT_DISCLOSE_ANY_INFO] : '';
        const rtrFromDate = convertStringToDate(values[FORM_CONSTANTS.RTR_FROM])
        const rtrToDate = convertStringToDate(values[FORM_CONSTANTS.RTR_TO])
        const retrieveRecords = values[FORM_CONSTANTS.RETRIEVE_RECORD_TYPE]
        const allDates = values[FORM_CONSTANTS.RTR_ALL]


        if (sensitiveInfo && 
                (
                    (Object.keys(sensitiveInfo).length === 0 && !disclosesensitiveInfo /*&& !doNotDisclosesensitiveInfo*/) ||
                    (Object.keys(sensitiveInfo).every(k => sensitiveInfo[k] === null) && !doNotDisclosesensitiveInfo)
                )) {
                errors[FORM_CONSTANTS.SENSITIVE_INFORMATION] = 'Sensitive information selection must be made.'
            }
            if (props.transactionDetails?.releaseInfo?.length) {
                // if (isRetrieveRecordsType?.display_error) {
                //     errors[FORM_CONSTANTS.RETRIEVE_RECORD_TYPE] = "At least one of the records and date range must be selected."
                // }

                let retrieveError = retrieveRecords && (Object.keys(retrieveRecords).map(k => retrieveRecords[k]).length === 0) ? "At least one of the records " : "";

                if (!allDates) {

                    if (!rtrFromDate || isNaN(rtrFromDate) || !rtrToDate || isNaN(rtrToDate))
                        retrieveError += (retrieveError ? "and e" : "E") + "ither a date range or 'All Dates' ";


                    // if (retrieveRecords && (Object.keys(retrieveRecords).map(k => retrieveRecords[k]).length !== 0) && (((!rtrFromDate || rtrFromDate === "Invalid Date") || (!rtrToDate || rtrToDate === "Invalid Date")))) {
                    //     errors[FORM_CONSTANTS.RETRIEVE_RECORD_TYPE] = "At least one of the records and either a date range or 'All Dates' must be selected."
                    // }

                    if (rtrFromDate) {
                        if ((rtrFromDate === INVALID_DATE) || (rtrFromDate && typeof rtrFromDate === "object" && isNaN(rtrFromDate.getTime()))) {
                            errors[FORM_CONSTANTS.RTR_FROM] = "Invalid date."
                        } else if(!isDateSqlOverflow(rtrFromDate)){
                            errors[FORM_CONSTANTS.RTR_FROM] = "Date must be between 1/2/1753 and 12/30/9999"
                        } else {
                            if (patientDOB && patientDOB != "Invalid Date" && rtrFromDate?.getTime() < patientDOB && patientDOB.getTime()) {
                                errors[FORM_CONSTANTS.RTR_FROM] = "FROM date should be greater than date of birth."
                            } else if (rtrFromDate?.getTime() > rtrToDate && rtrToDate?.getTime()) {
                                errors[FORM_CONSTANTS.RTR_FROM] = "FROM date should not be greater than TO date."
                            } else if (rtrFromDate?.getTime() > (new Date).getTime()) {
                                errors[FORM_CONSTANTS.RTR_FROM] = "FROM date should not be greater than the current date."
                            }
                        }
                    }

                    if (rtrToDate) {
                        if ((rtrToDate === INVALID_DATE) || (rtrToDate && typeof rtrToDate === "object" && isNaN(rtrToDate.getTime()))) {
                            errors[FORM_CONSTANTS.RTR_TO] = "Invalid date."
                        }  else if(!isDateSqlOverflow(rtrToDate)){
                            errors[FORM_CONSTANTS.RTR_TO] = "Date must be between 1/2/1753 and 12/30/9999"
                        } else {
                            if (patientDOB && patientDOB != "Invalid Date" && rtrToDate?.getTime() < patientDOB && patientDOB.getTime()) {
                                errors[FORM_CONSTANTS.RTR_TO] = "TO date should be greater than date of birth."
                            } else if (rtrToDate?.getTime() < rtrFromDate && rtrFromDate?.getTime()) {
                                errors[FORM_CONSTANTS.RTR_TO] = "TO date should be greater than FROM date."
                            } else if (rtrToDate?.getTime() > (new Date).getTime()) {
                                errors[FORM_CONSTANTS.RTR_TO] = "TO date should not be greater than the current date."
                            }
                        }
                    }
                }

                retrieveError += retrieveError ? "must be selected." : "";
                if (retrieveError)
                    errors[FORM_CONSTANTS.RETRIEVE_RECORD_TYPE] = retrieveError;
            }


        
    }
    return errors
}

export const infoToReleaseValidiation = (props, rtrData) => {
    let futureDate = new Date();
    // add a day to get any future dates
    futureDate.setHours(0,0,0,0);
    futureDate.setDate(futureDate.getDate() + 1);
    const patientDOB = convertStringToDate(props.updatedPatientDetailsAttributes.dob)

    let errors = {}
     // sensitive info and release of info

 
    const rtrFromDate = convertStringToDate(rtrData.fromDate)
    const rtrToDate = convertStringToDate( rtrData.toDate)
    const retrieveRecords = rtrData.recordTypes.filter(t => t.state)
    const allDates = rtrData.allDates


   
        if (props.transactionDetails?.releaseInfo?.length) {
            // if (isRetrieveRecordsType?.display_error) {
            //     errors[FORM_CONSTANTS.RETRIEVE_RECORD_TYPE] = "At least one of the records and date range must be selected."
            // }

            let retrieveError = retrieveRecords && (Object.keys(retrieveRecords).map(k => retrieveRecords[k]).length === 0) ? "At least one of the records " : "";

            if (!allDates) {

                if (!rtrFromDate || isNaN(rtrFromDate) || !rtrToDate || isNaN(rtrToDate))
                    retrieveError += (retrieveError ? "and e" : "E") + "ither a date range or 'All Dates' ";


                // if (retrieveRecords && (Object.keys(retrieveRecords).map(k => retrieveRecords[k]).length !== 0) && (((!rtrFromDate || rtrFromDate === "Invalid Date") || (!rtrToDate || rtrToDate === "Invalid Date")))) {
                //     errors[FORM_CONSTANTS.RETRIEVE_RECORD_TYPE] = "At least one of the records and either a date range or 'All Dates' must be selected."
                // }

                if (rtrFromDate) {
                    if ((rtrFromDate === INVALID_DATE) || (rtrFromDate && typeof rtrFromDate === "object" && isNaN(rtrFromDate.getTime()))) {
                        errors[FORM_CONSTANTS.RTR_FROM] = "Invalid date."
                    } else if(!isDateSqlOverflow(rtrFromDate)){
                        errors[FORM_CONSTANTS.RTR_FROM] = "Date must be between 1/2/1753 and 12/30/9999"
                    } else {
                        if (patientDOB && patientDOB != "Invalid Date" && rtrFromDate?.getTime() < patientDOB && patientDOB.getTime()) {
                            errors[FORM_CONSTANTS.RTR_FROM] = "FROM date should be greater than date of birth."
                        } else if (rtrFromDate?.getTime() > rtrToDate && rtrToDate?.getTime()) {
                            errors[FORM_CONSTANTS.RTR_FROM] = "FROM date should not be greater than TO date."
                        } else if (rtrFromDate?.getTime() > (new Date).getTime()) {
                            errors[FORM_CONSTANTS.RTR_FROM] = "FROM date should not be greater than the current date."
                        }
                    }
                }

                if (rtrToDate) {
                    if ((rtrToDate === INVALID_DATE) || (rtrToDate && typeof rtrToDate === "object" && isNaN(rtrToDate.getTime()))) {
                        errors[FORM_CONSTANTS.RTR_TO] = "Invalid date."
                    }  else if(!isDateSqlOverflow(rtrToDate)){
                        errors[FORM_CONSTANTS.RTR_TO] = "Date must be between 1/2/1753 and 12/30/9999"
                    } else {
                        if (patientDOB && patientDOB != "Invalid Date" && rtrToDate?.getTime() < patientDOB && patientDOB.getTime()) {
                            errors[FORM_CONSTANTS.RTR_TO] = "TO date should be greater than date of birth."
                        } else if (rtrToDate?.getTime() < rtrFromDate && rtrFromDate?.getTime()) {
                            errors[FORM_CONSTANTS.RTR_TO] = "TO date should be greater than FROM date."
                        } else if (rtrToDate?.getTime() > (new Date).getTime()) {
                            errors[FORM_CONSTANTS.RTR_TO] = "TO date should not be greater than the current date."
                        }
                    }
                }
            }

            retrieveError += retrieveError ? "must be selected." : "";
            if (retrieveError)
                errors[FORM_CONSTANTS.RETRIEVE_RECORD_TYPE] = retrieveError;
    }
     return errors

}

export default localValidations