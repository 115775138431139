import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import { connect } from "react-redux";
import { DEFAULT_DROPDOWN_VALUE, DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from '../../common/constants/ApiConstants';
import AccordionTable from '../../common/table/AccordionTable';
import AccordionTableRTR from '../../common/table/AccordionTableRTR';
import AuthorizationTable from '../../common/table/AuthorizationTable';
import AdditionalChargeRows from '../../common/table/AdditionalChargeRows';
import FormConstants, { BillingFormconstants, RequestorFormConstants } from '../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants';
import "../../components/dataEntryDetails/DataEntryDetails.scss";
import IconWithButton from '../IconWithButton/IconWithButton';
import ReduxFormcheckboxField from '../reduxFormFields/ReduxFormCheckboxField';
import ReduxFormDateField from '../reduxFormFields/ReduxFormDateField';
import ReduxFormDropdownField from '../reduxFormFields/ReduxFormDropDownField';
import ReduxFormTextField from '../reduxFormFields/ReduxFormTextField';
import ReduxFormRadioField from '../reduxFormFields/ReduxFormRadioField';
import "../../common/table/CustomTable.scss";
import { getMomentDate } from '../utils/appUtils';
import { saveInformationToReleaseActionCreator } from '../../components/dataEntryDetails/DataEntryDetailsDucks';
import el from 'date-fns/esm/locale/el/index.js';

interface AccordionProps {
  activeTabId: number;
  dispatch?: Function;
  requestTypeValue?: string;
  heading?: string;
  billingType?: any,
  onCheckSameAsSendTo?: any,
  allDatesCheck?: Function,
  accordionChargeTypeList?: any,
  data?: any
  onBlur?: Function,
  transactionDetails?: any,
  isSensitiveInfo?: String,
  sensitiveAccordionErrMsg?: String,
  setRtrError?: Function,
  rtrError?: String,
  formState?: any,
  phiCodes?: any,
  disclosureReasons?: any,
  additionalChargesErr?: any,
  setAdditionalChargesErr?: Function,
  releaseInfo?: any,
  transactionId?: any,
  allDatesChecked?: any,
  releaseInfoFromDate?: any,
  releaseInfoToDate?: any,
  saveInformationToRelease?: Function,
  updateBillingPaneFields?: Function,
  isNewTransaction?: any,
  useAuditable?: boolean
}

function AccordionComponent(props: AccordionProps) {

  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId('');
    } else {
      setActiveId(id);
    }
  }

  function createData(checkbox = <Checkbox color='primary' />, code, description, requestedItemID, isRetrieved) {
    return { checkbox, code, description, requestedItemID, isRetrieved };
  }

  const disclosureReasons = props.disclosureReasons

  const headCellsRequestor = [
    { id: 'checkbox', label: 'SELECT' },
    { id: 'code', label: 'CODE' },
    { id: 'description', label: 'DESCRIPTION' },
  ];

  const headCellsAdditionalCharges = [
    { id: 'chargeType', label: 'ORDER CHARGE TYPE' },
    { id: 'chargeDescription', label: 'DESCRIPTION' },
    { id: 'amount', label: 'AMOUNT' },
    { id: 'action', label: 'ACTION' }
  ]

  function createDataRTR(retrieve, recordType, id?: any) {
    return { retrieve, recordType, id };
  }
  const getInfoToReleaseData = (data: any) => {
    if (!data) { return [] }
    return data.map(item => {
      return createDataRTR(<Checkbox color='primary' />, item.code, item.requestedItemID)
    })
  }

  const headCellsRTR = [
    { id: 'retrieve', label: 'RETRIEVE' },
    { id: 'recordType', label: 'RECORD TYPE' },
  ];

  function createDataSensitiveInfo(include, category) {
    return { include, category };
  }



  const headCellsAuthorizationVerification = [
    { id: 'category', label: 'CATEGORY' },
    { id: 'isReferenced', label: 'IS REFERENCED?' },
  ];

  function createDataAuthorizationVerification(category, isReferenced) {
    return { category, isReferenced };
  }

  const rowsAuthorizationVerification = [
    createDataAuthorizationVerification("Is the authorization signed & dated by the individual whose records are being requested?", <Field
      name={FormConstants.GENETIC_INFORMATION}
      component={ReduxFormRadioField}
      props={{
        fieldlabel: "",
        radioLabel: '',
      }}
    />),
    createDataAuthorizationVerification("If the authorization is signed by a personal representative, does it contain a description of his or her authority to act for the individual?", <Field
      name={FormConstants.HIV}
      component={ReduxFormRadioField}
      props={{
        fieldlabel: "",
        radioLabel: '',
      }}
    />),
    createDataAuthorizationVerification("Is a power of attorney, court order, or other necessary legal documentation supporting the personal representative's authority included?", <Field
      name={FormConstants.MENTAL_HEALTH}
      component={ReduxFormRadioField}
      props={{
        fieldlabel: "",
        radioLabel: '',
      }}
    />),
  ];

  let sensitiveInfo = props.formState?.values && props.formState?.values[FormConstants.SENSITIVE_INFORMATION]
  let purposeType = props.formState?.values && props.formState?.values[FormConstants.PURPOSE_TYPE]
  const [isDisabled, setDisabled] = useState(false)
  const [doNotDisposeClicked, setDoNotDisposeClicked] = useState(false)
  useEffect(() => {
    if (!props.isNewTransaction && sensitiveInfo != null && Object.keys(sensitiveInfo).length === 0) {
      setDisabled(true)
    }
    else {
      if (!doNotDisposeClicked)
        setDisabled(false)
      else
        setDisabled(true)
    }
  })
  const rowsSensitiveInfo = props.transactionDetails?.sensitiveInfo?.map((el: any) => {
    return (createDataSensitiveInfo(<Checkbox
      color='primary'
      //disabled={isDisabled}
      className="checkSensitive"
    />, el.name))
  });

  const headCellsSensitiveInfo = [
    { id: 'include', label: 'INCLUDE' },
    { id: 'category', label: 'CATEGORY' },
  ];

  const [isEnabledAdd, setisEnabledAdd] = useState(false)
  let DiscloseAllSensnitiveInfo = props.formState?.values && props.formState?.values[FormConstants.DISCLOSE_ALL_INFO]
  let dontDiscloseAllSensnitiveInfo = props.formState?.values && props.formState?.values[FormConstants.DONT_DISCLOSE_ANY_INFO]

  const [retrieveRecordTypeData, setRetrieveRecordTypeData] = useState<string[]>();

  useEffect(() => {
    setRetrieveRecordTypeData(props.formState?.values && props.formState?.values[FormConstants.RETRIEVE_RECORD_TYPE]);
  }, [props.formState?.values]);

  const onClickAddCharge = () => {
    const newChargeRows = props.data || {}
    const newChargeRowKeys = Object.keys(newChargeRows)
    let updatedChargeRows = { ...newChargeRows }
    let updatedChargeRowKeys = [...newChargeRowKeys]
    const newKey = Math.random().toString()

    let disabledValue = null
    updatedChargeRowKeys.map((key: any) => {
      disabledValue = updatedChargeRows[key].disabled
      return ''
    })

    if (updatedChargeRowKeys.length === 1 && disabledValue) {
      updatedChargeRows = {
        "1": {
          glCodeID: DEFAULT_DROPDOWN_VALUE,
          description: "",
          chargeAmount: "",
          disabled: false
        }
      }
    } else {
      updatedChargeRows = {
        ...updatedChargeRows,
        [newKey]: {
          glCodeID: DEFAULT_DROPDOWN_VALUE,
          description: "",
          chargeAmount: ""
        }
      }
    }
    changeFieldValue(updatedChargeRows)
    setisEnabledAdd(true)
    props.setAdditionalChargesErr && props.setAdditionalChargesErr("")
  }
  const updateValueInDb = (row: any, defaultValue?: any) => {
    let currentValue = { ...sensitiveInfo }
    let keyName = ""
    let updatedValue = sensitiveInfo
    let extraProps = {}
    switch (row.category) {
      case FormConstants.GENETIC_INFORMATION:
        keyName = "geneticSensitiveInfo"
        updatedValue = currentValue[row.category] ? 0 : 1
        break
      case FormConstants.HIV:
        keyName = "hivAidsSensitiveInfo"
        updatedValue = currentValue[row.category] ? 0 : 1
        break
      case FormConstants.MENTAL_HEALTH:
        keyName = "mentalHealthSensitiveInfo"
        updatedValue = currentValue[row.category] ? 0 : 1
        break
      case FormConstants.SUBSTANCE_ABUSE:
        keyName = "substanceAbuseSensitiveInfo"
        updatedValue = currentValue[row.category] ? 0 : 1
        break
      case FormConstants.STD:
        keyName = 'sexuallyTransmittedDiseaseSensitiveInfo'
        updatedValue = currentValue[row.category] ? 0 : 1
        break
    }


    if (keyName.length) {
      const sensitiveObject = {
        [keyName]: defaultValue || updatedValue,
        ...extraProps,
        patientDataid: props.transactionDetails?.patient?.id,
        transactionId: props.transactionId,
        userID: global.userID
      }

      props.updateBillingPaneFields && props.updateBillingPaneFields(sensitiveObject)
    }
  }
  const changeFieldValue = (value: any) => {
    props.dispatch && props.dispatch({
      type: "@@redux-form/CHANGE", payload: value, meta: {
        field: BillingFormconstants.ADDITIONAL_CHARGES,
        touch: true, persistentSubmitErrors: false, form: FormConstants.DATA_ENTRY_FIELDS_FORM
      }
    })
  }
  const changeSensitiveInfoFieldValue = (value: any) => {
    props.dispatch && props.dispatch({
      type: "@@redux-form/CHANGE", payload: value, meta: {
        field: FormConstants.DISCLOSE_ALL_INFO,
        touch: true, persistentSubmitErrors: false, form: FormConstants.DATA_ENTRY_FIELDS_FORM
      }
    })

    props.dispatch && props.dispatch({
      type: "@@redux-form/CHANGE", payload: {}, meta: {
        field: FormConstants.SENSITIVE_INFORMATION,
        touch: true, persistentSubmitErrors: false, form: FormConstants.DATA_ENTRY_FIELDS_FORM
      }
    })
  }

  const getUpdatedValueOnSelect = (existingValue, row) => {
    let currentValue = {}
    currentValue = { ...existingValue }
    currentValue = {
      ...existingValue,
      [row.category]: {
        recordType: row.category,
      }
    }
    return currentValue
  }

  const ChangeNotDiscloseSensitiveInfoValue = (value: any) => {
    if (value) {
      let updatedValue = sensitiveInfo
      rowsSensitiveInfo.map((row, key) => {
        updateValueInDb(row, "1")
        updatedValue = getUpdatedValueOnSelect(updatedValue, row)
      })
      props.dispatch && props.dispatch({
        type: "@@redux-form/CHANGE", payload: updatedValue, meta: {
          field: FormConstants.SENSITIVE_INFORMATION,
          touch: true, persistentSubmitErrors: false, form: FormConstants.DATA_ENTRY_FIELDS_FORM
        }
      })
    } else {
      rowsSensitiveInfo.map((row, key) => {
        updateValueInDb(row, "0")
      })
      props.dispatch && props.dispatch({
        type: "@@redux-form/CHANGE", payload: {}, meta: {
          field: FormConstants.SENSITIVE_INFORMATION,
          touch: true, persistentSubmitErrors: false, form: FormConstants.DATA_ENTRY_FIELDS_FORM
        }
      })
    }
    props.dispatch && props.dispatch({
      type: "@@redux-form/CHANGE", payload: false, meta: {
        field: FormConstants.DONT_DISCLOSE_ANY_INFO,
        touch: true, persistentSubmitErrors: false, form: FormConstants.DATA_ENTRY_FIELDS_FORM
      }
    })
    setDisabled(false)
  }

  const ChangeAllDiscloseInfoValue = (value: any) => {
    setDisabled(value)
    setDoNotDisposeClicked(value)
    props.dispatch && props.dispatch({
      type: "@@redux-form/CHANGE", payload: false, meta: {
        field: FormConstants.DISCLOSE_ALL_INFO,
        touch: true, persistentSubmitErrors: false, form: FormConstants.DATA_ENTRY_FIELDS_FORM
      }
    })
  }

  const onChangeRTRDateRange = (fieldName: any, value) => {
    let updatedValues = {
      [FormConstants.RTR_FROM]: props.releaseInfoFromDate ? getMomentDate(props.releaseInfoFromDate) : "",
      [FormConstants.RTR_TO]: props.releaseInfoToDate ? getMomentDate(props.releaseInfoToDate) : "",
      [fieldName]: getMomentDate(value)
    }
    if (updatedValues[FormConstants.RTR_TO] && updatedValues[FormConstants.RTR_FROM]) {
      props.dispatch && props.dispatch({
        type: "@@redux-form/CHANGE", payload: false, meta: {
          field: FormConstants.RTR_ALL,
          touch: true, persistentSubmitErrors: false, form: FormConstants.DATA_ENTRY_FIELDS_FORM
        }
      })
      updateValuesToDB(updatedValues)
    }
  }

  const [auditable, setAuditable] = useState(props.useAuditable == true ? true : null);
  useEffect(() => {
    if (props.useAuditable === true)
      setAuditable(true);
  }, [props.transactionId, props.activeTabId]);

  const updateValuesToDB = (updatedValues: any) => {
    var locAuditable = auditable;
    getInfoToReleaseData(props.releaseInfo).map(item => {
      const active = (retrieveRecordTypeData && retrieveRecordTypeData[item.recordType]) ? 1 : 0;
      let requestObject = {
        transactionId: props.transactionId,
        active: active,
        id: item.id,
        fromDate: updatedValues[FormConstants.RTR_FROM],
        toDate: updatedValues[FormConstants.RTR_TO],
        auditable: locAuditable && active === 1
      }
      props.saveInformationToRelease && props.saveInformationToRelease(requestObject)
      if (active === 1 && props.useAuditable === true) {
        locAuditable = false;
        setAuditable(false);
      }
      return null
    })
  }

  const onClickAllDates = (value: any) => {
    props.allDatesCheck && props.allDatesCheck(value)
    let updatedValues = {
      [FormConstants.RTR_FROM]: "",
      [FormConstants.RTR_TO]: "",
    }
    if (value) {
      updateValuesToDB(updatedValues)
    }
  }

  const sortedAccordionChargeTypeList = Object.values(props.accordionChargeTypeList).sort((a:any, b:any) => {
    if (a.name < b.name) { return -1; }
    if (a.name > b.name) { return 1; }
    return 0;
  })
  
  return (
    <div className="Accordion" style={{ marginTop: '2rem' }}>
      { /* data entry details pane accordions*/}
      {props.activeTabId === 1 || props.activeTabId === 2 ?
        <Accordion defaultActiveKey={activeId}>
          <div className='panel-wrap active-panel'>
            <div className="new-panel-header section-headers">
              {props.heading}
            </div>

            {props.activeTabId === 1 && props.requestTypeValue === '00' ?
              <div className="new-panel-body panel-body  information-to-release-section">
                <div className="rtr-heading">
                  Select records and the date range that have been requested
                </div>
                {props.rtrError
                  ? <div className="error-message Phi-text rtr-alert-icon-after">{props.rtrError}</div>
                  : <></>
                }
                <div className={props.rtrError ? "rtr-wrap inputError" : "rtr-wrap"}>
                  <div className="rtr-row">

                    <div className="rtr-from">
                      <Field
                        name={FormConstants.RTR_FROM}
                        component={ReduxFormDateField}
                        props={{
                          label: "From",
                          placeholder: "mm/dd/yyyy",
                          maxDate: DEFAULT_MAX_DATE,
                          minDate: DEFAULT_MIN_DATE,
                          onChangeRTRDateRange
                        }}
                      />
                    </div>

                    <div className="rtr-to">
                      <Field
                        name={FormConstants.RTR_TO}
                        component={ReduxFormDateField}
                        props={{
                          label: "To",
                          placeholder: "mm/dd/yyyy",
                          maxDate: DEFAULT_MAX_DATE,
                          minDate: DEFAULT_MIN_DATE,
                          onChangeRTRDateRange
                        }}
                      />
                    </div>

                    <div className="rtr-all">
                      <Field
                        name={FormConstants.RTR_ALL}
                        component={ReduxFormcheckboxField}
                        props={{
                          fieldlabel: "",
                          checkboxLabel: 'All Dates',
                          defaultChecked: false,
                          onCheck: onClickAllDates
                        }}
                      />
                    </div>

                  </div>

                  <Row >

                    <Col xs={12} md={12} className="pl-0 rtr-overflow" >
                      <Field
                        name={FormConstants.RETRIEVE_RECORD_TYPE}
                        component={AccordionTableRTR}
                        props={{
                          rows: getInfoToReleaseData(props.releaseInfo),
                          headCells: headCellsRTR,
                          setRtrError: props.setRtrError,
                          transactionId: props.transactionId,
                          allDatesChecked: props.allDatesChecked,
                          releaseInfoFromDate: props.releaseInfoFromDate,
                          releaseInfoToDate: props.releaseInfoToDate,
                          auditable,
                          setAuditable
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              : null}

            {props.activeTabId === 1 && props.requestTypeValue === '000' ?
              <div className="new-panel-body panel-body  sensitive-information-accordion">
                <div className='sensitive-info-header'>

                  <div className='sensitive-info-question'>
                    Review the records and check for any information on the following disease/conditions.Does the patient record contain any reference or information below:
                  </div>
                </div>

                {props.sensitiveAccordionErrMsg && props.sensitiveAccordionErrMsg !== "" ?
                  <div className="error-message Phi-text rtr-alert-icon-after">
                    {props.sensitiveAccordionErrMsg}
                  </div> : null}

                <div className={props.sensitiveAccordionErrMsg && props.sensitiveAccordionErrMsg !== "" ? "sensitive-Info-inner inputError" : "sensitive-Info-inner"}>

                  <div className="review-msg">
                    <div className="select-all-check">
                      <Field
                        name={FormConstants.DISCLOSE_ALL_INFO}
                        component={ReduxFormcheckboxField}
                        props={{
                          fieldlabel: "",
                          checkboxLabel: 'Authorize the disclosure of ALL sensitive information',
                          ChangeNotDiscloseSensitiveInfoValue

                        }}
                      />
                    </div>

                    <div className="select-none-check">
                      <Field
                        name={FormConstants.DONT_DISCLOSE_ANY_INFO}
                        component={ReduxFormcheckboxField}
                        props={{
                          fieldlabel: "",
                          checkboxLabel: 'DO NOT Authorize the disclosure of ANY sensitive information',
                          changeSensitiveInfoFieldValue: ChangeAllDiscloseInfoValue
                        }}
                      />
                    </div>
                  </div>


                  <Row className='sensitive-Info-table'>
                    <Col xs={12} md={12} className="pl-0">
                      <Field
                        name={FormConstants.SENSITIVE_INFORMATION}
                        component={AccordionTableRTR}
                        props={{
                          rows: rowsSensitiveInfo,
                          headCells: headCellsSensitiveInfo,
                          discloseAll: DiscloseAllSensnitiveInfo,
                          dontDisclose: dontDiscloseAllSensnitiveInfo,
                          changeSensitiveInfoFieldValue,
                          transactionId: props.transactionId,
                          updateValueInDb,
                          auditable,
                          setAuditable
                        }}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="important-msg">
                  <b>Important:</b> {/* Any YES response will send the transaction to review. */}
                  There is an increased risk of an inappropriate release and/or a HIPAA violation if this information is released without additional authorization or documentation.
                </div>
              </div>
              : null}

            {props.activeTabId === 1 && props.requestTypeValue === '66' ?

              <div className="new-panel-body panel-body disclosure-reason-section ">
                <Row>
                  <Col xs={6} md={6} className="pl-3 pr-0">
                    <div className="data-entry request-type placeholder-style">
                      <Field
                        name={FormConstants.DISCLOSURE_REASON}
                        component={ReduxFormDropdownField}
                        props={{
                          label: "Reason for disclosure",
                          required: true,
                          options: disclosureReasons,
                          onChange: props.onBlur

                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={6} md={6} className="pl-0">
                    <div className="data-entry patient-dob">
                      <Field
                        name={FormConstants.DISCLOSURE_DATE}
                        component={ReduxFormDateField}
                        props={{
                          label: "Disclosure date",
                          placeholder: "mm/dd/yyyy",
                          maxDate: new Date(),
                          minDate: DEFAULT_MIN_DATE,
                          required: true,
                          onChange: props.onBlur,
                          onBlur: props.onBlur
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} className="pl-3 pr-0">
                    <div className="data-entry placeholder-style">
                      <Field
                        name={FormConstants.SPECIAL_NOTES}
                        component={ReduxFormTextField}
                        props={{
                          label: "Special notes",
                          placeholder: "add notes",
                          required: false,
                          maxLength: 256,
                          onBlur: props.onBlur

                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={6} md={6} className="pl-0">
                    <div className="data-entry placeholder-style">
                      <Field
                        name={FormConstants.PAGES_DISCLOSED}
                        component={ReduxFormTextField}
                        props={{
                          label: "Number of pages disclosed",
                          placeholder: "12345",
                          required: false,
                          maxLength: 5,
                          onBlur: props.onBlur
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} className="pl-3 pr-0">
                    <div className="data-entry patient-firstName placeholder-style">
                      <Field
                        name={FormConstants.PROTOCOL_NAME}
                        component={ReduxFormTextField}
                        props={{
                          label: "Protocol name",
                          placeholder: "add name",
                          required: false,
                          maxLength: 70,
                          onBlur: props.onBlur
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={6} md={6} className="pl-0">
                    <div className="data-entry placeholder-style">
                      <Field
                        name={FormConstants.PROTOCOL_NUMBER}
                        component={ReduxFormTextField}
                        props={{
                          label: "Protocol number",
                          placeholder: "123456789",
                          required: false,
                          maxLength: 20,
                          onBlur: props.onBlur
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={12} className="pl-0">
                    <Field
                      name={FormConstants.PHI}
                      component={AccordionTable}
                      props={{
                        headCells: headCellsRequestor,
                        rows: props.phiCodes.map((item) => createData(undefined, item.code, item.description, item.requestedItemID, item.isRetrieved))
                      }}
                    />
                  </Col>
                </Row>
              </div>
              : null}

            {/* {props.activeTabId === 2 && props.requestTypeValue === '31' 
              ? <div className="new-panel-body panel-body  authorization-verification">
                <div className="rtr-heading">
                  Answer the following questions from visually reviewing the Authorization Signature
                </div>
                <Field
                  name={RequestorFormConstants.AUTHORIZATION_VERIFICATION}
                  component={AuthorizationTable}
                  props={{
                    rows: rowsAuthorizationVerification,
                    headCells: headCellsAuthorizationVerification,
                  }}
                />
              </div>
              : null
            } */}


          </div>

        </Accordion> : null}


      {/* billing pane accordion component  */}

      {props.activeTabId === 3 ?
        <Accordion defaultActiveKey={activeId}>
          {/* additional charges accordion */}
          <div className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
            <div className="panel-header">
              <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                <span className="accordion-item__icon"><ExpandMoreIcon className={activeId === '0' ? 'rotate' : 'rotate-down'} /></span>Additional Charges
              </Accordion.Toggle>
            </div>

            <Accordion.Collapse eventKey="0">
              <div className="panel-body">
                <Row>
                  <Col xs={12} md={12} className="pl-0">
                    <div className="add-files-btn float-right mb-2 mx-2">
                      <IconWithButton
                        buttonText={"Add new charge"}
                        onButtonClick={onClickAddCharge}
                        isAddFile={true}
                        hideArrowIcon={true}
                        isDisabled={isEnabledAdd}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={12} className="pl-0">
                    <Field
                      name={BillingFormconstants.ADDITIONAL_CHARGES}
                      component={AdditionalChargeRows}
                      props={{
                        keys: props.data ? Object.keys(props.data) : [],
                        rows: props.data,
                        headCells: headCellsAdditionalCharges,
                        setEnable: setisEnabledAdd,
                        additionalChargeTypeOption: sortedAccordionChargeTypeList,
                        additionalChargesErr: props.additionalChargesErr,
                        setAdditionalChargesErr: props.setAdditionalChargesErr
                      }}
                    />

                  </Col>
                </Row>

              </div>
            </Accordion.Collapse>
          </div>

          {/* bill to accordion */}

          <div className='info-container'>
            <div className="new-panel-header section-headers" style={{ width: '100%', marginBottom: 10 }}> {'Bill To'} </div>
            <div className="d-flex" style={{"flexFlow": "row wrap", "flexBasis": "100%"}}>

              <div className="data-entry sameAsSendTo">
                <Field
                  name={BillingFormconstants.SAME_AS_SEND_TO}
                  component={ReduxFormcheckboxField}
                  props={{
                    fieldlabel: "Same as send to",
                    checkboxLabel: 'Yes',
                    onCheck: props.onCheckSameAsSendTo,
                    defaultChecked: false
                  }}
                />
              </div>

              <div className="data-entry">
                <Field
                  name={BillingFormconstants.REQUIRES_PREPAYMENT}
                  component={ReduxFormcheckboxField}
                  props={{
                    fieldlabel: "Requires Prepayment?",
                    checkboxLabel: 'Yes',
                    defaultChecked: props.transactionDetails?.prePay,
                    onChange: props.onBlur
                  }}
                />
              </div>

              <div className="data-entry billTo-text-input billingRequestorName placeholder-style">
                <Field
                  name={BillingFormconstants.BILL_TO_REQUESTOR_NAME}
                  component={ReduxFormTextField}
                  props={{
                    label: "Requestor name",
                    placeholder: "Requestor Name",
                    maxLength: 100,
                    required: true,
                    onBlur: props.onBlur
                  }}
                />
              </div>
              <div className='data-entry billTo-text-input placeholder-style'>
                <Field
                  name={BillingFormconstants.BILL_TO_FIRST_NAME}
                  component={ReduxFormTextField}
                  props={{
                    label: "First name",
                    placeholder: "First Name",
                    maxLength: 30,
                    onBlur: props.onBlur
                  }}
                />
              </div>
              <div className='data-entry billTo-text-input placeholder-style'>
                <Field
                  name={BillingFormconstants.BILL_TO_LAST_NAME}
                  component={ReduxFormTextField}
                  props={{
                    label: "Last name",
                    placeholder: "Last Name",
                    maxLength: 30,
                    onBlur: props.onBlur

                  }}
                />
              </div>
              <div className='data-entry billTo-text-input requestor-info placeholder-style'>
                <Field
                  name={BillingFormconstants.BILL_TO_ADDRESS_LINE1}
                  component={ReduxFormTextField}
                  props={{
                    label: "Address",
                    placeholder: "Address line 1",
                    required: true,
                    maxLength: 70,
                    onBlur: props.onBlur

                  }}
                />
              </div>
              <div className='data-entry billTo-text-input requestor-addressLine2 placeholder-style'>
                <Field
                  name={BillingFormconstants.BILL_TO_ADDRESS_LINE2}
                  component={ReduxFormTextField}
                  props={{
                    label: "",
                    maxLength: 70,
                    placeholder: "P.O. Box, Suite, Apt #",
                    onBlur: props.onBlur

                  }}
                />
              </div>
              <div className='data-entry billTo-text-input requestor-city placeholder-style'>
                <Field
                  name={BillingFormconstants.BILL_TO_CITY}
                  component={ReduxFormTextField}
                  props={{
                    label: "City",
                    placeholder: "City",
                    required: true,
                    maxLength: 30,
                    onBlur: props.onBlur

                  }}
                />
              </div>

              <div className='data-entry billTo-text-input requestor-state placeholder-style'>
                <Field
                  name={BillingFormconstants.BILL_TO_STATE}
                  component={ReduxFormTextField}
                  props={{
                    label: "State",
                    placeholder: "State",
                    required: true,
                    maxLength: 2,
                    onBlur: props.onBlur

                  }}
                />
              </div>
              <div className='data-entry billTo-text-input requestor-zip placeholder-style'>
                <Field
                  name={BillingFormconstants.BILL_TO_ZIP}
                  component={ReduxFormTextField}
                  props={{
                    label: "Zip code",
                    placeholder: "Zip code",
                    required: true,
                    maxLength: 10,
                    onBlur: props.onBlur

                  }}
                />
              </div>
              <div className='data-entry billTo-text-input placeholder-style'>
                <Field
                  name={BillingFormconstants.BILL_TO_PHONE}
                  component={ReduxFormTextField}
                  props={{
                    label: "Phone",

                    placeholder: "XXX-XXX-XXXXxXXXXX",
                    maxLength: 30,
                    onBlur: props.onBlur

                  }}
                />
              </div>
              <div className='data-entry billTo-text-input placeholder-style'>
                <Field
                  name={BillingFormconstants.BILL_TO_FAX}
                  component={ReduxFormTextField}
                  props={{
                    label: "Fax",
                    placeholder: "XXX-XXX-XXXX",
                    maxLength: 12,
                    onBlur: props.onBlur

                  }}
                />
              </div>
              <div className='data-entry billTo-text-input requestor-info placeholder-style'>
                <Field
                  name={BillingFormconstants.BILL_TO_EMAIL_ADDRESS}
                  component={ReduxFormTextField}
                  props={{
                    label: "Email address",
                    placeholder: "example@mail.com",
                    maxLength: 50,
                    onBlur: props.onBlur
                  }}
                />
              </div>
            </div>
          </div>


        </Accordion> : null}

    </div>
  );
}

function mapStateToProps(state: any) {
  const transactionDetails = state.dataEntryState?.transactionDetails
  return {
    accordionChargeTypeList: state.dataEntryState?.additionalChargeType,
    disclosureReasons: state.dataEntryState?.disclosureReasons,
    formState: state.form && state?.form[FormConstants.DATA_ENTRY_FIELDS_FORM],
    isNewTransaction: state.landingPageState.isNewTransaction,
    transactionDetails
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    saveInformationToRelease: (data: any) => {
      return dispatch(saveInformationToReleaseActionCreator(data))
    }
  }
}
export default connect<{}, {}, AccordionProps>(mapStateToProps, mapDispatchToProps)(
  AccordionComponent
)