import React from 'react';
import './TabComponents.scss';
import { Card, Figure } from 'react-bootstrap';
import FarIconPNG from '../../assets/images/tabImages/FAR-Icon.png';

interface FacilityActionRequiredProps {
    facilityActionRequired: number
}

function FacilityActionRequired(props: FacilityActionRequiredProps) {
  return (
    <Card className="cardBorderStyle">
      <Card.Body className='border-none'>
        <Figure className='mb-0'>
          <Figure.Image
            src={FarIconPNG}
            alt='FarIconLogo'
            className='mr-2 float-left'
            style={{maxWidth: "32px", maxHeight: "32px"}}
          />
          <Figure.Caption className='figure-caption-custom mt-1'>
          Facility Action Required
        </Figure.Caption>
        </Figure>
        <span className='card-data'>
          <span className="totalRecordCount">{props.facilityActionRequired}</span>
        </span>
      </Card.Body>
    </Card>
  );
}
export default FacilityActionRequired;
