import React, { Fragment, FunctionComponent, useEffect, useState  } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {buildNumberRequest} from "../../common/header/HeaderDucks"
import './Footer.scss';

function Footer(props) {
  useEffect(() => {
    props.getBuildnumber()
    }, [])
  return (
    <div className="footer">
      <div>
      <span>© { new Date().getFullYear() } Verisma Systems, Inc. All rights reserved.</span>
      <span className="float-right">build : {props.buildNumber} </span>
      </div>
    </div >
  )
}

function mapStateToProps(state: any) {
  return {
    buildNumber: state.headerState.buildNumber
  };
}

export function mapDispatchToProps(dispatch: Function) {
  return {
    getBuildnumber: () => dispatch(buildNumberRequest())

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
