import { takeLatest, all, takeEvery } from 'redux-saga/effects'
import {
    getOtpOrLogin,
    LoginConstants,
    getUserAttributes,
    logoutUsers,
    getFeatureFlags,
    getTokenLogin,
    returnToSearchSaga,
    getQAFeatureFlags,
} from '../../components/login/LoginPageDucks'
import { HeaderActionConstants, getBuildNumber } from '../../common/header/HeaderDucks'
import {
    LandingPageActionConstants, 
    generateOrGetTransactionId, 
    createTransactionSaga, 
    getTransactionSearchResultsSaga, 
    headerAssignTransactionIDSaga,
    headerUnssignTransactionIDSaga, 
    getHealthOrganizationsSearch, 
    clearUserLocksSaga, 
    getInitialsSaga,
    showReadOnlyTransactionSaga,
    getReadOnlyUserTasksSaga,
    addNewReadOnlyUserTaskSaga,
    updateReadOnlyUserTaskSaga,
    createReadOnlyUserTaskCommentSagas,
    deleteReadOnlyTransactionTaskSaga,
    getReadOnlyTransactionNotes,
    updateReadOnlyTransactionNotesSaga,
    getReadOnlyTransactionNoteTypesSaga,
    getLPAuditTrailSaga,
    getSystemBulletinMessagesSaga,
    userAcknowledgeBulletinSaga,
    readOnlyUploadMultipleDocumentsSaga,
    getReadOnlyTransactionDocumentsSaga,
    postReadOnlyWPSTokenSaga,
    getFacilitySpotlightDetailsSaga,
    getFacilitySpotlightFiltersSaga,
    resendTransactionSaga,
    getRejectOptionsSaga as getRejectOptionsSagaLanding,
    getLogoUrlSaga,
    getDefinedOutboundTemplateNamesSaga,
    getOutboundTemplateFieldsSaga,
    sendOutboundTemplateFieldsSaga,
    getTrackingLinkSaga,
    updateIsEscalated,
    updateIsEscalatedSaga,
    getNextQATransactionSaga,
} from '../../components/landingPage/LandingPageDucks'
import {
    getRequestTypesAndPurposes,
    DataEntryActionConstants,
    getTransactionAttributes,
    assignTransactionID,
    getFacilityDiscloseInstitutions,
    unassignTransactionID,
    getFeeSchedules,
    getTransactionNotes,
    getAdditionalChargeTypeList,
    getAdditionalChargeRecords,
    sendToRequestorData,
    sendToRequestorContactData,
    updateSendToRequestorDetails,
    updateBillToRequestorDetails,
    getRequestorSearchSaga,
    getRejectOptionsSaga as getRejectOptionsSagaDataEntry,
    getContactRecordsDetails,
    getShippingServices,
    getReleaseMethodOptions,
    getTransactionDocumentsSaga,
    patchPatientNoteSaga,
    patchRequestorNoteSaga,
    patchSendToRequestorContactSaga,
    getTransactionNoteTypesSaga,
    updateTransactionNotesSaga,
    updateAdditionalChargesSaga,
    uploadMultipleDocumentsSaga,
    rejectionContactOverrideSaga,
    getDocumentByIdSaga,
    updateDocumentSaga,
    getDocumentSourcesSaga,
    getRejectionLetterSaga,
    generateNextTransactionId,
    getPreviousTransactionSaga,
    deleteDocumentSaga,
    updateBillingFieldsSaga,
    getRetrieveProtocolsSaga,
    getCancellationReasonsSaga,
    postCancellationReasonsSaga,
    getPatientSearchSaga,
    getSelectedPHICodesSaga,
    getPHICodesSaga,
    createPatientRecordSaga,
    updatePHIdisclosedSaga,
    postRejectionActionSaga,
    getUserGroupsSaga,
    getDisclosureReasonSaga,
    updateDisclosureInformationSaga,
    getUserTasksSaga,
    getAssessmentResultsSaga,
    createUserTaskCommentSagas,
    addNewTaskSaga,
    updateUserTaskSaga,
    deleteTransactionTaskSaga,
    saveInformationToRleaseSaga,
    completeTransactionSaga,
    getBillToRequestorDetailsSaga,
    patchRequestorCompleteSaga,
    patchBillingCompleteSaga,
    patchDetailsCompleteSaga,
    patchPatientDetailsSaga,
    getAuditTypesSaga,
    getOBOsSaga,
    getValidateSaga,
    getAuditTrailSaga,
    getDuplicatesSaga,
    getTransactionDocumentsToCopySaga,
    resetTransactionDocumentsToCopy,
    updateTransactionOnFileSaga,
    postWPSTokenSaga,
    readBarCodeDDsSaga,
    getFacilitiesAdditionalFieldsSaga,
    getWaitAndHoldReasonsSaga,
    postWaitAndHoldReasonsSaga,
    patchTransactionContinueSaga,
    putAuditDigitalFaxValidationSaga,
    putAuditTransactionSensitiveInfoSaga,
    getStatusIndicatorSaga,
    userViewedDocumentSaga,
    returnToQueueSaga,
    getQAErrorsSaga,
    getQAIssuesSaga,
    postQATransactionIssueSaga,
    resolveQATransactionIssueSaga,
    removeQATransactionIssueSaga,
    getQAPDIssuesSaga,
    getAIFeedbackSaga,
    submitAIFeedbackSaga,
    deleteAIFeedbackSaga,
    getReviewEntitiesSaga,
    getSensitivityTypesSaga,
    createAIReviewEntitySaga,
    uploadReplaceDocumentSaga
} from '../../components/dataEntryDetails/DataEntryDetailsDucks'
import { apply } from 'redux-saga-test-plan/matchers'

export default function* root() {
    yield all([
        takeLatest(LoginConstants.getOtpOrLoginRequest, getOtpOrLogin),
        takeLatest(LoginConstants.getResendOtpForLoginRequest, getOtpOrLogin),
        takeLatest(LoginConstants.getUserAttributesRequest, getUserAttributes),
        takeLatest(LoginConstants.logoutUserRequest, logoutUsers),
        takeLatest(LoginConstants.getTokenLoginRequest, getTokenLogin),
        takeLatest(LoginConstants.getFeatureFlagsRequest, getFeatureFlags),
        takeLatest(LoginConstants.getQAFeatureFlagsRequest, getQAFeatureFlags),
        takeLatest(LoginConstants.returnToSearchRequest, returnToSearchSaga),
        takeLatest(HeaderActionConstants.buildNumberRequest, getBuildNumber),
        //takeLatest(LandingPageActionConstants.getHealthOrganizationsListRequest, getHealthOrganizationsList),
        takeEvery(LandingPageActionConstants.getHealthOrganizationsSearchRequest, getHealthOrganizationsSearch),
        takeLatest(LandingPageActionConstants.setSelectedFacilityDetails, generateOrGetTransactionId),
        takeLatest(LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION_SAGA, headerAssignTransactionIDSaga),
        takeLatest(LandingPageActionConstants.HEADER_UNASSIGN_TRANSACTION_SAGA, headerUnssignTransactionIDSaga),
        takeLatest(LandingPageActionConstants.createTransactionRequest, createTransactionSaga),
        takeLatest(LandingPageActionConstants.getTransactionSearchResultsRequest, getTransactionSearchResultsSaga),
        takeLatest(LandingPageActionConstants.clearUserLocksRequest, clearUserLocksSaga),
        takeLatest(LandingPageActionConstants.getInitialsRequest, getInitialsSaga),
        takeLatest(LandingPageActionConstants.SHOW_READONLY_TRANSACTION_REQUEST, showReadOnlyTransactionSaga),
        takeLatest(LandingPageActionConstants.getReadOnlyUserTasksRequest, getReadOnlyUserTasksSaga),
        takeLatest(LandingPageActionConstants.createReadOnlyUserTaskComment, createReadOnlyUserTaskCommentSagas),
        takeLatest(LandingPageActionConstants.addNewReadOnlyUserTaskRequest, addNewReadOnlyUserTaskSaga),
        takeLatest(LandingPageActionConstants.updateReadOnlyUserTaskRequest, updateReadOnlyUserTaskSaga),
        takeLatest(LandingPageActionConstants.deleteReadOnlyTransactionTaskRequest, deleteReadOnlyTransactionTaskSaga),
        takeLatest(LandingPageActionConstants.getReadOnlyTransactionNotesRequest, getReadOnlyTransactionNotes),
        takeLatest(LandingPageActionConstants.updateReadOnlyTransactionNotes, updateReadOnlyTransactionNotesSaga),
        takeLatest(LandingPageActionConstants.getReadOnlyTransactionNoteTypesRequest, getReadOnlyTransactionNoteTypesSaga),
        takeLatest(LandingPageActionConstants.getAuditTrailRequest, getLPAuditTrailSaga),
        takeLatest(LandingPageActionConstants.uploadMultipleDocumentsRequest, readOnlyUploadMultipleDocumentsSaga),
        takeLatest(LandingPageActionConstants.getTransactionDocumentsRequest, getReadOnlyTransactionDocumentsSaga),
        takeLatest(LandingPageActionConstants.postWPSRequest, postReadOnlyWPSTokenSaga),
        takeLatest(LandingPageActionConstants.getSystemBulletinMessagesRequest, getSystemBulletinMessagesSaga),
        takeLatest(LandingPageActionConstants.userAcknowledgesBulletinRequest, userAcknowledgeBulletinSaga),
        takeLatest(LandingPageActionConstants.getFacilitySpotlightFiltersRequest, getFacilitySpotlightFiltersSaga),
        takeLatest(LandingPageActionConstants.getFacilitySpotlightDetailsRequest, getFacilitySpotlightDetailsSaga),
        takeLatest(LandingPageActionConstants.resendTransactionRequest, resendTransactionSaga),
        takeLatest(LandingPageActionConstants.getRejectOptionsRequest, getRejectOptionsSagaLanding),
        takeLatest(LandingPageActionConstants.getLogoUrlRequest, getLogoUrlSaga),
        takeLatest(LandingPageActionConstants.getDefinedTemplateNamesRequest, getDefinedOutboundTemplateNamesSaga),
        takeLatest(LandingPageActionConstants.getOutboundTemplateFieldsRequest, getOutboundTemplateFieldsSaga),
        takeLatest(LandingPageActionConstants.sendOutboundTemplateFieldsRequest, sendOutboundTemplateFieldsSaga),
        takeLatest(LandingPageActionConstants.getTrackingLinkRequest, getTrackingLinkSaga),
        takeLatest(LandingPageActionConstants.updateIsEscalated, updateIsEscalatedSaga),
        takeLatest(LandingPageActionConstants.getQATransactionIdRequest, getNextQATransactionSaga),
        takeLatest(DataEntryActionConstants.getRequestTypesAndPurposesRequest, getRequestTypesAndPurposes),
        takeLatest(DataEntryActionConstants.GET_TRANSACTIONS_ATTRIBUTES_SAGA, getTransactionAttributes),
        takeLatest(DataEntryActionConstants.ASSIGN_TRANSACTION_ID_SAGA, assignTransactionID),
        takeLatest(DataEntryActionConstants.getFacilityDiscloseInstitutionsRequest, getFacilityDiscloseInstitutions),
        takeLatest(DataEntryActionConstants.UNASSIGN_TRANSACTION_ID_SAGA, unassignTransactionID),
        takeLatest(DataEntryActionConstants.GET_FEE_SCHEDULE_DATA, getFeeSchedules),
        takeLatest(DataEntryActionConstants.getTransactionNotesRequest, getTransactionNotes),
        takeLatest(DataEntryActionConstants.getAdditionalChargeType, getAdditionalChargeTypeList),
        takeLatest(DataEntryActionConstants.GET_ASSESSMENT_RESULTS, getAssessmentResultsSaga),
        takeLatest(DataEntryActionConstants.GET_REVIEW_ENTITIES, getReviewEntitiesSaga),
        takeLatest(DataEntryActionConstants.getAdditionalChargeRecords, getAdditionalChargeRecords),
        takeLatest(DataEntryActionConstants.SEND_TO_REQUESTOR_DATA_SAGA, sendToRequestorData),
        takeLatest(DataEntryActionConstants.SEND_TO_REQUESTOR_CONTACT_DATA_SAGA, sendToRequestorContactData),
        takeLatest(DataEntryActionConstants.UPDATE_SEND_TO_REQUESTOR_DATA_SAGA, updateSendToRequestorDetails),
        takeLatest(DataEntryActionConstants.updateBillToRequestorRequest, updateBillToRequestorDetails),
        takeLatest(DataEntryActionConstants.getRequestorSearchDetailsSAGA, getRequestorSearchSaga),
        takeLatest(DataEntryActionConstants.GET_DOCUMENT_BY_ID_SAGA, getDocumentByIdSaga),
        takeLatest(DataEntryActionConstants.getContactSearchDetailsSAGA, getContactRecordsDetails),
        takeLatest(DataEntryActionConstants.getTransactionDocumentsRequest, getTransactionDocumentsSaga),
        takeLatest(DataEntryActionConstants.getRejectOptionsRequest, getRejectOptionsSagaDataEntry),
        takeLatest(DataEntryActionConstants.GET_SHIPPING_SERVICE_DATA, getShippingServices),
        takeLatest(DataEntryActionConstants.getReleaseMethodOptionsData, getReleaseMethodOptions),
        takeLatest(DataEntryActionConstants.patchPatientsNotesSagas, patchPatientNoteSaga),
        takeLatest(DataEntryActionConstants.patchRequestorsNotesSagas, patchRequestorNoteSaga),
        takeLatest(DataEntryActionConstants.patchSendToRequestorContactSagas, patchSendToRequestorContactSaga),
        takeLatest(DataEntryActionConstants.getTransactionNoteTypesRequest, getTransactionNoteTypesSaga),
        takeLatest(DataEntryActionConstants.updateTransactionNotes, updateTransactionNotesSaga),
        takeLatest(DataEntryActionConstants.UPDATE_ADDITIONAL_CHARGES_SAGA, updateAdditionalChargesSaga),
        takeLatest(DataEntryActionConstants.uploadMultipleDocumentsRequest, uploadMultipleDocumentsSaga),
        takeLatest(DataEntryActionConstants.rejectionContactOverrideRequest, rejectionContactOverrideSaga),
        takeLatest(DataEntryActionConstants.updateDocumentRequest, updateDocumentSaga),
        takeLatest(DataEntryActionConstants.getDocumentSourcesRequest, getDocumentSourcesSaga),
        takeLatest(DataEntryActionConstants.getRejectionLetterSagas, getRejectionLetterSaga),
        takeLatest(DataEntryActionConstants.GET_NEXT_TRANSACTION_REQUEST_SAGA, generateNextTransactionId),
        takeLatest(DataEntryActionConstants.getPreviousTransactionSagas, getPreviousTransactionSaga),
        takeLatest(DataEntryActionConstants.deleteDocument, deleteDocumentSaga),
        takeLatest(DataEntryActionConstants.updateBillingPaneFields, updateBillingFieldsSaga),
        takeLatest(DataEntryActionConstants.getRetrieveProtocolRequest, getRetrieveProtocolsSaga),
        takeLatest(DataEntryActionConstants.getCancellationReasons, getCancellationReasonsSaga),
        takeLatest(DataEntryActionConstants.postCancellationReasonsRequest, postCancellationReasonsSaga),
        takeLatest(DataEntryActionConstants.GET_PATIENT_SEARCH_DETAILS_SAGA, getPatientSearchSaga),
        takeLatest(DataEntryActionConstants.GET_SELECTED_PHI_CODES_SAGA, getSelectedPHICodesSaga),
        takeLatest(DataEntryActionConstants.UPDATE_PHI_DISCLOSED_SAGA, updatePHIdisclosedSaga),
        takeLatest(DataEntryActionConstants.getPHICodesRequest, getPHICodesSaga),
        takeLatest(DataEntryActionConstants.createPatientRecordRequest, createPatientRecordSaga),
        takeLatest(DataEntryActionConstants.POST_REJECTION_ACTION_SAGA, postRejectionActionSaga),
        takeLatest(DataEntryActionConstants.getUserGroupsRequest, getUserGroupsSaga),
        takeLatest(DataEntryActionConstants.GET_DISCLOSURE_REASON_SAGA, getDisclosureReasonSaga),
        takeLatest(DataEntryActionConstants.UPDATE_DISCLOSURE_INFORMATION_SAGA, updateDisclosureInformationSaga),
        takeLatest(DataEntryActionConstants.getUserTasksRequest, getUserTasksSaga),
        takeLatest(DataEntryActionConstants.createUserTaskComment, createUserTaskCommentSagas),
        takeLatest(DataEntryActionConstants.addNewUserTaskRequest, addNewTaskSaga),
        takeLatest(DataEntryActionConstants.updateUserTask, updateUserTaskSaga),
        takeLatest(DataEntryActionConstants.DELETE_TRANSACTION_TASK_SAGA, deleteTransactionTaskSaga),
        takeLatest(DataEntryActionConstants.saveInformationToReleaseRequest, saveInformationToRleaseSaga),
        takeLatest(DataEntryActionConstants.COMPLETE_TRANSACTION_REQUEST_SAGA, completeTransactionSaga),
        takeLatest(DataEntryActionConstants.RETURN_TO_QUEUE_SAGA, returnToQueueSaga),
        takeLatest(DataEntryActionConstants.getRequestorBillingDetails, getBillToRequestorDetailsSaga),
        takeLatest(DataEntryActionConstants.DETAILS_COMPLETE_SAGA, patchDetailsCompleteSaga),
        takeLatest(DataEntryActionConstants.REQUESTOR_COMPLETE_SAGA, patchRequestorCompleteSaga),
        takeLatest(DataEntryActionConstants.BILLING_COMPLETE_SAGA, patchBillingCompleteSaga),
        takeLatest(DataEntryActionConstants.patchPatientDetails, patchPatientDetailsSaga),
        takeLatest(DataEntryActionConstants.getAuditTypesRequest, getAuditTypesSaga),
        takeLatest(DataEntryActionConstants.getOBOsRequest, getOBOsSaga),
        takeLatest(DataEntryActionConstants.getValidateRequest, getValidateSaga),
        takeLatest(DataEntryActionConstants.getAuditTrailRequest, getAuditTrailSaga),
        takeLatest(DataEntryActionConstants.GET_DUPLICATE_TRANSACTIONS, getDuplicatesSaga),
        takeLatest(DataEntryActionConstants.getTransactionDocumentsToCopyRequest, getTransactionDocumentsToCopySaga),
        takeLatest(DataEntryActionConstants.resetTransactionDocumentsToCopyRequest, resetTransactionDocumentsToCopy),
        takeLatest(DataEntryActionConstants.updateTransactionOnFileRequest, updateTransactionOnFileSaga),
        takeLatest(DataEntryActionConstants.postWPSRequest, postWPSTokenSaga),
        takeLatest(DataEntryActionConstants.readBarCodeDDSRequest, readBarCodeDDsSaga),
        takeLatest(DataEntryActionConstants.getFacilityAdditionalFieldsRequest, getFacilitiesAdditionalFieldsSaga),
        takeLatest(DataEntryActionConstants.getWaitAndHoldReasons, getWaitAndHoldReasonsSaga),
        takeLatest(DataEntryActionConstants.postWaitAndHoldReasonsRequest, postWaitAndHoldReasonsSaga),
        takeLatest(DataEntryActionConstants.patchTransactionContinueRequest, patchTransactionContinueSaga),
        takeLatest(DataEntryActionConstants.getStatusIndicatorRequest, getStatusIndicatorSaga),
        takeLatest(DataEntryActionConstants.putDigitalFaxValidationRequest, putAuditDigitalFaxValidationSaga),
        takeLatest(DataEntryActionConstants.putAuditTransactionSensitiveInfoRequest, putAuditTransactionSensitiveInfoSaga),
        takeLatest(DataEntryActionConstants.userViewedDocumentRequest, userViewedDocumentSaga),
        takeLatest(DataEntryActionConstants.GET_QA_ERRORS_RESULTS, getQAErrorsSaga),
        takeLatest(DataEntryActionConstants.GET_QA_ISSUES_RESULTS, getQAIssuesSaga),
        takeLatest(DataEntryActionConstants.postQATransactionIssueRequest, postQATransactionIssueSaga),
        takeLatest(DataEntryActionConstants.resolveQATransactionIssueRequest, resolveQATransactionIssueSaga),
        takeLatest(DataEntryActionConstants.removeQATransactionIssueRequest, removeQATransactionIssueSaga),
        takeLatest(DataEntryActionConstants.GET_QA_PD_ISSUES_RESULTS, getQAPDIssuesSaga),
        takeLatest(DataEntryActionConstants.GET_AI_FEEDBACK_REQUEST, getAIFeedbackSaga),
        takeLatest(DataEntryActionConstants.SUBMIT_AI_FEEDBACK_REQUEST, submitAIFeedbackSaga),
        takeLatest(DataEntryActionConstants.DELETE_AI_FEEDBACK_REQUEST, deleteAIFeedbackSaga),
        takeLatest(DataEntryActionConstants.GET_SENSITIVITY_TYPES_RESULTS, getSensitivityTypesSaga),
        takeLatest(DataEntryActionConstants.createAIReviewEntityRequest, createAIReviewEntitySaga),
        takeLatest(DataEntryActionConstants.updateReplaceDocumentRequest, uploadReplaceDocumentSaga)
    ])
}
