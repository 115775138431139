import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import '../landingPage/LandingPage.scss';
import { resetSelectedFacilityDetails, setSelectedFacilityDetails, createTransaction, updateSelectedFacilityDetails, onClickHealthOrganization, clearUserLocksActionRequest, 
  getInitialsRequest, updateRequestContext, getFacilitySpotlightDetails, getFacilitySpotlightFliters, getNextQATransaction } from './LandingPageDucks';
import LandingPage, { renderHealthOrganziationTransactionsData, getRoutePathName } from './LandingPage';
import Facility from '../../common/tabComponents/Facility';
import { push } from '../../redux/navigation/navigationDucks'
import { OrganizationDetailsModal, FacilitiesCountsModal, HealthSystemCountsModal, HealthSystemModal} from '../dataEntryDetails/dataEntryComponents/DataEntryDetails';
import { getFacilityDiscloseInstitutionsRequest, uploadDocument, getRequestTypesAndPurposesRequest } from '../dataEntryDetails/DataEntryDetailsDucks'
import { logoutUserRequest } from '../../components/login/LoginPageDucks';
import TimeoutWarning from "../../common/timeoutWarning/timeoutWarning";
import HomeIconBlue from "../../assets/images/headerImages/HomeIconBlue.png";
import SearchBox from '../../common/tabComponents/searchbox';
import { Breadcrumb, Col, Row, } from "react-bootstrap";
import LoadingSpinner from "../../common/loadingSpinner/loadingSpinner";
import NewRequestIcon from '../../assets/images/tabImages/NewRequestIcon.png';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import NormalTable from '../../common/table/NormalTable/NormalTable';
import { getMomentDate } from "../../common/utils/appUtils";
import SpotlightDetails from "../../common/modal/SpotlightDetailsModal";

interface HealthOrgFacilityDetailsProps {
  selectedFacilityDetails: any,
  setSelectedFacilityDetails: Function,
  resetSelectedFacilityDetails: Function,
  hltOrgTransactionsData: OrganizationDetailsModal,
  selectedHealthOrganizationDetails: OrganizationDetailsModal,
  navigateToDataEntryScreen: Function,
  getFacilityDiscloseInstitutionsRequest: Function,
  getFacilityFeeSchedules: Function,
  uploadDocument: Function,
  createTransaction: Function,
  createdTransactionDetails: any,
  updateFacilityDeatils: Function,
  createTransactionAPIStatus: number,
  healthOrganizationsSearch?: OrganizationDetailsModal[],
  onClickHealthOrganization?: any,
  getRequestsAndPurposes: Function,
  logoutUser: Function,
  userState: any,
  history: any,
  timeoutWarning: any,
  clearUserLocks?: Function,
  getInitialsRequest?: Function,
  userPersonalInfo: any,
  changeRequestContext: Function,
  getFacilitySpotlightFliters?: Function,
  getFacilitySpotlightDetails?: Function,
  facilitySpotlightFilters?: any,
  facilitySpotlightDetails?: any,
  spotlightFeature?: string,
  finalQaFeature?: string,
  getNextQATransaction: Function,
}

class HealthOrgFacilityDetails extends Component<HealthOrgFacilityDetailsProps, { searchTerm: string, anchorEl: any, spotlightGrid: Boolean, 
  tableData: any, selectedOrgId: any, selectedOrg: OrganizationDetailsModal, spotlightText: any, filteredHealthOrgs: OrganizationDetailsModal[]|null}, any> {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      anchorEl: null,
      spotlightGrid: false,
      tableData: [],
      selectedOrgId: null,
      spotlightText: null,
      filteredHealthOrgs: [],
      selectedOrg: {
        model: '',
        healthSystem: {
          model: '',
          id: 0,
          name: '',
          uri: '',
          facilities: [],
        },
        transactionCounts: {
          "model": '',
          total: 0,
          dataEntry: 0,
          recordAttachment: 0,
          recordValidation: 0,
          userTasks: 0,
          qaReview: 0,
          facilityActionRequired: 0
        },
        facilityCounts: []
      }
    };

  }

  onClickFacilityDetails = (facilityDetails: FacilitiesCountsModal) => {
    this.props.setSelectedFacilityDetails(facilityDetails);
    //this.props.navigateToDataEntryScreen("/data");
  }

  onClickHltOrg = (item?: OrganizationDetailsModal) => {
    item?.healthSystem.id && this.props.getRequestsAndPurposes(item?.healthSystem.id)
    this.props?.onClickHealthOrganization(item)
  }

  search = (term) => {
    this.onClickHltOrg();
    this.setState({ searchTerm: term })
  }

  filterOrgList = (healthOrgs: OrganizationDetailsModal[] | null) => {
    if (healthOrgs == null){
      this.setState({ filteredHealthOrgs: []});
    }else if (this.state.searchTerm === '') {
      this.setState({ filteredHealthOrgs: healthOrgs });
    } else {
      let list = healthOrgs.filter(item =>
        item.healthSystem.name.toLowerCase().includes(this.state.searchTerm) ||
        item.healthSystem.id.toString().includes(this.state.searchTerm) ||
        item.facilityCounts.some(hs =>
          hs.facility.name.toLowerCase().includes(this.state.searchTerm) ||
          hs.facility.id.toString().includes(this.state.searchTerm)
        )
      );
      list.sort((a, b) => {
        let a_org_starts = a.healthSystem.name.toLowerCase().startsWith((this.state.searchTerm)) || a.healthSystem.id.toString().startsWith((this.state.searchTerm));
        let b_org_starts = b.healthSystem.name.toLowerCase().startsWith((this.state.searchTerm)) || b.healthSystem.id.toString().startsWith((this.state.searchTerm));

        if (a_org_starts && !b_org_starts)
          return -1;
        else if (!a_org_starts && b_org_starts)
          return 1;

        let a_org_includes = a.healthSystem.name.toLowerCase().includes((this.state.searchTerm)) || a.healthSystem.id.toString().includes((this.state.searchTerm));
        let b_org_includes = b.healthSystem.name.toLowerCase().includes((this.state.searchTerm)) || b.healthSystem.id.toString().includes((this.state.searchTerm));

        if (a_org_includes && !b_org_includes)
          return -1;
        else if (!a_org_includes && b_org_includes)
          return 1;

        let a_fac_starts = a.facilityCounts.some(hs =>
          hs.facility.name.toLowerCase().startsWith(this.state.searchTerm) || hs.facility.id.toString().startsWith(this.state.searchTerm)
        )
        let b_fac_starts = b.facilityCounts.some(hs =>
          hs.facility.name.toLowerCase().startsWith(this.state.searchTerm) || hs.facility.id.toString().startsWith(this.state.searchTerm)
        )

        if (a_fac_starts && !b_fac_starts)
          return -1;
        else if (!a_fac_starts && b_fac_starts)
          return 1;

        let a_fac_includes = a.facilityCounts.some(hs =>
          hs.facility.name.toLowerCase().includes(this.state.searchTerm) || hs.facility.id.toString().includes(this.state.searchTerm)
        )
        let b_fac_includes = b.facilityCounts.some(hs =>
          hs.facility.name.toLowerCase().includes(this.state.searchTerm) || hs.facility.id.toString().includes(this.state.searchTerm)
        )

        if (a_fac_includes && !b_fac_includes)
          return -1;
        else if (!a_fac_includes && b_fac_includes)
          return 1;

        return 0;
      });
      if(list.length === 1){
        this.onClickHltOrg(list[0]);
      }
      this.setState({ filteredHealthOrgs: list});
    }
    this.forceUpdate();
  }

  componentDidMount() {
    if(global.userID === undefined){
      const id = sessionStorage.getItem("userID");
      global.userID = id ? Number(id) : 0;
    }
    this.props.getInitialsRequest && this.props.getInitialsRequest();
    
    const initOrgId = parseInt(window.location.hash.replace("#", ""));
    if(initOrgId && !isNaN(initOrgId)){
      let initOrg = this.props.healthOrganizationsSearch?.find(o => o.healthSystem.id === initOrgId);
      if(initOrg){
        this.onClickHltOrg(initOrg);
        const elementToScroll = document.getElementById(window.location.hash);
        elementToScroll && window.scrollTo({
            top: elementToScroll.offsetTop + 70,
            behavior: 'auto'
          });
      }else{
        this.search(initOrgId.toString())
      }
    }
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.healthOrganizationsSearch !== this.props.healthOrganizationsSearch){
      this.filterOrgList(nextProps.healthOrganizationsSearch || null);
    }
  }

  componentDidUpdate(prevProps, prevState){
    if (prevState.searchTerm !== this.state.searchTerm){
      this.filterOrgList(this.props.healthOrganizationsSearch || null);
    }
  }

  handleClose = (event) => {
    this.setState({ anchorEl: null, spotlightGrid: false  })
  }
  
  handleClick = (event, facilityId) => {
    this.props.getFacilitySpotlightFliters && this.props.getFacilitySpotlightFliters(facilityId);
    this.setState({ anchorEl: event.currentTarget, spotlightGrid: false, selectedOrgId: facilityId })
  }

  handleMenuItemClick = (event, item:OrganizationDetailsModal, name) => {
    this.setState({ selectedOrg: item })
    const { itemValue } = event.currentTarget.dataset;
    var requestObj = {
      facilityId: item.healthSystem?.id,
      filterId: itemValue
    }
    this.props.getFacilitySpotlightDetails && this.props.getFacilitySpotlightDetails(requestObj);

    const data =this.props.facilitySpotlightDetails.map(item => 
    this.createData(item.transactionID, item.patientLastName, item.patientFirstName, getMomentDate(item.patientDOB), getMomentDate(item.receivedDate), getMomentDate(item.dueDate), getMomentDate(item.taskDueDate), getMomentDate(item.delayLetterDate), item.requestorName, item.taskName, item.taskAssignedTo, item.reuestPurpose, item.transactionHoldReason, item.status))
    this.setState({ anchorEl: null, spotlightGrid: true, tableData: data, spotlightText:  name})

  };
  createData(transaction, lastName, firstName, dob, receivedDate, dueDate, taskDueDate, delayLetterDate, requestorName,  taskName, taskAssignedTo, reuestPurpose, transactionHoldReason, status)  {
    return { transaction, lastName, firstName, dob, receivedDate, dueDate, taskDueDate, delayLetterDate, requestorName,  taskName, taskAssignedTo, reuestPurpose, transactionHoldReason, status};
  }
  closeModel = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: null, spotlightGrid: false  })
  };

  render() {
    const { selectedHealthOrganizationDetails } = this.props;
    return (
      <>
        <LandingPage name={`Health Service Organizations`}
        searchPlaceholder="Start typing to search and filter facilities" getNextQATransaction={getNextQATransaction}>
          <>
            <div className="breadcrumbs-searchbox-row">
              <Breadcrumb className="facilities-breadcrumb header-breadcrumb d-lg-block d-none">
                <Breadcrumb.Item
                  onClick={() => this.props.history?.push('/home')}
                >
                  <img className="homeIcon" src={HomeIconBlue} alt="Home" />
                  <i className='arrow left' style={{ width: 100, height: 100 }}></i>
                  {getRoutePathName(selectedHealthOrganizationDetails).map((homeText, index) => (
                    <span key={"breadcrumbs" + index} className="breadcrumb-sub-item-facilities">
                      {homeText}
                    </span>
                  ))}
                </Breadcrumb.Item>
              </Breadcrumb>
              <SearchBox searchChange={this.search} placeholder={"Start typing to search and filter facilities"} searchterm={this.state.searchTerm} />
            </div>
            <div className="health-service-header">

              {!this.props.healthOrganizationsSearch && <LoadingSpinner />}
              {this.props.healthOrganizationsSearch && this.props.healthOrganizationsSearch.length == 0 && <p className='noOrg-message'>You do not have access to any health systems or facilities. Please contact your account manager for access.</p>}
              {this.props.healthOrganizationsSearch && this.props.healthOrganizationsSearch.length > 0 && this.state.searchTerm !== '' && this.state.filteredHealthOrgs && this.state.filteredHealthOrgs.length === 0 && <p className='noOrg-message'>There are no health systems which match your filter criteria.</p>}
              {this.state.filteredHealthOrgs && this.state.filteredHealthOrgs.length > 0 && this.state.filteredHealthOrgs.map((item: OrganizationDetailsModal, index: number) => {
                return (
                  selectedHealthOrganizationDetails == null || selectedHealthOrganizationDetails.healthSystem.name !== item?.healthSystem.name
                    ?
                    <div key= {"healtorgdetails " + index} className='healthOrg-container healthOrg-rollover'>
                     <Row>
                        <Col xs={10} md={10}>
                      <div id={"#" + item?.healthSystem.id} 
                      onClick={() => this.onClickHltOrg(item)}>
                      <span style={{ display: 'flex' }}>
                        <span className="linkTextColor org-name"
                          key={index} >
                          {item?.healthSystem.name}</span>
                        <span className="linkTextColor id-style org-number">{item?.healthSystem.id}</span>
                        </span>
                       
                      </div></Col>
                     
                      {this.props.spotlightFeature && this.props.spotlightFeature === 'true' ? 
                       <Col xs={2} md={2}>
                        <div className='spotlightLink'>
                              <a href="#!" className="newRequest" onClick={event => this.handleClick(event, item?.healthSystem.id)}>
                              <i className="fa fa-dot-circle-o" aria-hidden="true"></i>
                                Spotlight
                              </a>
                              
                        </div></Col>
                        : null }
                        {this.props.facilitySpotlightFilters && this.props.facilitySpotlightFilters.length > 0  ?
                        <Menu
                          keepMounted
                          anchorEl={this.state.anchorEl}
                          onClose={this.handleClose}
                          id={"#mn" + item?.healthSystem.id}
                          open={Boolean(this.state.anchorEl)}
                        >
                          {this.props.facilitySpotlightFilters.map(menu =>
                              <MenuItem data-item-value={menu.filterID} onClick={(event) => this.handleMenuItemClick(event,item, menu.filterName)}>{menu.filterName}</MenuItem>
                            )}
                        </Menu>
                        : null } 
                        {renderHealthOrganziationTransactionsData(item, this.props.finalQaFeature == "true", () => this.onClickHltOrg(item))}
                      {this.state.selectedOrgId === item.healthSystem?.id && this.state.spotlightGrid &&
                            <SpotlightDetails
                              show={this.handleMenuItemClick}
                              handleClose={event => this.closeModel(event)}
                              tableData={this.state.tableData}
                              spotlightText={this.state.spotlightText}
                              setSearchGrid={true}
                              
                            ></SpotlightDetails>
                          }
                      </Row>
                    </div>
                    : <div key= {"healtorgdetails " + index}>
                      <span id={"#" + item?.healthSystem.id} className='healthOrg-rollover'>
                        <Row>
                        <Col xs={10} md={10}>
                        <span onClick={() => this.onClickHltOrg()}>
                          <span style={{ display: 'flex', marginBottom: 7 }} onClick={() => this.onClickHltOrg()}>
                            <span className="linkTextColor org-name">
                                  {item.healthSystem.name}
                            </span>
                            <span className="linkTextColor id-style org-number">
                                  {item.healthSystem.id}
                            </span>
                            </span></span></Col>

                            {this.props.spotlightFeature && this.props.spotlightFeature === 'true' ?
                            <Col xs={2} md={2}>
                              <div className='spotlightLink'>
                                  <a href="#!" className="newRequest" onClick={event => this.handleClick(event, item?.healthSystem.id)}>
                                  <i className="fa fa-dot-circle-o" aria-hidden="true"></i>
                                    Spotlight
                                  </a>
                              </div>
                              </Col>
                            : null }
                        {this.props.facilitySpotlightFilters && this.props.facilitySpotlightFilters.length > 0  ?
                        <Menu
                          keepMounted
                          anchorEl={this.state.anchorEl}
                          onClose={this.handleClose}
                          open={Boolean(this.state.anchorEl)}
                        >
                           {this.props.facilitySpotlightFilters.map(menu =>
                              <MenuItem data-item-value={menu.filterID} onClick={(event) => this.handleMenuItemClick(event,item,menu.filterName)}>{menu.filterName}</MenuItem>
                            )}
                        </Menu>
                        : null }
                          {renderHealthOrganziationTransactionsData(item, this.props.finalQaFeature == "true", () => this.onClickHltOrg())}
                          {this.state.selectedOrgId === item.healthSystem.id && this.state.spotlightGrid &&
                            <SpotlightDetails
                              show={this.handleMenuItemClick}
                              handleClose={event => this.closeModel(event)}
                              tableData={this.state.tableData}
                              spotlightText={this.state.spotlightText}
                              setSearchGrid={true}
                            ></SpotlightDetails>
                          }
                        
                        </Row>
                      </span>
                      <Facility onClickFacility={this.onClickFacilityDetails}
                        createTransaction={this.props.createTransaction}
                        createTransactionAPIStatus={this.props.createTransactionAPIStatus}
                        updateFacilityDeatils={this.props.updateFacilityDeatils}
                        createdTransactionDetails={this.props.createdTransactionDetails}
                        getFacilityDiscloseInstitutionsRequest={this.props.getFacilityDiscloseInstitutionsRequest}
                        hltOrgTransactionsData={item} uploadDocument={this.props.uploadDocument}
                        clearUserLocks={this.props.clearUserLocks} 
                        changeRequestContext = {this.props.changeRequestContext}
                        searchTerm={this.state.searchTerm}
                        showQaReview={this.props.finalQaFeature == "true"}
                        />
                    </div>
                )
              })}
            </div>
          </>
        </LandingPage>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    hltOrgTransactionsData: state.landingPageState.hltOrgTransactionsData,
    selectedHealthOrganizationDetails: state.landingPageState.selectedHealthOrganizationDetails,
    selectedFacilityDetails: state.landingPageState.selectedFacilityDetails,
    transactionId: state.landingPageState.transactionId,
    createdTransactionDetails: state.landingPageState.createdTransactionDetails,
    createTransactionAPIStatus: state.landingPageState.createTransactionAPIStatus,
    healthOrganizationsSearch: state.landingPageState.getHealthOrganizationsSearch,
    userState: state.loginState,
    userPersonalInfo: state.landingPageState.userPersonalInfo,
    facilitySpotlightFilters: state.landingPageState.facilitySpotlightFilters,
    facilitySpotlightDetails: state.landingPageState.facilitySpotlightDetails,
    spotlightFeature: state.loginState.featureFlags.spotlight,
    finalQaFeature: state.loginState.featureFlags.FinalQA,
  }
}

export function mapDispatchToProps(dispatch: Function) {
  return {
    onClickHealthOrganization: (data: any) => dispatch(onClickHealthOrganization(data)),
    setSelectedFacilityDetails: (data: FacilitiesCountsModal) => dispatch(setSelectedFacilityDetails(data)),
    resetSelectedFacilityDetails: () => dispatch(resetSelectedFacilityDetails()),
    navigateToDataEntryScreen: (path: string) => dispatch(push(path)),
    getFacilityDiscloseInstitutionsRequest: (facilityId: string) => dispatch(getFacilityDiscloseInstitutionsRequest(facilityId)),
    uploadDocument: (data: any) => dispatch(uploadDocument(data)),
    createTransaction: (data: any) => dispatch(createTransaction(data)),
    updateFacilityDeatils: (data: any) => dispatch(updateSelectedFacilityDetails(data)),
    getRequestsAndPurposes: (healthSystemId: number) => dispatch(getRequestTypesAndPurposesRequest(healthSystemId)),
    logoutUser: (action) => dispatch(logoutUserRequest(action)),
    clearUserLocks: () => dispatch(clearUserLocksActionRequest()),
    getInitialsRequest: () => dispatch(getInitialsRequest()),
    changeRequestContext: (data: any) => dispatch(updateRequestContext(data)),
    getFacilitySpotlightFliters: (facilityId: string) => dispatch(getFacilitySpotlightFliters(facilityId)),
    getFacilitySpotlightDetails: (data: any) => dispatch(getFacilitySpotlightDetails(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthOrgFacilityDetails)
