import axios from 'axios';

//In order to have this project access the Dev API, create a file in the project root named '.env.development.local' and add the following line to it:
//REACT_APP_API_URL = 'https://app-verisma-dev.azurewebsites.net/api/api/'
//This file is listed in .gitignore and will not be included with push requests
const baseURL = process.env.REACT_APP_API_URL ?? "/api/api/";

export const documentURL = baseURL + "documents/"

const headers = {
  'Cache-Control': 'no-cache',
  'Content-Type': 'application/json',
  // 'HOST': 'rois-api.azurewebsites.net',
  'Content-Lenth': '8000',
  'refreshaudittrail': '0'
}

export const ApiUrl = () => { return baseURL; }

export const post = (url: string, data: any, customHeaders: any = headers) => axios.post(baseURL + url, data, { headers: customHeaders }).then((resp) => resp)
  .catch((error: any) => {
    throw error
  })

export const postProgress = (url: string, data: any, customHeaders: any = headers, onUploadProgress: any) => axios.post(baseURL + url, data, { headers: customHeaders, onUploadProgress: onUploadProgress }).then((resp) => resp)
  .catch((error: any) => {
    throw error
  })

export const get = (url: string) => axios.get(baseURL + url, { headers }).then(resp => resp)
  .catch((error: any) => {
    throw error
  })
export const deleteRequest = (url: string) => axios.delete(baseURL + url, { headers }).then(resp => resp)
  .catch((error: any) => {
    throw error
  })

export const putRequest = (url: string, data: any) => axios.put(baseURL + url, data, { headers }).then((resp) => resp)
  .catch((error: any) => {
    throw error
  })

export const patchRequest = (url: string, data: any) => axios.patch(baseURL + url, data, { headers }).then((resp) => resp)
  .catch((error: any) => {
    throw error
  })

export const getWithoutBaseURL = (url: string) => axios.get(url, { headers }).then(resp => resp)
  .catch((error: any) => {
    throw error
  })