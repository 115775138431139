import { AccessTimeTwoTone } from '@material-ui/icons'
import moment from 'moment'
import { action } from 'typesafe-actions'
import Api from '../../../services/Api'
import { get, putRequest, patchRequest, post, deleteRequest, getWithoutBaseURL, postProgress } from '../../../services/Network'
import FormConstants from '../dataEntryComponents/DataEntryFieldsConstants'

export const getRequestTypes = (action: any) => {
  return get(Api.getRequestTypesAndPurposes + action.data + "/requesttypes?userID=" + global.userID)
}

export const getInitials = (action: any) => {
  return get(Api.getUserAttributes + '?userID=' + global.userID)
}

export const getDisclosingInstituionAPI = (action: any) => {
  return get(Api.getDisclosingInstitutions + action.data + "/DisclosingInstitution?userID=" + global.userID)
}

export const updatePHIdisclosedAPI = (action: any) => {
  return patchRequest(Api.patchPHIdisclosed + action.data.transactionId + "/phidisclosed?requestedItemid=" + action.data.requestedItemID + "&userID=" + global.userID + "&delete=" + action.data.isDelete, {})
}

export const getDocumentByIdAPI = (action: any) => {
  return get(Api.getDocument + action.data.documentId + "/file?userID=" + global.userID + (action.data.auditOnShowDoc ? "" : "&addTrailEntry=false"))
}

export const deleteDocumentByIdAPI = (action: any) => {
  return deleteRequest(Api.getDocument + action.data + "?userID=" + global.userID)
}

export const getTransactionAttributesAPI = (action: any) => {
  return get(Api.getTransactionAttributes + action.data + "?userID=" + global.userID)
}

export const getFeeSchedulesAPI = (action: any) => {
  return get(Api.getFacilitiesAttributes + action.data.facilityId + "/feeSchedules?userID=" + global.userID + "&transactionid=" + action.data.transactionId)
}

export const getFeeScheduleAPI = (action: any) => {
  return get(Api.getDefaultFeeSchedule + action.data.transactionId + "/feeSchedule")
}

export const getAdditionalChargeTypeAPI = (action: any) => {
  return get(Api.getAdditionalChargeType + action.data + "/chargeTypes?userID=" + global.userID)
}

export const getAdditionalChargeRecordsAPI = (action: any) => {
  return get(Api.getTransactionNotes + action.data + "/AdditionalCharges?userID=" + global.userID)
}

export const getShippingServicesAPI = (action: any) => {
  return get(Api.getFacilitiesAttributes + action.data + "/shippingServices?userID=" + global.userID)
}

export const getCancellationReasonsAPI = (action: any) => {
  return get(Api.getAdditionalChargeType + action.data + "/cancellationReasons?userID=" + global.userID)
}

export const getSelectedPHICodesAPI = (action: any) => {
  return get(Api.getSelectedPHIodes + action.data + "/phidisclosed?userID=" + global.userID)
}

export const getReleaseMethodOptionsAPI = (action: any) => {
  return get(Api.getFacilitiesAttributes + action.data.facilityId + "/releaseMethods?userID=" + global.userID + "&transactionid=" + action.data.transactionId)
}
export const assignTransactionIdAPI = (action: any) => {
  return putRequest(Api.assignTransactionId + action.data + "/action?action=assign&userID=" + global.userID, {})
}

export const unAssignTransactionAPI = (action: any) => {
  if (action.data) {
    return putRequest(Api.assignTransactionId + action.data + "/action?action=unassign&userID=" + global.userID, {})
  }
}

export const getRequestorDetailsAPI = (action: any) => {
  if(action.requestorId && action.requestorContactId){
    return get(Api.getRequestorDetails + action.requestorId + "?requestorcontactid=" + action.requestorContactId + "&userid=" + global.userID)
    
  }
  else if (action.requestorId) {
    return get(Api.getRequestorDetails + action.requestorId + "?userid=" + global.userID)

  }
}

export const getRequestorBillingDetailsAPI = (action: any) => {
  return get(Api.getRequestorBillingDetails + action.requestorBillingId + "?&userid=" + global.userID)
}

export const getRequestorBillingDetailsByTxAPI = (action: any) => {
  return get(Api.getRequestorBillingDetailsByTx + action.transactionId + "?&userid=" + global.userID)
}

export const getContactDetailsAPI = (action: any) => {
  if (action.requestorId) {
    return get(Api.getRequestorDetails + action.requestorId + "/contacts?" + (action.distinct ? "distinct=true&" : "") + "userID=" + global.userID)
  }
}

export const getContactDetailByIdAPI = (action: any) => {
  if (action.requestorId) {
    return get(Api.getRequestorDetails + action.contactId + "/contact?userID=" + global.userID)
  }
}

export const getTransactionNotesAPI = (action: any) => {
  return get(Api.getTransactionNotes + action.data + "/notes?userID=" + global.userID)
}

export const putSendToRequestorAPI = (action: any) => {
  let queryParams = Object.keys(action.data.data)
    .map(key => `${key}=${encodeURIComponent(action.data.data[key])}`)
    .join('&');
  console.log('test', queryParams)
  return putRequest(Api.putSendToRequestor + action.data.transactionID + "/createsendtorequestor?" + queryParams, {})
}

export const putSendToRequestorContactAPI = (action: any) => {
  let queryParams = Object.keys(action.data.data)
    .map(key => `${key}=${encodeURIComponent(action.data.data[key])}`)
    .join('&');
  console.log('test', queryParams)
  return putRequest(Api.putSendToRequestor + action.data.requestorID + "/createsendtorequestorcontact?" + queryParams, {})
}

export const putResendTransactionAPI = (action: any) => {
  if (action.data.transactionId && action.data.contactId){
    return putRequest(Api.getResendTransaction + "resend/" + action.data.transactionId + "/" + action.data.contactId + "?userID=" + global.userID, {})
  }
}

export const updateSendToRequestorAPI = (action: any) => {
  let queryParams = Object.keys(action.data.data)
    .map(key => `${key}=${encodeURIComponent(action.data.data[key])}`)
    .join('&');
  if (action.data.requestorid) {
    return patchRequest(Api.updateBillToRequestorDetails + action.data.requestorid + "/updatesendtorequestor?" + queryParams, {})
  }
}

export const patchSendToRequestorContact = (action: any) => {
  let requestorContactId = action.data.data.requestorcontactid
  let updatedData = { ...action.data.data }
  delete updatedData['requestorcontactid']
  let queryParams = Object.keys(updatedData)
    .map(key => action.data.data[key] ? `${key}=${encodeURIComponent(action.data.data[key])}` : '')
    .join('&');
  
  if (requestorContactId) {
    return patchRequest(Api.sentToRequestorsContact + requestorContactId + "/updatesendtorequestorcontact?userID=" + global.userID + "&" + queryParams, {})
  }
}

export const updateBillToContactDetailsAPI = (action: any) => {
  let queryParams = Object.keys(action.data.data)
    .filter(key => action.data.data[key] !== undefined)
    .map(key => action.data.data[key] == null ? `${key}=` : `${key}=${encodeURIComponent(action.data.data[key])}`)
    .join('&');

  if (action.data.requestorbillingcontactid) {
    return patchRequest(Api.updateBillToRequestorDetails + action.data.requestorbillingcontactid + "/updatebilltorequestor?" + queryParams + '&transactionId=' + action.transactionId, {})
  }
}

export const getRequestorSearchDetailsAPI = (action: any) => {

  // check if action.data has advance fields
  let apiEndPoint = Api.getRequestorSearchDetails + action.facilityId + "/search?phone="
  if (action.data.phoneOrFax) {
    apiEndPoint += action.data.phoneOrFax + "&fax=" + action.data.phoneOrFax
  }
  if (action.data.address) {
    apiEndPoint += "&address=" + action.data.address
  }
  if (action.data.zip) {
    apiEndPoint += "&zip=" + action.data.zip
  }
  if (action.data.emailAddress) {
    apiEndPoint += "&email=" + action.data.emailAddress
  }

  if (action.data.rqSiteCode) {
    apiEndPoint += "&siteCode=" + action.data.rqSiteCode
  }
  if (action.data.internalRequestor) {
    apiEndPoint += "&requestorname=" + action.data.internalRequestor
  }
  if (action.data.typeOfRequestorID) {
    apiEndPoint += "&typeOfRequestorID=" + action.data.typeOfRequestorID
  }
  if (action.data.contactName) {
    apiEndPoint += "&contactName=" + action.data.contactName
  }
  if (action.data.requestorName) {
    apiEndPoint += "&requestorname=" + action.data.requestorName
  }
  return get(apiEndPoint + "&userID=" + global.userID)
}

export const getPatientSearchDetailsAPI = (action: any) => {
  return get(Api.getPatientDetails + action.data.facilityId + "?lastnameMRN=" + action.data.lastName + "&dob=" + action.data.dob + "&userID=" + global.userID)
}

export const getAssessmentResultsAPI = (action: any) => {
  let apiEndPoint = Api.getAssessment + "?transactionId=" + action.transactionId + "&userID=" + global.userID;
  return get(apiEndPoint)
}

export const getReviewEntitiesAPI = (action: any) => {
  let apiEndPoint = Api.getReviewEntities + action.transactionId;
  return get(apiEndPoint);
}

export const requestOtpOrLoginAPI = (action: any) => {
  return post(Api.requestOtpOrLogin + "?userName=" + action.userName +
    "&otp=" + action.otp, action)
}

export const getTokenLoginAPI = (action: any) => {
  let apiEndPoint = Api.getTokenLogin + action.token;
  return get(apiEndPoint)
}

export const returnToSearchAPI = (action: any) => {
  let apiEndPoint = Api.returnToSearch + "?userId=" + global.userID;
  return post(apiEndPoint, action);
}

export const logoutUserAPI = (action: any) => {
  return deleteRequest(Api.requestOtpOrLogin + "?userID=" + action.userId)
}

export const getUserAttributeAPI = (action: any) => {
  return get(Api.requestOtpOrLogin + "?userID=" + global.userID)
}

export const getTransactionDocumentsAPI = (action: any) => {
  return get(Api.getTransactionDocuments + action.data + "/documents?userID=" + global.userID)
}
export const getRejectOptionsAPI = (action: any) => {
  return get(Api.getRejectOptions + action.data + "/rejectionreasons?userID=" + global.userID)
}

export const patchPatientsNotesAPI = (action: any) => {
  return patchRequest(Api.patchPatientsNotes + action.data.patientid + "/notes?note=" + encodeURIComponent(action.data.note) + "&userID=" + global.userID, {})
}

export const patchRequestorNotesAPI = (action: any) => {
  return patchRequest(Api.patchRequestorsNotes + action.data.requestorid + "/notes?note=" + encodeURIComponent(action.data.note) + "&userID=" + global.userID, {})
}

export const uploadDocumentAPI = (action: any) => {
  let data = new FormData()
  data.append("document", action.data.file)
  const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Content-Lenth': 8000
  }
  let fileName = action.data.fileName.split('.').slice(0, -1).join('.')
  return postProgress(Api.uploadDocument + action.data.transactionId + 
    "&recordType=" + action.data.recordType + 
      "&fileDescription=" + encodeURIComponent(fileName.replace(/[/\\?%#*:|"<>]/g, '-')) + 
    "&userid=" + global.userID +
    (action.data.append ? "&append=true" : "")
    , data, headers, action.data.uploadProgress)
}

export const uploadBlobDocumentAPI = (action: any) => {
  let data = new FormData()
  data.append("document", action.data.blob, action.data.fileName)

  const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Content-Lenth': 8000
  }
  let fileName = action.data.fileName.split('.').slice(0, -1).join('.')
  return postProgress(Api.uploadDocument + action.data.transactionId + 
    "&recordType=" + action.data.recordType + 
    "&fileDescription=" + encodeURIComponent(fileName.replace(/[/\\?%#*:|"<>]/g, '-')) + 
    "&userid=" + global.userID + 
    (action.data.documentId ? "&documentFileId=" + action.data.documentId : "") + 
    (action.data.pagesRemoved ? "&pagesDeleted=" + action.data.pagesRemoved : "")
    , data, headers, action.data.uploadProgress)
}

export const postCancellationReasonsAPI = (action: any) => {
  let queryparamsstr = ""
  if (action.isDuplicate) {
    queryparamsstr += `&isDuplicate=${action.isDuplicate}`;
  }
  if (action.cancelReasonText !== '') {
    queryparamsstr += `&cancelReasonText=${encodeURIComponent(action.cancelReasonText)}`;
  }
  queryparamsstr += `&userID=${global.userID}`
  return post(Api.postCancellationReasons + action.transactionId + "/cancel?cancelReasonId=" + action.id + queryparamsstr, {})
}

export const generateOrGetTransactionIdAPI = (action: any) => {
  console.log("test \n",action)
  return get(Api.generateOrGetTransactionId + action.data.facility.id
    + "/next?userID=" + global.userID + "&context=" + action.requestContext)
}

//export const getHealthOrganizationListAPI = (action: any) => {
//  return get(Api.getHealthOrganizationsSearch + "?userID=" + global.userID)
//}

export const getHealthOrganizationSearchAPI = (action: any) => {
  let uri = Api.getHealthOrganizationsSearch + "?userID=" + global.userID;
  if (action.offset != null)
    uri += "&offset=" + action.offset;
  if (action.limit != null)
    uri += "&limit=" + action.limit;
  if (action.searchTerm != null)
    uri += "&searchTerm=" + encodeURIComponent(action.searchTerm);
  return get(uri)
}

export const getHealthSystemByFacilityAPI = (action: any) => {
  let uri = Api.getHealthSystemByFacility + "/" + action.data.facilityId + "?userID=" + global.userID
  return get(uri);
}

export const createNewTransactionAPI = (action: any) => {
  return putRequest(Api.createTransaction + action.data.facilityId + "/create?userID=" + global.userID, {})
}

export const getTransactionNoteTypesAPI = (action: any) => {
  return get(Api.getTransactionNoteTypes + action.data + "/noteTypes?userID=" + global.userID)
}

export const updateTransactionNotesAPI = (action: any) => {
  return putRequest(Api.updateTransactionNotes + action.data.transactionId + "/note?note=" + encodeURIComponent(action.data.note) + "&definedTypeid=" + action.data.type + "&userID=" + global.userID, {})
}

export const updateAdditionalChargesAPI = (action: any) => {
  return patchRequest(Api.getTransactionAttributes + action.data.transactionId + "/additionalCharges?userID=" + global.userID, action.data.requestData)
}

export const rejectionContactOverrideAPI = (action: any) => {
  return patchRequest(Api.rejectionContactOverride + action.data.transactionId + "/rejectioncontactoverride?requestoremail=" +
    action.data.email + "&requestorfax=" + action.data.fax + "&updatecontact=true&userID=" + global.userID, {})
}

export const updateDocumentAPI = (action: any) => {
  let endPoint = Api.updateDocument + action.data.documentId + "/update?fileDescription="
  if (action.data.fileDescription) {
    endPoint += action.data.fileDescription
  }
  if (action.data.recordSourceId) {
    endPoint += "&recordSourceId=" + action.data.recordSourceId
  }
  if (action.data.account) {
    endPoint += "&account=" + action.data.account
  }
  if (action.data.toDateOfService) {
    var d = moment(new Date(action.data.toDateOfService)).format("YYYY-MM-DD")
    endPoint += "&toDateOfService=" + d.replaceAll(":", "%3A").replaceAll(".", "%2E")
  }
  if (action.data.fromDateOfService) {
    var d = moment(new Date(action.data.fromDateOfService)).format("YYYY-MM-DD")
    endPoint += "&fromDateOfService=" + d.replaceAll(":", "%3A").replaceAll(".", "%2E")
  }
  return patchRequest(endPoint + "&userid=" + global.userID, {})
}

export const getDocumentSourcesAPI = (action: any) => {
  return get(Api.getDocumentSources + action.data + "/documentSources?userID=" + global.userID)
}

export const getRejectionLetterDataAPI = (action: any) => {
  return get(Api.getRejectionLetter + action.data + "/rejectiontemplate?userID=" + global.userID)
}

export const getPreviousTransactionAPI = (action: any) => {
  return get(Api.getPreviousTransaction + action.data + "/previous?userID=" + global.userID)
}

export const updateBillingPaneFieldsAPI = (action: any) => {
  let updatedData = { ...action.data }
  delete updatedData["transactionId"]
  let queryParams = Object.keys(updatedData)
    .map(key => `${key}=${encodeURIComponent(updatedData[key])}`)
    .join('&');
  return patchRequest(Api.updateBillingPane + action.data.transactionId + "/update?" + queryParams, {})
}

export const retrieveProtocolAPI = (action: any) => {
  return get(Api.getTransactionDocuments + action.data + "/retrievalprotocol?userID=" + global.userID)
}

export const getPHICodesAPI = (action: any) => {
  return get(Api.getPHICodes + action.data + "/phicodes?userID=" + global.userID)
}

export const createPatientRecordAPI = (action: any) => {
  const dob = moment(action.data[FormConstants.DOB]).format("MM/DD/YYYY")
  let apiEndPoint = action.data.transactionId + "/create?firstname=" + action.data[FormConstants.PATIENT_NAME] + "&middlename=" + action.data[FormConstants.PATIENT_MIDDLE_NAME] +
    "&lastname=" + action.data[FormConstants.PATIENT_LAST_NAME] + "&dob=" + dob
  apiEndPoint = action.data[FormConstants.EMPI_NUMBER] ? apiEndPoint + "&empi=" + action.data[FormConstants.EMPI_NUMBER] : apiEndPoint
  apiEndPoint = action.data[FormConstants.MRN_ID] ? apiEndPoint + "&mrn=" + action.data[FormConstants.MRN_ID] : apiEndPoint
  return putRequest(Api.createPatientRecord + apiEndPoint + "&userID=" + global.userID, {})
}

export const getTransactionSearchResultsAPI = (action: any) => {
  return get(Api.getTransactionSearchResults + "?orgId=" + action.data.orgId + "&searchItem=" + action.data.searchItem + "&userID=" + global.userID + "&allowReadOnly=" + action.data.allowReadOnly.toString())
}

export const getUserGroupsAPI = (action: any) => {
  return get(Api.getUserGroups + action.data + "?userId=" + global.userID)
}

export const postRejectionActionAPI = (action: any) => {
  let requestData = `/reject?action=${action.data.action}&includeAuthForm=${action.data.includeAuthForm}`;
  if (action.data.action === 'email') {
    requestData = requestData + `&overrideEmail=${encodeURIComponent(action.data.overrideEmail)}`
  } else if (action.data.action === 'fax') {
    requestData = requestData + `&overrideFax=${action.data.overrideFax}`
  }
  if (action.data.descriptionText) {
    requestData = requestData + `&descriptionText=${encodeURIComponent(action.data.descriptionText)}`
  }
  if (action.data.cancelReasonIds) {
    requestData = requestData + `&cancelReasonIds=${action.data.cancelReasonIds}`
  }
  return post(Api.postRejectionAction + action.data.transactionId + requestData + "&userID=" + global.userID, {})
}

export const getUserAttributesAPI = () => {
  return get(Api.requestOtpOrLogin + "?userID=" + global.userID)
}

export const generateNextTransactionIdAPI = (action: any) => {
  console.log(action)
  let facility = action.data.facilityId ?action.data.facilityId: action.data
  return get(Api.generateOrGetTransactionId + facility
    + "/next?userID=" + global.userID + "&context=" + action.context)
}

export const getDisclosureReasonAPI = (action: any) => {
  return get(Api.getDisclosureReason + action.data
    + "/disclosureReasons?userID=" + global.userID)
}

export const updateDisclosureInformationAPI = (action: any) => {
  let queryParams = Object.keys(action.data.data)
    .map(key => `${key}=${encodeURIComponent(action.data.data[key])}`)
    .join('&');
  return patchRequest(Api.updateDisclosureInformation + action.data.transactionID
    + "/disclosure?" + queryParams, {})
}

export const getUserTasksAPI = (action: any) => {
  return get(Api.getUserTasks + action.data + "/userTasks?userID=" + global.userID)
}

export const getUserTaskCommentsAPI = (action: any) => {
  return get(Api.getUserTaskComments + action.data + "/usertaskhistory?userID=" + global.userID)
}

export const createUserTaskCommentAPI = (action: any) => {
  return putRequest(Api.createUserTaskComment + action.data.taskId + "/usertaskcomment?comment=" + action.data.comment + "&userID=" + global.userID, {})
}

export const adNewUserTaskAPI = (action: any) => {
  let keyName = "assignedGroupIds"
  let value = action.data.assignedToGroupIds
  if (action.data.assignedTo?.id) {
    keyName = "assignedIds"
    value = action.data.assignedTo?.id
  }
  return putRequest(Api.addUserTask + action.data.transactionId + "/usertasks?title=" + action.data.subject + "&description=" + encodeURIComponent(action.data.description) + "&dueDate=" +
    encodeURIComponent(action.data.dueDate) + "&status=" + action.data.taskStatusID + "&" + keyName + "=" + value + "&userId=" + global.userID, {})
}

export const updateUserTaskAPI = (action: any) => {
  let keyName = "assignedGroupIds"
  let value = action.data.assignedToGroupIds
  if (action.data.assignedTo?.id) {
    keyName = "assignedIds"
    value = action.data.assignedTo?.id
  }
  return patchRequest(Api.addUserTask + action.data.transactionId + "/usertasks/" + action.data.id + "?title=" + action.data.subject + "&description=" + encodeURIComponent(action.data.description) + "&dueDate=" +
    encodeURIComponent(action.data.dueDate) + "&status=" + action.data.taskStatusID + "&" + keyName + "=" + value + "&userId=" + global.userID, {})
}

export const deleteTransactionTaskAPI = (action: any) => {
  return deleteRequest(Api.deleteTransactionTask + action.data.transactionId + "/usertasks/" + action.data.usertaskId + "?userID=" + global.userID)
}

export const saveInformationToReleaseAPI = (action: any) => {
  const auditable = action.data.auditable === null ? "" : "&auditable=" + action.data.auditable.toString();
  const freeText = encodeURIComponent(action.data.text);
  return patchRequest(Api.addUserTask + action.data.transactionId + "/informationToRelease?requestedItemID=" + action.data.id + "&active=" + action.data.active + "&freeText=" + freeText + "&fromDate=" + action.data.fromDate + "&toDate=" +
    encodeURIComponent(action.data.toDate) + auditable + "&userID=" + global.userID, {})
}

export const completeTransactionAPI = (action: any) => {
  return patchRequest(Api.completeTransaction + action.data + "/finish?userID=" + global.userID, {})
}

export const returnToQueueAPI = (action: any) => {
  return patchRequest(Api.completeTransaction + action.data + "/returnToQueue?userID=" + global.userID, {})
}

export const callReturnURLCompleteTransactionAPI = (urlString: any) => {
  return getWithoutBaseURL(urlString)
}

export const getBuildNumberAPI = () => {
  return get(Api.buildNumber)
}

export const patchDetailsCompleteAPI = (action: any) => {
  return patchRequest(Api.detailscomplete + action.data.transactionId + "/detailscomplete?userId=" + global.userID, {})
}

export const patchRequestorCompleteAPI = (action: any) => {
  return patchRequest(Api.requestorcomplete + action.data.transactionId + "/requestorcomplete?userId=" + global.userID, {})
}

export const patchBillingCompleteAPI = (action: any) => {
  return patchRequest(Api.billingcomplete + action.data.transactionId + "/billingcomplete?userId=" + global.userID, {})
}

export const clearUserLocksAPI = (action: any) => {
  return putRequest(Api.clearUserLocks + "?userID=" + global.userID, {})
}

export const patchPatientDetailsAPI = (action: any) => {
  const dob = moment(action.data[FormConstants.DOB]).format("MM/DD/YYYY")
  let apiEndPoint = action.data.patientDataid + "?transactionid=" + action.data.transactionId + "&firstname=" + action.data[FormConstants.PATIENT_NAME] + "&middlename=" + action.data[FormConstants.PATIENT_MIDDLE_NAME] +
    "&lastname=" + action.data[FormConstants.PATIENT_LAST_NAME] + "&dob=" + dob
  apiEndPoint = action.data[FormConstants.EMPI_NUMBER] ? apiEndPoint + "&empi=" + action.data[FormConstants.EMPI_NUMBER] : apiEndPoint
  apiEndPoint = action.data[FormConstants.MRN_ID] ? apiEndPoint + "&mrn=" + action.data[FormConstants.MRN_ID] : apiEndPoint
  return patchRequest(Api.patchPatientDetails + apiEndPoint + "&userID=" + global.userID, {})
}

export const getAuditTypesAPI = () => {
  return get(Api.getAuditTypes + "/" + global.userID)
}

export const getOBOsAPI = () => {
  return get(Api.getOBOs + "/" + global.userID)
}

export const getValidateAPI = (action: any) => {
  return get(Api.getValidate + action.data.transactionId + "/validate?userID=" + global.userID)
}

export const getAuditTrailAPI = (action: any) => {
  return get(Api.getAuditTrail + action.data.transactionId + "/audittrail?userID=" + global.userID)
}

export const getDuplicatesAPI = (action: any) => {
  return get(Api.getDuplicates + action.data.transactionId + "/duplicateCheck?caseNumber=" + encodeURIComponent(action.data.caseNumber) + "&userID=" + global.userID)
}
export const patchTransactionOnFileAPI = (action: any) => {
  let apiEndPoint = action.data.transactionId + "/transactiononfile/" + action.data.sourceTransactionId + "?documentIds=" + action.data.documentIds
  return patchRequest(Api.patchTransactionOnFile + apiEndPoint + "&userID=" + global.userID, {})
}
export const postWebPrintScanAPI = (action: any) => {
  return post(Api.getWebPrintScan + action.data.transactionId + "/" + global.userID + "?documentType=" + action.data.docType, action.data)
}

export const postQATransactionIssueAPI = (action: any) => {
  let apiEndPoint = Api.postQATransactionIssue + "?userID=" + global.userID;
  return post(apiEndPoint, action);
}

export const getTransactionDocumentsOnFileAPI = (action: any) => {
  return get(Api.getDocumentsForTransactionOnFile + action.data.transactionId + "/documents/" + action.data.sourceTransactionId + "?userID=" + global.userID)
}

export const readDocumentDDSBarcodeAPI = (action: any) => {
  return get(Api.getDocument + "barcode/" + action.data + "?userID=" + global.userID)
}

export const getFacilitiesAdditionalFieldsAPI = (action: any) => {
  let apiEndPoint = Api.getPHICodes + action.data + "/FacilityAdditionalFields?userID=" + global.userID;
  return get(apiEndPoint)
}

export const getWaitAndHoldReasonsAPI = (action: any) => {
  return get(Api.getWaitAndHoldReasons + action.data + "/waitholdreasons?userID=" + global.userID)
}

export const postWaitAndHoldReasonsAPI = (action: any) => {
  let apiEndPoint = action.transactionId + "/transactionwaithold?previousStatusId=" + action.statusIndicatorId + "&waitFor=" + action.waitFor
  return post(Api.postWaitAndHoldReasons + apiEndPoint + "&userID=" + global.userID, action.waitHoldReasons)
}
export const patchTransactionContinueAPI = (action: any) => {
  let queryparamsstr = "/transactioncontinue"
  queryparamsstr += `?userID=${global.userID}`
  return patchRequest(Api.patchTransactionContinue + action.transactionId + queryparamsstr, action.waitHoldReasons)
}

export const getFeatureFlagsAPI = (action: any) => {
  return get(Api.getFeatureFlags)
}

export const getQAFeatureFlagsAPI = (action: any) => {
  return get(Api.getQAFeatureFlags)
}

export const getTrackingLinkAPI = (action: any) => {
  return get(Api.getTrackingLink)
}

export const getSystemBulletinMessage = (action: any) =>{
  let queryParams = `get?userID=${global.userID}&orgId=${action.data}`

  return get(Api.systemBulletin + queryParams);
}

export const userAcknowledgeBulletin = (action: any) => {
  let queryParams = `userAcknowledges?userID=${global.userID}&systemBulletinId=${action.data}`;

  return post(Api.systemBulletin + queryParams,{});
}


export const putAuditDigitalFaxValidationAPI = (action: any) => {
  let apiEndPoint = action.transactionId + "/digitalfax?faxNumber=" + action.faxNumber
  return putRequest(Api.digitalFax + apiEndPoint + "&userID=" + global.userID, {})
}

export const putAuditTransactionSensitiveInfoAPI = (action: any) => {
  let apiEndPoint = action.transactionId + "/sensitiveinfo?recordContains=" + action.recordContains
  return putRequest(Api.transactionSensitiveInfo + apiEndPoint + "&userID=" + global.userID, {})
}

export const getStatusIndicatorAPI = (action: any) => {
  let apiEndPoint = Api.getStatusIndicator + action.data.transactionId + "/status?userID=" + global.userID; 
  return get(apiEndPoint)
}

export const getDefinedTemplateName = () => {
  return get(Api.outboundTemplate + "templateName?userID=" + global.userID)
}

export const getOutboundTemplateFields = (action: any) => {
  return get(Api.outboundTemplate + "createTemplateFields?templateId=" +  action.data.templateId + "&transactionId=" + action.data.transactionId + "&userID=" + global.userID)
}


export const SendOutboundTemplateFields = (action: any) => {
  return post(Api.outboundTemplate + "sendoutboundcommunication?templateId=" +  action.data.templateId + "&transactionId=" 
  + action.data.transactionId + "&action=" + action.data.action + "&contact=" + action.data.contact + "&userID=" + global.userID, action.data.fields)
}

export const getLogoUrlAPI = (action: any) => {
  return get(Api.outboundTemplate + "logoUrl?facilityId=" + action.data + "&userId=" + global.userID)
}


export const getFacilityCountAPI = (action: any) => {
  let apiEndPoint = Api.getFacilityCount + "facilities/" + action.data.facilityId + "/counts?userId=" + global.userID;
  return get(apiEndPoint)

}

export const getFacilitySpotlightFiltersAPI = (action: any) => {
  let apiEndPoint = Api.GetFacilitySpotlightDetails  + action.data + "/spotlightFilters?userId=" + global.userID;
  return get(apiEndPoint)
}

export const getFacilitySpotlightDetailsAPI = (action: any) => {
  let apiEndPoint = Api.GetFacilitySpotlightDetails  + action.data.facilityId + "/spotlightdetails?filterId=" + action.data.filterId + "&userId=" + global.userID;
  return get(apiEndPoint)
}

export const putUserViewDocumentAPI = (action: any) => {
  let apiEndPoint = action.data + "/vieweddocument"
  return putRequest(Api.transactionSensitiveInfo + apiEndPoint + "?userID=" + global.userID, {})
}

export const getQAErrorsAPI = () => {
  let apiEndPoint = Api.getQAErrors
  return get(apiEndPoint)
}

export const getQAIssuesAPI = (action: any) => {
  let apiEndPoint = Api.getQAIssues + "/transactionID=" + action.transactionID;
  return get(apiEndPoint)
}

export const resolveIssueAPI = (action: any) => {
  let apiEndPoint = Api.resolveQATransactionIssue + "?transactionId=" + action.transactionId + "&issueId=" + action.issueId + "&userId=" + global.userID;
  return post(apiEndPoint, {});
}

export const removeIssueAPI = (action: any) => {
  let apiEndPoint = Api.removeQATransactionIssue + "?transactionId=" + action.transactionId + "&issueId=" + action.issueId + "&userId=" + global.userID;
  return post(apiEndPoint, {});
}

export const getQAPDIssuesAPI = (action: any) => {
  let apiEndPoint = Api.getQAPDIssues + "/transactionID=" + action.transactionID;
  return get(apiEndPoint)
}

export const getAIFeedbackAPI = () => {
  return get(Api.getAIFeedback);
}

export const submitAIFeedbackAPI = (action: any) => {
  const apiEndPoint = Api.submitAIFeedback + `?entityId=${action.entityId}&feedbackType=${action.feedbackType}&userId=${global.userID}`;
  return putRequest(apiEndPoint, {});
}

export const deleteAIFeedbackAPI = (action: any) => {
  const apiEndPoint = Api.submitAIFeedback + `?entityId=${action.entityId}&userId=${global.userID}`;
  return deleteRequest(apiEndPoint);
}

export const getAISensitivityTypesAPI = () => {
  return get(Api.getAISensitivityTypes);
}

export const createAIReviewEntityAPI = (action: any) => {
  return post(Api.createQAReviewEntity, action.reviewEntity)
}

export const getnextTransactionQAAPI = () => {
  return get(Api.getNextQATransaction + "?userID=" + global.userID)
}

export const uploadUpdateDocumentAPI = (action: any) => {
  let data = new FormData()
  data.append("document", action.data.file)
  const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Content-Lenth': 8000
  }
  return postProgress(Api.updateReplaceDocument + action.data.documentId + 
    "?userid=" + global.userID 
    , data, headers, action.data.uploadProgress)
}

export const uploadUpdateBlobDocumentAPI = (action: any) => {
  let data = new FormData()
  data.append("document", action.data.blob, action.data.fileName)

  const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
    'Content-Lenth': 8000
  }
  return postProgress(Api.updateReplaceDocument + action.data.documentId + 
    "?userid=" + global.userID 
    , data, headers, action.data.uploadProgress)
}