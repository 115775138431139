import moment from "moment";
import React, { MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip, Accordion, Card, Button, Container } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CheckMarkFilled from "../../../assets/images/editIcons/checkMarkFilled.png";
import CancelMarkFilled from "../../../assets/images/x_cancel_icon.png";
import EditIcon from "../../../assets/images/editIcons/editSmallIcon.png";
import cancelImage from "../../../assets/images/tabImages/cancelButton.png";
import CancelPopup from "../../../common/alertPopup/cancelPopup";
import RejectModal from "../../../common/modal/rejectModal/RejectModal";
import checkMarkImage from "../../../assets/images/tabImages/Check Mark_ Large_ Outline_blue_1.png";
import returnOutlineLarge from "../../../assets/images/tabImages/returnOutlineLarge.png";
import NewTransactionNotesTable from "../../../common/table/NewTransactionNotes/NewTransactionNotes";
import NewTask from './NewTask';
import FeatureFlags, { FeatureFlagsType } from '../../../common/customTypes/FeatureFlagsType';
import NewTaskCollapsed from './NewTaskCollapsed';
import DeleteTaskPopUp from '../../../common/alertPopup/DeleteTaskPopUp';
import crossIcon from "../../../assets/images/x_cancel_icon.png";
import checkIcon from '../../../assets/images/check_green_icon.png';
import { LightTooltip, normalizePhone, getAIEntityCount } from '../../../common/utils/appUtils';
import { createTransaction,showReadOnlyTransactionRequest } from '../../landingPage/LandingPageDucks';
import CopyToClipboard from "../../../common/copyToClipboard/copyToClipboard";
import CheckboxField from '../../../common/formFields/CheckboxField'
import AddNewIssue from '../../../assets/images/tabImages/AddNewIssue.png';
import aiProcessingIcon from "../../../assets/images/AI_Processing_Icon.svg";
import {
  getMomentDate,
  isPastDate
} from "../../../common/utils/appUtils";
import {
  setNextTransactionIdActionCreator,
  setPreviousTransactionActionCreator,
  clearTransactionId,
  sendOutboundTemplateFieldsActionCreator
} from "../../landingPage/LandingPageDucks";
import "../DataEntryDetails.scss";
import {
  assignTransactionIDActionCreator,
  getPreviousTransactionActionCreator,
  getRejectionLetterActionCreator,
  nextTransactionIdActionCreator,
  patchPatientNoteActionCreators,
  patchRequestorNoteActionCreators,
  rejectionContactOverrideActionCreator,
  setNextTransactionNullActionCreator,
  unassignTransactionIDActionCreator,
  updateTransactionNotes,
  updateUserTasksActionCreator,
  updateUserTaskCommentsActionCreator,
  createUserTaskCommentActionCreator,
  addNewTaskActionCreator,
  updateTaskActionCreator,
  deleteTransactionTaskActionCreator,
  postRejectionDetailsActionCreator,
  resetDataEntryState,
  getAuditTrail,
  getQAIssuesActionCreator,
  getQAPDIssuesActionCreator,
  resolveQATransactionIssueActionCreator,
  removeQATransactionIssueActionCreator,
  setPDFPageNumberActionCreator,
  submitAIFeedbackActionCreator,
  getAssessmentResultsActionCreator,
  deleteAIFeedbackActionCreator,
  readBarCodeDDSRequest,
} from "../DataEntryDetailsDucks";
import { } from './DataEntryFieldsConstants';
import { BillingFormconstants, FormConstants, RequestorFormConstants, RejectModalConstants } from "./DataEntryFieldsConstants";
import AddNewRequestor from "../../../assets/images/tabImages/AddNewRequestor.png";
import { reduxForm, Field } from "redux-form";
import localValidations from "./validations/localValidations";
import submitValidations from "./validations/submitValidations";
import DisplayAuditItem from "../../../common/auditItem/auditItem";
import toast, { Toaster } from "react-hot-toast";
import WaitAndHoldModal from "../../../common/modal/WaitAndHoldModal";
import 'font-awesome/css/font-awesome.min.css';
import {
  generatePickerOptionsNoDefault
} from '../../../common/utils/appUtils';
import { API_SUCCESS, DEFAULT_DROPDOWN_VALUE } from "../../../common/constants/ApiConstants";
import AddIssueForm from "./AddIssueForm"
import { getQAFeatureFlagsRequest, returnToSearchRequest } from "../../login/LoginPageDucks";
import AssessmentFeedbackPopUp from "../../../common/alertPopup/AssessmentFeedbackPopUp";

interface IpropType {
  patientDetails?: any;
  transactionNotes?: any;
  requestorDetails?: any;
  transactionDetails?: any;
  dataEntryFormState?: any;
  getRejectionLetter?: any;
  transactionId?: any;
  getPreviousTransaction?: any;
  unassignTransactionID?: any;
  setNextTransactionIDNull?: any;
  facilityId?: any;
  getNextTransactionId?: any;
  previousTransactionId?: any;
  nextTransactionId?: any;
  setPreviousTransaction?: any;
  setNextTransactionID?: any;
  globalUserID?: any;
  patchPatientNotesData?: any;
  patchRequestorNotesData?: any;
  rejectionContactOverride?: any;
  rejectLetterData?: any;
  rejectLetterDataState?: any;
  transactionNoteTypes?: any;
  updateTransactionNotes?: any;
  userGroups?: any,
  userTasks: any,
  userTaskComments: any,
  updateUserTasks?: Function,
  updateUserTaskComments?: Function,
  userAttributes?: any,
  createUserTaskComment?: Function,
  addNewTask?: Function,
  updateTask?: Function,
  deleteTransactionTask?: Function,
  feeSchedules?: any,
  postRejectionAction?: any,
  rejectCompleted?: false,
  certificationRequired?: any,
  resetForm: Function,
  resetDataEntryState?: Function,
  clearTransactionId?: Function,
  getAuditTrailData?: Function,
  auditTrail?: Array<any>,
  onExitTransaction?: Function,
  intiaTxlSagaCallCount?: any,
  createTransaction?: Function,
  requestContext?: any,
  closeReadOnlyModal?: Function,
  requestTypes?: any,
  featureFlags?: FeatureFlagsType,
  getQAFeatureFlagsRequest?: Function,
  QAFeatureFlags?: FeatureFlagsType,
  selectedHoldReasons?: any,
  getTransactionAttributes?: Function,
  getUserGroups?: Function,
  assessmentResults?: any,
  getAssessmentResults?: Function,
  reviewEntities?: any,
  transactionStatus?: any,
  loadUnwaitedTx?: Function,
  sendOutboundTemplateFields?:Function,
  trackingLink?: string,
  getQAErrors?: any,
  getQAIssues?: any,
  qaPDIssues?: any,
  getQAPDIssues?: Function,
  fetchQAIssues?: Function,
  resolveQAIssue?: any,
  removeQAIssue?: any,
  setEscalation?: Function,
  setPageNumber?: any,
  documentsList?: any,
  getAIFeedback?: any,
  addFeedback?: Function,
  deleteFeedback?: Function,
  readBarCodeDDSRequest?: Function,
  currentFileType?: string,
  previousSearchParameters?: string,
  previousSearchPage?: number,
  returnToSearch?: Function,
}

function getStyles(status) {
  switch (status) {
    case "Completed":
      return { background: "darkseagreen" };
    case "In-Progress":
      return { background: "cadetblue" };
    case "Assigned":
      return { background: "#f2c6b3" };
    default:
      return { background: "#f2c6b3" };
  }
}

export function getDaysDiff(date: string) {
  if (date) {
    const now = moment();
    const numberOfDays = now.diff(date, "days");
    if (numberOfDays > 0) {
      return numberOfDays + " days ago";
    } else if (numberOfDays === 0) {
      return "Today";
    } else {
      return "In " + -numberOfDays + " days";
    }
  }
  return "";
}

export function TaskStatusDropdown(props) {
  return (
    <ul
      className="nav navbar-nav navbar-right"
      style={props.style || { height: "2rem" }}
    >
      <li className="dropdown">
        <span
          className="dropdown-toggle dropdown-style"
          style={getStyles(props.selectedStatus)}
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {props.selectedStatus}
          <span className="caret"></span>
        </span>
        <ul className="dropdown-menu">
          <li
            className="list-item"
            onClick={() => props.onChange(props.id, "Completed")}
          >
            <span className="dot"></span> Completed{" "}
          </li>
          <li
            className="list-item"
            onClick={() => props.onChange(props.id, "In-Progress")}
          >
            <span className="dot inProgress"></span> In-Progress{" "}
          </li>
          <li
            className="list-item"
            onClick={() => props.onChange(props.id, "Assigned")}
          >
            <span className="dot assigned"></span> Assigned{" "}
          </li>
        </ul>
      </li>
    </ul>
  );
}


export const assignTaskHeadCells = [
  { id: "dueBy", label: "DUE BY" },
  { id: "assignedTo", label: "ASSIGNED TO" },
  { id: "subject", label: "SUBJECT" },
  { id: "description", label: "DESCRIPTION" },
  { id: "status", label: "STATUS" },
];
export const assignTaskRow = {
  "0": {
    dueBy: "06/11/2020",
    assignedTo: "Some Individual",
    subject: "Do this",
    description: "loroel ipsum loroel ipsum",
    status: "Completed",
  },
  "1": {
    dueBy: "04/12/2020",
    assignedTo: "Some Individual",
    subject: "Do this",
    description: "sum loroel ipsum loroel ipsum",
    status: "Assigned",
  },
  "2": {
    dueBy: "05/05/2020",
    assignedTo: "Some Individual",
    subject: "Do this",
    description: "loroel ipsum ipsum loroel ipsum loroel ipsum",
    status: "In-Progress",
  },
};
function DataEntryDetailsLeftPane(props: IpropType) {
  const {
    patientDetails,
    transactionNotes,
    requestorDetails,
    transactionDetails,
    dataEntryFormState,
    assessmentResults,
    reviewEntities,
    getQAIssues,
    transactionId,
    previousTransactionId,
    nextTransactionId,
    setPreviousTransaction,
    setNextTransactionID,
    unassignTransactionID,
    setPageNumber,
    documentsList,
  } = props;

  const [showForm, setShowForm] = useState(false);
  const [show, setShow] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [editingTaskId, setEditingTaskId] = useState(0);
  const [rejectNotificationType, setRejectNotificationType] = useState("");
  const [showWaitModal, setShowWaitModal] = useState(false);
  const { values, initial } = dataEntryFormState || {};
  const [sensitiveInfoValues, setSensitiveInfoValues] = useState(values && values[FormConstants.SENSITIVE_INFORMATION]
    ? Object.keys(values[FormConstants.SENSITIVE_INFORMATION])
    : []);
    const allSensitiveInfoValues = [FormConstants.GENETIC_INFORMATION, FormConstants.HIV, FormConstants.MENTAL_HEALTH, FormConstants.SUBSTANCE_ABUSE, FormConstants.STD]
    const informationToReleaseValues = values && values[FormConstants.RETRIEVE_RECORD_TYPE]
    ? values[FormConstants.RETRIEVE_RECORD_TYPE]
    : {};
  const [showDeleteTaskPopUp, setDeleteTaskPopUp] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState('');
  const [patientNotes, setPatientNotes] = useState(patientDetails?.notes);
  const [patientNotesDialog, setPatientNotesDialog] = useState(patientDetails?.notes);
  const [showPatientNotesDialog, setShowPatientNotesDialog] = useState(false);
  const [requestorNotes, setRequestorNotes] = useState(requestorDetails?.notes);
  const [requestorNotesDialog, setRequestorNotesDialog] = useState(requestorDetails?.notes);
  const [showRequestorNotesDialog, setShowRequestorNotesDialog] = useState(false);
  const [SelectedTabView, setSelectedTabView] = useState("Summary");
  const [createTaskStatus, updateCreateTaskStatus] = useState(false);
  const inputPatientNotes = useRef() as MutableRefObject<HTMLTextAreaElement>;
  const inputRequestorNotes = useRef() as MutableRefObject<HTMLTextAreaElement>;
  const [showRejectCompletePopUp, setRejectCompletePopUp] = useState(false);
  const [transactionHoldReasonText, setTransactionHoldReasonText] = useState("");
  const [requestTypeText, setRequestTypeText] = useState("");
  const [showAllIssues, setShowAllIssues] = useState(false);
  const [showAddFeedback, setShowAddFeedback] = useState(false);
  const [entityId, setEntityId] = useState("");
  const [entityType, setEntityType] = useState("");
  const [editIssue, setEditIssue] = useState(false);
  const [editIssuePage, setEditIssuePage] = useState(0);
  const [editIssueType, setEditIssueType] = useState(0);
  const [reToggleForm, setReToggleForm] = useState(false);
  const [editIssueId, setEditIssueId] = useState("");
  const [editIssueDetails, setEditIssueDetails] = useState("");

  const informationToReleaseComments = values && values[FormConstants.RELEASE_INFO_COMMENTS]
  ? values[FormConstants.RELEASE_INFO_COMMENTS]
  : {};
  const informationToReleaseFromValues = values
  ? values[FormConstants.RTR_FROM]
  : "";
  const informationToReleaseToValues = values
  ? values[FormConstants.RTR_TO]
  : "";
  const allDates = values
  ? values[FormConstants.RTR_ALL]
  : "";

  const defaultRequestorNotes = requestorDetails?.notes;
  const defaultPatientNotes = patientDetails?.notes;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const entityTypeLabels = {
    "be9887d5-a989-4bb7-ba90-ac3e57287771": "STD",
    "5d26834e-daf7-4d0f-a0ab-8aace6afef50": "PSYC/MHLT",
    "5a06b99b-73f5-461a-8f81-c8ae31bb5c32": "DRUG",
    "c0f322c2-d3c4-43af-8677-42ed3b210ca5": "HIV",
    "8888c3c9-6cc6-408b-bc5a-19d0b1a7cd0d": "HPV",
    "5b06b99b-73f5-461a-8f81-c8ae31bb5c32": "PATIENT",
    "5c06b99b-73f5-461a-8f81-c8ae31bb5c32": "RELEASE"
  };
 
  useLayoutEffect(() => {
    if (props.getQAFeatureFlagsRequest) {
      props.getQAFeatureFlagsRequest();
  }
  }, [])
 
  useLayoutEffect(() => {
    if (props.getQAFeatureFlagsRequest) {
      props.getQAFeatureFlagsRequest();
  }
  }, [])
  
  useEffect(() => {
    const vals = (props.dataEntryFormState?.values && props.dataEntryFormState?.values[FormConstants.SENSITIVE_INFORMATION]) || {};
    const newSensitiveValues = Object.keys(vals).filter(v => vals[v] !== null);
    setSensitiveInfoValues(newSensitiveValues);
  }, [props.dataEntryFormState]);

  useEffect(() => {
    if (props.rejectLetterDataState === API_SUCCESS) {
      setShow(true);
    }
  }, [props.rejectLetterDataState]);

  useEffect(() => {
    if (previousTransactionId?.id) {
      setPreviousTransaction(previousTransactionId.id);
    }
  }, [previousTransactionId, setPreviousTransaction]);

  useEffect(() => {
    if (nextTransactionId) {
      setNextTransactionID(nextTransactionId);
    }
  }, [nextTransactionId, setNextTransactionID]);

  useEffect(() => {
    if (showPatientNotesDialog && inputPatientNotes.current) {
      inputPatientNotes.current.focus();
    }
    if (showRequestorNotesDialog && inputRequestorNotes.current) {
      inputRequestorNotes.current.focus();
    }
  }, [showPatientNotesDialog, showRequestorNotesDialog]);

  useEffect(() => {
    if (props.rejectCompleted) {
      setRejectCompletePopUp(false);
    }
  }, [props.rejectCompleted]);

  useEffect(() => {
    if (requestorNotes !== defaultRequestorNotes) {
      setRequestorNotes(defaultRequestorNotes);
      setRequestorNotesDialog(requestorDetails?.notes);
    }
    if (patientNotes !== defaultPatientNotes) {
      setPatientNotes(defaultPatientNotes);
      setPatientNotesDialog(defaultPatientNotes);
    }
  }, [defaultRequestorNotes, defaultPatientNotes]);

  useEffect(() => {
    if (props.transactionId && global.userID !== undefined) {
      props.getAuditTrailData && props.getAuditTrailData(props.transactionId);
    }
  }, [props.getAuditTrailData, props.transactionId, global.userID]);

  useEffect(() => {
    console.log("Audit Trail Updated");
  }, [props.auditTrail]);

  useEffect(() => {
    setTransactionHoldReasonText(transactionDetails?.transactionHoldReason);
  }, [transactionDetails?.transactionHoldReason]);

  useEffect(() => {
    if (transactionDetails?.requestPurpose?.typeName && transactionDetails?.requestPurpose?.name) {
      setRequestTypeText(`${transactionDetails.requestPurpose.typeName} | ${transactionDetails.requestPurpose.name}`);
    }
  }, [transactionDetails?.requestPurpose]);

  useEffect(() => {
    if (props.userGroups.length === 0 && props.facilityId) {
      props.getUserGroups && props.getUserGroups(props.facilityId);
    }
  }, [props.userGroups, props.facilityId]);

  useEffect(() => {
    if (transactionDetails.transactionStatus?.id >= 130 && transactionDetails.transactionStatus?.id <= 141) {
      setSelectedTabView("FinalQA");
    }
  }, [props.transactionDetails?.transactionStatus]);

  const toggleForm = () => {
    setShowForm(!showForm);
    }

  const resetTransaction = (unassign = true) => {
    if (unassign) unassignTransactionID(props.transactionId);

    if (props.resetDataEntryState) {
      props.resetDataEntryState();

    }
    if (props.clearTransactionId) {
      props.clearTransactionId();

    }
  }

  const showModel = () => {
    props.getRejectionLetter(props.transactionId);
  };

  const closeModel = () => {
    setShow(false);
  };
  const showCancel = () => {
    setShowCancelModal(true);
  };
  const closeCancel = () => {
    setShowCancelModal(false);
  };

  const goToPreviousTransaction = () => {
    if (props.onExitTransaction && props.onExitTransaction()) {
      resetTransaction(false);
      props.resetForm();
      props.getPreviousTransaction(props.transactionId);
      //props.unassignTransactionID(props.transactionId);
      props.setNextTransactionIDNull();
    }
  };

  const goToNextTransaction = () => {
    if (props.onExitTransaction && props.onExitTransaction()) {
      props.getNextTransactionId({ facilityId: props.facilityId, transactionId: props.transactionId }, props.requestContext);
      resetTransaction(false);
      props.resetForm();
    }
  };

  const createNewTransaction = () => {
    if (props.onExitTransaction && props.onExitTransaction()) {
      props.createTransaction && props.createTransaction({ facilityId: props.facilityId})
      resetTransaction(true);
      props.resetForm();
    }
  };
  const deleteTaskbyId = (id) => {
    setTaskToDelete(id);
  }

  const getConfirmation = (value) => {
    if (value === 'OK') {
      props.deleteTransactionTask && props.deleteTransactionTask(props.transactionId, taskToDelete)
      setTaskToDelete('')
    }
    if (value === 'Cancel') {
      setTaskToDelete('')
    }
  }
  
  const initiateCreateNewTask = () => {
    updateCreateTaskStatus(true)
    setEditingTaskId(0)
  };

  useEffect(() => {
    if (nextTransactionId) {
      //unassignTransactionID(transactionId);
      setNextTransactionID(nextTransactionId);
    }
  }, [nextTransactionId, setNextTransactionID]);

  useEffect(() => {
    if (showPatientNotesDialog) {
      inputPatientNotes.current.focus();
    }
    if (showRequestorNotesDialog) {
      inputRequestorNotes.current.focus();
    }
  });
  useEffect(() => {
    if (props.rejectCompleted) {
      setRejectCompletePopUp(false);
    }
  });

  useEffect(() => {
    if (requestorNotes !== defaultRequestorNotes) {
      setRequestorNotes(defaultRequestorNotes);
      setRequestorNotesDialog(requestorDetails?.notes);
    }
    if (patientNotes !== defaultPatientNotes) {
      setPatientNotes(defaultPatientNotes);
      setPatientNotesDialog(defaultPatientNotes);
    }
  }, [defaultRequestorNotes, defaultPatientNotes]);

  //const [prevTX, setPrevTX] = useState("");

  useEffect(() => {
    //if(props.transactionId !== prevTX){
      //setPrevTX(props.transactionId)\
      if(global.userID !== undefined)
        props.getAuditTrailData && props.transactionId && props.getAuditTrailData(props.transactionId)
    //}
  }, [props.getAuditTrailData, props.transactionId, global.userID])

  useEffect(() => {
    if (props.currentFileType) {
      if (props.currentFileType !== "RELEASE" && showForm) {
          toggleForm();
      }
    }
  });

  const patientNotesEditHandler = () => {
    setShowPatientNotesDialog(!showPatientNotesDialog);
    setPatientNotesDialog(patientNotes)
  };

  const patientNotesHandleChange = (event) => {
    setPatientNotesDialog(event.target.value);
  };

  const requestorNotesEditHandler = () => {
    setShowRequestorNotesDialog(!showRequestorNotesDialog);
    setRequestorNotesDialog(requestorNotes)
  };

  const requestorNotesHandleChange = (event) => {
    setRequestorNotesDialog(event.target.value);
  };
  const textDialogOnBlurPatient = () => {
    props.patchPatientNotesData(
      props.transactionDetails?.patient?.id,
      patientNotesDialog,
      props.globalUserID,
      props.transactionId
    );
    setTimeout(() => {
      setShowPatientNotesDialog(false);
    }, 200);
    setPatientNotes(patientNotesDialog);
  };
  const textDialogOnBlurRequestor = () => {
    props.patchRequestorNotesData(
      props.requestorDetails.requestorID,
      requestorNotesDialog,
      props.globalUserID,
      props.requestorDetails?.requestorInfo?.id
    );
    setTimeout(() => {
      setShowRequestorNotesDialog(false);
    }, 200);
    setRequestorNotes(requestorNotesDialog);
  };

  const goToHealthOrganization = (data: any, radioSelect, templateId = DEFAULT_DROPDOWN_VALUE, templateFields = null) => {
    if(templateId === DEFAULT_DROPDOWN_VALUE){
      const requestPostObject = {
        transactionId: props.transactionId,
        action: "",
        descriptionText: "",
        includeAuthForm: false,
        overrideEmail: "",
        overrideFax: "",
        cancelReasonIds: "",
        facilityId: props.facilityId,
        requestContext: props.requestContext
      };
      if (radioSelect === "Email") {
        requestPostObject.action = 'email';
        requestPostObject.overrideEmail =
          data && data[RejectModalConstants.REJECT_MODAL_EMAIL];
        setRejectNotificationType("Email has been sent.");
      }
      else if (radioSelect === "Fax Number") {
        requestPostObject.action = 'fax';
        requestPostObject.overrideFax = data && data[RejectModalConstants.PHONE_FAX_NUMBER];
        setRejectNotificationType("Fax has been sent.");
      }
      else {
        requestPostObject.action = 'print';
        setRejectNotificationType("Notification has been sent to fulfillment for printing.");
      }
      requestPostObject.cancelReasonIds = data && data[RejectModalConstants.REJECT_REASON_IDS];
      requestPostObject.includeAuthForm = data && data[RejectModalConstants.REJECT_INCLUDE_AUTH_FORM] ? true : false;
      requestPostObject.descriptionText = data && data[RejectModalConstants.REJECT_MODAL_DESCRIPTION];
  
      props.postRejectionAction(requestPostObject);
    }
    else{
      let action = '';
      let contact = '';
      if (radioSelect === "Email") {
        action = 'email';
        contact = data && data[RejectModalConstants.REJECT_MODAL_EMAIL];
        setRejectNotificationType("Email has been sent.");
      }
      else if (radioSelect === "Fax Number") {
        action = 'fax';
        contact = data && data[RejectModalConstants.PHONE_FAX_NUMBER];
        setRejectNotificationType("Fax has been sent.");
      }
      else {
        action = 'print';
        setRejectNotificationType("Notification has been sent to fulfillment for printing.");
      }


      props.sendOutboundTemplateFields && props.sendOutboundTemplateFields(templateId, props.transactionId, action, templateFields, props.facilityId, props.requestContext, contact)
    }
   
    setRejectCompletePopUp(true);
  };

  const renderSensitiveInfoItem = (item: any) => {
    const infoSelected =
      props.dataEntryFormState?.values && (
        props.dataEntryFormState?.values[FormConstants.DISCLOSE_ALL_INFO] ||
        props.dataEntryFormState?.values[FormConstants.DONT_DISCLOSE_ANY_INFO] || (
          props.dataEntryFormState?.values[FormConstants.SENSITIVE_INFORMATION] && (
            props.dataEntryFormState?.values[FormConstants.SENSITIVE_INFORMATION][FormConstants.GENETIC_INFORMATION] ||
            props.dataEntryFormState?.values[FormConstants.SENSITIVE_INFORMATION][FormConstants.HIV] ||
            props.dataEntryFormState?.values[FormConstants.SENSITIVE_INFORMATION][FormConstants.MENTAL_HEALTH] ||
            props.dataEntryFormState?.values[FormConstants.SENSITIVE_INFORMATION][FormConstants.SUBSTANCE_ABUSE] ||
            props.dataEntryFormState?.values[FormConstants.SENSITIVE_INFORMATION][FormConstants.STD]
          )
        )
      );

    const icon = sensitiveInfoValues.indexOf(item) != -1 ? checkMarkImage : CancelMarkFilled;
    return (<>
      <span className="check-icon">
        {infoSelected ? (<img src={icon} alt="X" />) : null}
      </span>
      <span>{item}</span>
    </>);
  }

  const sendToBillToSame = () => {
    if(values){
    return values[BillingFormconstants.BILL_TO_REQUESTOR_NAME] === requestorDetails?.fullName &&
      values[BillingFormconstants.BILL_TO_FIRST_NAME] === requestorDetails?.requestorInfo?.firstName &&
      values[BillingFormconstants.BILL_TO_LAST_NAME] === requestorDetails?.requestorInfo?.lastName &&
      values[BillingFormconstants.BILL_TO_ADDRESS_LINE1] === requestorDetails?.requestorAddress?.address1 &&
      values[BillingFormconstants.BILL_TO_ADDRESS_LINE2] === requestorDetails?.requestorAddress?.address2 &&
      values[BillingFormconstants.BILL_TO_CITY] === requestorDetails?.requestorAddress?.city &&
      values[BillingFormconstants.BILL_TO_STATE] === requestorDetails?.requestorAddress?.state &&
      values[BillingFormconstants.BILL_TO_ZIP] === requestorDetails?.requestorAddress?.zipCode &&
      values[BillingFormconstants.BILL_TO_PHONE]?.replace(/[^0-9]/g, "") === requestorDetails?.requestorInfo?.phone?.replace(/[^0-9]/g, "") &&
      values[BillingFormconstants.BILL_TO_EMAIL_ADDRESS] ===  requestorDetails?.requestorInfo?.email
    }
    else
    return {}
  }
  
  const handlePageClick = (pageNumber: number, fileDesc: string) => {
    setPageNumber(pageNumber, fileDesc);
  };

  const toastPop = (message) =>{
    toast(message, {id: "rejectPopUp", duration: 5000});
    setTimeout(() => { 
      setRejectCompletePopUp(false); 
      setRejectNotificationType("");
    }, 5000);
  }

  const showWaitModalPopup = () => {
    setShowWaitModal(true);
  };
  const closeWaitModal = () => {
    setShowWaitModal(false);
  };

  const getTransactionAttributes = (value) => {
    props.getTransactionAttributes && props.getTransactionAttributes(value);
  }

  const onChangeEscalation = (e) => {
    props.setEscalation && props.setEscalation(e.target.checked);
  }

  const onShowAllIssues = (ev) => {
    setShowAllIssues(ev.target.checked);
  }

  const onIssueResolved = async (issueId) => {
    await props.resolveQAIssue(props.transactionId, issueId);
    props.fetchQAIssues && await props.fetchQAIssues(props.transactionId);
    props.getQAPDIssues && await props.getQAPDIssues(props.transactionId);
  }

  const onIssueRemoved = async (issueId) => {
    await props.removeQAIssue(props.transactionId, issueId);
    props.fetchQAIssues && await props.fetchQAIssues(props.transactionId);
    props.getQAPDIssues && await props.getQAPDIssues(props.transactionId);
  }

  const onIncorrectFeedbackClicked = (entity: any) => {
    setEntityId(entity.id);
    setEntityType(entity.type);
    setShowAddFeedback(true);
  }

  const addFeedback = (entityId: string, feedbackType: string) => {
    if (props.addFeedback) {
      props.addFeedback(entityId, feedbackType);
      props.getAssessmentResults && props.getAssessmentResults(props.transactionId);
    }
  }

  const undoFeedback = (entityId: string) => {
    if (props.deleteFeedback) {
      props.deleteFeedback(entityId);
      props.getAssessmentResults && props.getAssessmentResults(props.transactionId);
    }
  }

  useEffect(() => {
    //check if toggleForm should be done a second time
    if (reToggleForm){
      toggleForm();
      setReToggleForm(false);
    }
    
  },[reToggleForm])

  const setIssueForm = (event) => {
    var isEdit = (event.target.id === "edit-issue-link");
    setEditIssue(isEdit);
    setEditIssueId("");
    setEditIssuePage(0);
    setEditIssueType(0);
    setEditIssueDetails("");
    //setEdi
    if (isEdit){
      setEditIssueId(event.target.dataset.id as string);
      setEditIssuePage(event.target.dataset.page as number);
      setEditIssueType(event.target.dataset.type as number);
      setEditIssueDetails(event.target.dataset.details as string);
    }

    if (showForm && (isEdit != editIssue || editIssueId !== event.target.dataset.id)){
      setReToggleForm(true);
    }

      if (props.currentFileType === "RELEASE") { 
          toggleForm();
      }
  }
  
  const onReturnToSearch = () => {
    let organizationId = props.transactionId?.split('-')?.[0];
    let requestBufferId = props.transactionId?.split('-')?.[1];
    props.returnToSearch?.(organizationId && parseInt(organizationId), requestBufferId && parseInt(requestBufferId), props.previousSearchParameters, props.previousSearchPage);
  }

  return (
    <div className="request-wrapper">
      <div className="content-body">
        <div className="request-id">
          {props.transactionId}
          <CopyToClipboard copyText={props.transactionId} />
          {transactionDetails?.isReadOnly && props.previousSearchParameters && <button
            onClick={() => onReturnToSearch()}
            className="btn"
            type="button"
            style={{ marginLeft: "auto", height: "40px" }}
          >
            <img src={returnOutlineLarge} style={{ marginRight: "5px" }} />
            Back to Search Results
          </button>}
        </div>
        <Row>
          <Col className="bottom-border" />
        </Row>

          <Row>
          <>

            <Col className="px-6  text-left col-6 cancel">
              {!transactionDetails.isReadOnly && (
              <>
              <span className="cancel" onClick={showCancel}>
                <img
                  className="cancelButton image-margin-cancel"
                  src={cancelImage}
                  alt="X"
                />
                Cancel
              </span>
              </>
              )}
            </Col>
            {showCancelModal ? (
              <CancelPopup
                show={showCancel}
                handleClose={closeCancel}
                headerText="Cancel request"
                buttonText="Ok"
                setShowCancelModal={setShowCancelModal}
                resetForm={props.resetForm}
                resetFormDataEntryState={resetTransaction}
              />
            ) : null}
            <Col
              className="px-6 text-right col-6 reject"
              style={{ right: "0px" }}
            >
              { 
              props.requestorDetails?.requestorID && ![250,260,300,355].includes(props.transactionStatus?.id) ?
              <span className="cancel" onClick={showModel}>
                  <i className="fa fa-envelope">&nbsp;</i>Outbound Communications
              </span>:
              null}
            </Col>
          </>
        </Row>

        {showWaitModal ? (
          <WaitAndHoldModal
            show={showWaitModalPopup}
            handleClose={closeWaitModal}
            headerText="Hold request"
            buttonText="Ok"
            setShowWaitAndHoldModal={setShowWaitModal}
            resetForm={props.resetForm}
            resetFormDataEntryState={resetTransaction}
            holdForFacility={transactionDetails?.facility?.name}
            selectedHoldReasons={props.selectedHoldReasons}
            isReadonly={transactionDetails?.isReadOnly}
            closeReadOnlyModal={props.closeReadOnlyModal}
            getTransactionAttributes={getTransactionAttributes}
            transactionId={props.transactionId}
            facilityId={transactionDetails?.facility?.id}
            loadUnwaitedTx={props.loadUnwaitedTx}
          />
        ) : null}
        {show ? (
           <RejectModal
           show={showModel}
           handleClose={closeModel}
           goLandingPage={goToHealthOrganization}
           rejectLetterData={props.rejectLetterData}
           resetForm={props.resetForm}
           resetFormDataEntryState={resetTransaction}
           readonly={transactionDetails.isReadOnly}
         />
        ) : null}
        <Row>
          <ul className="nav nav-pills nav-fill">
            <Col
              lg={4}
              xs={4}
              md={4}
              className="nav-item"
              style={{ paddingRight: "0px" }}
            >
              <span
                className={
                  SelectedTabView === "Summary"
                    ? "nav-link first-tab active"
                    : "first-tab nav-link"
                }
                style={{ borderRight: "none", cursor: 'pointer' }}
                aria-current="page"
                onClick={() => setSelectedTabView("Summary")}
              >
                Summary
              </span>
            </Col>
            <Col
              lg={4}
              xs={4}
              md={4}
              className="nav-item"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >

                <LightTooltip title={<>Past due tasks: {props.userTasks.filter(item => isPastDate(item.dueDate)).length}<br/>Total tasks: {props.userTasks.length}</>} placement="top">
                  <span
                    className={
                      SelectedTabView === "Tasks"
                        ? "nav-link active task-tab"
                        : "nav-link task-tab"
                    }
                    onClick={() => setSelectedTabView("Tasks")}
                    style={{ cursor: 'pointer' }}
                  >
                    Tasks ( <span className="overdue-tasks">{props.userTasks.filter(item => isPastDate(item.dueDate)).length}</span> /{" "}
                    <span className="total-tasks">{props.userTasks.length}</span> )
                  </span>
                </LightTooltip>

            </Col>
            <Col
              lg={4}
              xs={4}
              md={4}
              className="nav-item"
              style={(props.QAFeatureFlags != null && props.QAFeatureFlags.finalQA  ? { paddingLeft: "0px", paddingRight: "0px" } : { paddingLeft: "0px"})}>
              <span
                className={`nav-link ${SelectedTabView === "Audit" ? "active" : ""} ${props.QAFeatureFlags != null && props.QAFeatureFlags.finalQA ? "" : "last-tab"}`}
                style={(props.QAFeatureFlags != null && props.QAFeatureFlags.finalQA  ? { borderLeft: "none", cursor: 'pointer', borderRight: "none" } : { borderLeft: "none", cursor: 'pointer' })}
                onClick={() => setSelectedTabView("Audit")}
              >
                History
              </span>
            </Col>
            {props.QAFeatureFlags != null && props.QAFeatureFlags.finalQA && (
              <Col
                lg={4}
                xs={4}
                md={4}
                className="nav-item"
                style={{ paddingLeft: "0px"}}
              >
                <span
                  className={
                    SelectedTabView === "FinalQA"
                      ? "nav-link last-tab active"
                      : "last-tab nav-link"
                  }
                  style={{ cursor: 'pointer' }}
                  onClick={() => setSelectedTabView("FinalQA")}
                >
                  Final QA
                </span>
              </Col>
            )}
          </ul>
        </Row>
        <Row>
          <Col
            className={
              SelectedTabView === "Summary" ? "" : ""
            }
          />
        </Row>

        {SelectedTabView === "Summary" ? (
          <div className="leftBody tab-summary">
            <Row>
              <Col>
                <span className="section-headers">
                  {patientDetails?.contact?.firstName}{" "}
                  {patientDetails?.contact?.middleInitial ? patientDetails?.contact?.middleInitial + ' ' : patientDetails?.contact?.middleName ? patientDetails?.contact?.middleName.substr(0, 1) + ' ' : ' '}
                  {patientDetails?.contact?.lastName}
                </span>
              </Col>
              <Col className="text-right section-semi-headers" style={{ right: "0px" }}>
                <span>
                  {patientDetails?.dob
                    ? moment(new Date(patientDetails?.dob)).format("MM/DD/YYYY")
                    : ""}
                </span>
              </Col>
            </Row>
            <Row className="margin-bottom">
              <Col>
                <span className="section-semi-headers">MRN: </span><span>{props.transactionDetails?.mrn}</span>
              </Col>
              <Col>
                <span className="section-semi-headers">Case #: </span><span>{props.transactionDetails?.caseNumber}</span>
              </Col>
            </Row>
            {props.transactionDetails?.facility?.useRequestID && props.transactionDetails?.requestID ? 
            <Row className="margin-bottom">
              <Col>
                  <span className="section-semi-headers">Release ID: </span><span>{props.transactionDetails?.requestID}</span>
              </Col>
            </Row> : <></>}
            <div>
              <Row className='patient-notes-container' style={{ display: (showPatientNotesDialog) ? 'none' : 'flex' }}>
                <Col sm={11} className="pt-6 text-left">
                  <span className="patient-notes">
                    <span className="section-semi-headers">Patient Notes: </span>
                    {patientNotes &&
                      patientNotes.replaceAll &&
                      patientNotes.replaceAll("\n[ \t]*\n", "\n")}
                  </span>
                </Col>
                {!transactionDetails.isReadOnly && props.transactionDetails?.patient?.id ? <Col className="text-right px-0 right-alignment" sm={1}>
                  <img
                    className="cancelButton image-margin-reject edit-icon"
                    src={EditIcon}
                    alt="X"
                    onClick={patientNotesEditHandler}
                  />
                </Col> : null}
              </Row>
              {showRejectCompletePopUp &&
               
                toastPop(`${rejectNotificationType} \n
                This may take up to 20 seconds.`)
              }
              {showPatientNotesDialog && (
                <div className='patient-notes-input'>
                  <Row>
                    <Col>
                      <textarea
                        className="dialog"
                        name="textarea"
                        value={patientNotesDialog}
                        onChange={patientNotesHandleChange}
                        maxLength={256}
                        ref={inputPatientNotes}
                      />
                    </Col>
                  </Row>
                  <Row className="text-right">
                    <Col>
                      <LightTooltip title="Cancel Notes" placement="right">
                        <span className="add-new-icon pointer-cursor cancel-task-icon" onKeyDown={k => { k.key === 'Enter' && patientNotesEditHandler() }}  onClick={patientNotesEditHandler} tabIndex={0}>
                          <img src={crossIcon} alt={"undo save"} />
                        </span>
                      </LightTooltip>

                      <LightTooltip title="Save Notes" placement="right">
                        <span className="add-new-icon pointer-cursor save-task-icon" onKeyDown={k => { k.key === 'Enter' && textDialogOnBlurPatient() }} onClick={textDialogOnBlurPatient} tabIndex={0}>
                          <img src={checkIcon} alt={"Save Comment"} />
                        </span>
                      </LightTooltip>

                    </Col>
                  </Row>
                </div>
              )}
            </div>

            <Row>
              <Col className="bottom-border margin-bottom" />
            </Row>
            <Row>

              <Col xs={12} md={12} sm={12} lg={12} className="section-headers">
                Requestor Information:
              </Col>

              <Col className="text-left">
                <span>{requestorDetails?.fullName}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="">
                  {requestorDetails?.requestorInfo?.firstName}{" "}
                  {requestorDetails?.requestorInfo?.lastName}
                </span>
              </Col>
              {requestorDetails?.requestorInfo?.isVerified ? (
                <Col className="text-right" style={{ right: "0px" }}>
                  <img
                    className="cancelButton image-margin-reject edit-icon"
                    src={CheckMarkFilled}
                    alt="X"
                  />
                  <span className="verified-text">VERIFIED</span>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col>
                <span className="text-left">
                  {requestorDetails?.requestorAddress?.address1}
                  {" "}
                  {requestorDetails?.requestorAddress?.address2}
                </span>
              </Col>
            </Row>
            <Row className="margin-bottom">
              <Col className="text-left">
                <span>
                  {requestorDetails?.requestorAddress?.city}
                  {requestorDetails?.requestorAddress?.city &&
                    requestorDetails?.requestorAddress?.state
                    ? ","
                    : ""}
                  {requestorDetails?.requestorAddress?.state}{" "}
                  {requestorDetails?.requestorAddress?.zipCode}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="text-left">
                  {normalizePhone(requestorDetails?.requestorInfo?.phone)}
                </span>
              </Col>
            </Row>
            <Col className="text-left" style={{ paddingLeft: "0px" }}>
              <span>{requestorDetails?.requestorInfo?.email}</span>
            </Col>
            <Row></Row>
            <Row className="margin-bottom" style={{ display: showRequestorNotesDialog ? 'none' : 'flex' }}>
              <Col sm={11} className="pt-6 text-left">
                <span className="requestor-notes">
                  <span className="section-semi-headers">Requestor Notes: </span>
                  {requestorNotes &&
                    requestorNotes.replaceAll &&
                    requestorNotes.replaceAll("\n[ \t]*\n", "\n")}
                </span>
              </Col>
              {!transactionDetails.isReadOnly && props.requestorDetails?.requestorID ? <Col className="text-right px-0 right-alignment" sm={1}>
                <img
                  className="cancelButton image-margin-reject edit-icon"
                  src={EditIcon}
                  alt="X"
                  onClick={requestorNotesEditHandler}
                />
              </Col> : null}
            </Row>
            {showRequestorNotesDialog && (
              <div>
                <Row>
                  <Col>
                    <textarea
                      className="dialog"
                      name="textarea"
                      value={requestorNotesDialog}
                      onChange={requestorNotesHandleChange}
                      maxLength={256}
                      ref={inputRequestorNotes}
                    />
                  </Col>
                </Row>
                <Row className="text-right">
                  <Col>
                    <LightTooltip title="Cancel Notes" placement="right">
                      <span onClick={requestorNotesEditHandler} onKeyDown={k => { k.key === 'Enter' && requestorNotesEditHandler() }}  className="add-new-icon pointer-cursor cancel-task-icon" tabIndex={0}>
                        <img src={crossIcon} alt={"undo save"} />
                      </span>
                    </LightTooltip>

                    <LightTooltip title="Save Notes" placement="right">
                      <span onClick={textDialogOnBlurRequestor} onKeyDown={k => { k.key === 'Enter' && textDialogOnBlurRequestor() }}  className="add-new-icon pointer-cursor save-task-icon" tabIndex={0}>
                        <img src={checkIcon} alt={"Save Comment"} />
                      </span>
                    </LightTooltip>

                  </Col>
                </Row>
              </div>
            )}

            <Row>
              <Col className="bottom-border margin-bottom" />
            </Row>

            <Row>
              <Col>
                <span className="section-headers">
                  Request Status
                </span>
              </Col>
              {((transactionDetails.isReadOnly || transactionDetails.transactionStatus?.id >= 130 && transactionDetails.transactionStatus?.id <= 141) && props.selectedHoldReasons && props.selectedHoldReasons.length === 0) ? <></> : 
              <Col className="text-right section-semi-headers" style={{ right: "0px" }}>
                  <span onClick={showWaitModalPopup}>
                    {props.selectedHoldReasons && props.selectedHoldReasons.length > 0 ? (
                      <>
                        <i className="fa fa-play" aria-hidden="true"></i>
                        <span className="span-paused">STOP HOLD</span>
                      </>
                    ) : (
                      <>
                        <i className="fa fa-pause" aria-hidden="true"></i>
                        <span className="span-paused">START HOLD</span>
                      </>
                    )}
                  </span>
              </Col>
              }
            </Row>
            <Row>
              <Col>
                <div className="text-left">
                  {requestTypeText}
                </div>
                <div className="text-left">
                  {props.transactionDetails?.accessCode && 
                    props.trackingLink ? (<a style={{fontSize: "14px"}} target="_blank" rel="noreferrer" href={ props.trackingLink + "/trackrequest/track/?ids=" + props.transactionDetails?.accessCode }>{props.transactionDetails?.accessCode}</a>) : props.transactionDetails?.accessCode}
                </div>
                <div className="text-left">
                  {props.transactionDetails?.releaseMethod?.name && "Release Method: " + props.transactionDetails.releaseMethod.name}
                </div>
              </Col>
              <Col className="text-right" style={{ right: "0px" }}>
                {props.transactionDetails?.disclosingInstitution?.name && <div>{(props.transactionDetails?.facility?.useClinics ? "Clinic: " : "Department: ") + props.transactionDetails?.disclosingInstitution?.name}</div>}
                <div>{props.transactionStatus?.name}</div>
                {[1,2].includes(transactionDetails.tableID) && props.transactionStatus?.id <= 138 ? 
                <div className="nopadding">
                   <CheckboxField
                    fieldlabel=""
                    onChange={onChangeEscalation}
                    defaultChecked={props.transactionDetails.isEscalated}
                    errMsg=""
                    checkboxLabel={'Escalate for QA Review'}
                  />
                  
                </div>
                : <></>}
              </Col>
            </Row>
            <Row>
              <Col className="bottom-border margin-bottom" />
            </Row>
            {transactionDetails.isReadOnly && !transactionDetails?.transactionRequest?.isDisclosureRequest ?
              (<>
                <Row>
                  <Col xs={12} md={12} sm={12} lg={12} className="section-headers">Billing Information</Col>
                </Row>
                {transactionDetails?.feeSchedule?.name ?
                  <Row>
                    <Col>
                      <div className="transaction-row-info">Fee Schedule:</div>
                      <div>{transactionDetails?.feeSchedule.name}</div>
                    </Col>
                  </Row> : <></>}
                <Row>
                  <Col className="transaction-row-setion">
                    <Row>
                      <Col>
                        <div className="transaction-row-info">Invoice Amount</div>
                        <div>{formatter.format(transactionDetails?.invoiceAmount)}</div>
                      </Col>
                      {transactionDetails?.paidAmount && (transactionDetails?.invoiceAmount !== transactionDetails?.paidAmount) ?
                        <Col>
                          <div className="transaction-row-info">Partially Paid</div>
                          <div>{formatter.format(transactionDetails?.paidAmount)}</div>
                        </Col> : <></>}
                    </Row>
                    {transactionDetails?.payments.length > 0 ?
                      <>
                        <Row className="payment-highlight">
                          <Col>
                            <div className="transaction-row-info">Payments</div>
                          </Col>
                        </Row>
                        {transactionDetails?.payments.filter(p => p.paymentAmount !== 0).map((p, i) =>
                          <Row className="payment-highlight">
                            <Col>{getMomentDate(p.date)}</Col>
                            <Col>
                              <div>Amount: {formatter.format(p.paymentAmount)}</div>
                              <div>Check #: {p.checkNum}</div>
                              {p.firstName || p.lastName ? <div>Payor Name: {p.firstName + " " + p.lastName}</div> : null}
                              {p.ccEmail ? <div>Email: {p.ccEmail}</div> : null}
                            </Col>
                          </Row>
                        )}
                      </>
                      : <></>
                    }
                    {sendToBillToSame() ? null :
                      <>
                        <Row>
                          <Col>
                            <div className="transaction-row-info">Bill To Information</div>
                          </Col>
                        </Row>
                        {values ? (<Row>
                          <Col>
                            {values[BillingFormconstants.BILL_TO_REQUESTOR_NAME] ? (<div>{values[BillingFormconstants.BILL_TO_REQUESTOR_NAME]}</div>) : null}
                            {values[BillingFormconstants.BILL_TO_ADDRESS_LINE1] ? (<div>{values[BillingFormconstants.BILL_TO_ADDRESS_LINE1]}</div>) : null}
                            {values[BillingFormconstants.BILL_TO_ADDRESS_LINE2] ? (<div>{values[BillingFormconstants.BILL_TO_ADDRESS_LINE2]}</div>) : null}
                            {values[BillingFormconstants.BILL_TO_CITY] || values[BillingFormconstants.BILL_TO_STATE] || values[BillingFormconstants.BILL_TO_ZIP] ? (<div>
                              {values[BillingFormconstants.BILL_TO_CITY] ? values[BillingFormconstants.BILL_TO_CITY] : null},&nbsp;
                              {values[BillingFormconstants.BILL_TO_STATE] ? values[BillingFormconstants.BILL_TO_STATE] : null}&nbsp;
                              {values[BillingFormconstants.BILL_TO_ZIP] ? values[BillingFormconstants.BILL_TO_ZIP] : null}
                            </div>) : null}
                            {values[BillingFormconstants.BILL_TO_PHONE] ? (<div>{normalizePhone(values[BillingFormconstants.BILL_TO_PHONE])}</div>) : null}
                          </Col>
                          <Col>
                            {values[BillingFormconstants.BILL_TO_FAX] ? (<div>{values[BillingFormconstants.BILL_TO_FAX]}</div>) : null}
                            {values[BillingFormconstants.BILL_TO_EMAIL_ADDRESS] ? (<div>{values[BillingFormconstants.BILL_TO_EMAIL_ADDRESS]}</div>) : null}
                            {values[BillingFormconstants.BILL_TO_FIRST_NAME] ? (<div>{values[BillingFormconstants.BILL_TO_FIRST_NAME]}</div>) : null}
                            {values[BillingFormconstants.BILL_TO_LAST_NAME] ? (<div>{values[BillingFormconstants.BILL_TO_LAST_NAME]}</div>) : null}
                          </Col>
                        </Row>) : null}
                      </>}
                  </Col>
                </Row>
                <Row>
                  <Col className="bottom-border margin-bottom" />
                </Row>
              </>) : (<></>)}
            <Row>
              <Col xs={12} md={12} sm={12} lg={12} className="section-headers">
                Information To Release:
              </Col>
            </Row>
            {allDates ?
              <Row>
                <Col xs={12} md={12} sm={12} lg={12}>
                  All Dates
                </Col>
              </Row>
              :
              <Row>
                <Col xs={6} md={6} sm={6} lg={6} className="">
                  <span className="itr-dt-label">From: </span>
                  <span className="itr-from-dt">
                    {getMomentDate(informationToReleaseFromValues)}
                  </span>

                </Col>
                <Col xs={6} md={6} sm={6} lg={6} className="">

                  <span className="itr-dt-label">To: </span>
                  <span className="itr-to-dt">
                    {getMomentDate(informationToReleaseToValues)}
                  </span>
                </Col>
              </Row>
            }
            <Row className="sensitive-info-sec">
              {Object.keys(informationToReleaseValues).map((item) => {
                return (
                  <Col
                    xs={6}
                    md={6}
                    lg={6}
                    sm={6}
                    className="sensitive-info-display"
                  >
                    <span className="check-icon">
                      <img src={checkMarkImage} alt="X" />
                    </span>
                    <span>{item + (informationToReleaseValues[item].text ? " - " + informationToReleaseValues[item].text : "")}</span>
                  </Col>
                );
              })}
            </Row>
              {Object.keys(informationToReleaseComments).map((item) => {
                if (informationToReleaseComments[item].recordType === 'Other' && !informationToReleaseComments[item].checked && informationToReleaseComments[item].text) {
                  return (
                    <Col xs={6} md={6} lg={6} sm={6} className="" style={{paddingLeft: 0}} >
                      <span><strong>Comment: </strong></span><span>{(informationToReleaseComments[item].text ? informationToReleaseComments[item].text : "")}</span>
                    </Col>
                  )
                }
              })}
            <Row>
              <Col className="bottom-border margin-bottom" />
            </Row>
            {!transactionDetails?.transactionRequest?.isDisclosureRequest ?
              (<>
            <Row>
              <Col xs={12} md={12} sm={12} lg={12} className="name section-headers">
                Sensitive Information:
              </Col>
            </Row>
            <Row className="sensitive-info-sec">
              {allSensitiveInfoValues.map((item) => {
                return (
                  <Col
                    xs={6}
                    md={6}
                    lg={6}
                    sm={6}
                    className="sensitive-info-display"
                  >
                    {
                      renderSensitiveInfoItem(item)
                    }

                  </Col>
                );
              })}
            </Row>
            <Row>
              <Col className="bottom-border margin-bottom" />
            </Row>
              </> ): (<></>)}
            <div className="date-style">
              <Row>
                <Col sm={5}>
                  <span>Auth Date:</span>
                </Col>
                <Col sm={2} className="p-0">
                  <span>
                    {getMomentDate(transactionDetails.date?.authorization)}
                  </span>
                </Col>
                <Col sm={5} className="text-right text-align-right">
                  <span>
                    {getDaysDiff(transactionDetails.date?.authorization)}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <span>Request Date:</span>
                </Col>
                <Col sm={2} className="p-0">
                  <span>{getMomentDate(transactionDetails.date?.request)}</span>
                </Col>
                <Col sm={5} className="text-right text-align-right">
                  <span>{getDaysDiff(transactionDetails.date?.request)}</span>
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <span>Received Date:</span>
                </Col>
                <Col sm={2} className="p-0">
                  <span>
                    {getMomentDate(transactionDetails.date?.received)}
                  </span>
                </Col>
                <Col sm={5} className="text-right text-align-right">
                  <span>{getDaysDiff(transactionDetails.date?.received)}</span>
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <span>Entered Date:</span>
                </Col>
                <Col sm={2} className="p-0">
                  <span>{getMomentDate(transactionDetails.date?.entry)}</span>
                </Col>
                <Col sm={5} className="text-right text-align-right">
                  <span>{getDaysDiff(transactionDetails.date?.entry)}</span>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm={5}>
                  <span>Due Date:</span>
                </Col>
                <Col sm={2} className="p-0">
                  <span>{getMomentDate(transactionDetails.date?.due)}</span>
                </Col>
                <Col sm={5} className="text-right text-align-right">
                  <span>{getDaysDiff(transactionDetails.date?.due)}</span>
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <span>Certification Requested: {props.transactionDetails.certificationRequired ? 'Yes' : 'No'}</span>
              </Col>
            </Row>
            <Row className="margin-bottom" />
            <Row>
              <Col className="bottom-border margin-bottom" />
            </Row>
            <NewTransactionNotesTable
              rows={transactionNotes}
              setGrid={setShowGrid}
              transactionNoteTypes={props.transactionNoteTypes}
              updateTransactionNotes={props.updateTransactionNotes}
              transactionId={props.transactionId}
            />
            <Row className="transaction-notes-section">
              <Col>
                {transactionNotes.map(
                  (row) => {
                    let fullName = row.createdByName.split(' ');
                    return (
                      <>
                        <Row className="transaction-row-setion">
                          <Col className='transaction-row-info'>
                            <div>{getMomentDate(row.created, "MM/DD/YYYY h:mm A")}</div>
                            <div>{fullName[fullName.length - 1] + ", " + fullName[0]}</div>
                          </Col>
                          <div>{row.type?.name}</div>
                          <Col className='transaction-row-text'>
                            <div>{row.text}</div>
                          </Col>
                        </Row>
                        <Row className="extra-line"></Row>
                      </>

                    );
                  }
                )}
              </Col>
            </Row>
          </div>
        ) : SelectedTabView === "Tasks" ? (
          <div className="leftBody tab-tasks">
            <Row>
              <Col>
                <a
                  href="#!"
                  className={createTaskStatus || editingTaskId !== 0 ? "addtaskstyles" : "empty-css"}
                  onClick={initiateCreateNewTask}
                >
                  <img
                    src={AddNewRequestor}
                    alt={"Add Task"}
                    className="add-new-icon"
                  />
                  <span className="">{"Add Task"}</span>
                </a>
              </Col>
            </Row>


            {showDeleteTaskPopUp ?
              <DeleteTaskPopUp
                setDeleteTaskPopUp={setDeleteTaskPopUp}
                getConfirmation={getConfirmation}
              />
              : null}

            {createTaskStatus ?
              <NewTask 
                updateTask={props.updateTask} 
                transactionId={props.transactionId} 
                addNewTask={props.addNewTask} 
                createUserTaskComment={props.createUserTaskComment} 
                userAttributes={props.userAttributes} 
                editingTaskId={editingTaskId} 
                userGroups={props.userGroups}
                updateUserTaskComments={props.updateUserTaskComments} 
                updateUserTasks={props.updateUserTasks}
                userTaskComments={props.userTaskComments}
                isNewTask={true} 
                setEditingTaskId={setEditingTaskId} 
                updateCreateTaskStatus={updateCreateTaskStatus}
              />
              : null}

            {props.userTasks.map((task, index) => {
              if (task.id === editingTaskId) {
                return <NewTask updateTask={props.updateTask} transactionId={props.transactionId} addNewTask={props.addNewTask} createUserTaskComment={props.createUserTaskComment} userAttributes={props.userAttributes} editingTaskId={editingTaskId} setEditingTaskId={setEditingTaskId} updateCreateTaskStatus={updateCreateTaskStatus} userTaskComments={props.userTaskComments} updateUserTaskComments={props.updateUserTaskComments} updateUserTasks={props.updateUserTasks}
                  userGroups={props.userGroups} taskDetails={task} index={index} />
              }
              else {
                return <NewTaskCollapsed updateTask={props.updateTask} transactionId={props.transactionId} userGroups={props.userGroups} userAttributes={props.userAttributes} editingTaskId={editingTaskId} setEditingTaskId={setEditingTaskId} updateCreateTaskStatus={updateCreateTaskStatus} userTaskComments={props.userTaskComments} taskDetails={task} index={index} updateUserTaskComments={props.updateUserTaskComments} updateUserTasks={props.updateUserTasks}
                  setDeleteTaskPopUp={setDeleteTaskPopUp}
                  deleteTaskbyId={deleteTaskbyId} />
              }
            })
            }
          </div>
        ) : SelectedTabView === "Audit" ? (
          <div className="leftBody tab-audit">
            {props.auditTrail?.map((d, i) => {
              return [i > 0 ? (<hr />) : (<></>),
              (<DisplayAuditItem auditData={d} />)]
            })}
          </div>
        ) : SelectedTabView === "FinalQA" && props.QAFeatureFlags != null && props.QAFeatureFlags.finalQA  ? (
          <div className="leftBody tab-finalqa">
            {props.QAFeatureFlags != null && props.QAFeatureFlags.finalQAAccordions && Array.isArray(assessmentResults) && assessmentResults.length >= 0 && props.transactionDetails.isInAIProcessing && (
            <div>
            <span className="section-headers" style={{"color": "#5F9EC5"}}>AI Findings</span>
           <Accordion>
              <Card>
                <Card.Header className="section-semi-headers border-none">
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                    Sensitive Information
                    {props.transactionDetails.isInAIProcessing && Array.isArray(assessmentResults) && assessmentResults.length == 0 ? 
                    <img src={aiProcessingIcon} className="ai-processing-icon" title="AI processing not yet completed"></img> : 
                    <div className="fa-stack ai-processing-icon" title="AI processing results">
                        <i className="fa fa-circle fa-stack-2x" aria-hidden="true"></i>
                        <span className="fa-stack-1x ai-results-num">{getAIEntityCount(assessmentResults, "sensitive")}
                        </span>
                    </div>
                    }
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                  <Container>
                      {Array.isArray(assessmentResults) && assessmentResults.map(doc =>
                        (<>
                          <Row>
                            <Col md={12} className="section-semi-headers">{doc.fileDesc}</Col>
                          </Row>
                          <Row className="bold-font no-gutters">
                            <Col md={1}>{!transactionDetails.isReadOnly && <></>}</Col>
                            <Col md={3}>Text</Col>
                            <Col md={2}>Type</Col>
                            <Col md={1} className="small-col"  style={{marginRight: 15}}>Page</Col>
                            <Col md={3}>Confidence</Col>
                            <Col md={2}>{!transactionDetails.isReadOnly && <></>}</Col>
                          </Row>
                          <Row>
                            <Col className="bottom-border margin-bottom" />
                          </Row>
                          {doc.detectedEntities.filter(ent =>
                            ent.type.toLowerCase() != "5c06b99b-73f5-461a-8f81-c8ae31bb5c32" // auth
                          ).map(ent =>
                            (<>
                              <Row>
                                <Col md={1}>
                                
                                  { !transactionDetails.isReadOnly && !ent.feedback && (<>
                                    <a href="#" onClick={() => addFeedback(ent.id, "9a37fe6f-bbe7-43f3-8b80-750fae70e080")} >
                                      <img src={checkIcon} alt="Confirm as correct" style={{ verticalAlign: 'top' }} title="Approve"/>
                                    </a>
                                    </>)}
                                </Col>
                                <Col md={3}>{ent.spanText}</Col>
                                <Col md={2}>
                                {ent.typeName + " "}
                                  <i className="fa fa-square fa-border fa-pull-left" style={{color: ent.annotationColorCode}}  title="Annotation Color" ></i>
                                </Col>
                                <Col md={1} className="btn btn-link small-col" onClick={() => handlePageClick(ent.page + 1, doc.fileDesc)}>{ent.page + 1}</Col>
                                <Col md={2}>{(ent.confidence * 100.0).toFixed(2)}%</Col>
                                <Col md={3} className="d-flex align-items-start" >
                                {!transactionDetails.isReadOnly && ent.feedback && (<>
                                    {props.getAIFeedback?.find(fb => fb.id == ent.feedback)?.name}
                                    {!ent.feedbackAccepted && <a href="#" onClick={() => undoFeedback(ent.id)} ><div className="fa fa-undo" aria-hidden="true" style={{ marginLeft: '10px' }}></div></a>}
                                  </>)}
                                  {!transactionDetails.isReadOnly &&  !ent.feedback && (<>
                                    <a href="#" onClick={() => onIncorrectFeedbackClicked(ent)} className="align-top">
                                      <img src={CancelMarkFilled} alt="Mark as incorrect" className="align-top" title="Reject"/>
                                    </a>
                                  </>)}
                                </Col>
                              </Row>
                            </>)
                          )}
                        </>)
                      )}
                    </Container>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion>
              <Card>
                <Card.Header className="section-semi-headers border-none">
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                    Comingling
                    {props.transactionDetails.isInAIProcessing && Array.isArray(assessmentResults) && assessmentResults.length == 0 ? 
                    <img src={aiProcessingIcon} className="ai-processing-icon" title="AI processing not yet completed"></img> : 
                    <div className="fa-stack ai-processing-icon" title="AI processing results">
                        <i className="fa fa-circle fa-stack-2x" aria-hidden="true"></i>
                        <span className="fa-stack-1x ai-results-num">{getAIEntityCount(assessmentResults, "patient")}</span>
                    </div>}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body> 
                    <Container>
                      {Array.isArray(assessmentResults) && assessmentResults.map(doc =>
                        (<>
                          <Row>
                            <Col xs={12} md={12} sm={12} lg={12} className="section-semi-headers border-none">{doc.fileDesc}</Col>
                          </Row>
                          <Row className="bold-font no-gutters">
                          <Col md={1}></Col>
                            <Col md={3}>Name</Col>
                            <Col md={1} className="small-col"  style={{marginRight: 15}}>Page</Col>
                            <Col md={3}>Confidence</Col>
                            <Col md={3}></Col>
                          </Row>
                          <Row>
                            <Col className="bottom-border margin-bottom" />
                          </Row>
                          {Object.keys(doc.patientNames).map(name =>
                            (<>
                            
                              <Row className="bold-font">{doc.patientNames[name][0].spanText}</Row>
                              {doc.patientNames[name].map(patient =>
                                (<>
                                  <Row>
                                    <Col md={1}> {!transactionDetails.isReadOnly && !patient.feedback && (<>
                                        <a href="#" onClick={() => addFeedback(patient.id, "9a37fe6f-bbe7-43f3-8b80-750fae70e080")} style={{ display: 'flex', alignItems: 'flex-start', margin: '0', padding: '0' }}>
                                          <img src={checkIcon} alt="Confirm as correct" style={{ margin: '0', padding: '0' }} title="Approve"/>
                                        </a>
                                        </>)}
                                        </Col>
                                    <Col md={3}>{patient.spanText}</Col>
                                    <Col md={1} className="btn btn-link small-col" onClick={() => handlePageClick(patient.page + 1, doc.fileDesc)} style={{padding:0}}>{patient.page + 1}</Col>
                                    <Col md={3}>{(patient.confidence * 100.0).toFixed(2)}%</Col> 
                                    <Col md={3}>
                                      {!transactionDetails.isReadOnly && patient.feedback && (<>
                                        {props.getAIFeedback?.find(fb => fb.id == patient.feedback)?.name}
                                        {!patient.feedbackAccepted && <a href="#" onClick={() => undoFeedback(patient.id)}  style={{ display: 'inline-flex' }}><div className="fa fa-undo" aria-hidden="true"></div></a>}
                                      </>)}
                                      {!transactionDetails.isReadOnly &&  !patient.feedback && (<>
                                        <a href="#" onClick={() => onIncorrectFeedbackClicked(patient)} style={{ display: 'flex', alignItems: 'flex-start', margin: '0', padding: '0' }}>
                                          <img src={CancelMarkFilled} alt="Mark as incorrect" style={{ margin: '0', padding: '0' }} title="Reject"/>
                                        </a>
                                      </>)}
                                    </Col>
                                  </Row>
                                </>)
                              )}
                            </>)
                          )}
                        </>)
                      )}
                    </Container>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion>
              <Card>
                <Card.Header className="section-semi-headers border-none">
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
                    Patient Auth
                    {props.transactionDetails.isInAIProcessing && Array.isArray(assessmentResults) && assessmentResults.length == 0 ? 
                    <img src={aiProcessingIcon} className="ai-processing-icon" title="AI processing not yet completed"></img> : 
                    <div className="fa-stack ai-processing-icon" title="AI processing results">
                        <i className="fa fa-circle fa-stack-2x" aria-hidden="true"></i>
                        <span className="fa-stack-1x ai-results-num">{getAIEntityCount(assessmentResults, "auth")}</span>
                    </div>}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <Container>
                      {Array.isArray(assessmentResults) && assessmentResults.map(doc =>
                        (<>
                          <Row>
                            <Col xs={12} md={12} sm={12} lg={12} className="section-semi-headers">{doc.fileDesc}</Col>
                          </Row>
                          <Row className="bold-font no-gutters">
                            <Col md={2}></Col>
                            <Col md={2}>Page</Col>
                            <Col md={4}>Confidence</Col>
                            <Col md={3}></Col>
                          </Row>
                          <Row>
                            <Col className="bottom-border margin-bottom" />
                          </Row>
                          {doc.detectedEntities.filter(ent => ent.type.toLowerCase() == "5c06b99b-73f5-461a-8f81-c8ae31bb5c32").map(auth =>
                            (<>
                              <Row>
                              <Col md={2}>{!transactionDetails.isReadOnly && !auth.feedback && (<>
                                    <a href="#" onClick={() => addFeedback(auth.id, "9a37fe6f-bbe7-43f3-8b80-750fae70e080")}>
                                      <img src={checkIcon} alt="Confirm as correct" title="Approve"/>
                                    </a>
                                  </>)}
                                </Col>
                                <Col md={2} className="btn btn-link " onClick={() => handlePageClick(auth.page + 1, doc.fileDesc)}  style={{ textAlign: 'left' }}>{auth.page + 1}</Col>
                                <Col md={4}>{(auth.confidence * 100.0).toFixed(2)}%</Col>
                                <Col md={3}>
                                  {!transactionDetails.isReadOnly && auth.feedback && (<>
                                    {props.getAIFeedback?.find(fb => fb.id == auth.feedback)?.name}
                                    {!auth.feedbackAccepted && <a href="#" onClick={() => undoFeedback(auth.id)} style={{ marginLeft: '10px' }}><div className="fa fa-undo" aria-hidden="true"></div></a>}
                                  </>)}
                                  {!transactionDetails.isReadOnly && !auth.feedback && (<>
                                    <a href="#" onClick={() => onIncorrectFeedbackClicked(auth)}>
                                      <img src={CancelMarkFilled} alt="Mark as incorrect" title="Reject"/>
                                    </a>
                                  </>)}
                                </Col>
                              </Row>
                            </>)
                          )}
                        </>)
                      )}
                    </Container>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            </div>
            )}
            {
            props.QAFeatureFlags != null && props.QAFeatureFlags.finalQAAccordions && Array.isArray(assessmentResults) && assessmentResults.length === 0 && !props.transactionDetails.isInAIProcessing ? (
            <div className="ai-empty-results"> The AI process was unable to find information or the transaction was not eligible for the AI process. </div>) : <div/> 
            }
            <span className="section-headers" style={{"color": "#5F9EC5"}}>Reviewer Findings</span>
            <Accordion>
              <Card>
                <Card.Header className="section-semi-headers border-none">
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                    Issues
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className="issue-block">
                      <Row>
                        <Col md={12}>
                          <CheckboxField
                            fieldlabel=""
                            onChange={onShowAllIssues}
                            defaultChecked={showAllIssues}
                            errMsg=""
                            checkboxLabel={"Show All"}
                          />
                        </Col>
                      </Row>
                      <Row className="issue-underline table-header">
                        <Col
                          className="first-col link">
                        </Col>
                        <Col md={4}
                          className="middle-col nonlink" style={{textAlign: "left"}}>
                          Issue
                        </Col>
                        <Col md={2}
                          className="middle-col nonlink" style={{textAlign: "left"}}>
                          Page #
                        </Col>
                        <Col md={4}
                          className="middle-col nonlink" style={{textAlign: "left"}}>
                          Added By
                        </Col>
                        <Col 
                          className="last-col link">
                        </Col>
                      </Row>
                      {getQAIssues && Array.isArray(getQAIssues) && props.getQAIssues.filter((row) =>
                        showAllIssues || (row.active && !row.resolved)
                      ).map((row) => (
                        <>                       
                          <div className="issue-item">
                            <Row className="issue-info">
                            <Col className="first-col nonlink" style={{
                                textDecoration: row.active ? "" : "line-through",
                                color: row.resolved && row.active ? "#a0a0a0" : ""
                              }}>
                            {row.active && !row.resolved && (<>
                                <a href="#">
                                  <img src={checkIcon} alt="Resolve Issue" onClick={async () => await onIssueResolved(row.issueID)} title="Resolve"/>
                                </a>
                                </>)}
                                </Col>
                              <Col md={4} className="middle-col nonlink" style={{
                                textDecoration: row.active ? "" : "line-through",
                                color: row.resolved && row.active ? "#a0a0a0" : "",
                                textAlign: "left"
                              }}>
                                {row.active && !row.resolved && (
                                <a id="edit-issue-link" href="#" title="Edit" data-id={row.issueID} data-page={row.pageNumber} data-type={row.qaErrorID} data-details={row.issueDetails} onClick={setIssueForm}>
                                  ({row.qaErrorTypeName}) {row.qaErrorName}
                                </a>)}
                                {(!row.active || row.resolved) && ('(' + row.qaErrorTypeName + ') ' + row.qaErrorName)}
                              </Col>
                              <Col md={2} 
                                className="middle-col nonlink btn btn-link"
                                onClick={() => handlePageClick(row.pageNumber, row.fileName)} style={{textAlign: "left"}}>
                                  {row.pageNumber}
                              </Col>
                              <Col md={4} className="middle-col nonlink" style={{
                                textDecoration: row.active ? "" : "line-through",
                                color: row.resolved && row.active ? "#a0a0a0" : "",
                                textAlign: "left"
                              }}>
                                {row.firstName} {row.lastName}
                              </Col>
                              <Col className="last-col link"  style={{
                                textDecoration: row.active ? "" : "line-through",
                                color: row.resolved && row.active ? "#a0a0a0" : "",
                                textAlign: "right"
                              }}>
                                {row.active && !row.resolved && (<>
                                <a href="#" title="Resolve">
                                  <img src={CancelMarkFilled} className="reject-issue" alt="Remove Issue" onClick={async () => await onIssueRemoved(row.issueID)} title="Remove"/>
                                </a>
                                </>)}
                                {!row.active && (<>
                                  Removed
                                </>)}
                                {row.active && row.resolved && (<>
                                  Resolved
                                </>)}
                              </Col>
                            </Row>
                            <div className="issue-details" style={{
                              textDecoration: row.active ? "" : "line-through",
                              color: row.resolved && row.active ? "#a0a0a0" : ""
                            }}>
                              {row.issueDetails}
                            </div>
                          </div>
                          <div className="issue-underline"></div>
                        </>
                      ))}
                    </div>
                    <br />
                    {transactionDetails.isReadOnly ? null : 
                    <div className="add-issue-container">
                      <a href="#" id="add-issue-link" onClick={setIssueForm}>
                        <img
                          src={AddNewIssue}
                          className="add-new-issue-icon" />Add Issue
                      </a>
                      {
                        props.currentFileType !== "RELEASE" ? (
                          <div className="pd-issue-warning"> Select Release Document to add issues. </div>) : <div/> 
                      }
                    </div>
                    }
                    {showForm && <AddIssueForm
                      toggleForm={toggleForm}
                      showForm={showForm}
                      getQAErrors={props.getQAErrors}
                      userAttributes={props.userAttributes}
                      transactionId={props.transactionId} 
                      isEditIssue={editIssue}
                      editPageNumber={editIssuePage}
                      editIssueType={editIssueType}
                      editIssueId={editIssueId}
                      editIssueDetails={editIssueDetails}
                      />}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            {props.QAFeatureFlags != null && props.QAFeatureFlags.finalQAAccordions && (
            <div>
            <Accordion>
              <Card>
                <Card.Header className="section-semi-headers border-none">
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                    Sensitive Information
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Container>
                      {reviewEntities && Object.keys(reviewEntities).map(doc =>
                        (<>
                          <Row>
                            <Col md={12} className="section-semi-headers">{doc}</Col>
                          </Row>
                          <Row className="bold-font no-gutters">
                            <Col md={3}>Type</Col>
                            <Col md={3} className="small-col">Page</Col>
                            <Col md={3}>Noted By</Col>
                            <Col md={3}>Submitted On</Col>
                          </Row>
                          <Row>
                            <Col className="bottom-border margin-bottom" />
                          </Row>
                          {reviewEntities[doc].filter(ent =>
                            ent.sensitiveInfoType.toLowerCase() != "5c06b99b-73f5-461a-8f81-c8ae31bb5c32" && // auth
                            ent.sensitiveInfoType.toLowerCase() != "5b06b99b-73f5-461a-8f81-c8ae31bb5c32" // patient
                          ).map(ent =>
                            (<>
                              <Row>
                                <Col md={3}>{ent.typeName} </Col>
                                <Col md={3} className="btn btn-link small-col" onClick={() => handlePageClick(ent.page + 1, doc)} >{ent.page + 1}</Col>
                                <Col md={3}>{ent.lastName}, {ent.firstName}</Col>
                                <Col md={3}>{new Date(ent.submitted).toLocaleDateString()}</Col>
                              </Row>
                            </>)
                          )}
                        </>)
                      )}
                    </Container>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion>
              <Card>
                <Card.Header className="section-semi-headers border-none">
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                    Comingling
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body> 
                    <Container>
                    {reviewEntities && Object.keys(reviewEntities).map(doc =>
                        (<>
                          <Row>
                            <Col md={12} className="section-semi-headers">{doc}</Col>
                          </Row>
                          <Row className="bold-font no-gutters">
                            <Col md={4} className="small-col">Page</Col>
                            <Col md={4}>Noted By</Col>
                            <Col md={4}>Submitted On</Col>
                          </Row>
                          <Row>
                            <Col className="bottom-border margin-bottom" />
                          </Row>
                          {reviewEntities[doc].filter(ent =>
                            ent.sensitiveInfoType.toLowerCase() == "5b06b99b-73f5-461a-8f81-c8ae31bb5c32" // patient
                          ).map(ent =>
                            (<>
                              <Row>
                                <Col md={4} className="btn btn-link small-col" onClick={() => handlePageClick(ent.page + 1, doc)} >{ent.page + 1}</Col>
                                <Col md={4}>{ent.lastName}, {ent.firstName}</Col>
                                <Col md={4}>{new Date(ent.submitted).toLocaleDateString()}</Col>
                              </Row>
                            </>)
                          )}
                        </>)
                      )}
                    </Container>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion>
              <Card>
                <Card.Header className="section-semi-headers border-none">
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
                    Patient Auth
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <Container>
                    {reviewEntities && Object.keys(reviewEntities).map(doc =>
                        (<>
                          <Row>
                            <Col md={12} className="section-semi-headers">{doc}</Col>
                          </Row>
                          <Row className="bold-font no-gutters">
                            <Col md={4} className="small-col">Page</Col>
                            <Col md={4}>Noted By</Col>
                            <Col md={4}>Submitted On</Col>
                          </Row>
                          <Row>
                            <Col className="bottom-border margin-bottom" />
                          </Row>
                          {reviewEntities[doc].filter(ent =>
                            ent.sensitiveInfoType.toLowerCase() == "5c06b99b-73f5-461a-8f81-c8ae31bb5c32" // auth
                          ).map(ent =>
                            (<>
                              <Row>
                                <Col md={4} className="btn btn-link small-col" onClick={() => handlePageClick(ent.page + 1, doc)} >{ent.page + 1}</Col>
                                <Col md={4}>{ent.lastName}, {ent.firstName}</Col>
                                <Col md={4}>{new Date(ent.submitted).toLocaleDateString()}</Col>
                              </Row>
                            </>)
                          )}
                        </>)
                      )}
                    </Container>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            </div>
            )}
            {showAddFeedback && <>
              <AssessmentFeedbackPopUp
                setShowAddFeedback={setShowAddFeedback}
                addFeedback={addFeedback}
                entityId={entityId}
                entityType={entityType}
                feedbackTypes={props.getAIFeedback}
              />
            </>}
          </div>
          ) : null
        }
      </div>
      {transactionDetails.isReadOnly || transactionDetails.transactionStatus?.id >= 130 ? <></> :
        <div className="panel-footer">
          <span
            className="float-left ml-3 mt-2"
            onClick={goToPreviousTransaction}
          >
            <Link to="/data">
              <i className="next-prev-link left-arrow"></i> Previous Request
            </Link>
          </span>
          <span
            className="float-left mt-2"
            onClick={createNewTransaction}
          >
          <img
            src={AddNewRequestor}
            alt={"Add Task"}
            className="add-new-icon"
            style={{paddingRight: '.5em'}}
          />
            <Link to="/data">
                  New Request
            </Link>
          </span>
          <span className="float-right mr-1 mt-2" onClick={goToNextTransaction}>
            <Link to="/data">
              Next Request <i className="next-prev-link right-arrow"></i>
            </Link>
          </span>
        </div>
      }
    </div>
  );
}
function mapStateToProps(state: any, props: any) {
  return {
    globalUserID: state.loginState.userID,
    userAttributes: state.loginState.userAttributes,
    activeTabId: state.dataEntryState.activeTabId,
    transactionId: props.transactionId || state.landingPageState.transactionId,
    transactionNoteTypes: props.transactionNoteTypes || state.dataEntryState.transactionNoteTypes,
    rejectLetterData: props.rejectLetterData || state.dataEntryState.rejectLetterData,
    rejectLetterDataState: props.rejectLetterData || state.dataEntryState.rejectLetterDataState,
    facilityId: props.facilityId || state.landingPageState.selectedFacilityDetails?.facility.id,
    nextTransactionId: state.dataEntryState.nextTransactionId,
    previousTransactionId: state.dataEntryState.previousTransactionId,
    dataEntryFormState: props.dataEntryFormState || (state.form && state.form[FormConstants.DATA_ENTRY_FIELDS_FORM]),
    userGroups: props.userGroups || state.dataEntryState.userGroups,
    feeSchedules: props.feeSchedules || state.dataEntryState.feeSchedule,
    rejectCompleted: state.dataEntryState.rejectCompleted,
    certificationRequired: state.dataEntryState.leftPaneTransactionDetails.certificationRequired,
    auditTrail: props.auditTrail || state.dataEntryState.auditTrail,
    issueTrail: props.issueTrail || state.dataEntryState.issueTrail,
    intiaTxlSagaCallCount: props.intiaTxlSagaCallCount || state.dataEntryState.intiaTxlSagaCallCount,
    requestTypes: props.requestTypes || state.landingPageState.requestTypes,
    purposeOfRequests: state.landingPageState.purposeOfRequests,
    requestContext: state.landingPageState.requestContext,
    featureFlags: state.loginState.featureFlags,
    QAFeatureFlags: state.loginState.QAFeatureFlags,
    selectedHoldReasons: generatePickerOptionsNoDefault(props.transactionDetails?.transactionWaitHold, "waitHoldReasonName", "waitHoldReasonID"),
    transactionStatus: state.landingPageState.readOnlyTransactionDetails && state.landingPageState.readOnlyTransactionDetails.transactionId === props.transactionId ? props.transactionDetails?.transactionStatus : state.dataEntryState.transactionDetails?.transactionStatus,
    trackingLink: state.landingPageState.trackingLink,
    assessmentResults: state.dataEntryState.getAssessmentResults,
    reviewEntities: state.dataEntryState.reviewEntities,
    getQAErrors: state.dataEntryState.getQAErrors,
    qaPDIssues: state.dataEntryState.qaPDIssues,
    documentsList: state.dataEntryState.documentsList,
    getAIFeedback: state.dataEntryState.getAIFeedback,
    currentFileType: state.dataEntryState.currentFileType,
    previousSearchParameters: state.loginState.previousSearchParameters,
    previousSearchPage: state.loginState.previousSearchPage,
  };
}
export function mapDispatchToProps(dispatch: Function, props: any) {
  return {
    patchPatientNotesData: (patientid: any, note: any, globalUserID: any, transactionId: any) =>
      dispatch(patchPatientNoteActionCreators(patientid, note, globalUserID, transactionId)),
    patchRequestorNotesData: (requestorid: any, note: any, globalUserID: any, contactId: any) =>
      dispatch(
        patchRequestorNoteActionCreators(requestorid, note, globalUserID, contactId)
      ),
    updateTransactionNotes: (data: any) => props.updateTransactionNotes ? props.updateTransactionNotes(data) : dispatch(updateTransactionNotes(data)),
    rejectionContactOverride: (data: any) =>
      dispatch(rejectionContactOverrideActionCreator(data)),
    getRejectionLetter: (transactionId: string) =>
      dispatch(getRejectionLetterActionCreator(transactionId)),
    getPreviousTransaction: (transactionId: string) =>
      dispatch(getPreviousTransactionActionCreator(transactionId)),
    unassignTransactionID: (transactionId: string) =>
      dispatch(unassignTransactionIDActionCreator(transactionId)),
    assignTransactionID: (transactionId: string) =>
      dispatch(assignTransactionIDActionCreator(transactionId)),
    setPreviousTransaction: (previousTransactionId: string) =>
      dispatch(setPreviousTransactionActionCreator(previousTransactionId)),
    getNextTransactionId: (data: string, context:any) =>
      dispatch(nextTransactionIdActionCreator(data, context)),
    setNextTransactionID: (transactionId: string) =>
      dispatch(setNextTransactionIdActionCreator(transactionId)),
    setNextTransactionIDNull: () =>
      dispatch(setNextTransactionNullActionCreator()),
    updateUserTasks: (data) => props.addNewTask ? props.addNewTask(data) : dispatch(updateUserTasksActionCreator(data)),
    updateUserTaskComments: (data) => props.updateUserTaskComments ? props.updateUserTaskComments(data) : dispatch(updateUserTaskCommentsActionCreator(data)),
    createUserTaskComment: (data) => props.createUserTaskComment ? props.createUserTaskComment(data) : dispatch(createUserTaskCommentActionCreator(data)),
    addNewTask: (data) => props.addNewTask ? props.addNewTask(data) : dispatch(addNewTaskActionCreator(data)),
    updateTask: (data) => props.updateTask ? props.updateTask(data) : dispatch(updateTaskActionCreator(data)),
    deleteTransactionTask: (transactionId: string, usertaskId: string) => props.deleteTransactionTask ? props.deleteTransactionTask(transactionId, usertaskId) : dispatch(deleteTransactionTaskActionCreator(transactionId, usertaskId)),
    postRejectionAction: (data: any) =>
      dispatch(postRejectionDetailsActionCreator(data)),
    resetDataEntryState: () => dispatch(resetDataEntryState()),
    clearTransactionId: () => dispatch(clearTransactionId()),
    createTransaction: (data: any) => dispatch(createTransaction(data)),
    getAuditTrailData: (transactionId: string) => props.getAuditTrailData ? props.getAuditTrailData(transactionId) : dispatch(getAuditTrail(transactionId)),
    getTransactionAttributes: (transactionId: string) => dispatch(showReadOnlyTransactionRequest(transactionId)),
    sendOutboundTemplateFields: (templateId: any, transactionId: any, action: any, templateFields: any, facilityId: any, context: any, contact: any) => dispatch(sendOutboundTemplateFieldsActionCreator(templateId, transactionId, action, templateFields, facilityId, context, contact)),
    setEscalation: (data) => props.setEscalation && props.setEscalation(data),
    fetchQAIssues: (transactionId: string) => dispatch(getQAIssuesActionCreator(transactionId)),
    getQAPDIssues: (transactionId: string) => dispatch(getQAPDIssuesActionCreator(transactionId)),
    resolveQAIssue: (transactionId: string, issueId: string) => dispatch(resolveQATransactionIssueActionCreator(transactionId, issueId)),
    removeQAIssue: (transactionId: string, issueId: string) => dispatch(removeQATransactionIssueActionCreator(transactionId, issueId)),
    setPageNumber: (pageNumber: number, fileDesc: string) => dispatch(setPDFPageNumberActionCreator(pageNumber, fileDesc)),
    addFeedback: (entityId: string, feedbackType: string) => dispatch(submitAIFeedbackActionCreator(entityId, feedbackType)),
    deleteFeedback: (entityId: string) => dispatch(deleteAIFeedbackActionCreator(entityId)),
    getAssessmentResults:(transactionId: string) => dispatch(getAssessmentResultsActionCreator(transactionId)),
    readBarCodeDDSRequest: (documentId: string) => dispatch(readBarCodeDDSRequest(documentId)),
    getQAFeatureFlagsRequest: () => dispatch(getQAFeatureFlagsRequest()),
    returnToSearch: (organizationId?: number, requestBufferId?: number, parameters?: string, page?: number) => dispatch(returnToSearchRequest(organizationId, requestBufferId, parameters, page)),
  };
}
const DataEntryDetailsReduxForm = reduxForm<{}, IpropType>({
  form: FormConstants.DATA_ENTRY_FIELDS_FORM,
  validate: localValidations,
  onSubmit: submitValidations,
  enableReinitialize: true,
  touchOnChange: true,
})(DataEntryDetailsLeftPane);

export default connect<{}, {}, IpropType>(
  mapStateToProps,
  mapDispatchToProps
)(DataEntryDetailsReduxForm);
