import React, { Fragment, FunctionComponent, useEffect, useState, MouseEvent } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  Navbar,
  Dropdown,
  Row,
  Col,
  Form,
  Breadcrumb,
  Nav,
  InputGroup
} from "react-bootstrap";
import VerismaLogo from "../../assets/images/headerImages/VerismaLogo.png";
import QuestionMark from "../../assets/images/headerImages/question-mark.png";
import HomeIcon from "../../assets/images/headerImages/HomeIcon.png";
import UserImage from "../../assets/images/headerImages/user-avatar.png";
import logoutImage from '../../assets/images/headerImages/User.png';
import returnOutlineLarge from "../../assets/images/tabImages/returnOutlineLarge.png";
import magnifying_glass_small_outline from "../../assets/images/headerImages/magnifying_glass_small_outline.svg";
import { ApiUrl } from "../../services/Network";
import "../header/Header.scss";
import { onChangeDarkMode } from "./HeaderDucks";
import { clearPreviousSearch, logoutUsers, putHeartbeatRequest, returnToSearchRequest } from '../../components/login/LoginPageDucks'
import { Link } from "react-router-dom";
import {
  resetSelectedHealthOrganizatonDetails, getTransactionSearchResultsRequest, getTransactionSearchResultsReset, getTransactionIdSuccess,
  headerAssignTransactionActionHeader, clearSelectedSearchTransaction,
  headerUnassignTransactionIDActionCreator, getInitialsRequest, showReadOnlyTransactionRequest,clearUserLocksActionRequest,
  getReadOnlyTransactionDocuments, getNextQATransaction,
  updateRequestContext,
} from "../../components/landingPage/LandingPageDucks";
import NormalTable from '../../common/table/NormalTable/NormalTable';
import { push } from "../../redux/navigation/navigationDucks";
import { logoutUserRequest, callHeartbeatSaga } from '../../components/login/LoginPageDucks';
import { getMomentDate } from "../utils/appUtils";
import APIErrorPopUp from '../alertPopup/APIErrorPopUp'
import ErrorMessagePopup from '../alertPopup/ErrorMessagePopup';
import InformationPrompt from '../alertPopup/InformationPrompt';
import SearchBox from '../tabComponents/searchbox';
import NightIcon from '../../assets/images/headerImages/NightIcon.png'
import DayIcon from '../../assets/images/headerImages/DayIcon.png'
import urgent_outline_large from "../../assets/images/urgent_outline_large.svg";
import { onChangeDataEntryTab, resetDataEntryState, getAssessmentResultsActionCreator, getReviewEntitiesActionCreator, getQAErrorsActionCreator, getTransactionDocuments} from '../../components/dataEntryDetails/DataEntryDetailsDucks';
import TimeoutWarning from "../../common/timeoutWarning/timeoutWarning";
import ReadOnlyTransaction from "./ReadOnlyTransaction";
import { Checkbox } from "@material-ui/core";
import {resetRequestContext } from '../../components/landingPage/LandingPageDucks'
import { getQAErrorsAPI } from "../../components/dataEntryDetails/test/DataEntry.APIs";
import AutoLogoutTimeOut from "../autoLogoutTimeOut/autoLogoutTimeOut";

interface HeaderProps {
  homeText: string[];
  onChangeDarkMode: Function;
  isDarkModeEnabled: boolean;
  name: string,
  resetSelectedHealthOrganizatonDetails: Function,
  logoutUser: Function,
  theme: string,
  alertAPIFailure: number,
  getTransactionSearchResultsRequest?: Function,
  getTransactionSearchResultsReset?: Function,
  transactionSearchResults: any,
  selectedHealthOrganizationDetails?: any,
  getTransactionIdSuccess?: Function,
  goToTransactionDetails?: Function,
  selectedFacilityDetails?: any,
  transactionSearchResultsStatus?: any,
  userState: any,
  headerClassName?: any,
  searchPlaceholder?: string,
  searchChanged?: ((term: string) => void)
  assignSearchTransactionID?: any,
  searchSelectedTransactionId?: any,
  searchSelectedFacilityId?: any,
  clearSelectedSerachTransaction?: any,
  assignAPIInfo?: any,
  existingTransactionID?: any,
  unassignSearchTransactionID?: any,
  callHeartbeat: Function,
  userPersonalInfo: any,
  onChangeDataEntryTab?: Function,
  resetDataEntryState?: Function,
  onExitTransaction?: Function,
  showReadOnlyTransaction?: Function,
  resetRequestContext?: Function,
  searchTerm?: string,
  clearUserLocks?: Function,
  getAssessmentResults: any,
  getQAFeatureFlagsRequest?: Function,
  finalQaFeature?: string,
  previousSearchParameters?: string,
  previousSearchPage?: number,
  returnToSearch?: Function,
  transactionId?: string,
  clearPreviousSearch: Function,
  getReviewEntities: Function,
  reviewEntities: any,
  getQAErrors: any,
  getTransactionDocuments: Function,
  getNextQATransaction: Function,
  getNextQATransactionCall: Function,
  changeRequestContext: Function,
  readOnlyTransactionDetails?: any,
}

const Header: FunctionComponent<HeaderProps> = (props) => {
  const [showSearchGrid, setSearchGrid] = useState(false);
  const [searchText, setSearchText] = useState("")
  const [allowReadOnly, setAllowReadOnly] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage ] = useState("");
  const history = useHistory();
  useEffect(() => {
    const getTheme = props.theme;
    if (getTheme === 'dark') {
      props.onChangeDarkMode(true);
    }

    if ((process?.env?.NODE_ENV && process.env.NODE_ENV !== 'development') && props.userState?.userID != null) {
      //startHeartbeat();
    }
  });

  useEffect(() => {
    if(!showSearchGrid){
      props.getTransactionSearchResultsReset && props.getTransactionSearchResultsReset()
    }
  }, [showSearchGrid])

  function onValueChange() {
    props.onChangeDarkMode(!props.isDarkModeEnabled);
  }

  function onAllowReadOnlyChange(){
    setAllowReadOnly(!allowReadOnly);
  }

  function exitTransaction(breadcrumbitem?: number) {

    if (!props.onExitTransaction || props.onExitTransaction()) {
      if (breadcrumbitem === undefined || breadcrumbitem < 3){
        props.clearPreviousSearch();
        props.resetSelectedHealthOrganizatonDetails();
        props.resetRequestContext && props.resetRequestContext();
        props.clearUserLocks && props.clearUserLocks();
        let path = '/home';

        if (breadcrumbitem !== undefined){
          if (breadcrumbitem === 1) {
            path += "#" + props.selectedHealthOrganizationDetails.healthSystem.id.toString();
          } else {
            path += "#" + props.selectedFacilityDetails.facility.id.toString();
          }
        }

        history?.push(path)
      }
    }
  }

  const sanitizeSearchTerm = (value: string) => {
    setSearchErrorMessage("")

    if(value.replace(" ", "")?.match(/^\d{1,2}([/\-.])\d{1,2}\1\d{2,4}$/g)){
      let tempDate = Date.parse(value)
      if(tempDate < Date.parse("1/1/1753") || tempDate > Date.parse("12/31/9999")){
        setSearchErrorMessage("Invalid date")
        return "";
      }
      if (!isNaN(tempDate)) {
        return value;
      }
    }

    if(value?.match(/-/g)?.length === 1){
      let tempsplitvalue = value.split('-')
      if(tempsplitvalue[0].length < 7 && value[0] === '0'){
        return '100' + value;
      }else{
        return value;
      }
    }

    if(value.length === 10 && /^-?\d+$/.test(value)){
      return value.substring(0, 3) + '-' + value.substring(3, 6) + '-' + value.substring(6, 10)
    }

    return value

  }


  const onClickSearch = () => {
    if (searchText.length < 3) {
      setSearchGrid(false)
    } else {
      
      let searchItem = sanitizeSearchTerm(searchText)
      if(searchItem.length > 0){
        setSearchGrid(true);
        const requestObject = {
          orgId: "",
          searchItem: searchItem,
          allowReadOnly: allowReadOnly
        }
        props.getTransactionSearchResultsRequest && props.getTransactionSearchResultsRequest(requestObject)
      }
      
      
    }
  }

  const loadUnwaitedTx = (transactionId: string) => {
    if (!props.onExitTransaction || props.onExitTransaction()) {
      if (props.existingTransactionID) {
        props.unassignSearchTransactionID && props.unassignSearchTransactionID(props.existingTransactionID)
      }
      props.resetDataEntryState && props.resetDataEntryState();
      props.onChangeDataEntryTab && props.onChangeDataEntryTab(1);
    }
    props.resetRequestContext && props.resetRequestContext();
  }

  const onSearchTableRowClickHandler = (rowData: any) => {
    if (rowData.isReadOnly === "Yes") {
      props.showReadOnlyTransaction && props.showReadOnlyTransaction(rowData.transaction)
    } else if (!props.onExitTransaction || props.onExitTransaction()) {
      if (props.existingTransactionID) {
        props.unassignSearchTransactionID && props.unassignSearchTransactionID(props.existingTransactionID)
      }
      setTimeout(() => {
        props.assignSearchTransactionID && props.assignSearchTransactionID(rowData.transaction, rowData.facilityId)
      }, 1000)
      props.resetDataEntryState && props.resetDataEntryState();
      setSearchText("");
      props.onChangeDataEntryTab && props.onChangeDataEntryTab(1);
    }
    props.resetRequestContext && props.resetRequestContext();
    props.getTransactionSearchResultsReset && props.getTransactionSearchResultsReset();
  }

  const logOut = () => {
    if (!props.onExitTransaction || props.onExitTransaction()) {
      props.clearPreviousSearch();
      if (props.userState.userAttributes === null)
        history?.push('/')
      else
        props.logoutUser({ userId: props.userState.userAttributes.id, redirect: () => history.push('/') })

      props.resetDataEntryState && props.resetDataEntryState();

    }
  }

  const onReturnToSearch = () => {
    let organizationId = props.transactionId?.split('-')?.[0];
    let requestBufferId = props.transactionId?.split('-')?.[1];
    props.returnToSearch && props.returnToSearch(organizationId && parseInt(organizationId), requestBufferId && parseInt(requestBufferId), props.previousSearchParameters, props.previousSearchPage);
  }

  useEffect(() => {
    if (props.searchSelectedTransactionId) {
      props.getTransactionIdSuccess && props.getTransactionIdSuccess(props.searchSelectedTransactionId)
      props.goToTransactionDetails && props.goToTransactionDetails()
      props.clearSelectedSerachTransaction && props.clearSelectedSerachTransaction()
    }
  }, [props.searchSelectedTransactionId])

  function createData(transaction, lastName, firstName, dob, receivedDate, entryDate, requestorName, facilityId, caseNumber, isReadOnly, patientComments, status) {
    return { transaction, lastName, firstName, dob, receivedDate, entryDate, requestorName, facilityId, caseNumber, isReadOnly, patientComments, status};
  }

  const headCellsSearch: { id: string, label: string }[] = [
    { id: 'transaction', label: 'TRANSACTION#' },
    { id: 'lastName', label: 'LAST NAME' },
    { id: 'firstName', label: 'FIRST NAME' },
    { id: 'dob', label: 'DOB' },
    { id: 'receivedDate', label: 'RECEIVED DATE' },
    { id: 'entryDate', label: 'ENTRY DATE' },
    { id: 'requestorName', label: 'REQUESTOR NAME' },
    { id: 'caseNumber', label: 'CASE NUMBER' },
    { id: 'status', label: 'STATUS' }
  ];

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (!props.transactionSearchResults?.length) { 
      setTableData([]) 
    }

    setTableData(props.transactionSearchResults.map(item => {
      return createData(item.transactionID, item.patientLastName, item.patientFirstName, getMomentDate(item.patientDOB), getMomentDate(item.receivedDate), getMomentDate(item.resDataEntryDate), item.requestorName, item.organizationID, item.caseNumber, item.isReadOnly ? "Yes" : "No", item.patientComments, item.status)
    }))
  }, [props.transactionSearchResults])


  const CustomToggle = React.forwardRef(({ children, onClick }: any, ref) => (
    <a
      href="#!"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >

      {props.userState.userID ?
        <div className='userIcon'>
          {props.userPersonalInfo.firstName && <p className='userInitials'>{`${props.userPersonalInfo.firstName[0]}${props.userPersonalInfo.lastName[0]}`}</p>}
        </div>
        :
        <div className='userIcon'> </div>
      }
      {children}
    </a>
  ));


  const CustomHelpToggle = React.forwardRef(({ children, onClick }: any, ref) => (
    <a
      href="#!"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <img
        className="roundIcon"
        src={QuestionMark}
        alt="Logo"
      />

      {children}
    </a>
  ));


  function startHeartbeat() {
    const heartbeatId = parseInt(sessionStorage.getItem("heartbeatID") || "");
    if (isNaN(heartbeatId)) {
      const timeout = 1 * 60000;
      const id = setInterval(async function () {
        try {
        const response = await props.callHeartbeat({ userId: props.userState.userID });
        const status = response.status;
        if (status != 200) {
          endHeartbeat();
          props.logoutUser({ userId: props.userState.userID, redirect: () => history.push('/') })
        }
      } catch (error) {
        endHeartbeat();
        sessionStorage.clear();
        sessionStorage.removeItem("userID");
        sessionStorage.removeItem("userAttributes");
        sessionStorage.removeItem("featureFlags");
        history?.push('/');
      }
      }, timeout);
      sessionStorage.setItem("heartbeatID", id.toString());
    }
  }

  function endHeartbeat() {
    const heartbeatId = parseInt(sessionStorage.getItem("heartbeatID") || "");
    if (!isNaN(heartbeatId)) {
      clearInterval(heartbeatId);
    }
  }

  function GetNextQATransactionClick() {
    props.resetDataEntryState && props.resetDataEntryState();
    props.changeRequestContext(8);
    props.getNextQATransactionCall();
  }

  function onAdvancedSearchClick(event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) {
    if (!(props.onExitTransaction?.() ?? true)) {
      event.preventDefault();
    }
  }

  return (
    <Fragment>
      {/* Header first row for logo, search, theme toggle and user icon */}
      <ReadOnlyTransaction
        getAssessmentResults = {props.getAssessmentResults}
        getQAFeatureFlagsRequest = {props.getQAFeatureFlagsRequest}
        reviewEntities = {props.reviewEntities}
        getReviewEntities = {props.getReviewEntities}
        getQAErrors = {props.getQAErrors}
        getTransactionDocuments = {props.getTransactionDocuments}
      />
      {/* <TimeoutWarning logout={logOut} /> */}
      <AutoLogoutTimeOut
        logoutUser={logOut}
      />
      <div className="common-row-level-margin" id="header-row" style={{alignItems: "center", display: "flex", flexWrap: "nowrap", padding: "20px 15px 0 15px"}}>
        <div style={{ flexGrow: "1" }}>
          <Link className="logo-button" onClick={() => exitTransaction()} to="#">
            <img src={VerismaLogo} style={{width: "153px", height: "42px"}} alt="Logo" />
          </Link>
        </div>
        <div style={{ flexShrink: "1" }}>
          <div>
            <Form inline onSubmit={e => e.preventDefault()}>

              <div className="Oval-text-box" title={allowReadOnly ? "Click this toggle to search pending only (faster)" : "Click this toggle to search across all statuses (slower)"}>
                <div className="sa-btn-circle" onClick={onAllowReadOnlyChange}>
                  <div className="sa-toggler">
                    <div className={"sa-circle" + (allowReadOnly ? "" : " switched")}></div>
                    <div className="sa-off">&nbsp;</div>
                    <div className="sa-on">&nbsp;</div>
                  </div>
                </div>
                <div className="Oval-text-box-inner">
                  <input
                    type="text"
                    id="Search"
                    className=""
                    placeholder={allowReadOnly ? "Search all statuses..." : "Search pending only..."}
                    onChange={(e) => setSearchText(e.target.value)}
                    value={searchText}
                  ></input>

                </div>
                <button className="btn search-btn" onClick={() => onClickSearch()}>
                  Search
                </button>

              </div>

            </Form>
          </div>
          <div>
            {searchErrorMessage.length > 0 ?
              <div className="searchErrorMsg" style={{ 'marginLeft': "50px", position: "absolute" }}>{searchErrorMessage}</div>
              : null
            }  
          </div>
        </div>
        <a href={ApiUrl() + "auth/advancedsearch?userId=" + global.userID} style={{marginLeft: "21px", textDecoration: "none"}} onClick={onAdvancedSearchClick}>
            <img src={magnifying_glass_small_outline} style={{marginRight: "5px"}} />
            <span style={{textDecoration: "underline", color: "#fff", fontSize: "12px", lineHeight: "12px", fontWeight: 600}}>Advanced Search</span>
        </a>
        {props.finalQaFeature == "true" && <div style={{ flexShrink: "1" }}>
          <button className="btn" type="button" onClick={() => GetNextQATransactionClick()} style={{ marginLeft: "21px", height: "40px" }}>
            <img src={urgent_outline_large} style={{ marginRight: "5px" }} />
            Final QA
          </button>
        </div>}
        <div style={{ flexShrink: "1" }}>
          <div className="dm-btn-circle" onClick={onValueChange}>
            <div className="dm-toggler">
              <div className="dm-circle"></div>
              <img className='day-logo' src={DayIcon} alt={'Day Mode'} />
              <img className='night-logo' src={NightIcon} alt={'Night Mode'} />
            </div>
          </div>
        </div>
        <div style={{ flexShrink: "1" }}>
          <Navbar>
          <Nav className="ml-auto">
          <Dropdown>
            <Dropdown.Toggle as={CustomHelpToggle} id="dropdown-custom-components" >
            </Dropdown.Toggle>
            <Dropdown.Menu align={"right"}>
              <Dropdown.Item onClick={() => window.open("https://verismahelpdesk.blob.core.windows.net/public-share/WPSInstall.exe")}><a href="#!">Install Web Print/Scan App</a></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </Nav>
          </Navbar>
        </div>
        <div style={{ flexShrink: "1", paddingLeft: "10px" }}>
          <Navbar>
            <Nav className="ml-auto">
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            </Dropdown.Toggle>
                <Dropdown.Menu align={"right"}>
              <Dropdown.Item onClick={logOut}><a href="#!">Log Out</a></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </Nav>
          </Navbar>
        </div>
      </div>

      {showSearchGrid ?
        <NormalTable
          onTableRowClickHandler={onSearchTableRowClickHandler}
          setGrid={setSearchGrid}
          rows={tableData}
          headCells={headCellsSearch}
          addNewText=""
          defaultSortCol="patientName"
          transactionSearchResultsStatus={props.transactionSearchResultsStatus}
          cssName="table-backdrop"
          searchTablecss="search-table"
          headerSearch={true}
        />
        : null}

      {/* Header second row for page heading and breadcrumb */}
     
      <Row className="common-row-level-margin" style={{marginLeft: "14%"}}>
      
        <Col className={props.headerClassName}>
          <Navbar>
            <APIErrorPopUp />
            <ErrorMessagePopup />
            <InformationPrompt assignAPIInfo={props.assignAPIInfo} />
            <Dropdown>
              <Row>
                <Col style={{ paddingRight: 0 }}>
                  {/* <img src={DownArrow} alt='Down Arrow'></img> */}
                </Col>
              </Row>
            </Dropdown>
            <Navbar.Collapse className="justify-content-end">
              {props.previousSearchParameters && !props.readOnlyTransactionDetails && <div style={{flexGrow: 1}}>
                <button
                  onClick={() => onReturnToSearch()}
                  className="btn"
                  type="button"
                  style={{ marginRight: "auto", height: "40px" }}
                >
                  <img src={returnOutlineLarge} style={{ marginRight: "5px" }} />
                  Back to Search Results
                </button>
              </div>}
              {props.name === 'Data Entry' &&
                <Breadcrumb className="header-breadcrumb d-lg-block d-none">
                  <Breadcrumb.Item onClick={() => exitTransaction()}>
                    <img className="homeIcon" src={HomeIcon} alt="Home" />
                  </Breadcrumb.Item>
                    {props.homeText.map((homeText, index) => (
                      <Breadcrumb.Item onClick={() => exitTransaction(index)}>
                        <span className="breadcrumb-sub-item">
                          {homeText}
                          <i className="arrow right"></i>
                        </span>
                      </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
              }
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
    </Fragment>
  );
}


function mapStateToProps(state: any) {
  return {
    isDarkModeEnabled: state.headerState.isDarkModeEnabled,
    theme: state.headerState.theme,
    alertAPIFailure: state.dataEntryState?.alertAPIFailure,
    transactionSearchResults: state.landingPageState.transactionSearchResults,
    selectedHealthOrganizationDetails: state.landingPageState.selectedHealthOrganizationDetails,
    selectedFacilityDetails: state.landingPageState.selectedFacilityDetails,
    transactionSearchResultsStatus: state.landingPageState.transactionSearchResultsStatus,
    userState: state.loginState,
    searchSelectedTransactionId: state.landingPageState.searchSelectedTransactionId,
    searchSelectedFacilityId: state.landingPageState.searchSelectedFacilityId,
    assignAPIInfo: state.landingPageState.assignAPIInfo,
    existingTransactionID: state.landingPageState.transactionId,
    userPersonalInfo: state.landingPageState.userPersonalInfo,
    getAssessmentResults: state.dataEntryState.getAssessmentResults,
    finalQaFeature: state.loginState.featureFlags.FinalQA,
    previousSearchParameters: state.loginState.previousSearchParameters,
    previousSearchPage: state.loginState.previousSearchPage,
    transactionId: state.landingPageState.transactionId,
    reviewEntities: state.dataEntryState.reviewEntities,
    getQAErrors: state.dataEntryState.getQAErrors,
    readOnlyTransactionDetails: state.landingPageState.readOnlyTransactionDetails,
  };
}

export function mapDispatchToProps(dispatch: Function) {
  return {
    onChangeDarkMode: (data: boolean) => dispatch(onChangeDarkMode(data)),
    resetSelectedHealthOrganizatonDetails: () => dispatch(resetSelectedHealthOrganizatonDetails()),
    getTransactionSearchResultsRequest: (data: any) => dispatch(getTransactionSearchResultsRequest(data)),
    getTransactionSearchResultsReset: () => dispatch(getTransactionSearchResultsReset()),
    getTransactionIdSuccess: (data: any) => dispatch(getTransactionIdSuccess(data)),
    goToTransactionDetails: () => dispatch(push("/data")),
    logoutUser: (action) => dispatch(logoutUserRequest(action)),
    assignSearchTransactionID: (transactionId: string, facilityId: string) => dispatch(headerAssignTransactionActionHeader(transactionId, facilityId)),
    clearSelectedSerachTransaction: () => dispatch(clearSelectedSearchTransaction()),
    unassignSearchTransactionID: (transactionId: string) => dispatch(headerUnassignTransactionIDActionCreator(transactionId)),
    callHeartbeat: (action) => callHeartbeatSaga(action),
    getInitialsRequest: () => dispatch(getInitialsRequest()),
    onChangeDataEntryTab: (data: number) => dispatch(onChangeDataEntryTab(data, false)),
    resetDataEntryState: () => dispatch(resetDataEntryState()),
    showReadOnlyTransaction: (transactionId: string) => dispatch(showReadOnlyTransactionRequest(transactionId)),
    resetRequestContext: () => dispatch(resetRequestContext()),
    clearUserLocks: () => dispatch(clearUserLocksActionRequest()),
    getAssessmentResults:(transactionId: string) => dispatch(getAssessmentResultsActionCreator(transactionId)),
    returnToSearch: (organizationId?: number, requestBufferId?: number, parameters?: string, page?: number) => dispatch(returnToSearchRequest(organizationId, requestBufferId, parameters, page)),
    clearPreviousSearch: () => dispatch(clearPreviousSearch()),
    getReviewEntities:(transactionId: string) => dispatch(getReviewEntitiesActionCreator(transactionId)),
    getQAErrors:() => dispatch(getQAErrorsActionCreator()),
    getTransactionDocuments: (transactionId: string) =>
      dispatch(getTransactionDocuments(transactionId)),
    getNextQATransactionCall: (data: any) => dispatch(getNextQATransaction(data)),
    changeRequestContext: (data: any) => dispatch(updateRequestContext(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
