import React, { useEffect, useState } from 'react';
import './IconWithButton.scss';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {ProceedIcon, SameAsIcon} from '../../assets/images/proceedIcon';

interface IconWithButtonProps {
  onButtonClick: any,
  buttonText: string,
  hideArrowIcon?: boolean,
  imageRequired?: boolean,
  imagePath?: string,
  isDisabled?: boolean,
  addIcon?: boolean,
  isAttachment?: boolean
  isAddFile?: boolean
  isSameAs?: boolean
  isSave?: boolean
  isCloseIcon?: boolean,
  rotateArrow?: number,
  isVisible?: boolean,
  title?: string,
}

function IconWithButton(props: IconWithButtonProps) {
  const returnClassName = (param: string) => {
    switch (param) {
      case "PROCEED":
        return "proceed-icon";
      case "ADD FILES":
        return "add-icon";
      case "ATTACH RECORDS":
        return "attachment-icon";
      case "ATTACH REQUEST":
        return "attachment-icon";
      case "ATTACH INTERNAL":
        return "attachment-icon";
      case "ATTACH AUXILIARY":
        return "attachment-icon";
      case "ATTACH MEDICAL":
        return "attachment-icon";
      case "SAVE":
        return "save-icon";
      case "OK":
        return "save-icon";
      case "YES":
        return "save-icon";
      default:
        return "proceed-icon";
    }
  };

  const [arrowStyle, setArrowStyle] = useState({});

  useEffect(() => {
    if (props.rotateArrow == null)
      setArrowStyle({});
    else
      setArrowStyle({ transform: 'rotate(' + props.rotateArrow.toString() + 'deg)' });
  }, [props.rotateArrow])

  return props.isVisible == null || props.isVisible == true ? (
    <div className="mt-2 icon-with-button">
      <button type="button" onClick={props.onButtonClick} className="btn" style={{ whiteSpace: 'nowrap', display: 'flex' }} disabled={props.isDisabled} title={props.title}>
        {props.imageRequired ? <img src={props.imagePath} alt="img" /> : ""}
        {props.isAddFile ? <span><ControlPointIcon className="add-icon" /></span> : ''}
        {props.isCloseIcon ? <span><HighlightOffIcon className="add-icon" /></span> : ''}
        {props.isSave ? <span><CheckCircleOutlineIcon className="save-icon" /></span> : ''}
        {props.isSameAs ? <span><SameAsIcon color='white' /></span> : ''}
        {props.isAttachment ? <span><AttachFileOutlinedIcon className={returnClassName(props.buttonText.toUpperCase())} /></span> : ''}
        {props.hideArrowIcon ? "" : <span style={{alignSelf: 'center'}}><ProceedIcon color='white' /></span>}
        <span className='text-style save'>{props.buttonText}</span>
      </button>
    </div>
  ) : <></>
}

export default IconWithButton
