//Constants

import { get } from '../../services/Network'
import Api from "../../services/Api";
import { call, put, all } from 'redux-saga/effects'
import moment from 'moment'
import { isSuccessStatusCode, API_INITIAL, API_FETCHING, API_SUCCESS, API_FAILURE, TASK_STATUS_VALUES } from "../../common/constants/ApiConstants";
import {
    getRequestTypes,
    getDisclosingInstituionAPI,
    getTransactionAttributesAPI,
    assignTransactionIdAPI,
    unAssignTransactionAPI,
    getRequestorDetailsAPI,
    getContactDetailsAPI,
    getFeeSchedulesAPI,
    getTransactionNotesAPI,
    getAdditionalChargeTypeAPI,
    getAdditionalChargeRecordsAPI,
    putSendToRequestorAPI,
    updateSendToRequestorAPI,
    updateBillToContactDetailsAPI,
    getRequestorSearchDetailsAPI,
    getTransactionDocumentsAPI,
    getRejectOptionsAPI,
    getShippingServicesAPI,
    getReleaseMethodOptionsAPI,
    patchPatientsNotesAPI,
    patchRequestorNotesAPI,
    patchSendToRequestorContact,
    getContactDetailByIdAPI,
    uploadDocumentAPI,
    uploadBlobDocumentAPI,
    postCancellationReasonsAPI,
    getRequestorBillingDetailsAPI,
    getRequestorBillingDetailsByTxAPI,
    getTransactionNoteTypesAPI,
    updateTransactionNotesAPI,
    updateAdditionalChargesAPI,
    putSendToRequestorContactAPI,
    rejectionContactOverrideAPI,
    getDocumentByIdAPI,
    updateDocumentAPI,
    getDocumentSourcesAPI,
    getRejectionLetterDataAPI,
    getPreviousTransactionAPI,
    deleteDocumentByIdAPI,
    updateBillingPaneFieldsAPI,
    retrieveProtocolAPI,
    getCancellationReasonsAPI,
    getPatientSearchDetailsAPI,
    getSelectedPHICodesAPI,
    getPHICodesAPI,
    createPatientRecordAPI,
    updatePHIdisclosedAPI,
    postRejectionActionAPI,
    getUserGroupsAPI,
    generateNextTransactionIdAPI,
    createUserTaskCommentAPI,
    getDisclosureReasonAPI,
    updateDisclosureInformationAPI,
    getUserTasksAPI,
    getUserTaskCommentsAPI,
    adNewUserTaskAPI,
    updateUserTaskAPI,
    deleteTransactionTaskAPI,
    saveInformationToReleaseAPI,
    completeTransactionAPI,
    patchDetailsCompleteAPI,
    patchRequestorCompleteAPI,
    patchBillingCompleteAPI,
    patchPatientDetailsAPI,
    getAuditTypesAPI,
    getOBOsAPI,
    getValidateAPI,
    getAuditTrailAPI,
    getDuplicatesAPI,
    patchTransactionOnFileAPI,
    postWebPrintScanAPI,
    getTransactionDocumentsOnFileAPI,
    readDocumentDDSBarcodeAPI,
    getFacilitiesAdditionalFieldsAPI,
    getWaitAndHoldReasonsAPI,
    postWaitAndHoldReasonsAPI,
    patchTransactionContinueAPI,
    putAuditDigitalFaxValidationAPI,
    putAuditTransactionSensitiveInfoAPI,
    getStatusIndicatorAPI,
    putUserViewDocumentAPI,
    returnToQueueAPI,
    getAssessmentResultsAPI,
    getQAErrorsAPI,
    getQAIssuesAPI,
    getQAPDIssuesAPI,
    postQATransactionIssueAPI,
    resolveIssueAPI,
    removeIssueAPI,
    getAIFeedbackAPI,
    submitAIFeedbackAPI,
    deleteAIFeedbackAPI,
    getReviewEntitiesAPI,
    getAISensitivityTypesAPI,
    createAIReviewEntityAPI,
    uploadUpdateBlobDocumentAPI,
    uploadUpdateDocumentAPI
} from './test/DataEntry.APIs';
import {
    pickerHelperFunction, generateSearchResponseObject, generateContactSearchReponseObject,
    generatePickerOptions,
    generateRejectLetterResponseObject,
    pickerHelperFeeScheduleFunction,generatePickerOptionsNoDefault, compareDDSFields
} from '../../common/utils/appUtils';
import {
    generatePickerOptionsNew,
    updateUserTaskDetailsHelperFunction,
    updateUserTaskCommentsHelperFunction,
    sortDateObject,
    getSortedTasks
} from '../../common/utils/reduxUtils';

import { normalizeData } from '../../common/utils/appUtils';
import { push, goBack } from '../../redux/navigation/navigationDucks';
import { LandingPageActionConstants, clearUserLocksSaga } from '../landingPage/LandingPageDucks';

import {BillingFormconstants} from './dataEntryComponents/DataEntryFieldsConstants';
import { stringify } from 'querystring';
import DataEntry from '../../common/tabComponents/DataEntry';

export const DataEntryActionConstants = {
    onChangeTab: "onChangeTab/DataEntryConstants",
    GET_ASSESSMENT_RESULTS: "GET_ASSESSMENT_RESULTS",
    GET_ASSESSMENT_RESULTS_SUCCESS: "GET_ASSESSMENT_RESULTS_SUCCESS",
    GET_ASSESSMENT_RESULTS_FAILURE: "GET_ASSESSMENT_RESULTS_FAILURE",
    GET_REVIEW_ENTITIES: "GET_REVIEW_ENTITIES",
    GET_REVIEW_ENTITIES_SUCCESS: "GET_REVIEW_ENTITIES_SUCCESS",
    GET_REVIEW_ENTITIES_FAILURE: "GET_REVIEW_ENTITIES_FAILURE",
    getRequestTypesAndPurposesRequest: "getRequestTypesAndPurposesRequest/DataEntryDetails",
    getRequestTypesAndPurposesSuccess: "getRequestTypesAndPurposesSuccess/DataEntryDetails",
    getRequestTypesAndPurposesFailure: "getRequestTypesAndPurposesFailure/DataEntryActionConstants",
    GET_TRANSACTIONS_ATTRIBUTES: 'GET_TRANSACTIONS_ATTRIBUTES',
    GET_TRANSACTIONS_ATTRIBUTES_SAGA: 'GET_TRANSACTIONS_ATTRIBUTES_SAGA',
    GET_FEE_SCHEDULE_DATA: 'GET_FEE_SCHEDULE_DATA',
    ASSIGN_TRANSACTION_ID: 'ASSIGN_TRANSACTION_ID',
    ASSIGN_TRANSACTION_ID_SAGA: 'ASSIGN_TRANSACTION_ID_SAGA',
    GET_TRANSACTIONS_PATIENT_ATTRIBUTES: 'GET_TRANSACTIONS_PATIENT_ATTRIBUTES',
    getTransactionDetailsSuccess: "getTransactionDetailsSuccess/DataEntryDetails",
    getDefaultFeeSchedule: "getDefaultFeeSchedule",
    getFacilityDiscloseInstitutionsSuccess: "getFacilityDiscloseInstitutionSuccess/DataEntryDetails",
    getFacilityDiscloseInstitutionsFailure: "getFacilityDiscloseInstitutionsFailure/DataEntryDetails",
    getFeeScheduleFailure: "getFeeScheduleFailure/DataEntryDetails",
    getFacilityDiscloseInstitutionsRequest: "getFacilityDiscloseInstitutionsRequest/DataEntryDetails",
    UNASSIGN_TRANSACTION_ID: 'UNASSIGN_TRANSACTION_ID',
    UNASSIGN_TRANSACTION_ID_SAGA: 'UNASSIGN_TRANSACTION_ID_SAGA',
    GET_DOCUMENT_BY_ID_SAGA: 'GET_DOCUMENT_BY_ID_SAGA',
    getRequestorDetailsSuccess: "getRequestorDetailsSuccess/DataEntryDetails",
    getContactDetailsSuccess: "getContactDetailsSuccess/DataEntryDetails",
    updatePatientDetails: "updatePatientDetails/DataEntryDetails",
    updateRequestorDetails: "updateRequestorDetails/DataEntryDetails",
    updateTransactionDetails: "updateTransactionDetails/DataEntryDetails",
    GET_FEE_SCHEDULE_SUCCESS: "GET_fEE_SCHEDULE_SUCCESS/DATAENTRYDETAILS",
    getTransactionNotesRequest: "getTransactionNotesRequest/DataEntryDetails",
    getTransactionNotesSuccess: "getTransactionNotesSuccess/DataEntryDetails",
    getTransactionNotesFailure: "getTransactionNotesFailure/DataEntryDetails",
    getAdditionalChargeType: "getAdditionalChargeType",
    getAdditionalChargeTypeSuccess: "GET_ADDITIONAL_CHARGE_TYPE_SUCCESS",
    getAdditionalChargeTypeFailure: "getAdditionalChargeTypeFailure/DataEntryDetails",
    getAdditionalChargeRecords: "getAdditionalChargeRecords",
    getAdditionalChargeRecordsSuccess: "GET_ADDITIONAL_CHARGE_RECORDS_SUCCESS",
    getAdditionalChargeRecordsFailure: "GET_ADDITIONAL_CHARGE_RECORDS_FAILURE",
    SEND_TO_REQUESTOR_DATA: 'SEND_TO_REQUESTOR_DATA',
    SEND_TO_REQUESTOR_DATA_SAGA: 'SEND_TO_REQUESTOR_DATA_SAGA',
    SEND_TO_REQUESTOR_CONTACT_DATA_SAGA: 'SEND_TO_REQUESTOR_CONTACT_DATA_SAGA',
    SEND_TO_REQUESTOR_CONTACT_DATA: 'SEND_TO_REQUESTOR_CONTACT_DATA',
    UPDATE_SEND_TO_REQUESTOR_DATA: 'UPDATE_SEND_TO_REQUESTOR_DATA',
    UPDATE_SEND_TO_REQUESTOR_DATA_SAGA: 'UPDATE_SEND_TO_REQUESTOR_DATA_SAGA',
    updateBillToRequestorRequest: "updateBillToRequestorRequest/DataEntryDetails",
    updateBillToRequestorSuccess: "updateBillToRequestorSuccess/DataEntryDetails",
    updateBillToRequestorFailure: "updateBillToRequestorFailure/DataEntryDetails",
    updateDeliveryInstructionsStatus: "updateDeliveryInstructionsStatus",
    getRequestorSearchDetailsSuccess: "getRequestorSearchDetailsSuccess/DataEntryDetails",
    getRequestorSearchDetailsFailure: "getRequestorSearchDetailsFailure/DataEntryDetails",
    getRequestorSearchDetailsSAGA: "getRequestorSearchDetailsSAGA/DataEntryDetails",
    getRejectOptionsRequest: "getRejectOptionsRequest/DataEntryDetails",
    getRejectOptionsSuccess: "getRejectOptionsSuccess/DataEntryDetails",
    getRejectOptionsFailure: "getRejectOptionsFailure/DataEntryDetails",
    getContactSeacrhDetailsReset: "getContactSeacrhDetailsReset/DataEntryDetails",
    getContactSeacrhDetailsSuccess: "getContactSeacrhDetailsSuccess/DataEntryDetails",
    getContactSearchDetailsFailure: "getContactSearchDetailsFailure/DataEntryDetails",
    getContactSearchDetailsSAGA: "getContactSearchDetailsSAGA/DataEntryDetails",
    getTransactionDocumentsRequest: "getTransactionDocumentsRequest/DataEntryDetails",
    getTransactionDocumentsSuccess: "getTransactionDocumentsSuccess/DataEntryDetails",
    getTransactionDocumentsFailure: "getTransactionDocumentsFailure/DataEntryDetails",
    GET_SHIPPING_SERVICE_DATA: "getShippingServicesData",
    getShippingServicesSuccess: "GET_SHIPPING_SERVICES_SUCCESS",
    getShippingServicesFailure: "GET_SHIPPING_SERVICES_FAILURE",
    getReleaseMethodOptionsData: 'GET_RELEASE_METHOD_OPTIONS_DATA',
    getReleaseMethodOptionsSuccess: "GET_RELEASE_METHOD_OPTIONS_SUCCESS",
    getReleaseMethodOptionsFailure: "GET_RELEASE_METHOD_OPTIONS_FAILURE",
    patchPatientsNotesSagas: "patchPatientsNotesSagas/DataEntryDetails",
    patchRequestorsNotesSagas: "patchRequestorsNotesSagas/DataEntryDetails",
    patchSendToRequestorContactSagas: "patchSendToRequestorContactSagas/DataEntryDetails",
    uploadDocumentRequest: "uploadDocumentRequest/DataEntryDetails",
    uploadDocumentSuccess: "uploadDocumentSuccess/DataEntryDetails",
    uploadDocumentFailure: "uploadDocumentFailure/DataEntryDetails",
    uploadDocumentInitial: "uploadDocumentInitial/DataEntryDetails",
    getRequestorBillingDetailsSuccess: "GET_REQUESTOR_BILLING_DETAILS_SUCCESS",
    getTransactionNoteTypesRequest: "getTransactionNoteTypesRequest/DataEntryDetails",
    getTransactionNoteTypesSuccess: "getTransactionNoteTypesSuccess/DataEntryDetails",
    updateTransactionNotes: "updateTransactionNotes/DataEntryDetails",
    UPDATE_ADDITIONAL_CHARGES: 'UPDATE_ADDITIONAL_CHARGES',
    UPDATE_ADDITIONAL_CHARGES_SAGA: 'UPDATE_ADDITIONAL_CHARGES_SAGA',
    uploadMultipleDocumentsRequest: "uploadMultipleDocumentsRequest/DataEntryDetails",
    rejectionContactOverrideRequest: "rejectionContactOverrideRequest/DataEntryDetails",
    getDocumentByIdSuccess: "getDocumentByIdSuccess",
    getDocumentByIdFailure: "getDocumentByIdFailure",
    resetState: "resetState/DataEntryDetails",
    updateDocumentRequest: "updateDocumentRequest/DataEntryDetails",
    getDocumentSourcesRequest: "getDocumentSourcesRequest/DataEntryDetails",
    getDocumentSourcesSuccess: "getDocumentSourcesSuccess/DataEntryDetails",
    getRejectionLetterDataAPISuccess: "getRejectionLetterDataAPISuccess/DataEntryDetails",
    getRejectionLetterDataAPIInitial: "getRejectionLetterDataAPIInitial/DataEntryDetails",
    getRejectionLetterSagas: "getRejectionLetterSagas/DataEntryDetails",
    getPreviousTransactionAPISuccess: "getPreviousTransactionAPISuccess/DataEntryDetails",
    getPreviousTransactionAPIFailure: "getPreviousTransactionAPIFailure/DataEntryDetails",
    getPreviousTransactionSagas: "getPreviousTransactionSagas/DataEntryDetails",
    deleteDocument: 'deleteDocumentById',
    deleteDocumentAPIInitial: 'deleteDocumentAPIInitial/DataEntryDetails',
    deleteDocumentAPISuccess: 'deleteDocumentAPISuccess/DataEntryDetails',
    deleteDocumentAPIFailure: 'deleteDocumentAPIFailure/DataEntryDetails',
    updateBillingPaneFields: "updateBillingPaneFields/DataEntryDetails",
    GET_NEXT_TRANSACTION_REQUEST: "GET_NEXT_TRANSACTION_REQUEST",
    GET_NEXT_TRANSACTION_REQUEST_SAGA: "GET_NEXT_TRANSACTION_REQUEST_SAGA",
    SET_NEXT_TRANSACTION_NULL: "SET_NEXT_TRANSACTION_NULL",
    SET_LAST_USED_REQUESTOR: "SET_LAST_USED_REQUESTOR",
    getRetrieveProtocolRequest: "getRetrieveProtocolRequest/DataEntryDetails",
    GET_SELECTED_PHI_CODES_SAGA: "getSelectedPHICodesSaga/DataEntryDetails",
    getSelectedPHICodesSuccess: "getSelectedPHICodesAPISuccess",
    getSelectedPHICodesFailure: "getSelectedPHICodesAPIFailure",
    getRetrieveProtocolSuccess: "getRetrieveProtocolSuccess/DataEntryDetails",
    getRetrieveProtocolFailure: "getRetrieveProtocolFailure/DataEntryDetails",
    getCancellationReasons: "GET_CANCELLATION_REASONS_SAGA",
    getCancellationReasonsSuccess: "getCancellationReasonsSuccess",
    getCancellationReasonsFailure: "getCancellationReasonsFailure",
    postCancellationReasonsSuccess: "postCancellationReasonsSuccess/DataEntryDetails",
    postCancellationReasonsRequest: "postCancellationReasonsRequest/DataEntryDetails",
    GET_PATIENT_SEARCH_DETAILS: "GET_PATIENT_SEARCH_DETAILS",
    GET_PATIENT_SEARCH_DETAILS_FAILURE: "GET_PATIENT_SEARCH_DETAILS_FAILURE",
    GET_PATIENT_SEARCH_DETAILS_SAGA: "GET_PATIENT_SEARCH_DETAILS_SAGA",
    getPHICodesRequest: "getPHICoesRequest/DataEntryDetails",
    getPHICodesSuccess: "getPHICodesSuccess/DaetaEntryDetails",
    createPatientRecordRequest: "createPatientRecordRequest/DataEntryDetails",
    createPatientRecordSuccess: "createPatientRecordSuccess/DataEntryDetails",
    UPDATE_PHI_DISCLOSED_SAGA: "UPDATE_PHI_DISCLOSED_SAGA",
    UPDATE_PHI_DISCLOSED_SUCCESS: "UPDATE_PHI_DISCLOSED_SUCCESS",
    UPDATE_PHI_DISCLOSED_FAILURE: "UPDATE_PHI_DISCLOSED_FAILURE",
    POST_REJECTION_ACTION: "POST_REJECTION_ACTION",
    POST_REJECTION_ACTION_FAILURE: "POST_REJECTION_ACTION_FAILURE",
    POST_REJECTION_ACTION_SAGA: "POST_REJECTION_ACTION_SAGA",
    getUserGroupsRequest: "getUserGroupsRequest/DataEntryDetails",
    getUserGroupsSuccess: "getUserGroupsSuccess/DataEntryDetails",
    getUserGroupsFailure: "getUserGroupsFailure/DataEntryDetails",
    GET_DISCLOSURE_REASON: "GET_DISCLOSURE_REASON",
    GET_DISCLOSURE_REASON_SAGA: 'GET_DISCLOSURE_REASON_SAGA',
    updateFormSyncErrors: "updateFormSyncErrors/DataEntryDetails",
    UPDATE_DISCLOSURE_INFORMATION: 'UPDATE_DISCLOSURE_INFORMATION',
    UPDATE_DISCLOSURE_INFORMATION_SAGA: 'UPDATE_DISCLOSURE_INFORMATION_SAGA',
    getUserTasksRequest: "getUserTasksRequest/DataEntryDetails",
    getUserTasksSuccess: "getUserTasksSuccess/DataEntryDetails",
    getUserTasksFailure: "getUserTassksFailure/DataEntryDetails",
    updateUserTasksList: "updateUserTasksList/DataEntryDetails",
    updateUserTaskComments: "updateUserTaskComments/DataEntryDetails",
    createUserTaskComment: "createUserTaskComment/DataEntryDetails",
    addNewUserTaskRequest: "addNewUserTaskRequest/DataEntryDetails",
    updateUserTask: "updateUserTask/DataEntryDetails",
    DELETE_TRANSACTION_TASK: "DELETE_TRANSACTION_TASK",
    DELETE_TRANSACTION_TASK_SAGA: "DELETE_TRANSACTION_TASK_SAGA",
    API_ERROR_STATE: "API_ERROR_STATE",
    HIDE_API_ERROR_MESSAGE: "HIDE_API_ERROR_MESSAGE",
    POPUP_ERROR_STATE: "POPUP_ERROR_STATE",
    HIDE_POPUP_ERROR_MESSAGE: "HIDE_POPUP_ERROR_MESSAGE",
    saveInformationToReleaseRequest: "saveInformationToRelease/DataEntryDetails",
    saveInformationToReleaseResponse: "saveInformationToReleaseResponse/DataEntryDetails",
    COMPLETE_TRANSACTION_REQUEST: "COMPLETE_TRANSACTION_REQUEST",
    COMPLETE_TRANSACTION_REQUEST_SAGA: "COMPLETE_TRANSACTION_REQUEST_SAGA",
    RETURN_TO_QUEUE_SAGA: "RETURN_TO_QUEUE_SAGA",
    getRequestorBillingDetails: "GET BILL_TO_REQUESTOR_DETAILS",
    getRequestorBillingDetailsFailure: "getRequestorBillingDetailsFailure",
    DETAILS_COMPLETE_SAGA: "DETAILS_COMPLETE_SAGA",
    REQUESTOR_COMPLETE_SAGA: "REQUESTOR_COMPLETE_SAGA",
    BILLING_COMPLETE_SAGA: "BILLING_COMPLETE_SAGA",
    patchPatientDetails: "patchPatientDetails/DataEntryDetails",
    getAuditTypesRequest: "getAuditTypesRequest/DataEntryDetails",
    getAuditTypesSuccess: "getAuditTypesSuccess/DataEntryDetails",
    getAuditTypesFailure: "getAuditTypesFailure/DataEntryActionConstants",
    getOBOsRequest: "getOBOsRequest/DataEntryDetails",
    getOBOsSuccess: "getOBOsSuccess/DataEntryDetails",
    getOBOsFailure: "getOBOsFailure/DataEntryActionConstants",
    getValidateRequest: "GET_VALIDATE_REQUEST",
    getValidateSuccess: "GET_VALIDATE_SUCCESS",
    getValidateFailure: "GET_VALIDATE_FAILURE",
    requestorVerificationEmailSent: "isRequestorVerificationEmailSent/DataEntryDetails",
    requestorVerificationEmailSentReset: "isRequestorVerificationEmailSentReset/DataEntryDetails",
    getAuditTrailRequest: "GET_AUDIT_TRAIL_REQUEST",
    getAuditTrailResponse: "GET_AUDIT_TRAIL_RESPONSE",
    getStatusIndicatorRequest: "GET_STATUS_INDICATOR_REQUEST",
    getStatusIndicatorResponse: "GET_STATUS_INDICATOR_RESPONSE",
    GET_DUPLICATE_TRANSACTIONS: "GET_DUPLICATE_TRANSACTIONS",
    SHOW_DUPLICATE_TRANSACTIONS: "SHOW_DUPLICATE_TRANSACTIONS",
    HIDE_DUPLICATE_TRANSACTIONS: "HIDE_DUPLICATE_TRANSACTIONS",
    getTransactionDocumentsToCopySuccess: "getTransactionDocumentsToCopySuccess/DataEntryDetails",
    getTransactionDocumentsToCopyRequest: "getTransactionDocumentsToCopyRequest/DataEntryDetails",
    resetTransactionDocumentsToCopyRequest: "resetTransactionDocumentsToCopyRequest/DataEntryDetails",
    updateTransactionOnFileRequest: "updateTransactionOnFileRequest/DataEntryDetails",
    patchTransactionOnFileSaga: "patchTransactionOnFileSaga/DataEntryDetails",
    patchTransactionOnFileSuccess: "patchTransactionOnFileSuccess/DataEntryDetails",
    patchTransactionOnFileFailure: "patchTransactionOnFileFailure/DataEntryDetails",
    postWPSRequest: "GET_WPS_REQUEST",
    postWPSResponse: "GET_WPS_RESPONSE",
    clearPreviousTransactionOnComplete: "CLEAR_PREVIOUS_TRANSACTION_ON_COMPLETE",
    readBarCodeDDSRequest: "readBarCodeDDSRequest/DataEntryDetails",
    readBarCodeDDSSuccess: "readBarCodeDDSSuccess/DataEntryDetails",
    readBarCodeDDSFailure: "readBarCodeDDSFailure/DataEntryActionConstants",
    initialTxCountIncrement: "initialTxCountIncrement/DataEntryDetails",
    getFacilityAdditionalFieldsSuccess: "getFacilityAdditionalFieldsSuccess/DataEntryDetails",
    getFacilityAdditionalFieldsFailure: "getFacilityAdditionalFieldsFailure/DataEntryDetails",
    getFacilityAdditionalFieldsRequest: "getFacilityAdditionalFieldsRequest/DataEntryDetails",
    getWaitAndHoldReasonsSuccess: "getWaitAndHoldReasonsSuccess",
    getWaitAndHoldReasonsFailure: "getWaitAndHoldReasonsFailure",
    getWaitAndHoldReasons: "GET_WAIT_HOLD_REASONS_SAGA",
    postWaitAndHoldReasonsRequest: "postWaitAndHoldReasonsRequest/DataEntryDetails",
    postWaitAndHoldReasonsSuccess: "postWaitAndHoldReasonsSuccess/DataEntryDetails",
    patchTransactionContinueRequest: "patchTransactionContinueRequest/DataEntryDetails",
    patchTransactionContinueSuccess: "patchTransactionContinueSuccess/DataEntryDetails",
    updateClinicIDResponse: "updateClinicIDResponse",
    userInputUpdateDataEntryDetailRequestorContact: "userInputUpdateRequestorContact/DataEntryDetails",
    putDigitalFaxValidationSuccess: "putDigitalFaxValidationSuccess/DataEntryDetails",
    putDigitalFaxValidationRequest: "putDigitalFaxValidationRequest/DataEntryDetails",
    putAuditTransactionSensitiveInfoSuccess: "putAuditTransactionSensitiveInfoSuccess/DataEntryDetails",
    putAuditTransactionSensitiveInfoRequest: "putAuditTransactionSensitiveInfoRequest/DataEntryDetails",
    userViewedDocumentRequest: "audit/userViewedDocument/Request",
    userViewedDocumentSucess: "audit/userViewedDocument/Sucess",
    userViewedDocumentFailure: "audit/userViewedDocument/Failure",
    setConfirmFax: "save/ComfirmFaxNumber",
    GET_QA_ERRORS_RESULTS: "GET_QA_ERRORS_RESULTS",
    GET_QA_ERRORS_RESULTS_SUCCESS: "GET_QA_ERRORS_RESULTS_SUCCESS",
    GET_QA_ERRORS_RESULTS_FAILURE: "GET_QA_ERRORS_RESULTS_FAILURE",
    GET_QA_ISSUES_RESULTS: "GET_QA_ISSUES_RESULTS",
    GET_QA_ISSUES_RESULTS_SUCCESS: "GET_QA_ISSUES_SUCCESS",
    GET_QA_ISSUES_RESULTS_FAILURE: "GET_QA_ISSUES_FAILURE",
    GET_QA_PD_ISSUES_RESULTS: "GET_QA_PD_ISSUES_RESULTS",
    GET_QA_PD_ISSUES_RESULTS_SUCCESS: "GET_QA_PD_ISSUES_SUCCESS",
    GET_QA_PD_ISSUES_RESULTS_FAILURE: "GET_QA_PD_ISSUES_FAILURE",
    GET_SENSITIVITY_TYPES_RESULTS: "GET_SENSITIVITY_TYPES_RESULTS",
    GET_SENSITIVITY_TYPES_RESULTS_SUCCESS: "GET_SENSITIVITY_TYPES_RESULTS_SUCCESS",
    GET_SENSITIVITY_TYPES_RESULTS_FAILURE: "GET_SENSITIVITY_TYPES_RESULTS_FAILURE",
    postQATransactionIssueSuccess: "POST_QA_TRANSACTION_ISSUE_SUCCESS",
    postQATransactionIssueFailure: "POST_QA_TRANSACTION_ISSUE_FAILURE",
    postQATransactionIssueRequest: "POST_QA_TRANSACTION_ISSUE_REQUEST",
    resolveQATransactionIssueSuccess: "RESOLVE_QA_TRANSACTION_ISSUE_SUCCESS",
    resolveQATransactionIssueFailure: "RESOLVE_QA_TRANSACTION_ISSUE_FAILURE",
    resolveQATransactionIssueRequest: "RESOLVE_QA_TRANSACTION_ISSUE_REQUEST",
    removeQATransactionIssueSuccess: "REMOVE_QA_TRANSACTION_ISSUE_SUCCESS",
    removeQATransactionIssueFailure: "REMOVE_QA_TRANSACTION_ISSUE_FAILURE",
    removeQATransactionIssueRequest: "REMOVE_QA_TRANSACTION_ISSUE_REQUEST",
    SET_PDF_PAGE_NUMBER: "SET_PDF_PAGE_NUMBER",
    GET_AI_FEEDBACK_REQUEST: "GET_AI_FEEDBACK_REQUEST",
    GET_AI_FEEDBACK_SUCCESS: "GET_AI_FEEDBACK_SUCCESS",
    GET_AI_FEEDBACK_FAILURE: "GET_AI_FEEDBACK_FAILURE",
    SUBMIT_AI_FEEDBACK_REQUEST: "SUBMIT_AI_FEEDBACK_REQUEST",
    SUBMIT_AI_FEEDBACK_SUCCESS: "SUBMIT_AI_FEEDBACK_SUCCESS",
    SUBMIT_AI_FEEDBACK_FAILURE: "SUBMIT_AI_FEEDBACK_FAILURE",
    DELETE_AI_FEEDBACK_REQUEST: "DELETE_AI_FEEDBACK_REQUEST",
    DELETE_AI_FEEDBACK_SUCCESS: "DELETE_AI_FEEDBACK_SUCCESS",
    DELETE_AI_FEEDBACK_FAILURE: "DELETE_AI_FEEDBACK_FAILURE",
    createAIReviewEntityRequest: "createAIReviewEntityRequest",
    createAIReviewEntitySuccess: "createAIReviewEntitySuccess",
    createAIReviewEntityFailure: "createAIReviewEntityFailure",
    SET_CURRENT_FILE_TYPE: "SET_CURRENT_FILE_TYPE",
    updateReplaceDocumentRequest: "updateReplaceDocumentRequest",
    updateReplaceDocumentSuccess: "updateReplaceDocumentSuccess",
    updateReplaceDocumentFailure: "updateReplaceDocumentFailure" 
}

const ReduxScope = {
    transactionId: ""
}

// create reducers here
const defaultState = {
    activeTabId: 1,
    proceedBtnClick: false,
    requestTypes: {},
    purposeOfRequests: {},
    getRequestsAndPuposesAPIStatus: API_INITIAL,
    transactionIDAttributes: {},
    isTransactionIdAssigned: false,
    patientDetailsAttributes: {},
    leftPanePatientDetailsAttributes: {},
    transactionDetails: { transactionDds: {}},
    leftPaneTransactionDetails: {},
    discloseInstitutions: [],
    getDiscloseInstitutionsAPIStatus: API_INITIAL,
    requestorDetails: {requestorInfo: {}},
    leftPaneRequestorDetails: {},
    requestorContactDetails: {},
    feeSchedule: [],
    releaseMethod: [],
    requestComments: '',
    releaseMethodAPIStatus: API_INITIAL,
    shippingService: [],
    getShippingServicesAPIStatus: API_INITIAL,
    nextTransactionId: null,
    billingType: [{ label: 'Full Payment', value: '1' },
    { label: 'Courtesy', value: '2' },
    { label: 'Non Billable', value: '3' }],
    transactionNotes: [],
    additionalChargeType: [],
    getTransactionNotesAPIStatus: API_INITIAL,
    getAdditionalChargeTypeAPIStatus: API_INITIAL,
    getAdditionalChargeRecordsAPIStatus: API_INITIAL,
    additionalChargeRecords: {},
    getRequestorSearchDetailsAPIStatus: API_INITIAL,
    getContactSearchDetailsAPIStatus: API_INITIAL,
    requestorSearchDetails: [],
    patientSearchDetails: [],
    rejectOptions: [],
    contactSearchDetails: [],
    uploadDocumentAPIStatus: API_INITIAL,
    uploadDocumentFailure: null,
    postCancellationReasonsAPIStatus: API_INITIAL,
    documentsList: [],
    getTransactionDocumentsAPIStatus: API_INITIAL,
    requestorBillingDetails: {},
    transactionNoteTypes: [],
    createTransactionAPIStatus: 0,
    createdTransactionDetails: {},
    documentFileById: {},
    documentSources: [],
    rejectLetterData: {},
    rejectLetterDataState: API_INITIAL,
    previousTransactionId: {},
    deleteDocumentAPIStatus: API_INITIAL,
    deleteDocumentAPISuccess: '',
    alertAPIFailure: API_INITIAL,
    retrieveProtocolStatus: API_INITIAL,
    retrieveProtocol: [],
    transactionCancellationReasons: [],
    selectedPHICodes: [],
    selectedPHICodesAPIStatus: API_INITIAL,
    phiCodes: [],
    getPHICodesStatus: API_INITIAL,
    getUserGroupsAPIStatus: API_INITIAL,
    userGroups: [],
    disclosureReasons: [],
    formSyncErrors: {},
    userTasks: [],
    getUserTasksStatus: API_INITIAL,
    getAssessmentResultsStatus: API_INITIAL,
    getReviewEntitiesStatus: API_INITIAL,
    userTaskComments: {},
    apiErrorArray: [],
    popupErrorMessageArray: [],
    rejectCompleted: false,
    rightPaneUpdatedPatientDetailsAttributes: {},
    patientDetailsUpdated: false,
    auditTypes: [],
    onBehalfOf: [],
    getAuditTypesAPIStatus: API_INITIAL,
    getOBOsAPIStatus: API_INITIAL,
    requestorVerificationEmailSent: false,
    getValidation: API_INITIAL,
    requestorVerificationEmailPopupTriggered: false,
    getAuditTrailStatus: API_INITIAL,
    auditTrail: [],
    getStatusIndicatorStatus: API_INITIAL,
    duplicateTransactions: [],
    documentsToCopyList: [],
    copyTransactionComplete: false,
    postWPSStatus: API_INITIAL,
    wpsToken: "",
    lastUsedRequestorInfo: {},
    readBarCodeDDSAPIStatus: API_INITIAL,
    readBarCodeDDS: {},
    completeTransactionFinished: false,
    intiaTxlSagaCallCount: 0,
    FacilityAdditionalFields: {},
    FacilityAdditionalFieldsApiStatus: API_INITIAL,
    transactionWaitAndHoldReasons: [],
    updateDeliveryInstructionsStatus: API_INITIAL,
    requestorComplete: false,
    confirmFax: "",
    getAssessmentResults: {},
    getReviewEntities: [],
    getQAErrorsStatus: API_INITIAL,
    getQAErrors: {},
    getQAIssuesStatus: API_INITIAL,
    resolveQAIssueStatus: API_INITIAL,
    removeQAIssueStatus: API_INITIAL,
    getQAIssues: [],
    getQAPDIssuesStatus: API_INITIAL,
    getQAPDIssues: {},
    qaPDIssues: [],
    postQATransactionIssueStatus: API_INITIAL,
    updateReplaceDocumentStatus: API_INITIAL
}

export interface sendToRequestorDataProps {
    transactionID: String;
    requestBody: {
        code?: String;
        requestorname: String;
        firstname: String;
        lastname: String;
        address1: String;
        address2?: String;
        city: String;
        state: String;
        zipcode: String;
        country: String;
        prePay?: Boolean;
        attentionto?: String;
        email: String;
        phone: String;
        fax?: String;
        isverified: Boolean;
        userID: Number;
    }
}
export interface sendToContactDataProps {
    requestorID: String;
    contactBody: {
        firstname: String;
        lastname: String;
        email: String;
        phone: String;
        fax?: String;
        isverified: Boolean;
        userID: Number;
    }
}

export interface updateAdditionalChargesProps {
    transactionID: String;
    requestData: {
        id?: Number;
        glCodeID: Number;
        description: String;
        chargeAmount: Number;
    }
}

export interface qaReviewEntityProps {
    typeId: string;
    documentFilename: string;
    page: number;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    highlightText: string;
    organizationID: number;
    requestBufferID: number;
    userId: Number;
}


export const dataEntryState = (state = defaultState, action: any) => {
    switch (action.type) {
        case DataEntryActionConstants.onChangeTab:
            state.proceedBtnClick = action.tabClick ? false : true;
            return {
                ...state,
                activeTabId: action.data,
                proceedBtnClick: action.tabClick ? false : true
            }
        case DataEntryActionConstants.getRequestTypesAndPurposesRequest:
            return {
                ...state,
                getRequestsAndPuposesAPIStatus: API_FETCHING
            }
        case DataEntryActionConstants.getRequestTypesAndPurposesSuccess:
            return {
                ...state,
                requestTypes: action.data.requestTypes,
                purposeOfRequests: action.data.purposeOfRequests,
                getRequestsAndPuposesAPIStatus: API_SUCCESS
            }
        case DataEntryActionConstants.getRequestTypesAndPurposesFailure:
            return {
                ...state,
                getRequestsAndPuposesAPIStatus: API_FAILURE
            }
        case DataEntryActionConstants.GET_TRANSACTIONS_ATTRIBUTES:
            return {
                ...state,
                transactionIDAttributes: { ...action.data }
            }
        case DataEntryActionConstants.ASSIGN_TRANSACTION_ID:
            return {
                ...state,
                isTransactionIdAssigned: action.data,
                completeTransactionFinished: false
            }
        case DataEntryActionConstants.GET_TRANSACTIONS_PATIENT_ATTRIBUTES:
            return {
                ...state,
                patientDetailsAttributes: { ...action.data },
                leftPanePatientDetailsAttributes: { ...action.data }
            }
        case DataEntryActionConstants.getTransactionDetailsSuccess:
            return {
                ...state,
                transactionDetails: action.data,
                leftPaneTransactionDetails: action.data,
                completeTransactionFinished: false
            }
        case DataEntryActionConstants.getDefaultFeeSchedule:
            return{
                ...state,
                transactionDetails: {
                    ...state.transactionDetails,
                    feeSchedule: action.data
                }
            }
        case DataEntryActionConstants.getFacilityDiscloseInstitutionsSuccess:
            return {
                ...state,
                discloseInstitutions: action.data,
                getDiscloseInstitutionsAPIStatus: API_SUCCESS
            }
        case DataEntryActionConstants.getFacilityDiscloseInstitutionsFailure:
            return {
                ...state,
                getDiscloseInstitutionsAPIStatus: API_FAILURE
            }
        case DataEntryActionConstants.getFacilityDiscloseInstitutionsRequest:
            return {
                ...state,
                getDiscloseInstitutionsAPIStatus: API_FETCHING
            }
        case DataEntryActionConstants.UNASSIGN_TRANSACTION_ID:
            return {
                ...state,
                isTransactionIdAssigned: action.data
            }
        case DataEntryActionConstants.getRequestorDetailsSuccess:
            return {
                ...state,
                requestorDetails: action.data,
                leftPaneRequestorDetails: action.data

            }
        case DataEntryActionConstants.getContactDetailsSuccess:
            return {
                ...state,
                requestorContactDetails: action.data
            }
        case DataEntryActionConstants.updatePatientDetails:
            return {
                ...state,
                leftPanePatientDetailsAttributes: updatePatientDetails({ ...state }, action.data),
                rightPaneUpdatedPatientDetailsAttributes: updatePatientDetailsRightPane({ ...state }, action.data),
                patientDetailsUpdated: true
            }
        case DataEntryActionConstants.updateRequestorDetails:
            return {
                ...state,
                requestorDetails: updateRequestorDetails({ ...state }, action.data)
            }
        case DataEntryActionConstants.updateTransactionDetails:
            return {
                ...state,
                leftPaneTransactionDetails: updateTransactionDetails({ ...state }, action.data)
            }
        case DataEntryActionConstants.GET_FEE_SCHEDULE_SUCCESS:
            return {
                ...state,
                feeSchedule: pickerHelperFeeScheduleFunction(action.data, "name", "id", "billingTypeID")
            }
        case DataEntryActionConstants.getTransactionNotesRequest:
            return {
                ...state,
                getTransactionNotesAPIStatus: API_FETCHING
            }
        case DataEntryActionConstants.getTransactionNotesSuccess:
            return {
                ...state,
                getTransactionNotesAPIStatus: API_SUCCESS,
                transactionNotes: action.data
            }
        case DataEntryActionConstants.getTransactionNotesFailure:
            return {
                ...state,
                getTransactionNotesAPIStatus: API_FAILURE
            }
        case DataEntryActionConstants.getAdditionalChargeTypeSuccess:
            return {
                ...state,
                getAdditionalChargeTypeAPIStatus: API_SUCCESS,
                additionalChargeType: normalizeData(action.data, "id")
            }

        case DataEntryActionConstants.getAdditionalChargeTypeFailure:
            return {
                ...state,
                getAdditionalChargeTypeAPIStatus: API_FAILURE,

            }
        case DataEntryActionConstants.getAdditionalChargeRecordsSuccess:
            return {
                ...state,
                getAdditionalChargeRecordsAPIStatus: API_SUCCESS,
                additionalChargeRecords: normalizeData(action.data, "id")
            }
        case DataEntryActionConstants.getRequestorSearchDetailsSuccess:
            return {
                ...state,
                getRequestorSearchDetailsAPIStatus: API_SUCCESS,
                requestorSearchDetails: generateSearchResponseObject(action.data)
            }
        case DataEntryActionConstants.getRequestorSearchDetailsFailure:
            return {
                ...state,
                getRequestorSearchDetailsAPIStatus: API_FAILURE,
                requestorSearchDetails: action.data
            }
        case DataEntryActionConstants.getRequestorSearchDetailsSAGA:
            return {
                ...state,
                getRequestorSearchDetailsAPIStatus: API_FETCHING
            }
        case DataEntryActionConstants.getContactSeacrhDetailsReset:
            return {
                ...state,
                getContactSearchDetailsAPIStatus: API_INITIAL,
                requestorContactDetails: null,
                contactSearchDetails: []
            }
        case DataEntryActionConstants.getContactSeacrhDetailsSuccess:
            return {
                ...state,
                getContactSearchDetailsAPIStatus: API_SUCCESS,
                contactSearchDetails: generateContactSearchReponseObject(action.data)
            }
        case DataEntryActionConstants.getContactSearchDetailsFailure:
            return {
                ...state,
                getContactSearchDetailsAPIStatus: API_FAILURE,
                contactSearchDetails: action.data
            }
        case DataEntryActionConstants.getContactSearchDetailsSAGA:
            return{
                ... state,
                getContactSearchDetailsAPIStatus: API_FETCHING,
                contactSearchDetails: []
            }
        case DataEntryActionConstants.GET_PATIENT_SEARCH_DETAILS:
            return {
                ...state,
                patientSearchDetails: action.data,
                getPatientSearchResultsAPIStatus: API_SUCCESS
            }
        case DataEntryActionConstants.GET_PATIENT_SEARCH_DETAILS_FAILURE:
            return {
                ...state,
                getPatientSearchResultsAPIStatus: API_FAILURE
            }

        case DataEntryActionConstants.SEND_TO_REQUESTOR_DATA:
            return {
                ...state,
                sendToRequestorDataSuccess: action.data
            }
        case DataEntryActionConstants.SEND_TO_REQUESTOR_CONTACT_DATA:
            return {
                ...state,
                sendToRequestorContactDataSuccess: action.data
            }
        case DataEntryActionConstants.getRejectOptionsSuccess:
            return {
                ...state,
                rejectOptions: generatePickerOptions(action.data, "name", "description", "id")
            }
        case DataEntryActionConstants.getTransactionDocumentsRequest:
            return {
                ...state,
                getTransactionDocumentsAPIStatus: API_FETCHING
            }
        case DataEntryActionConstants.getTransactionDocumentsSuccess:
            return {
                ...state,
                copyTransactionComplete: false,
                getTransactionDocumentsAPIStatus: API_SUCCESS,
                deleteDocumentAPIStatus: API_INITIAL,
                documentsList: action.data.map(item => {
                    const fileSize = item.file?.size?.bytes
                    return {
                        ...item,
                        size: isNaN(fileSize) ? fileSize : (Number(fileSize) * 0.00000095367432).toFixed(2)
                    }
                })
            }
        case DataEntryActionConstants.getTransactionDocumentsFailure:
            return {
                ...state,
                getTransactionDocumentsAPIStatus: API_FAILURE
            }

        case DataEntryActionConstants.UPDATE_SEND_TO_REQUESTOR_DATA:
            return {
                ...state,
                updateSendToRequestorDataSuccess: action.data
            }

        case DataEntryActionConstants.getShippingServicesSuccess:
            return {
                ...state,
                getShippingServicesAPIStatus: API_SUCCESS,
                shippingService: generatePickerOptions(action.data, "name", "name")
            }
        case DataEntryActionConstants.getShippingServicesFailure:
            return {
                ...state,
                getShippingServicesAPIStatus: API_FAILURE,
            }
        case DataEntryActionConstants.getReleaseMethodOptionsSuccess:
            return {
                ...state,
                releaseMethodAPIStatus: API_SUCCESS,
                releaseMethod: pickerHelperFunction(action.data, "name", "id")
            }
        case DataEntryActionConstants.getReleaseMethodOptionsFailure:
            return {
                ...state,
                releaseMethodAPIStatus: API_FAILURE,
            }

        case DataEntryActionConstants.uploadDocumentRequest:
            return {
                ...state,
                uploadDocumentAPIStatus: API_FETCHING,
                uploadDocumentFailure: null,
                uploadDocumentSuccess: null
            }
        case DataEntryActionConstants.uploadDocumentSuccess:
            return {
                ...state,
                uploadDocumentAPIStatus: API_SUCCESS,
                uploadDocumentFailure: null,
                uploadDocumentSuccess: action.data
            }
        case DataEntryActionConstants.uploadDocumentFailure:
            return {
                ...state,
                uploadDocumentAPIStatus: API_FAILURE,
                uploadDocumentFailure: action.data,
                uploadDocumentSuccess: null
            }
        case DataEntryActionConstants.uploadDocumentInitial:
            return {
                ...state,
                uploadDocumentAPIStatus: API_INITIAL,
                uploadDocumentFailure: null,
                uploadDocumentSuccess: null
            }
        case DataEntryActionConstants.postCancellationReasonsSuccess:
            return {
                ...state,
                postCancellationReasonsAPIStatus: API_SUCCESS
            }
        case DataEntryActionConstants.postCancellationReasonsRequest:
            return {
                ...state,
                postCancellationReasonsAPIStatus: API_FETCHING
            }
        case DataEntryActionConstants.getTransactionNoteTypesSuccess:
            return {
                ...state,
                transactionNoteTypes: generatePickerOptionsNew(action.data, "name", "id")
            }
        case DataEntryActionConstants.getRequestorBillingDetailsSuccess:
            if(state.requestorDetails["prePay"])
                action.data.prePay = true;
            return {
                ...state,
                requestorBillingDetails: action.data
            }
        case DataEntryActionConstants.UPDATE_ADDITIONAL_CHARGES:
            return {
                ...state,
                updateAdditionalChargesSuccess: action.data
            }
        case DataEntryActionConstants.GET_NEXT_TRANSACTION_REQUEST:
            return {
                ...defaultState,
                nextTransactionId: action.data,
                completeTransactionFinished: false
            }
        case DataEntryActionConstants.getDocumentByIdSuccess:
            return {
                ...state,
                documentFileById: action.data,
                getDocumentAPIstatus: API_SUCCESS
            }
        case DataEntryActionConstants.getDocumentByIdFailure:
            return {
                ...state,
                getDocumentAPIstatus: API_FAILURE
            }
        case DataEntryActionConstants.resetState:
            return {
                ...defaultState,
                lastUsedRequestorInfo: state.lastUsedRequestorInfo
            }
        case DataEntryActionConstants.getDocumentSourcesSuccess:
            return {
                ...state,
                documentSources: generatePickerOptions(action.data, "name", "id")
            }
        case DataEntryActionConstants.getRejectionLetterDataAPISuccess:
            return {
                ...state,
                rejectLetterData: generateRejectLetterResponseObject(action.data),
                rejectLetterDataState: API_SUCCESS,
                rejectCompleted: false
            }
        case DataEntryActionConstants.getRejectionLetterDataAPIInitial:
            return {
                ...state,
                rejectLetterDataState: API_INITIAL
            }
        case DataEntryActionConstants.getPreviousTransactionAPISuccess:
            return {
                ...state,
                previousTransactionId: action.data
            }
        case DataEntryActionConstants.getPreviousTransactionAPIFailure:
            return {
                ...state,
                alertAPIFailure: API_FAILURE
            }

        case DataEntryActionConstants.getRequestorBillingDetailsFailure:
            return {
                ...state,
                getBillToRequestorDetailsAPIStatus: API_FAILURE
            }
        case DataEntryActionConstants.deleteDocumentAPIInitial:
            return {
                ...state,
                deleteDocumentAPISuccess: null,
                deleteDocumentAPIStatus: API_INITIAL
            }
        case DataEntryActionConstants.deleteDocumentAPISuccess:
            return {
                ...state,
                deleteDocumentAPISuccess: action.data,
                deleteDocumentAPIStatus: API_SUCCESS
            }
        case DataEntryActionConstants.deleteDocumentAPIFailure:
            return {
                ...state,
                deleteDocumentAPISuccess: false,
                deleteDocumentAPIStatus: API_FAILURE
            }
        case DataEntryActionConstants.SET_NEXT_TRANSACTION_NULL:
            return {
                ...state,
                nextTransactionId: null
            }
        case DataEntryActionConstants.SET_LAST_USED_REQUESTOR:
            return {
                ...state,
                lastUsedRequestorInfo: action.data
            }
        case DataEntryActionConstants.getRetrieveProtocolRequest:
            return {
                ...state,
                retrieveProtocolStatus: API_FETCHING
            }
        case DataEntryActionConstants.getRetrieveProtocolSuccess:
            return {
                ...state,
                retrieveProtocolStatus: API_SUCCESS,
                retrieveProtocol: action.data
            }
        case DataEntryActionConstants.getRetrieveProtocolFailure:
            return {
                ...state,
                retrieveProtocolStatus: API_FAILURE
            }
        case DataEntryActionConstants.getCancellationReasonsSuccess:
            return {
                ...state,
                transactionCancellationReasons: generatePickerOptions(action.data, "reason", "id"),
                transactionCancellationReasonsAPISuccess: API_SUCCESS
            }
        case DataEntryActionConstants.getCancellationReasonsFailure:
            return {
                ...state,
                transactionCancellationReasonsAPIFailure: API_FAILURE
            }
        case DataEntryActionConstants.getSelectedPHICodesSuccess:
            return {
                ...state,
                selectedPHICodes: action.data,
                selectedPHICodesAPIStatus: API_SUCCESS
            }
        case DataEntryActionConstants.getSelectedPHICodesFailure:
            return {
                ...state,
                selectedPHICodesAPIStatus: API_FAILURE
            }

        case DataEntryActionConstants.getPHICodesRequest:
            return {
                ...state,
                getPHICodesStatus: API_FETCHING
            }
        case DataEntryActionConstants.getPHICodesSuccess:
            return {
                ...state,
                getPHICodesStatus: API_SUCCESS,
                phiCodes: action.data
            }
        case DataEntryActionConstants.createPatientRecordSuccess:
            let updatedTransactionDetails = updatePatientDetailsForTransaction({ ...state.transactionDetails }, action.data.id)
            return {
                ...state,
                transactionDetails: updatedTransactionDetails,
                leftPaneTransactionDetails: updatePatientDetailsForTransaction({ ...state.leftPaneTransactionDetails }, action.data.id),
            }
        case DataEntryActionConstants.POST_REJECTION_ACTION:
            return {
                ...state,
                postRejectionActionSuccess: action.data,
                rejectCompleted: true
            }
        case DataEntryActionConstants.POST_REJECTION_ACTION_FAILURE:
            return {
                ...state,
                rejectCompleted: true
            }
        case DataEntryActionConstants.getUserGroupsRequest:
            return {
                ...state,
                getUserGroupsAPIStatus: API_FETCHING
            }
        case DataEntryActionConstants.getUserGroupsSuccess:
            let groupList = action.data.filter(item => item.name.split(",").length <= 1)
            groupList.sort(compare)
            let individualsList = action.data.filter(item => item.name.split(",").length > 1)
            individualsList.sort(compareIndividuals)
            let updatedList = groupList.concat(individualsList)
            let options = generatePickerOptions(updatedList, "name", "id")
            options.shift()
            return {
                ...state,
                getUserGroupsAPIStatus: API_SUCCESS,
                userGroups: options
            }
        case DataEntryActionConstants.GET_DISCLOSURE_REASON:
            return {
                ...state,
                disclosureReasons: generatePickerOptions(action.data, "name", "id")
            }
        case DataEntryActionConstants.updateFormSyncErrors:
            return {
                ...state,
                formSyncErrors: {
                    ...state.formSyncErrors,
                    ...action.data
                }
            }
        case DataEntryActionConstants.UPDATE_DISCLOSURE_INFORMATION:
            return {
                ...state,
                updateDisclosureInformationSucess: action.data
            }
        case DataEntryActionConstants.getUserTasksRequest:
            return {
                ...state,
                getUserTasksStatus: API_FETCHING
            }
        case DataEntryActionConstants.getUserTasksSuccess:
            return {
                ...state,
                getUserTasksStatus: API_SUCCESS,
                userTasks: action.data,
                userTaskComments: action.comments
            }
        case DataEntryActionConstants.getUserTasksFailure:
            return {
                ...state,
                getUserTasksStatus: API_FAILURE,
            }
         case DataEntryActionConstants.GET_ASSESSMENT_RESULTS:
            return {
                 ...state,
                 getAssessmentResultsStatus: API_FETCHING
             }
        case DataEntryActionConstants.GET_ASSESSMENT_RESULTS_SUCCESS:
           return {
                ...state,
                getAssessmentResultsStatus: API_SUCCESS,
                getAssessmentResults: action.data,
             }
        case DataEntryActionConstants.GET_ASSESSMENT_RESULTS_FAILURE:
            return {
                ...state,
                getAssessmentResultsStatus: API_FAILURE,
            }
        case DataEntryActionConstants.GET_REVIEW_ENTITIES:
            return {
                ...state,
                getReviewEntitiesStatus: API_FETCHING
            }
        case DataEntryActionConstants.GET_REVIEW_ENTITIES_SUCCESS:
            return {
                ...state,
                getReviewEntitiesStatus: API_SUCCESS,
                reviewEntities: action.data,
            }
        case DataEntryActionConstants.GET_REVIEW_ENTITIES_FAILURE:
            return {
                ...state,
                getReviewEntitiesStatus: API_FAILURE,
            }
        case DataEntryActionConstants.updateUserTasksList:
            return {
                ...state,
                userTasks: updateUserTaskDetailsHelperFunction([...state.userTasks], action.data.index, action.data.updatedData, action.data.isNewTask)
            }
        case DataEntryActionConstants.updateUserTaskComments:
            return {
                ...state,
                userTaskComments: updateUserTaskCommentsHelperFunction({ ...state.userTaskComments }, action.data)
            }
        case DataEntryActionConstants.DELETE_TRANSACTION_TASK:
            let tempState = { ...state }
            let tempTaskArray: any = tempState.userTasks;
            let newTaskArray: any = [];
            for (let i = 0; i < tempTaskArray.length; i++) {
                if (tempTaskArray[i].id !== action.data) {
                    newTaskArray.push(tempTaskArray[i]);
                }
            }
            return {
                ...state,
                userTasks: newTaskArray
            }
        case DataEntryActionConstants.API_ERROR_STATE:
            if (action.data) {
                let tempApiErrorArray: any = [...state.apiErrorArray]

                let dataObj: any = {
                    status: action.data ? action.data.status : '',
                    statusText: action.data ? action.data.statusText : '',
                    responseURL: action.data ? action.data.request?.responseURL : ''
                }

                if (Array.isArray(action.data?.data)){
                    dataObj.messages = action.data.data.map(d => ({ code: d.code, message: d.message }));
                }else{
                    dataObj.code = action.data ? action.data.data?.code : '';
                    dataObj.message = action.data ? action.data.data?.message : ''
                }

                dataObj.id = tempApiErrorArray.length;
                dataObj.showAlert = true;
                tempApiErrorArray.push(dataObj);

                return {
                    ...state,
                    apiErrorArray: tempApiErrorArray,
                }
            } else {
                return state
            }
        case DataEntryActionConstants.HIDE_API_ERROR_MESSAGE:
            let tempApiErrorArr: any = [...state.apiErrorArray]
            tempApiErrorArr[action.data].showAlert = false
            return {
                ...state,
                apiErrorArray: tempApiErrorArr,
            }
        case DataEntryActionConstants.POPUP_ERROR_STATE:
            if (action.data) {
                return {
                    ...state,
                    popupErrorMessageHeader: action.data?.popupErrorHeader,
                    popupErrorMessageBody: action.data?.popupErrorBody,
                    popupErrorMessageArray: action.data?.popupErrorMessages ?? []
                }
            } else {
                return state
            }
        case DataEntryActionConstants.HIDE_POPUP_ERROR_MESSAGE:
            return {
                ...state,
                popupErrorMessageArray: null,
                popupErrorMessageHeader: null
            }
        case DataEntryActionConstants.COMPLETE_TRANSACTION_REQUEST:
            return {
                ...state,
                completeTransactionRequestSucess: action.data,
                completeTransactionFinished: true
            }
        case DataEntryActionConstants.GET_PATIENT_SEARCH_DETAILS_SAGA:
            return {
                ...state,
                getPatientSearchResultsAPIStatus: API_FETCHING
            }
        case DataEntryActionConstants.patchPatientDetails:
            return {
                ...state,
                patientDetailsUpdated: false,
                rightPaneUpdatedPatientDetailsAttributes: {}
            }
        case DataEntryActionConstants.getAuditTypesRequest:
            return {
                ...state,
                getAuditTypesAPIStatus: API_FETCHING
            }
        case DataEntryActionConstants.getAuditTypesSuccess:
            return {
                ...state,
                auditTypes: action.data,
                getAuditTypesAPIStatus: API_SUCCESS
            }
        case DataEntryActionConstants.getAuditTypesFailure:
            return {
                ...state,
                getAuditTypesAPIStatus: API_FAILURE
            }
        case DataEntryActionConstants.getOBOsRequest:
            return {
                ...state,
                getOBOAPIStatus: API_FETCHING
            }
        case DataEntryActionConstants.getOBOsSuccess:
            return {
                ...state,
                onBehalfOf: action.data,
                getOBOsAPIStatus: API_SUCCESS
            }
        case DataEntryActionConstants.getOBOsFailure:
            return {
                ...state,
                getOBOsAPIStatus: API_FAILURE
            }
        case DataEntryActionConstants.getValidateRequest:
            return {
                ...state,
                getValidation: API_FETCHING
            }
        case DataEntryActionConstants.getValidateSuccess:
            return {
                ...state,
                getValidation: API_SUCCESS
            }
        case DataEntryActionConstants.getValidateFailure:
            return {
                ...state,
                getValidation: API_FAILURE
            }
        case DataEntryActionConstants.requestorVerificationEmailSent:
            return {
                ...state,
                requestorVerificationEmailSent: true,
            }

        case DataEntryActionConstants.requestorVerificationEmailSentReset:
            return {
                ...state,
                requestorVerificationEmailSent: false
            }
        case DataEntryActionConstants.getAuditTrailRequest:
            return {
                ...state,
                getAuditTrailStatus: API_FETCHING
            }
        case DataEntryActionConstants.getAuditTrailResponse:
            return {
                ...state,
                auditTrail: action.data,
                getAuditTrailStatus: API_INITIAL
            }
        case DataEntryActionConstants.getStatusIndicatorRequest:
            return {
                ...state,
                getStatusIndicatorStatus: API_FETCHING
            }
        case DataEntryActionConstants.getStatusIndicatorResponse:
            return {
                ...state,
                getStatusIndicatorStatus: API_INITIAL,
                transactionDetails:{
                    ...state.transactionDetails,
                    transactionStatus: action.data
                }
            }
        case DataEntryActionConstants.SHOW_DUPLICATE_TRANSACTIONS:
            if (action.data) {
                return {
                    ...state,
                    duplicateTransactions: action.data.duplicates,
                }
            } else {
                return state
            }
        case DataEntryActionConstants.HIDE_DUPLICATE_TRANSACTIONS:
            return {
                ...state,
                duplicateTransactions: null
            }
        case DataEntryActionConstants.getTransactionDocumentsToCopyRequest:
            return {
                ...state,
                getTransactionDocumentsAPIStatus: API_FETCHING
            }
        case DataEntryActionConstants.getTransactionDocumentsToCopySuccess:
            return {
                ...state,
                getTransactionDocumentsAPIStatus: API_SUCCESS,
                documentsToCopyList: action.data.filter(row => row.type?.role === "MED").map(item => {
                    const fileSize = item.file?.size?.bytes
                    return {
                        ...item,
                        size: isNaN(fileSize) ? fileSize : (Number(fileSize) * 0.00000095367432).toFixed(2)
                    }
                })
            }
        case DataEntryActionConstants.resetTransactionDocumentsToCopyRequest:
            return {
                ...state,
                documentsToCopyList: [],
                copyTransactionComplete: false,
            }
        case DataEntryActionConstants.patchTransactionOnFileSuccess:
            return {
                ...state,
                copyTransactionComplete: true,
                documentsToCopyList: []
            }
        case DataEntryActionConstants.patchTransactionOnFileFailure:
            return {
                ...state,
                copyTransactionComplete: true,
                documentsToCopyList: []
            }
        case DataEntryActionConstants.postWPSRequest:
            return {
                ...state,
                transactionId: action.data.transactionId,
                docType: action.data.docType,
                postWPSStatus: API_FETCHING
            }
        case DataEntryActionConstants.postWPSResponse:
            return {
                ...state,
                wpsToken: action.data,
                postWPSStatus: API_INITIAL
            }
        case DataEntryActionConstants.clearPreviousTransactionOnComplete:
            return {
                ...defaultState,
                lastUsedRequestorInfo: state.lastUsedRequestorInfo,
                sendToContactDataProps: null,
                updateAdditionalChargesProps: null,
                sendToRequestorDataProps: null,
                completeTransactionFinished: true
            }
        case DataEntryActionConstants.readBarCodeDDSRequest:
            return {
                ...state,
                readBarCodeDDSAPIStatus: API_FETCHING
            }
        case DataEntryActionConstants.readBarCodeDDSSuccess:
            return {
                ...state,
                readBarCodeDDSAPIStatus: API_SUCCESS,
                readBarCodeDDS: action.data

            }
        case DataEntryActionConstants.readBarCodeDDSFailure:
            return {
                ...state,
                readBarCodeDDSAPIStatus: API_FAILURE
            }
        case DataEntryActionConstants.COMPLETE_TRANSACTION_REQUEST_SAGA:
            return {
                ...state,
                completeTransactionFinished: true
            }
        case DataEntryActionConstants.initialTxCountIncrement:
            return {
                ...state,
                intiaTxlSagaCallCount: state.intiaTxlSagaCallCount + 1
            }

        case DataEntryActionConstants.getFacilityAdditionalFieldsRequest:
            return {
                ...state,
                FacilityAdditionalFieldsApiStatus: API_FETCHING
            }

        case DataEntryActionConstants.getFacilityAdditionalFieldsSuccess:
            return{
                ...state,
                FacilityAdditionalFields: action.data,
                FacilityAdditionalFieldsApiStatus: API_SUCCESS
            }

        case DataEntryActionConstants.getFacilityAdditionalFieldsFailure:
            return{
                ...state,
                FacilityAdditionalFieldsApiStatus: API_FAILURE
            }
        case DataEntryActionConstants.saveInformationToReleaseResponse:{
            if(action.data.freeText){
                return {
                    ...state,
                    transactionDetails: {
                        ...state.transactionDetails,
                        releaseInfo: action.data.returnItem,
                        requestComments: action.data.freeText
                    }
                }
            }else
                return{
                    ...state,
                    transactionDetails: {
                        ...state.transactionDetails,
                        releaseInfo: action.data.returnItem
                    }
                }
        }
        case DataEntryActionConstants.getWaitAndHoldReasonsSuccess:
            return {
                ...state,
                transactionWaitAndHoldReasons: generatePickerOptionsNoDefault(action.data, "name", "waitHoldReasonID"),
                transactionWaitAndHoldReasonsAPISuccess: API_SUCCESS
            }
        case DataEntryActionConstants.getWaitAndHoldReasonsFailure:
            return {
                ...state,
                transactionWaitAndHoldReasonsAPIFailure: API_FAILURE
            }
        case DataEntryActionConstants.postWaitAndHoldReasonsSuccess:
                return {
                    ...state,
                    postWaitAndHoldReasonsAPIStatus: API_SUCCESS
                }
        case DataEntryActionConstants.postWaitAndHoldReasonsRequest:
                return {
                    ...state,
                    postWaitAndHoldReasonsAPIStatus: API_FETCHING
                }
        case DataEntryActionConstants.patchTransactionContinueRequest:
                return {
                    ...state,
                    patchTransactionContinueAPIStatus: API_FETCHING
                }
        case DataEntryActionConstants.patchTransactionContinueSuccess:
            return {
                ...state,
                patchTransactionContinueAPIStatus: API_FETCHING
            }
        case DataEntryActionConstants.updateDeliveryInstructionsStatus:
            return {
                ...state,
                updateDeliveryInstructionsStatus: action.data
            }

        case DataEntryActionConstants.GET_QA_ERRORS_RESULTS:
            return {
                ...state,
                getQAErrorsStatus: API_FETCHING
            }
        case DataEntryActionConstants.GET_QA_ERRORS_RESULTS_SUCCESS:
            return {
                ...state,
                getQAErrorsStatus: API_SUCCESS,
                getQAErrors: action.data

            }
        case DataEntryActionConstants.GET_QA_ERRORS_RESULTS_FAILURE:
            return {
                ...state,
                getQAErrorsStatus: API_FAILURE
            }
        case DataEntryActionConstants.GET_QA_ISSUES_RESULTS:
            return {
                ...state,
                getQAIssuesStatus: API_FETCHING
            }
        case DataEntryActionConstants.GET_QA_ISSUES_RESULTS_SUCCESS:
            return {
                ...state,
                getQAIssuesStatus: API_SUCCESS,
                getQAIssues: action.data
            }
        case DataEntryActionConstants.GET_QA_ISSUES_RESULTS_FAILURE:
            return {
                ...state,
                getQAIssuesStatus: API_FAILURE
            }
        case DataEntryActionConstants.GET_SENSITIVITY_TYPES_RESULTS:
            return {
                ...state,
                getSensitivityTypesStatus: API_FETCHING
            }
        case DataEntryActionConstants.GET_SENSITIVITY_TYPES_RESULTS_SUCCESS:
            return {
                ...state,
                getSensitivityTypesStatus: API_SUCCESS,
                getSensitivityTypes: action.data
            }
        case DataEntryActionConstants.GET_SENSITIVITY_TYPES_RESULTS_FAILURE:
            return {
                ...state,
                getSensitivityTypesStatus: API_FAILURE
            }
        case DataEntryActionConstants.resolveQATransactionIssueRequest:
            return {
                ...state,
                resolveQAIssueStatus: API_FETCHING,
            }
        case DataEntryActionConstants.resolveQATransactionIssueSuccess:
            return {
                ...state,
                resolveQAIssueStatus: API_SUCCESS,
            }
        case DataEntryActionConstants.resolveQATransactionIssueFailure:
            return {
                ...state,
                resolveQAIssueStatus: API_FAILURE,
            }
        case DataEntryActionConstants.removeQATransactionIssueRequest:
            return {
                ...state,
                removeQAIssueStatus: API_FETCHING,
            }
        case DataEntryActionConstants.removeQATransactionIssueSuccess:
            return {
                ...state,
                removeQAIssueStatus: API_SUCCESS,
            }
        case DataEntryActionConstants.removeQATransactionIssueFailure:
            return {
                ...state,
                removeQAIssueStatus: API_FAILURE,
            }
        case DataEntryActionConstants.GET_QA_PD_ISSUES_RESULTS:
            return {
                ...state,
                getAQPDIssueStatus: API_FETCHING
            }
        case DataEntryActionConstants.GET_QA_PD_ISSUES_RESULTS_SUCCESS:
            return {
                ...state,
                getQAPDIssuesStatus: API_SUCCESS,
                qaPDIssues: action.data
            }
        case DataEntryActionConstants.GET_QA_PD_ISSUES_RESULTS_FAILURE:
            return {
                ...state,
                getQAPDIssuesStatus: API_FAILURE
            }
        case DataEntryActionConstants.postQATransactionIssueRequest:
            return {
                ...state,
                postQATransactionIssueAPIStatus: API_FETCHING,
                error: null
            }
        case DataEntryActionConstants.postQATransactionIssueSuccess:
            return {
                ...state,
                postQATransactionIssueAPIStatus: API_SUCCESS,
                payload: {
                    transactionID: action.transactionID,
                    qaErrorID: action.qaErrorID,
                    userID: global.userID,
                    pageNumber: action.pageNumber,
                    issueDetails: action.issueDetails,
                    fileName: action.fileName
                }
            }
        case DataEntryActionConstants.postQATransactionIssueFailure:
            return {
                ...state,
                postQATransactionIssueAPIStatus: API_FAILURE,
                error: {
                    transactionID: action.transactionID,
                    qaErrorID: action.qaErrorID,
                    userID: global.userID,
                    pageNumber: action.pageNumber,
                    issueDetails: action.issueDetails,
                    fileName: action.fileName
                }
            }

        case DataEntryActionConstants.updateClinicIDResponse:{
            const inst:any = state.discloseInstitutions.find((i:any) => i.id == action.data);
            const newInst = {...inst};

            return {
                ...state,
                leftPaneTransactionDetails:{
                    ...state.leftPaneTransactionDetails,
                    disclosingInstitution: newInst
                },
                transactionDetails: {
                    ...state.transactionDetails,
                    disclosingInstitution: newInst
                }
            }
        }

        case DataEntryActionConstants.userInputUpdateDataEntryDetailRequestorContact:
           
            const retItem = {
                ...state,
                requestorDetails:{
                    ...state.requestorDetails,
                    requestorInfo:{
                        ...state.requestorDetails.requestorInfo,
                        id: action.data.data.requestorcontactid
                    }

                }
            }

            if(action.data.data.firstname){
                retItem.requestorDetails.requestorInfo['firstName'] = action.data.data.firstname
            }

            if(action.data.data.lastname){
                retItem.requestorDetails.requestorInfo['lastName'] = action.data.data.lastname
            }

            if(action.data.data.email){
                retItem.requestorDetails.requestorInfo['email'] = action.data.data.email
            }

            if(action.data.data.fax){
                retItem.requestorDetails.requestorInfo['fax'] = action.data.data.fax
            }

            if(action.data.data.phone){
                retItem.requestorDetails.requestorInfo['phone'] = action.data.data.phone
            }

            return retItem;
        case DataEntryActionConstants.REQUESTOR_COMPLETE_SAGA:
            return {
                ...state,
                requestorComplete: true
            }
        case DataEntryActionConstants.setConfirmFax:
            return {
                ...state,
                confirmFax: action.data
            }
        case DataEntryActionConstants.SET_PDF_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.pageNumber,
                currentFile: action.fileDesc
            }
        case DataEntryActionConstants.GET_AI_FEEDBACK_REQUEST:
            return {
                ...state,
                getAIFeedbackStatus: API_FETCHING,
            }
        case DataEntryActionConstants.GET_AI_FEEDBACK_SUCCESS:
            return {
                ...state,
                getAIFeedbackStatus: API_SUCCESS,
                getAIFeedback: action.data
            }
        case DataEntryActionConstants.GET_AI_FEEDBACK_FAILURE:
            return {
                ...state,
                getAIFeedbackStatus: API_FAILURE,
            }
        case DataEntryActionConstants.SUBMIT_AI_FEEDBACK_REQUEST:
            return {
                ...state,
                submitAIFeedbackStatus: API_FETCHING,
            }
        case DataEntryActionConstants.SUBMIT_AI_FEEDBACK_SUCCESS:
            return {
                ...state,
                submitAIFeedbackStatus: API_SUCCESS,
            }
        case DataEntryActionConstants.SUBMIT_AI_FEEDBACK_FAILURE:
            return {
                ...state,
                submitAIFeedbackStatus: API_FAILURE,
            }
        case DataEntryActionConstants.DELETE_AI_FEEDBACK_REQUEST:
            return {
                ...state,
                deleteAIFeedbackStatus: API_FETCHING,
            }
        case DataEntryActionConstants.DELETE_AI_FEEDBACK_SUCCESS:
            return {
                ...state,
                deleteAIFeedbackStatus: API_SUCCESS,
            }
        case DataEntryActionConstants.DELETE_AI_FEEDBACK_FAILURE:
            return {
                ...state,
                deleteAIFeedbackStatus: API_FAILURE,
            }
        case DataEntryActionConstants.SET_CURRENT_FILE_TYPE:
            return {
                ...state,
                currentFileType: action.fileType
            }
        case DataEntryActionConstants.updateReplaceDocumentRequest:
            return {
                ...state,
                updateReplaceDocumentStatus: API_FETCHING
            }
        case DataEntryActionConstants.updateReplaceDocumentSuccess:
            return {
                ...state,
                updateReplaceDocumentStatus: API_SUCCESS
            }
        case DataEntryActionConstants.updateReplaceDocumentFailure:
            return {
                ...state,
                updateReplaceDocumentStatus: API_FAILURE
            }
        default:
            return state
    }
}

function compare(a, b) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

function compareIndividuals(a, b) {
    let la = a.name.split(",")[0];
    let lb = b.name.split(",")[0];
    if (la < lb) {
        return -1;
    }
    if (la > lb) {
        return 1;
    }
    return 0;
}

// create action creators here
export const updateShowAPIErrorActionCreator = (id) => {
    return {
        type: DataEntryActionConstants.HIDE_API_ERROR_MESSAGE,
        data: id
    }
}

export const updateShowPopupErrorActionCreator = (data) => {
    return {
        type: DataEntryActionConstants.HIDE_POPUP_ERROR_MESSAGE,
        data
    }
}

export const updateUserTasksActionCreator = (data) => {
    return {
        type: DataEntryActionConstants.updateUserTasksList,
        data
    }
}

export const updateUserTaskCommentsActionCreator = (data) => {
    return {
        type: DataEntryActionConstants.updateUserTaskComments,
        data
    }
}

export const getAssessmentResultsActionCreator = (transactionId: string) => {
    return {
        type: DataEntryActionConstants.GET_ASSESSMENT_RESULTS,
        transactionId
    }
}

export const getReviewEntitiesActionCreator = (transactionId: string) => {
    return {
        type: DataEntryActionConstants.GET_REVIEW_ENTITIES,
        transactionId
    }
}

export const getUserTasksActionCreator = (data) => {
    return {
        type: DataEntryActionConstants.getUserTasksRequest,
        data
    }
}

export const updateFormSyncErrorsActionCreator = (data) => {
    return {
        type: DataEntryActionConstants.updateFormSyncErrors,
        data
    }
}

export const billToRequestorDetailsActionCreator = (data) => {
    return {
        type: DataEntryActionConstants.getRequestorBillingDetails,
        data
    }
}

export const createPatientRecordRequest = (data, onSuccess) => {
    return {
        type: DataEntryActionConstants.createPatientRecordRequest,
        data,
        onSuccess
    }
}

export const getPHICodesRequest = (data) => {
    return {
        type: DataEntryActionConstants.getPHICodesRequest,
        data
    }
}

export const setNextTransactionNullActionCreator = () => {
    return {
        type: DataEntryActionConstants.SET_NEXT_TRANSACTION_NULL
    }
}

export const setLastUsedRequestorInfo = (requestorInfo) => {
    return {
        type: DataEntryActionConstants.SET_LAST_USED_REQUESTOR,
        data: requestorInfo
    }
}

export const resetDataEntryState = () => {
    return {
        type: DataEntryActionConstants.resetState
    }
}

export const uploadDocument = (data: any) => {
    return {
        type: DataEntryActionConstants.uploadDocumentRequest,
        data
    }
}
export const postCancellationReasonsActionCreators = (transactionId: string, id: any, cancelReasonText: string = '', isDuplicate: boolean = false) => {
    return {
        type: DataEntryActionConstants.postCancellationReasonsRequest,
        transactionId,
        id,
        isDuplicate,
        cancelReasonText
    }
}

export const postQATransactionIssueActionCreators = (organizationId: number, requestBufferId: number, errorId: number, details: string, filename: string, pageNumber: number, editIssueId?: string) => {
    return {
        type: DataEntryActionConstants.postQATransactionIssueRequest,
        transactionID: organizationId + "-" + requestBufferId,
        organizationId,
        requestBufferId,
        errorId,
        pageNumber,
        details,
        filename,
        id: editIssueId,
    }
}

export const resolveQATransactionIssueActionCreator = (transactionId: string, issueId: string) => {
    return {
        type: DataEntryActionConstants.resolveQATransactionIssueRequest,
        transactionId,
        issueId,
    };
}

export const removeQATransactionIssueActionCreator = (transactionId: string, issueId: string) => {
    return {
        type: DataEntryActionConstants.removeQATransactionIssueRequest,
        transactionId,
        issueId,
    };
}

export const getDoucmentSourcesActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.getDocumentSourcesRequest,
        data
    }
}


export const deleteDocumentActionCreator = (doctype: string, data: any) => {
    return {
        type: DataEntryActionConstants.deleteDocument,
        doctype,
        data
    }
}

export const getRejectionLetterActionCreator = (transactionId: string) => {
    return {
        type: DataEntryActionConstants.getRejectionLetterSagas,
        data: transactionId
    }
}

export const getPreviousTransactionActionCreator = (transactionId: string) => {
    return {
        type: DataEntryActionConstants.getPreviousTransactionSagas,
        data: transactionId
    }
}

export const getQAErrorsActionCreator = () => {
    return {
        type: DataEntryActionConstants.GET_QA_ERRORS_RESULTS,
    }
}

export const getQAIssuesActionCreator = (transactionID: string) => {
    return {
        type: DataEntryActionConstants.GET_QA_ISSUES_RESULTS,
        transactionID
    }
}

export const getQAPDIssuesActionCreator = (transactionID: string) => {
    return {
        type: DataEntryActionConstants.GET_QA_PD_ISSUES_RESULTS,
        transactionID
    }
}

export const getSensitivityTypesActionCreator = () => {
    return {
        type: DataEntryActionConstants.GET_SENSITIVITY_TYPES_RESULTS
    }
}

export const createQAReviewEntityCreator = (reviewEntity: qaReviewEntityProps) => {
    return {
        type: DataEntryActionConstants.createAIReviewEntityRequest,
        reviewEntity
    }
}

export const getRejectOptions = (data: string) => {
    return {
        type: DataEntryActionConstants.getRejectOptionsRequest,
        data
    }
}

export const onChangeDataEntryTab = (data: number, tabClick: boolean) => {
    return {
        type: DataEntryActionConstants.onChangeTab,
        data,
        tabClick
    }
}

export const getRequestTypesAndPurposesRequest = (healthSystemId: Number) => {
    return {
        type: DataEntryActionConstants.getRequestTypesAndPurposesRequest,
        data: healthSystemId
    }
}


export const updateBillToRequestorDetailsRequest = (data: any) => {
    return {
        type: DataEntryActionConstants.updateBillToRequestorRequest,
        data
    }
}
export const getTransactionAttributesActionCreator = (transactionId: string) => {
    return {
        type: DataEntryActionConstants.GET_TRANSACTIONS_ATTRIBUTES_SAGA,
        data: transactionId
    }
}


export const getDocumentByIdActionCreator = (documentId: string, auditOnShowDoc: boolean) => {
    return {
        type: DataEntryActionConstants.GET_DOCUMENT_BY_ID_SAGA,
        data: { documentId, auditOnShowDoc }
    }
}

export const assignTransactionIDActionCreator = (transactionId: string) => {
    ReduxScope.transactionId = transactionId;
    return {
        type: DataEntryActionConstants.ASSIGN_TRANSACTION_ID_SAGA,
        data: transactionId
    }
}

export const getFacilityDiscloseInstitutionsRequest = (facilityId: string) => {
    return {
        type: DataEntryActionConstants.getFacilityDiscloseInstitutionsRequest,
        data: facilityId
    }
}

export const unassignTransactionIDActionCreator = (transactionId: string) => {
    ReduxScope.transactionId = "";
    return {
        type: DataEntryActionConstants.UNASSIGN_TRANSACTION_ID_SAGA,
        data: transactionId
    }
}

export const getFeeScheduleData = (facilityId: string, transactionId: string, setDefault: boolean) => {
    let details = { facilityId, transactionId, setDefault }
    return {
        type: DataEntryActionConstants.GET_FEE_SCHEDULE_DATA,
        data: details
    }
}

export const getReleaseMethodOptionsData = (facilityId: string, transactionId: string) => {
    let details = { facilityId, transactionId }
    return {
        type: DataEntryActionConstants.getReleaseMethodOptionsData,
        data: details
    }
}



export const updatePHIDisclosed = (transactionId: string, requestedItemID: string, isDelete: boolean) => {
    let PHIdetails = { transactionId, requestedItemID, isDelete }
    return {
        type: DataEntryActionConstants.UPDATE_PHI_DISCLOSED_SAGA,
        data: PHIdetails
    }
}

export const getShippingServicesData = (facilityId: string) => {
    return {
        type: DataEntryActionConstants.GET_SHIPPING_SERVICE_DATA,
        data: facilityId
    }
}

export const getTransactionNotesRequest = (data: any) => {
    return {
        type: DataEntryActionConstants.getTransactionNotesRequest,
        data
    }
}

export const getAdditionalChargeType = (data: any) => {
    return {
        type: DataEntryActionConstants.getAdditionalChargeType,
        data
    }
}

export const getAdditionalChargeRecordsCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.getAdditionalChargeRecords,
        data
    }
}
export const getRequestorSearchDetailsActionCreator = (data: any, facilityId: string, globalUserId) => {
    return {
        type: DataEntryActionConstants.getRequestorSearchDetailsSAGA,
        data,
        facilityId,
        globalUserId
    }
}
export const getContactSearchDetailsActionCreator = (requestorId: any, distinct?: boolean | undefined) => {
    return {
        type: DataEntryActionConstants.getContactSearchDetailsSAGA,
        requestorId,
        distinct
    }
}

export const updateDocumentActionCreqtor = (data: any) => {
    return {
        type: DataEntryActionConstants.updateDocumentRequest,
        data
    }
}

export const updatePatientDetailsForTransaction = (transactionDetails: any, patientId: any) => {
    let updatedPatientDetails = transactionDetails || {}
    updatedPatientDetails = {
        ...updatedPatientDetails,
        patient: {
            ...updatedPatientDetails.patient,
            id: patientId
        }
    }
    return updatedPatientDetails
}

export const updateTransactionDetails = (localState: any, details: any) => {
    let updatedTransactionDetails = localState.leftPaneTransactionDetails || {}
    updatedTransactionDetails = {
        ...updatedTransactionDetails,
        ...details,
        date: {
            ...updatedTransactionDetails.date,
            ...details
        }
    }
    return updatedTransactionDetails
}

export const updateRequestorDetails = (localState: any, details: any) => {
    let updatedRequestorDetails = localState.requestorDetails || {}
    updatedRequestorDetails = {
        ...updatedRequestorDetails,
        requestorAddress: {
            ...updatedRequestorDetails.requestorAddress,
            ...details
        },
        requestorInfo: {
            ...updatedRequestorDetails.requestorInfo,
            ...details
        },
        ...details
    }
    return updatedRequestorDetails
}

export const updatePatientDetails = (localState, details: any) => {
    let updatedPatientDetails = localState.leftPanePatientDetailsAttributes
    updatedPatientDetails = {
        ...updatedPatientDetails,
        ...details,
        contact: {
            ...updatedPatientDetails.contact,
            ...details
        }
    }
    return updatedPatientDetails
}
export const updatePatientDetailsRightPane = (localState, details: any) => {
    let updatedPatientDetails = localState.rightPaneUpdatedPatientDetailsAttributes
    updatedPatientDetails = {
        ...updatedPatientDetails,
        ...details,
        contact: {
            ...updatedPatientDetails.contact,
            ...details
        }
    }
    return updatedPatientDetails
}
export const sendToRequestorDataActionCreator = (requestData: sendToRequestorDataProps) => {
    return {
        type: DataEntryActionConstants.SEND_TO_REQUESTOR_DATA_SAGA,
        data: requestData
    }
}

export const sendToContactDataActionCreator = (requestContactData: sendToContactDataProps) => {
    return {
        type: DataEntryActionConstants.SEND_TO_REQUESTOR_CONTACT_DATA_SAGA,
        data: requestContactData
    }
}

export const getTransactionDocuments = (transactionId: string) => {
    return {
        type: DataEntryActionConstants.getTransactionDocumentsRequest,
        data: transactionId
    }
}
export const updateSendToRequestorDataActionCreator = (requestData: sendToRequestorDataProps) => {
    return {
        type: DataEntryActionConstants.UPDATE_SEND_TO_REQUESTOR_DATA_SAGA,
        data: requestData
    }
}

export const patchPatientNoteActionCreators = (patientid: any, note: any, globalUserID: any, transactionId: any) => {
    return {
        type: DataEntryActionConstants.patchPatientsNotesSagas,
        data: { patientid, note, globalUserID, transactionId}
    }
}

export const patchRequestorNoteActionCreators = (requestorid: any, note: any, globalUserID: any, contactId: any) => {
    return {
        type: DataEntryActionConstants.patchRequestorsNotesSagas,
        data: { requestorid, note, globalUserID, contactId }
    }
}
export const patchSendToRequestorContactActionCreators = (requestData: sendToRequestorDataProps) => {
    return {
        type: DataEntryActionConstants.patchSendToRequestorContactSagas,
        data: requestData
    }
}

export const updateRequestorContactInfo = (data : any) => {
    return{
        type: DataEntryActionConstants.userInputUpdateDataEntryDetailRequestorContact, 
        data: data
    }
}

export const getTransactionNoteTypes = (facilityId: string) => {
    return {
        type: DataEntryActionConstants.getTransactionNoteTypesRequest,
        data: facilityId
    }
}

export const getCancellationReasons = (facilityId: string) => {
    return {
        type: DataEntryActionConstants.getCancellationReasons,
        data: facilityId
    }
}



export const updateTransactionNotes = (data: any) => {
    return {
        type: DataEntryActionConstants.updateTransactionNotes,
        data
    }
}
export const updateAdditionalChargesActionCreator = (requestData: updateAdditionalChargesProps) => {
    return {
        type: DataEntryActionConstants.UPDATE_ADDITIONAL_CHARGES_SAGA,
        data: requestData
    }
}

export const uploadMultipleDocumentsActionCreator = (data: any, onSuccess?: Function) => {
    return {
        type: DataEntryActionConstants.uploadMultipleDocumentsRequest,
        data,
        onSuccess
    }
}
export const getRetrieveProtocolActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.getRetrieveProtocolRequest,
        data
    }
}
export const getSelectedPHICodesActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.GET_SELECTED_PHI_CODES_SAGA,
        data
    }
}


export const rejectionContactOverrideActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.rejectionContactOverrideRequest,
        data
    }
}

export const updateBillingPaneFields = (data: any, updateData?: any) => {
    return {
        type: DataEntryActionConstants.updateBillingPaneFields,
        data,
        updateData
    }
}
export const nextTransactionIdActionCreator = (facilityId: any, context: any = 1) => {
    return {
        type: DataEntryActionConstants.GET_NEXT_TRANSACTION_REQUEST_SAGA,
        data: {facilityId, context}
    }
}

export const getPatientSearchDetailsActionCreator = (facilityId: any, lastName: any, dob: any) => {
    return {
        type: DataEntryActionConstants.GET_PATIENT_SEARCH_DETAILS_SAGA,
        data: { facilityId, lastName, dob }
    }
}

export const postRejectionDetailsActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.POST_REJECTION_ACTION_SAGA,
        data
    }
}

export const setPDFPageNumberActionCreator = (pageNumber: number, fileDesc: string) => {
    return {
        type: DataEntryActionConstants.SET_PDF_PAGE_NUMBER,
        pageNumber,
        fileDesc
    }
}

export const getUserGroupsActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.getUserGroupsRequest,
        data
    }
}

export const getDisclosureReasonActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.GET_DISCLOSURE_REASON_SAGA,
        data
    }
}

export const updateDisclosureInformationActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.UPDATE_DISCLOSURE_INFORMATION_SAGA,
        data
    }
}

export const createUserTaskCommentActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.createUserTaskComment,
        data
    }
}

export const addNewTaskActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.addNewUserTaskRequest,
        data
    }
}

export const updateTaskActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.updateUserTask,
        data
    }
}

export const deleteTransactionTaskActionCreator = (transactionId: string, usertaskId: string) => {
    return {
        type: DataEntryActionConstants.DELETE_TRANSACTION_TASK_SAGA,
        data: { transactionId, usertaskId }
    }
}

export const saveInformationToReleaseActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.saveInformationToReleaseRequest,
        data
    }
}

export const completeTransactionTaskActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.COMPLETE_TRANSACTION_REQUEST_SAGA,
        data
    }
}

export const returnToQueueTaskActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.RETURN_TO_QUEUE_SAGA,
        data
    }
}

export const createPatientRecordSuccess = (data) => {
    return {
        type: DataEntryActionConstants.createPatientRecordSuccess,
        data
    }
}

export const detailsCompleteActionCreator = (transactionId: string) => {
    return {
        type: DataEntryActionConstants.DETAILS_COMPLETE_SAGA,
        data: { transactionId }
    }
}

export const requestorCompleteActionCreator = (transactionId: string) => {
    return {
        type: DataEntryActionConstants.REQUESTOR_COMPLETE_SAGA,
        data: { transactionId }
    }
}

export const billingCompleteActionCreator = (transactionId: string) => {
    return {
        type: DataEntryActionConstants.BILLING_COMPLETE_SAGA,
        data: { transactionId }
    }
}

export const patchPatientDetailsCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.patchPatientDetails,
        data
    }
}

export const getAuditTypesRequest = (data: any) => {
    return {
        type: DataEntryActionConstants.getAuditTypesRequest,
        data
    }
}

export const getOBOsRequest = (data: any) => {
    return {
        type: DataEntryActionConstants.getOBOsRequest,
        data
    }
}

export const getValidateRequest = (transactionId: string) => {
    return {
        type: DataEntryActionConstants.getValidateRequest,
        data: { transactionId }
    }
}

export const getValidateSuccess = (data: any) => {
    return {
        type: DataEntryActionConstants.getValidateSuccess,
        data
    }
}

export const getValidateFailure = (data: any) => {
    return {
        type: DataEntryActionConstants.getValidateFailure,
        data
    }
}
// requestor verification notifcation
export const requestorVerificationEmailSent = () => {
    return {
        type: DataEntryActionConstants.requestorVerificationEmailSent,
    }
}

export const RequestorVerificationEmailSentReset = () => {
    return {
        type: DataEntryActionConstants.requestorVerificationEmailSentReset
    }
}

export const getAuditTrail = (transactionId: string) => {
    return {
        type: DataEntryActionConstants.getAuditTrailRequest,
        data: { transactionId }
    }
}

export const checkDuplicateTransactions = (transactionId: string, caseNumber: string) => {
    return {
        type: DataEntryActionConstants.GET_DUPLICATE_TRANSACTIONS,
        data: {
            transactionId,
            caseNumber
        }
    }
}

export const ShowDuplicateTransactions = (data: any) => {
    return {
        type: DataEntryActionConstants.SHOW_DUPLICATE_TRANSACTIONS,
        data
    }
}

export const hideDuplicateTransactionsPopup = () => {
    return {
        type: DataEntryActionConstants.HIDE_DUPLICATE_TRANSACTIONS,
        data: null
    }
}


export const getTransactionDocumentsToCopy = (transactionId: string) => {
    return {
        type: DataEntryActionConstants.getTransactionDocumentsToCopyRequest,
        data: transactionId
    }
}
export const resetTransactionDocumentsToCopy = () => {
    return {
        type: DataEntryActionConstants.resetTransactionDocumentsToCopyRequest
    }
}
export const updateTransactionOnFileCreater = (data: any) => {
    return {
        type: DataEntryActionConstants.updateTransactionOnFileRequest,
        data
    }
}

export const postWPSToken = (transactionId: string, docType: string) => {
    return {
        type: DataEntryActionConstants.postWPSRequest,
        data: { transactionId, docType }
    }
}

export const clearPreviousTransactionOnCompleteCreator = () => {
    return {
        type: DataEntryActionConstants.clearPreviousTransactionOnComplete
    }
}

export const readBarCodeDDSRequest = (documentId: string) => {
    return {
        type: DataEntryActionConstants.readBarCodeDDSRequest,
        data: documentId
    }
}

export const readBarCodeDDSSuccess = (data: any) => {
    return {
        type: DataEntryActionConstants.readBarCodeDDSSuccess,
        data: data
    }
}

export const readBarCodeDDSFailure = () => {
    return {
        type: DataEntryActionConstants.readBarCodeDDSFailure
    }
}

export const updateRequestorDetailsAction = (data: any) => {
    return {
        type: DataEntryActionConstants.updateRequestorDetails,
        data: data
    }
}

 export const getFacilityAdditionalFieldsActionCreator = (data: any) =>{
     return {
         type: DataEntryActionConstants.getFacilityAdditionalFieldsRequest,
         data: data
     }
 }

 export const getFacilityAdditionalFieldsFailure = (data: any) =>{
    return {
        type: DataEntryActionConstants.getFacilityAdditionalFieldsFailure,
    }
}

export const getFacilitiesAdditionalFieldsSuccess = (data: any) => {
    return {
        type: DataEntryActionConstants.getFacilityAdditionalFieldsSuccess,
        data: data
    }
}

export const getWaitAndHoldReasons = (data: any) => {
    return {
        type: DataEntryActionConstants.getWaitAndHoldReasons,
        data: data
    }
}

export const postWaitAndHoldReasonsActionCreators = (transactionId: string, waitHoldReasons: any, statusIndicatorId: any, waitFor: string) => {
    return {
        type: DataEntryActionConstants.postWaitAndHoldReasonsRequest,
        transactionId,
        waitHoldReasons,
        statusIndicatorId,
        waitFor
    }
}

export const patchTransactionContinueActionCreator = (transactionId: string, waitHoldReasons: any) => {
    return {
        type: DataEntryActionConstants.patchTransactionContinueRequest,
        transactionId,
        waitHoldReasons
    }
}

export const putDigitalFaxValidationCreator = (transactionId: string, faxNumber: any) => {
    return {
        type: DataEntryActionConstants.putDigitalFaxValidationRequest,
        transactionId,
        faxNumber
    }
}

export const putAuditTransactionSensitiveInfoCreator = (transactionId: string, recordContains: any ) => {
    return {
        type: DataEntryActionConstants.putAuditTransactionSensitiveInfoRequest,
        transactionId,
        recordContains
    }
}

export const resetRequestorContactList = () => {
   return { 
        type: DataEntryActionConstants.getContactSeacrhDetailsReset, 
        data: [] 
    }
}

export const userViewedDocumentRequest = (documentId: string) => {
    return { 
         type: DataEntryActionConstants.userViewedDocumentRequest, 
         data: documentId
     }
 }

 export const setConfirmFaxNumberAction = (faxNumber: string) => {
    return {
        type: DataEntryActionConstants.setConfirmFax,
        data:faxNumber
    }
 }

 export const updateEscalationAction = (state: boolean) => {
    return {
        
    }
 }

export const getAIFeedbackActionCreator = () => {
    return {
        type: DataEntryActionConstants.GET_AI_FEEDBACK_REQUEST,
    }
}

export const submitAIFeedbackActionCreator = (entityId: string, feedbackType: string) => {
    return {
        type: DataEntryActionConstants.SUBMIT_AI_FEEDBACK_REQUEST,
        entityId,
        feedbackType,
    }
}

export const deleteAIFeedbackActionCreator = (entityId: string) => {
    return {
        type: DataEntryActionConstants.DELETE_AI_FEEDBACK_REQUEST,
        entityId,
    }
}

export const setCurrentFileTypeActionCreator = (fileType: string) => {
    return {
        type: DataEntryActionConstants.SET_CURRENT_FILE_TYPE,
        fileType
    }
}

export const updateReplaceDocucmentActionCreator = (data: any) => {
    return {
        type: DataEntryActionConstants.updateReplaceDocumentRequest,
        data
    }
}

// call wrapper function

function* callAPI<call>(...args: unknown[]) {
    // @ts-expect-error
    let result = yield call(...args);
    if (result && result.headers["refreshaudittrail"] === "1" && ReduxScope.transactionId) {
        yield put({
            data: { transactionId: ReduxScope.transactionId },
            type: DataEntryActionConstants.getAuditTrailRequest
        });
    }
    if (result && result.headers["refreshstatusindicator"] === "1" && ReduxScope.transactionId) {
        yield put({
            data: { transactionId: ReduxScope.transactionId },
            type: DataEntryActionConstants.getStatusIndicatorRequest
        });
    }
    if (result.data.code === 1020 && result.data.message?.startsWith("Transaction in use by ")){
        const err = {
            status: 1020,
            statusText: result.data.message,
            responseURL: ""
        }
        yield put({ type: LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION_INFORMATION, data: result.data.message })
        yield put(push("/home"))

        //yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: err })
        //yield put(push("/home"));
    }
    return result;
}

// create sagas here

export function* saveInformationToRleaseSaga(action: any) {
    try {
        let freeText = undefined;
        for (const item of action.data.recordTypes.filter(t => action.data.saveOnly.indexOf(t.id) > -1) ){
            const saveItem = {
                transactionId: action.data.transactionId,
                active: item.state ? 1 : 0,
                id: item.id,
                text: item.text,
                fromDate: action.data.fromDate === null ? "" :(<Date>action.data.fromDate).toJSON(),
                toDate: action.data.toDate === null ? "" : (<Date>action.data.toDate).toJSON(),
                auditable: action.data.auditable
            }

            if (item.id === 31)
                freeText = item.text;

            if ((saveItem.fromDate && saveItem.toDate) || (!saveItem.fromDate && !saveItem.toDate))
                yield callAPI(saveInformationToReleaseAPI, { data: saveItem })    
        }

        const returnItem = action.data.recordTypes.map(t => ({
            allDates: action.data.allDates,
            checked: t.state,
            code: t.code,
            dateOfService: action.data.fromDate === null && action.data.toDate === null ? null :{
                model: "dateRange",
                from: action.data.fromDate === null ? "" : moment(action.data.fromDate).format("MM/DD/YYYY"),
                to: action.data.toDate === null ? "" : moment(action.data.toDate).format("MM/DD/YYYY"),
            },
            id: t.id,
            model: "releaseInfoItem",
            name: t.name,
            requestedItemID: t.id

        }))

        yield put({ type: DataEntryActionConstants.saveInformationToReleaseResponse, data: { returnItem, freeText: freeText } })

        //if(action.data.recordTypes.some(x => x.state === true)){
        //    yield put({ type: DataEntryActionConstants.GET_TRANSACTIONS_ATTRIBUTES_SAGA, data: action.data.transactionId })
        //}
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* updateUserTaskSaga(action: any) {
    try {
        yield callAPI(updateUserTaskAPI, action)
        yield put({ type: DataEntryActionConstants.getUserTasksRequest, data: action.data.transactionId })
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* addNewTaskSaga(action: any) {
    try {
        yield callAPI(adNewUserTaskAPI, action)
        yield put({ type: DataEntryActionConstants.getUserTasksRequest, data: action.data.transactionId })
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* createUserTaskCommentSagas(action: any) {
    try {
        yield callAPI(createUserTaskCommentAPI, action)
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* generateNextTransactionId(action: any) {
    try {
        let result;
        if (action.data.facilityId) {
            result = yield callAPI(generateNextTransactionIdAPI, { data: action.data.facilityId, context: action.data.context })

        } else {
            result = yield callAPI(generateNextTransactionIdAPI, { data: action.data, context: action.data.context })

        }
        if (isSuccessStatusCode(result.status) && result.data.id) {
            if(result.data.isReadOnly){
                yield put({ type: LandingPageActionConstants.SHOW_READONLY_TRANSACTION_REQUEST, data: result.data.id})
                yield put(push("/home"))
            }else{
                yield put({ type: DataEntryActionConstants.GET_NEXT_TRANSACTION_REQUEST, data: result.data.id })
                yield callAPI(unAssignTransactionAPI, { data: action.data.transactionId });
            }
        }
        else {
            yield put({ type: LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION_INFORMATION, data: 'No next transaction available.' })
            yield call(clearUserLocksSaga, {data: global.userID}) 
            yield put(push("/home"))
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getAssessmentResultsSaga(action: any) {
    try {
        const response = yield callAPI(getAssessmentResultsAPI, action);
        if(isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.GET_ASSESSMENT_RESULTS_SUCCESS, data: response.data})
        } else {
            yield put({ type: DataEntryActionConstants.GET_ASSESSMENT_RESULTS_FAILURE, data: response.transactionId})
        }
    } catch(err) {
        yield put ({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).message});
    }
}

export function* getReviewEntitiesSaga(action: any) {
    try {
        const response = yield callAPI(getReviewEntitiesAPI, action);
        if(isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.GET_REVIEW_ENTITIES_SUCCESS, data: response.data})
        } else {
            yield put({ type: DataEntryActionConstants.GET_REVIEW_ENTITIES_FAILURE, data: response.transactionId})
        }
    } catch(err) {
        yield put ({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).message});
    }
}

export function* getRequestTypesAndPurposes(action: any) {
    try {
        const result = yield callAPI(getRequestTypes, action)
        if (isSuccessStatusCode(result.status)) {
            let requestTypes = {}
            result.data.map((request: any) => {
                requestTypes = {
                    ...requestTypes,
                    [request.id]: {
                        ...request,
                        requestPurpose: request.requestPurpose.map(purpose => purpose.id)
                    }
                }
                return null
            })
            let purposeOfRequests = {}
            result.data.map((request: any) => {
                purposeOfRequests = {
                    ...purposeOfRequests,
                    [request.id]: request.requestPurpose,
                }
                return null
            })
            yield put({ type: DataEntryActionConstants.getRequestTypesAndPurposesSuccess, data: { requestTypes, purposeOfRequests } })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getRequestTypesAndPurposesFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })

    }

}

export function* getFacilityDiscloseInstitutions(action: any) {
    try {
        const result = yield callAPI(getDisclosingInstituionAPI, action)
        if (isSuccessStatusCode(result.status)) {
            yield put({ type: DataEntryActionConstants.getFacilityDiscloseInstitutionsSuccess, data: result.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getFacilityDiscloseInstitutionsFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* updatePHIdisclosedSaga(action: any) {
    try {
        yield callAPI(updatePHIdisclosedAPI, action)
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getDocumentByIdSaga(action: any) {
    try {
        const result = yield callAPI(getDocumentByIdAPI, action)
        if (isSuccessStatusCode(result.status)) {
            yield put({ type: DataEntryActionConstants.getDocumentByIdSuccess, data: result.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getDocumentByIdFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).message })
    }
}

export function* deleteDocumentSaga(action: any) {
    try {
        let result;

        if (action.doctype === "MED" || action.doctype === "AUX"){
            result = yield callAPI(deleteDocumentByIdAPI, action)
        }else{
            throw new Error("This saga can only delete AUX and MED files")
        }

        if (isSuccessStatusCode(result.status)) {
            yield put({ type: DataEntryActionConstants.deleteDocumentAPISuccess, data: result.data })
            yield put({ type: DataEntryActionConstants.deleteDocumentAPIInitial })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.deleteDocumentAPIFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getAdditionalChargeTypeList(action: any) {
    try {
        const result = yield callAPI(getAdditionalChargeTypeAPI, action)
        if (isSuccessStatusCode(result.status) && Array.isArray(result.data)) {
            yield put({ type: DataEntryActionConstants.getAdditionalChargeTypeSuccess, data: result.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getAdditionalChargeTypeFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }


}

export function* getShippingServices(action: any) {
    try {
        const result = yield callAPI(getShippingServicesAPI, action)
        if (isSuccessStatusCode(result.status)) {
            yield put({ type: DataEntryActionConstants.getShippingServicesSuccess, data: result.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getShippingServicesFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getCancellationReasonsSaga(action: any) {
    try {
        const result = yield callAPI(getCancellationReasonsAPI, action)
        if (isSuccessStatusCode(result.status)) {
            yield put({ type: DataEntryActionConstants.getCancellationReasonsSuccess, data: result.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getCancellationReasonsFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }


}

export function* getSelectedPHICodesSaga(action: any) {
    try {
        const result = yield callAPI(getSelectedPHICodesAPI, action)
        if (isSuccessStatusCode(result.status)) {
            yield put({ type: DataEntryActionConstants.getSelectedPHICodesSuccess, data: result.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getSelectedPHICodesFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })

    }

}

export function* getReleaseMethodOptions(action: any) {
    try {
        const result = yield callAPI(getReleaseMethodOptionsAPI, action)
        if (isSuccessStatusCode(result.status)) {
            yield put({ type: DataEntryActionConstants.getReleaseMethodOptionsSuccess, data: result.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getReleaseMethodOptionsFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }


}

export function* getAdditionalChargeRecords(action: any) {
    try {
        const result = yield callAPI(getAdditionalChargeRecordsAPI, action)
        if (isSuccessStatusCode(result.status) && Array.isArray(result.data)) {
            yield put({ type: DataEntryActionConstants.getAdditionalChargeRecordsSuccess, data: result.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getAdditionalChargeRecordsFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }

} 

export function* getBillToRequestorDetailsSaga(action: any) {
    try {
        const billToRequestorDetails = yield callAPI(getRequestorBillingDetailsAPI, { requestorBillingId: action.data })
        yield put({ type: DataEntryActionConstants.getRequestorBillingDetailsSuccess, data: billToRequestorDetails.data })

    } catch (err) {
        yield put({ type: DataEntryActionConstants.getRequestorBillingDetailsFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}



export function* getTransactionAttributes(action: any) {
    try {
        const transactionIdAttributesRes = yield callAPI(getTransactionAttributesAPI, action)
        if (isSuccessStatusCode(transactionIdAttributesRes.status)) {
            let transactionId = transactionIdAttributesRes.data.facility?.id + '-' + transactionIdAttributesRes.data.id
            const requestObject = {
                facilityId: transactionIdAttributesRes.data.facility?.id.toString(),
                transactionId: transactionId.toString()
            }
            const releaseMethodData = yield callAPI(getReleaseMethodOptionsAPI, { data: requestObject })
            if (isSuccessStatusCode(releaseMethodData.status)) {
                yield put({ type: DataEntryActionConstants.getReleaseMethodOptionsSuccess, data: releaseMethodData.data })
            }
            if (transactionIdAttributesRes.data.requestPurpose) {
                yield put({ type: DataEntryActionConstants.GET_TRANSACTIONS_ATTRIBUTES, data: transactionIdAttributesRes.data.requestPurpose })
            }
            if (transactionIdAttributesRes.data.patient) {
                const patientDetails = yield callAPI(get, Api.getTransactionPatientAttributes + transactionIdAttributesRes.data.patient.id + "?userID=" + global.userID + "&transactionid=" + action.data)
                yield put({ type: DataEntryActionConstants.GET_TRANSACTIONS_PATIENT_ATTRIBUTES, data: patientDetails.data })
            }
            if (transactionIdAttributesRes.data.facility.useDepartments || transactionIdAttributesRes.data.facility.useClinics) {
                try {
                    const result = yield callAPI(getDisclosingInstituionAPI, { data: transactionIdAttributesRes.data.facility.id })
                    if (isSuccessStatusCode(result.status)) {
                        yield put({ type: DataEntryActionConstants.getFacilityDiscloseInstitutionsSuccess, data: result.data })
                    }
                } catch (err) {
                    yield put({ type: DataEntryActionConstants.getFacilityDiscloseInstitutionsFailure })
                    yield put({
                        type: LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION_INFORMATION, 
                        data: "There was an error retrieving the list of " + (transactionIdAttributesRes.data.facility.useDepartments ? "departments" : "clinics")
                    })
                }
            }
            yield put({ type: DataEntryActionConstants.getTransactionDetailsSuccess, data: transactionIdAttributesRes.data })
            if (transactionIdAttributesRes.data.sendToRequestor?.id) {
                const requestorDetails = yield callAPI(getRequestorDetailsAPI, {
                    requestorId: transactionIdAttributesRes.data.sendToRequestor?.id,
                    requestorContactId: transactionIdAttributesRes.data.sendToContact?.id
                })
                yield put({ type: DataEntryActionConstants.getRequestorDetailsSuccess, data: requestorDetails.data })

            }
            if (transactionIdAttributesRes.data.billToContact?.id) {
                const billToRequestorDetails = yield callAPI(getRequestorBillingDetailsAPI, { requestorBillingId: transactionIdAttributesRes.data.billToContact?.id })
                yield put({ type: DataEntryActionConstants.getRequestorBillingDetailsSuccess, data: billToRequestorDetails.data })
            }
            if (transactionIdAttributesRes.data.sendToRequestor?.id) {
                yield put({ type: DataEntryActionConstants.getContactDetailsSuccess, data: [] })
                yield put(getContactSearchDetailsActionCreator(transactionIdAttributesRes.data.sendToRequestor?.id, true))
                const requestorContactDetails = yield callAPI(getContactDetailsAPI, { requestorId: transactionIdAttributesRes.data.sendToRequestor?.id, distinct: true })
                yield put({ type: DataEntryActionConstants.getContactDetailsSuccess, data: requestorContactDetails.data })
            }
        }
    } catch (err) {
        console.log("ERROR IN TRANSACTION DETAILS", err)
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }


}

export function* getFeeSchedules(action: any) {
    try {
        const feeSchedulesRes = yield callAPI(getFeeSchedulesAPI, action)
        if (isSuccessStatusCode(feeSchedulesRes.status)) {
            yield put({ type: DataEntryActionConstants.GET_FEE_SCHEDULE_SUCCESS, data: feeSchedulesRes.data })
            if(action.data.setDefault){
                yield put(getTransactionAttributesActionCreator(action.data.transactionId))
            }
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getFeeScheduleFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })

    }

}


export function* assignTransactionID(action: any) {
    try {
        const response = yield callAPI(assignTransactionIdAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.ASSIGN_TRANSACTION_ID, data: true })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })

    }

}

export function* unassignTransactionID(action: any) {
    try {
        const response = yield callAPI(unAssignTransactionAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.UNASSIGN_TRANSACTION_ID, data: false })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getTransactionNotes(action: any) {
    try {
        const response = yield callAPI(getTransactionNotesAPI, action)
        if (isSuccessStatusCode(response.status) && Array.isArray(response.data)) {
            const sortedResults = sortDateObject(response.data, "created").reverse()
            yield put({ type: DataEntryActionConstants.getTransactionNotesSuccess, data: sortedResults })
        } else {
            yield put({ type: DataEntryActionConstants.getTransactionNotesFailure })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }


}

export function* sendToRequestorData(action: any) {
    try {
        const response = yield callAPI(putSendToRequestorAPI, action)
        const requestObject = {
            transactionId: action.data.transactionID,
            userID: global.userID,
            sendToRequestorid: response.data.id,
        }
        yield put({ type: DataEntryActionConstants.updateBillingPaneFields, data: requestObject, updateData: true })
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.SEND_TO_REQUESTOR_DATA, data: true })
            yield put({ type: DataEntryActionConstants.getContactSeacrhDetailsSuccess, data: [] })
            // requestor verification notification for email
            if (response.data.notificationFlag && response.data.notificationFlag.isVerificationSent && action.data.requestType != '31') {
                yield put({ type: DataEntryActionConstants.requestorVerificationEmailSent })

            }
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* sendToRequestorContactData(action: any) {
    try {
        const response = yield callAPI(putSendToRequestorContactAPI, action)
        let requestObject1 = {
            requestorid: action.data.requestorID,
            data: { ...action.data.requestorDetails },
        }
        //yield put({ type: DataEntryActionConstants.UPDATE_SEND_TO_REQUESTOR_DATA_SAGA, data: requestObject1 })
        const requestObject = {
            transactionId: action.data.transactionID,
            userID: global.userID,
            sendToRequestorid: action.data.requestorID,
            sendToRequestorContactid: response.data.id
        }
        const updateedContactID = {
            data:{
              requestorcontactid:response.data.id
                    
            }
        } 
        yield put({type: DataEntryActionConstants.userInputUpdateDataEntryDetailRequestorContact, data: updateedContactID})
        yield put({ type: DataEntryActionConstants.updateBillingPaneFields, data: requestObject, updateData: true })
        if (isSuccessStatusCode(response.status)) {
            if(response.data && response.data.isVerified){
                yield put({ type: DataEntryActionConstants.requestorVerificationEmailSent })
            }
            yield put({ type: DataEntryActionConstants.SEND_TO_REQUESTOR_CONTACT_DATA, data: true })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }

}

export function* updateSendToRequestorDetails(action: any) {
    try {
        yield callAPI(updateSendToRequestorAPI, action)
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* updateBillToRequestorDetails(action: any) {
    try {
        action.transactionId = ReduxScope.transactionId
        yield callAPI(updateBillToContactDetailsAPI, action)
        if (Object.keys(action.data.data).indexOf("deliveryInstructions") > -1){
            yield put({ type: DataEntryActionConstants.updateDeliveryInstructionsStatus, data: API_SUCCESS })
            yield put({ type: DataEntryActionConstants.updateDeliveryInstructionsStatus, data: API_INITIAL })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
        if (Object.keys(action.data.data).indexOf("deliveryInstructions") > -1) {
            yield put({ type: DataEntryActionConstants.updateDeliveryInstructionsStatus, data: API_FAILURE })
            yield put({ type: DataEntryActionConstants.updateDeliveryInstructionsStatus, data: API_INITIAL })
        }
    }
}

export function* getRequestorSearchSaga(action: any) {
    try {
        const response = yield callAPI(getRequestorSearchDetailsAPI, action);
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.getRequestorSearchDetailsSuccess, data: response.data })
        } else {
            yield put({ type: DataEntryActionConstants.getRequestorSearchDetailsFailure })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getPatientSearchSaga(action: any) {
    try {
        const response = yield callAPI(getPatientSearchDetailsAPI, action);
        if (isSuccessStatusCode(response.status)) {
            let patientInfo: any = [];
            for (let eachRow of response.data) {
                let customPatientData: any = { ...eachRow, ...eachRow.contact }
                customPatientData.dob = moment(new Date(eachRow.dob)).format("MM/DD/YYYY");
                patientInfo.push(customPatientData)
            }
            yield put({ type: DataEntryActionConstants.GET_PATIENT_SEARCH_DETAILS, data: patientInfo })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.GET_PATIENT_SEARCH_DETAILS_FAILURE })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getTransactionDocumentsSaga(action: any) {
    try {
        const response = yield callAPI(getTransactionDocumentsAPI, action)
        if (isSuccessStatusCode(response.status) && Array.isArray(response.data)) {
            yield put({ type: DataEntryActionConstants.getTransactionDocumentsSuccess, data: response.data })
        } else {
            yield put({ type: DataEntryActionConstants.getTransactionDocumentsFailure })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getTransactionDocumentsFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }


}

export function* getRejectOptionsSaga(action: any) {
    try {
        const response = yield callAPI(getRejectOptionsAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.getRejectOptionsSuccess, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })

    }

}

export function* getContactRecordsDetails(action: any) {
    try {
        yield put({ type: DataEntryActionConstants.getContactSeacrhDetailsReset, data: [] })
        const response = yield callAPI(getContactDetailsAPI, action);
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.getContactSeacrhDetailsSuccess, data: response.data })
        } else {
            yield put({ type: DataEntryActionConstants.getContactSearchDetailsFailure })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* patchPatientNoteSaga(action: any) {
    try {
        yield callAPI(patchPatientsNotesAPI, action);
        let response = yield callAPI(get, Api.getTransactionPatientAttributes + action.data.patientid + "?userID=" + global.userID + "&transactionid=" + action.data.transactionId)
        yield put({ type: DataEntryActionConstants.GET_TRANSACTIONS_PATIENT_ATTRIBUTES, data: response.data })
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* patchRequestorNoteSaga(action: any) {
    try {
        yield callAPI(patchRequestorNotesAPI, action)
        const requestorDetails = yield callAPI(getRequestorDetailsAPI, {
            requestorId: action.data.requestorid,
            requestorContactId: action.data.contactId
        })
        yield put({ type: DataEntryActionConstants.getRequestorDetailsSuccess, data: requestorDetails.data })

    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* patchSendToRequestorContactSaga(action: any) {
    try {
        const response = yield callAPI(patchSendToRequestorContact, action);
        if (isSuccessStatusCode(response.status)) {
            // requestor verification notification for email
            if (response.data.notificationFlag && response.data.notificationFlag.isVerificationSent && action.requestType != '31') {
                yield put({ type: DataEntryActionConstants.requestorVerificationEmailSent })

            }
            if(action.data.data.transactionId === ReduxScope.transactionId){
                if (response.data.id !== action.data.data.requestorcontactid){
                    console.log(`testing contact ${response.data.id}`)
                    action.data.data.requestorcontactid = response.data.id;
                }
                yield put({type: DataEntryActionConstants.userInputUpdateDataEntryDetailRequestorContact, data: action.data})
                yield put({type: DataEntryActionConstants.getContactSeacrhDetailsSuccess })
            }
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* uploadMultipleDocumentsSaga(action: any) {
    try {
        const response = yield callAPI(action.data.blob ? uploadBlobDocumentAPI : uploadDocumentAPI, action)
        if (isSuccessStatusCode(response.status)) {
            if(response.data.verified){
                yield put({
                    type: DataEntryActionConstants.uploadDocumentSuccess, data: {
                        filename: action.data.fileName,
                        fileID: action.data.fileID,
                        error: null,
                        arePageizesValid: response.data.arePagesLetterSize,
                        invalidPagesNumbers: response.data.invalidPagesNumbers
                    },
                    timestamp: new Date()
                })
                yield put(push("/data"))
            }else{
                throw new Error("Verification failed. Please manually refresh.")
            }
        }
    } catch (err) {
        yield put({
            type: DataEntryActionConstants.uploadDocumentFailure, data: {
                filename: action.data.fileName,
                fileID: action.data.fileID,
                error: (err as any).message
            },
            timestamp: new Date()
        })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
        yield put({ type: DataEntryActionConstants.uploadDocumentInitial })
    }
}

export function* uploadDocumentSaga(action: any) {
    try {
        const response = yield callAPI(uploadDocumentAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({
                type: DataEntryActionConstants.uploadDocumentSuccess, data: {
                    filename: action.data.fileName,
                    fileID: action.data.fileID,
                    error: null,
                    arePageizesValid: response.data.arePagesLetterSize,
                    invalidPagesNumbers: response.data.invalidPagesNumbers
                },
                timestamp: new Date()
            })
            yield put(push("/data"))
        }
    } catch (err) {
        yield put({
            type: DataEntryActionConstants.uploadDocumentFailure, data: {
                filename: action.data.fileName,
                fileID: action.data.fileID,
                error: (err as any).message
            },
            timestamp: new Date()
        })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
        yield put({ type: DataEntryActionConstants.uploadDocumentInitial })
    }
}

export function* postCancellationReasonsSaga(action: any) {
    try {
        const response = yield callAPI(postCancellationReasonsAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.postCancellationReasonsSuccess })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* postQATransactionIssueSaga(action: any) {
    try {
        const response = yield callAPI(postQATransactionIssueAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.postQATransactionIssueSuccess})
            yield put(getQAIssuesActionCreator(action.transactionID));
            yield put(getQAPDIssuesActionCreator(action.transactionID));
        } else {
            yield put({ type: DataEntryActionConstants.postQATransactionIssueFailure})
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* resolveQATransactionIssueSaga(action: any) {
    try {
        const response = yield callAPI(resolveIssueAPI, action);
        if (isSuccessStatusCode(response.status)) {
            yield (put({ type: DataEntryActionConstants.resolveQATransactionIssueSuccess}));
            yield put(getQAIssuesActionCreator(action.transactionId));
            yield put(getQAPDIssuesActionCreator(action.transactionId));
        } else {
            yield (put({ type: DataEntryActionConstants.resolveQATransactionIssueFailure}));
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response });
    }
}

export function* removeQATransactionIssueSaga(action: any) {
    try {
        const response = yield callAPI(removeIssueAPI, action);
        if (isSuccessStatusCode(response.status)) {
            yield (put({ type: DataEntryActionConstants.removeQATransactionIssueSuccess}));
            yield put(getQAIssuesActionCreator(action.transactionId));
            yield put(getQAPDIssuesActionCreator(action.transactionId));
        } else {
            yield (put({ type: DataEntryActionConstants.removeQATransactionIssueFailure}));
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response });
    }
}

export function* getTransactionNoteTypesSaga(action: any) {
    try {
        const response = yield callAPI(getTransactionNoteTypesAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.getTransactionNoteTypesSuccess, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }

}

export function* updateTransactionNotesSaga(action: any) {
    try {
        yield callAPI(updateTransactionNotesAPI, action)
        yield callAPI(getTransactionNotes, { data: action.data.transactionId })
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* updateAdditionalChargesSaga(action: any) {
    try {
        yield callAPI(updateAdditionalChargesAPI, action)
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* rejectionContactOverrideSaga(action: any) {
    try {
        yield callAPI(rejectionContactOverrideAPI, action)
        yield put(goBack())
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* updateDocumentSaga(action: any) {
    try {
        yield callAPI(updateDocumentAPI, action)
        yield put(getTransactionDocuments(action.data.transactionId))
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getDocumentSourcesSaga(action: any) {
    try {
        const response = yield callAPI(getDocumentSourcesAPI, action)
        yield put({ type: DataEntryActionConstants.getDocumentSourcesSuccess, data: response.data })
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }


}

export function* getRejectionLetterSaga(action: any) {
    try {
        const response = yield callAPI(getRejectionLetterDataAPI, action);
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.getRejectionLetterDataAPISuccess, data: response.data })
            yield put({ type: DataEntryActionConstants.getRejectionLetterDataAPIInitial })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getPreviousTransactionSaga(action: any) {
    try {
        const response = yield callAPI(getPreviousTransactionAPI, action);
        if (isSuccessStatusCode(response.status) && response.data.id) {
            if(response.data.isReadOnly){
                yield put({ type: LandingPageActionConstants.SHOW_READONLY_TRANSACTION_REQUEST, data: response.data.id})
                yield put(push("/home"))
            }else{
                yield put({ type: DataEntryActionConstants.getPreviousTransactionAPISuccess, data: response.data })
            }
        }
        else if (!response.data.id) {
            yield put({ type: LandingPageActionConstants.HEADER_ASSIGN_TRANSACTION_INFORMATION, data: 'No previous transaction available.' })
            yield put(push("/home"))
        }
        else {
            yield put({ type: DataEntryActionConstants.getPreviousTransactionAPIFailure, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* updateBillingFieldsSaga(action: any) {
    try {
        const response = yield callAPI(updateBillingPaneFieldsAPI, action);
        if ((action.data.transactionId && action.updateData) && (ReduxScope.transactionId === action.data.transactionId)) {
            yield put(getTransactionAttributesActionCreator(action.data.transactionId))
        }

        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.SEND_TO_REQUESTOR_DATA, data: true })
            if (action.data.clinicdepartmentid){
                yield put({ type: DataEntryActionConstants.updateClinicIDResponse, data: action.data.clinicdepartmentid })
            }
            if (action.data.sendToRequestorid){
                const txid =  action.data.transactionId.split("-");
                yield put(getFeeScheduleData(txid[0], action.data.transactionId, true));
            }
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getPHICodesSaga(action: any) {
    try {
        const response = yield callAPI(getPHICodesAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.getPHICodesSuccess, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }


}

export function* getRetrieveProtocolsSaga(action: any) {
    try {
        const response = yield callAPI(retrieveProtocolAPI, action);
        if (isSuccessStatusCode(response.status) && Array.isArray(response.data)) {
            yield put({ type: DataEntryActionConstants.getRetrieveProtocolSuccess, data: response.data })
        } else {
            yield put({ type: DataEntryActionConstants.getRetrieveProtocolFailure })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }


}

export function* createPatientRecordSaga(action: any) {
    try {
        const response = yield callAPI(createPatientRecordAPI, action)
        if (isSuccessStatusCode(response.status)) {
            const requestObject = {
                transactionId: action.data.transactionId,
                userID: global.userID,
                patientDataid: response.data.id,
            }
            ///yield put({ type: DataEntryActionConstants.updateBillingPaneFields, data: requestObject, updateData: true })
            yield put(getTransactionAttributesActionCreator(action.data.transactionId))
            yield put({ type: DataEntryActionConstants.createPatientRecordSuccess, data: response.data })
            action.onSuccess && action.onSuccess()
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* postRejectionActionSaga(action: any) {
    try {
        const response = yield callAPI(postRejectionActionAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.POST_REJECTION_ACTION, data: response.data })
            yield callAPI(generateNextTransactionId, { data: {facilityId: action.data.facilityId, context: action.data.requestContext} })
        } else {
            yield put({ type: DataEntryActionConstants.POST_REJECTION_ACTION_FAILURE })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
        yield put({ type: DataEntryActionConstants.POST_REJECTION_ACTION_FAILURE })
    }
}

export function* getUserGroupsSaga(action: any) {
    try {
        const response = yield callAPI(getUserGroupsAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.getUserGroupsSuccess, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }


}

export function* getDisclosureReasonSaga(action: any) {
    try {
        const response = yield callAPI(getDisclosureReasonAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.GET_DISCLOSURE_REASON, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })

    }

}

export function* updateDisclosureInformationSaga(action: any) {
    try {
        const response = yield callAPI(updateDisclosureInformationAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.UPDATE_DISCLOSURE_INFORMATION, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getUserTasksSaga(action: any) {
    try {
        const response = yield callAPI(getUserTasksAPI, action)
        if (isSuccessStatusCode(response.status) && Array.isArray(response.data)) {
            let normalizedUserTaskComments = {}
            let taskIdsList: any[] = []
            const responseAPI = yield all(response.data.map(item => {
                taskIdsList.push(item.id)
                return call(getUserTaskCommentsAPI, { data: item.id })
            }))
            taskIdsList.map((id, index) => {
                const sortedComments = responseAPI ? sortDateObject(responseAPI[index].data, "createdDate").reverse() : []
                normalizedUserTaskComments = {
                    ...normalizedUserTaskComments,
                    [id]: sortedComments
                }
                return null
            })
            let totalTasks = getSortedTasks(response.data)
            yield put({ type: DataEntryActionConstants.getUserTasksSuccess, data: totalTasks, comments: normalizedUserTaskComments })
        } else {
            yield put({ type: DataEntryActionConstants.getUserTasksFailure })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }


}

export function* deleteTransactionTaskSaga(action: any) {
    try {
        const response = yield callAPI(deleteTransactionTaskAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.DELETE_TRANSACTION_TASK, data: action.data.usertaskId })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).message })
    }
}

export function* completeTransactionSaga(action: any) {
    try {
        const response = yield callAPI(completeTransactionAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.COMPLETE_TRANSACTION_REQUEST, data: response.data.message })
            if (response.data.code === 0) {
                let win = window.open(response.data.message)
                setTimeout(() => { win ? win.close() : console.log() }, 9000)
            }
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).message })
    }
}

export function* returnToQueueSaga(action: any) {
    try {
        const response = yield callAPI(returnToQueueAPI, action);
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.COMPLETE_TRANSACTION_REQUEST, data: response.data.message })
            if (response.data.code === 0) {
                let win = window.open(response.data.message)
                setTimeout(() => { win ? win.close() : console.log() }, 9000)
            }
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).message });
    }
}

export function* patchDetailsCompleteSaga(action: any) {
    try {
        yield callAPI(patchDetailsCompleteAPI, action)
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* patchRequestorCompleteSaga(action: any) {
    try {
        yield callAPI(patchRequestorCompleteAPI, action)     

        const billToRequestorDetails = yield callAPI(getRequestorBillingDetailsByTxAPI, action.data)
        yield put({ type: DataEntryActionConstants.getRequestorBillingDetailsSuccess, data: billToRequestorDetails.data })

    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* patchBillingCompleteSaga(action: any) {
    try {
        yield callAPI(patchBillingCompleteAPI, action)
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* patchPatientDetailsSaga(action: any) {
    try {
        yield callAPI(patchPatientDetailsAPI, action)
        yield put({ type: DataEntryActionConstants.updatePatientDetails, data: action.data })
    } catch (err) {
        yield put({ type: DataEntryActionConstants.patchPatientDetails })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getAuditTypesSaga() {
    try {
        const response = yield callAPI(getAuditTypesAPI)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.getAuditTypesSuccess, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getAuditTypesFailure, data: (err as any).response })
    }
}

export function* getOBOsSaga() {
    try {
        const response = yield callAPI(getOBOsAPI)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.getOBOsSuccess, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getOBOsFailure, data: (err as any).response })
    }
}

export function* getValidateSaga(action: any) {
    try {
        const response = yield callAPI(getValidateAPI, action)
        if (isSuccessStatusCode(response.status)) {
            if (response.data.length == 0) {
                yield put({ type: DataEntryActionConstants.getValidateSuccess, data: true })
            } else {
                yield put({ type: DataEntryActionConstants.getValidateFailure, data: false })
                yield put({
                    type: DataEntryActionConstants.POPUP_ERROR_STATE, data: {
                        popupErrorHeader: "Validation Errors Occurred",
                        popupErrorBody: "The following errors occurred when trying to complete the transaction request:",
                        popupErrorMessages: response.data.map(d => d.message),

                    }
                })
            }
        } else {
            yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: response.status })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getAuditTrailSaga(action: any) {
    try {
        const response = yield call(getAuditTrailAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.getAuditTrailResponse, data: response.data })
        } else {
            yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getQAErrorsSaga() {
    try {
        const response = yield callAPI(getQAErrorsAPI)
        if (isSuccessStatusCode(response.status)) {
            if (response.data.length > 0) {
                yield put({ type: DataEntryActionConstants.GET_QA_ERRORS_RESULTS_SUCCESS, data: response.data})
            }
        } else {
            yield put({ type: DataEntryActionConstants.GET_QA_ERRORS_RESULTS_FAILURE, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getSensitivityTypesSaga() {
    try {
        const response = yield callAPI(getAISensitivityTypesAPI)
        if (isSuccessStatusCode(response.status)) {
            if (response.data.length > 0) {
                yield put({ type: DataEntryActionConstants.GET_SENSITIVITY_TYPES_RESULTS_SUCCESS, data: response.data})
            }
        } else {
            yield put({ type: DataEntryActionConstants.GET_SENSITIVITY_TYPES_RESULTS_FAILURE, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getQAIssuesSaga(action: any) {
    try {
        const response = yield callAPI(getQAIssuesAPI, action)
        if (isSuccessStatusCode(response.status)) {
            if (response.data.length > 0) {
                yield put({ type: DataEntryActionConstants.GET_QA_ISSUES_RESULTS_SUCCESS, data: response.data})
            }
        } else {
            yield put({ type: DataEntryActionConstants.GET_QA_ISSUES_RESULTS_FAILURE, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getQAPDIssuesSaga(action: any) {
    try {
        const response = yield callAPI(getQAPDIssuesAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.GET_QA_PD_ISSUES_RESULTS_SUCCESS, data: response.data})            
        } else {
            yield put({ type: DataEntryActionConstants.GET_QA_PD_ISSUES_RESULTS_FAILURE, data: response.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getDuplicatesSaga(action: any) {
    try {
        const response = yield callAPI(getDuplicatesAPI, action)
        if (isSuccessStatusCode(response.status)) {
            if (response.data.length > 0) {
                yield put({ type: DataEntryActionConstants.SHOW_DUPLICATE_TRANSACTIONS, data: { duplicates: response.data } })
            }
        } else {
            yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: response.status })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getTransactionDocumentsToCopySaga(action: any) {
    try {
        const response = yield callAPI(getTransactionDocumentsOnFileAPI, action)
        if (isSuccessStatusCode(response.status) && Array.isArray(response.data)) {
            yield put({ type: DataEntryActionConstants.getTransactionDocumentsToCopySuccess, data: response.data })
        } else {
            yield put({ type: DataEntryActionConstants.getTransactionDocumentsFailure })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getTransactionDocumentsFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}
export function* updateTransactionOnFileSaga(action: any) {
    try {
        const response = yield callAPI(patchTransactionOnFileAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put(getTransactionDocuments(action.data.transactionId))
            yield put({ type: DataEntryActionConstants.patchTransactionOnFileSuccess })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.patchTransactionOnFileFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* postWPSTokenSaga(action: any) {
    try {
        const response = yield callAPI(postWebPrintScanAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.postWPSResponse, data: response.data })
        } else {
            yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: response.status })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* readBarCodeDDsSaga(action: any) {
    try {
        const response = yield callAPI(readDocumentDDSBarcodeAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.readBarCodeDDSSuccess, data: response.data })
        } else {
            yield put({ type: DataEntryActionConstants.readBarCodeDDSFailure })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.readBarCodeDDSFailure })
    }
}

export function* getFacilitiesAdditionalFieldsSaga(action: any) {
    try {
        const response = yield callAPI(getFacilitiesAdditionalFieldsAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.getFacilityAdditionalFieldsSuccess, data: response.data })
        } else {
            yield put({ type: DataEntryActionConstants.getFacilityAdditionalFieldsSuccess })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getFacilityAdditionalFieldsSuccess })
    }
}


export function* getWaitAndHoldReasonsSaga(action: any) {
    try {
        const result = yield callAPI(getWaitAndHoldReasonsAPI, action)
        if (isSuccessStatusCode(result.status)) {
            yield put({ type: DataEntryActionConstants.getWaitAndHoldReasonsSuccess, data: result.data })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.getWaitAndHoldReasonsFailure })
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    } finally {
        yield put({ type: DataEntryActionConstants.initialTxCountIncrement })
    }
}
export function* postWaitAndHoldReasonsSaga(action: any) {
    try {
        const response = yield callAPI(postWaitAndHoldReasonsAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.postWaitAndHoldReasonsSuccess })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}
export function* patchTransactionContinueSaga(action: any) {
    try {
        const response = yield callAPI(patchTransactionContinueAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.patchTransactionContinueSuccess })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* putAuditDigitalFaxValidationSaga(action: any) {
    try {
        const response = yield callAPI(putAuditDigitalFaxValidationAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.putDigitalFaxValidationSuccess })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* putAuditTransactionSensitiveInfoSaga(action: any) {
    try {
        const response = yield callAPI(putAuditTransactionSensitiveInfoAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.putAuditTransactionSensitiveInfoSuccess })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* getStatusIndicatorSaga(action: any) {
    try {
        const response = yield call(getStatusIndicatorAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.getStatusIndicatorResponse, data: response.data })
        } else {
            yield put({ type: DataEntryActionConstants.getStatusIndicatorResponse })
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response
})
    }
}

export function* userViewedDocumentSaga(action: any){
    try {
        const response = yield call(putUserViewDocumentAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.userViewedDocumentSucess})
        } else{
            yield put({ type: DataEntryActionConstants.userViewedDocumentFailure})
        }
        
    } catch (err) {
        yield put({ type: DataEntryActionConstants.userViewedDocumentFailure})
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response})
    }
}

export function* getAIFeedbackSaga() {
    try {
        const response = yield call(getAIFeedbackAPI);
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.GET_AI_FEEDBACK_SUCCESS, data: response.data });
        } else {
            yield put({ type: DataEntryActionConstants.GET_AI_FEEDBACK_FAILURE });
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.GET_AI_FEEDBACK_FAILURE });
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response });
    }
}

export function* submitAIFeedbackSaga(action: any) {
    try {
        const response = yield call(submitAIFeedbackAPI, action);
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.SUBMIT_AI_FEEDBACK_SUCCESS });
        } else {
            yield put({ type: DataEntryActionConstants.SUBMIT_AI_FEEDBACK_FAILURE });
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.SUBMIT_AI_FEEDBACK_FAILURE });
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response });
    }
}

export function* deleteAIFeedbackSaga(action: any) {
    try {
        const response = yield call(deleteAIFeedbackAPI, action);
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.DELETE_AI_FEEDBACK_SUCCESS });
        } else {
            yield put({ type: DataEntryActionConstants.DELETE_AI_FEEDBACK_FAILURE });
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.DELETE_AI_FEEDBACK_FAILURE });
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response });
    }
}

export function* createAIReviewEntitySaga(action: any) {
    try {
        const response = yield callAPI(createAIReviewEntityAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.createAIReviewEntitySuccess })
        } else {
            yield put({ type: DataEntryActionConstants.createAIReviewEntityFailure });
        }
    } catch (err) {
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}

export function* uploadReplaceDocumentSaga(action: any){
    try{
        const response = yield callAPI(action.data.blob ? uploadUpdateBlobDocumentAPI : uploadUpdateDocumentAPI, action)
        if (isSuccessStatusCode(response.status)) {
            yield put({ type: DataEntryActionConstants.updateReplaceDocumentSuccess })
            yield put({ type: DataEntryActionConstants.uploadDocumentSuccess })
        } else {
            yield put({ type: DataEntryActionConstants.updateReplaceDocumentFailure });
            yield put({ type: DataEntryActionConstants.uploadDocumentFailure });
        }
    }catch(err){
        yield put({type: DataEntryActionConstants.updateReplaceDocumentFailure})
        yield put({type: DataEntryActionConstants.uploadDocumentFailure})
        yield put({ type: DataEntryActionConstants.API_ERROR_STATE, data: (err as any).response })
    }
}
