import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import WebViewer, { Core, WebViewerInstance} from '@pdftron/webviewer';
import { connect } from "react-redux";
import AddReviewEntityPopup from "./AddReviewEntityPopup";
import { createQAReviewEntityCreator, getSensitivityTypesActionCreator, qaReviewEntityProps, getReviewEntitiesActionCreator } from "../../components/dataEntryDetails/DataEntryDetailsDucks";
import { Console } from 'console';

interface IpropType {
    fileData: any,
    saveTrigger: boolean,
    mergeTrigger: boolean,
    getFileData: Function,
    canSave?: boolean,
    setCanSave?: Function, 
    license: string,
    deleteFile?: Function,
    pageNumber: number,
    fileDesc: string,
    getAISensitivityTypes?: any,
    sensitivityTypes?: any,
    createQAReviewEntity: Function,
    transactionId: string,
    getReviewEntities: Function,
    reviewEntities: any,
    currentFileType?: string,
    refresh: boolean,
}

var pdfViewerInstance;

function PdfViewer(props: IpropType) {
    const viewerDiv = useRef<HTMLDivElement | null>(null)
    const [viewerInstance, setViewerInstance] = useState<WebViewerInstance | undefined>()
    const [originalPageCount, setOriginalPageCount] = useState(0);
    const [showReviewEntityForm, setShowReviewEntityForm] = useState(false);
    const [annotation, setAnnotation] = useState<qaReviewEntityProps | undefined>();
    const [selectedAnnotation, setSelectedAnnotation] = useState<Core.Annotations.Annotation>();
    const [selectedAnnotationManager, setSelectedAnnotationManager ] = useState<Core.AnnotationManager>();

    const mergeFileInput = useRef<any>(null)

    const handleFileInput = (e) => {

        viewerInstance?.Core.createDocument(e.target.files[0]).then(doc => {
            const sourcePages = viewerInstance.Core.documentViewer.getDocument().getPageCount();
            viewerInstance.Core.documentViewer.getDocument().insertPages(doc, undefined, sourcePages + 1);
        })
    }
 
    const toggleReviewEntityForm = () => {
        setShowReviewEntityForm(!showReviewEntityForm)
    }

    function OnAddEntityPressed() {
        setSelectedAnnotationManager(viewerInstance?.Core.annotationManager ?? pdfViewerInstance.Core.annotationManager);
        const annotation = viewerInstance ? viewerInstance.Core.annotationManager.getSelectedAnnotations()[0] : pdfViewerInstance.Core.annotationManager.getSelectedAnnotations()[0];
        setSelectedAnnotation(viewerInstance ? viewerInstance.Core.annotationManager.getSelectedAnnotations()[0] : pdfViewerInstance.Core.annotationManager.getSelectedAnnotations()[0]);
        
        //annotation.FillColor = "{R: 204, G: 255, B: 204, A: 1}";
        //annotation.setStyles({FillColor: new Annotations.Color(200, 200, 200)});
        const rect = annotation.getRect();
        var page = annotation.getPageNumber() - 1;
        var leftSide = rect.x1;
        var topSide = rect.y1;
        var rightSide = rect.x2;
        var bottomSide = rect.y2;
        var spanText = annotation.ek['trn-annot-preview'];
        var doc = props.fileData.file.name + ".pdf";
        var orgId = props.transactionId.substring(0, props.transactionId.indexOf("-"));
        var reqId = props.transactionId.substring(props.transactionId.indexOf("-") + 1, props.transactionId.length);
        var userId = global.userID;

        let data = {
            typeId: "none",
            documentFilename: props.fileData.file.name,
            page: annotation.getPageNumber() - 1,
            x1: rect.x1,
            y1: rect.y1,
            x2: rect.x2,
            y2: rect.y2,
            highlightText: annotation.ek['trn-annot-preview'],
            organizationID: Number(props.transactionId.substring(0, props.transactionId.indexOf("-"))),
            requestBufferID: Number(props.transactionId.substring(props.transactionId.indexOf("-") + 1, props.transactionId.length)),
            userId: global.userID
        }

        setAnnotation(data);

        props.getAISensitivityTypes();
        toggleReviewEntityForm();
    }

    useEffect( () => {
        if(viewerInstance) {
            viewerInstance.Core.documentViewer.setCurrentPage(props.pageNumber, true)
        }
    }, [props.pageNumber])

    useEffect(() => {
        if(props.saveTrigger){
            const getFileData = async () => {
                if (viewerInstance) {
                    const { documentViewer, annotationManager } = viewerInstance.Core;
                    const doc = documentViewer.getDocument();
                    const xfdfString = await annotationManager.exportAnnotations();
                    const data = await doc.getFileData({
                        // saves the document with annotations in it
                        xfdfString
                    });
                    const arr = new Uint8Array(data);
                    const blob = new Blob([arr], { type: 'application/pdf' });
                    return blob
                }
            }

            const pagesRemoved = originalPageCount - (viewerInstance?.Core.documentViewer.getPageCount() || 0);

            getFileData().then(blob => {
                props.getFileData && props.getFileData({
                    ...props.fileData,
                    blobData: blob,
                    pagesDeleted: pagesRemoved
                })
            })
        }
    },[props.saveTrigger])

    useEffect(() => {
        if(props.mergeTrigger){
            mergeFileInput.current && mergeFileInput.current.click();
        }
    }, [props.mergeTrigger])

    useEffect(() => {
        if (props.fileData?.filePath) {
            if (viewerInstance){
                const fixedPath = props.fileData.filePath.replace(/^\./,'')
                viewerInstance.UI.loadDocument(fixedPath, {extension: 'pdf'})
                viewerInstance.Core.documentViewer.setCurrentPage(props.pageNumber, true);
                viewerInstance.UI.updateElement("markSensitiveInfo",{
                    type: "actionButton",
                    img: "icon-tool-pen-highlight",
                    onClick: OnAddEntityPressed,
                    title: "Mark Sensitive Information"
                });
                if ( props.fileData.type?.role === "RELEASE"){
                    viewerInstance.UI.disableElements(['thumbDelete'])
                    viewerInstance.UI.disableElements(['deletePage'])
                }else{
                    viewerInstance.UI.enableElements(['thumbDelete'])
                    viewerInstance.UI.enableElements(['deletePage'])
                }

            }else{
                WebViewer({
                    path: 'lib/pdftron',
                    initialDoc: props.fileData.filePath,
                    extension: 'pdf',
                    licenseKey: props.license,
                    css: 'assets/css/pdfviewer.css'
                }, viewerDiv.current as HTMLDivElement)
                .then(instance => {
                    //instance.UI.disableElements(['header'])
                    instance.UI.disableElements(['selectToolButton'])
                    
                    instance.UI.disableElements(['pageAdditionalControlsHeader'])
                    instance.UI.disableElements(['moveToTop'])
                    instance.UI.disableElements(['moveToBottom'])

                    instance.UI.disableElements(['pageInsertionHeader'])
                    instance.UI.disableElements(['insertPageAbove'])
                    instance.UI.disableElements(['insertPageBelow'])

                    instance.UI.disableElements(['pageManipulationHeader'])
                    instance.UI.disableElements(['replacePage'])
                    instance.UI.disableElements(['extractPage'])
                    instance.UI.disableElements(['insertPage'])
                    //instance.UI.disableElements(['deletePage'])
                    instance.UI.disableElements(['thumbnailsControlInsert'])
                    //instance.UI.disableElements(['rotateHeader'])
                    //instance.UI.disableElements(['rotateClockwiseButton'])
                    //instance.UI.disableElements(['rotateCounterClockwiseButton'])


                    instance.UI.disableElements(['viewControlsDivider1'])

                    instance.UI.disableElements(['toggleNotesButton']) 
                    instance.UI.disableElements(['toolsHeader'])
                    instance.UI.disableElements(['ribbons'])
                    instance.UI.disableElements(['leftPanelTabs'])
                    //instance.UI.disableElements(['pageManipulationOverlayButton'])
                    //instance.UI.disableElements(['thumbRotateClockwise'])
                    //instance.UI.disableElements(['contextMenuPopup'])

                    instance.UI.disableElements(['textUnderlineToolButton'])
                    instance.UI.disableElements(['textSquigglyToolButton'])
                    instance.UI.disableElements(['textStrikeoutToolButton'])
                    instance.UI.disableElements(['linkButton'])
                    
                    instance.UI.disableElements(['annotationCommentButton'])
                    instance.UI.disableElements(['annotationStyleEditButton'])
                    instance.UI.disableElements(['linkButton'])

                    instance.UI.disableElements(['stickyToolButton'])
                    instance.UI.disableElements(['freeHandToolButton'])
                    instance.UI.disableElements(['freeHandHighlightToolButton'])
                    instance.UI.disableElements(['freeTextToolButton'])
                    instance.UI.disableElements(['markInsertTextToolButton'])
                    instance.UI.disableElements(['markReplaceTextToolButton'])

                    instance.UI.disableElements(['linkAnnotationUnlinkButton'])

                    if (props.canSave)
                    {
                        instance.UI.annotationPopup.add([{
                            type: "actionButton",
                            img: "icon-tool-pen-highlight",
                            onClick: OnAddEntityPressed,
                            title: "Mark Sensitive Information",
                            id:"markSensitiveInfo",
                            dataElement:"markSensitiveInfo"
                        }]);
                    }

                    if ( props.canSave !== undefined && !props?.canSave ){
                        instance.UI.disableElements(['thumbDelete'])
                        instance.UI.disableElements(['deletePage'])
                    }else{
                        instance.Core.documentViewer.addEventListener('documentLoaded', () => {
                            setOriginalPageCount(instance.Core.documentViewer.getPageCount());
                        });

                        instance.Core.documentViewer.addEventListener('documentUnloaded', () => {
                            props.setCanSave && props.setCanSave(false);
                        });

                        if (props.deleteFile){
                            instance.UI.updateElement('thumbDelete', {
                                onClick: () => {
                                    const selectedPages = instance.UI.ThumbnailsPanel.getSelectedPageNumbers();
                                    if(selectedPages.length == 0){
                                        selectedPages.push(1);
                                    }
                                    const doc = instance.Core.documentViewer.getDocument();
                                    props.deleteFile && props.deleteFile(
                                        selectedPages.length == instance?.Core.documentViewer.getPageCount(),
                                        () => { doc.removePages(selectedPages); }
                                    );
                                }
                            });
                        }
                    }
                    setViewerInstance(instance);
                    pdfViewerInstance = instance;

                    const { documentViewer } = instance.Core;
                    const document = instance.UI.iframeWindow.document;
                
                    instance.UI.setHeaderItems(header => {
                        // Create a div to hold the page info
                        const pageInfoDiv = document.createElement('div');
                        pageInfoDiv.style.padding = '0.5em';
                        pageInfoDiv.style.fontSize = '14px';
                        pageInfoDiv.style.color = '#000'; // Ensure text color is visible

                        let totalPages;
                        documentViewer.addEventListener('documentLoaded', () => {
                            totalPages = documentViewer.getPageCount();
                        });

                        // Function to update the page info text
                        const updatePageInfo = () => {
                            let currentPage = documentViewer.getCurrentPage();
                            
                            // Create an input element for the current page number
                            const pageInput = document.createElement('input');
                            pageInput.type = 'number';
                            pageInput.value = currentPage.toString();
                            pageInput.min = "1";
                            pageInput.max = totalPages;
                            pageInput.style.width = '40px';
                            pageInput.style.textAlign = 'center';

                            // Update the page when the user inputs a number
                            pageInput.addEventListener('change', () => {
                            let inputPage = parseInt(pageInput.value);
                            if (inputPage >= 1 && inputPage <= totalPages) {
                                documentViewer.setCurrentPage(inputPage, false);
                            } else {
                                // Reset the input value if out of bounds
                                pageInput.value = currentPage.toString();
                            }
                            });
                            // Clear the existing content and add the input box and total page count
                            pageInfoDiv.innerHTML = ''; 
                            pageInfoDiv.appendChild(pageInput);
                            pageInfoDiv.appendChild(document.createTextNode(` / ${totalPages}`));
                            pageInfoDiv.style.cssText = `color: var(--color=gray-2);`
                        };

                        documentViewer.addEventListener('documentLoaded', () => {
                            totalPages = documentViewer.getPageCount();
                            updatePageInfo();
                        });

                        // Listen for page changes and update the page info
                        documentViewer.addEventListener('pageNumberUpdated', updatePageInfo);

                        // Create a custom element for the header
                        const newCustomElement = {
                        type: 'customElement',
                        render: () => pageInfoDiv,
                        };

                        // Add the custom element to the header
                        header.push(newCustomElement);
                      });
                });
            }
        } else if  (viewerInstance) {
            viewerInstance?.UI.dispose();

            setViewerInstance(undefined);
        }

    }, [props.fileData?.filePath, props.refresh])
    
    return (
        <>
            <div className='webviewer' ref={viewerDiv} />
            <div className="file-uploader" style={{ "visibility": "hidden" }}>
                <input type="file" accept='application/pdf'  ref={mergeFileInput} onChange={handleFileInput} />
            </div>
            {showReviewEntityForm ? ( 
                <AddReviewEntityPopup
                toggleForm={toggleReviewEntityForm}
                showForm={showReviewEntityForm}
                setShowReviewEntityForm={setShowReviewEntityForm}
                sensitivityTypes={props.sensitivityTypes}
                createQAReviewEntity={props.createQAReviewEntity}
                annotation={annotation}
                selectedAnnotation={selectedAnnotation ?? new Core.Annotations.Annotation()}
                getReviewEntities={props.getReviewEntities}
                transactionId={props.transactionId}
                reviewEntities={props.reviewEntities}
                selectedAnnotationManager = {selectedAnnotationManager ?? new Core.AnnotationManager(viewerInstance ?? pdfViewerInstance)}
            />) : null
            }
        </>
    );
}

function mapStateToProps(state: any, props: any) {
    return {
        license: state.loginState.featureFlags.ptlk,
        pageNumber: state.dataEntryState.pageNumber,
        fileDesc: state.dataEntryState.fileDesc,
        sensitivityTypes : state.dataEntryState.getSensitivityTypes,
        annotation: state.dataEntryState.annotation,
        reviewEntities: state.dataEntryState.reviewEntities,
        currentFileType: state.dataEntryState.currentFileType,
    }
}
 
export function mapDispatchToProps(dispatch: Function) {
    return {
        getAISensitivityTypes: () => dispatch(getSensitivityTypesActionCreator()),
        createQAReviewEntity: (reviewEntity: qaReviewEntityProps) => dispatch(createQAReviewEntityCreator(reviewEntity)),
        getReviewEntities: (transactionId: string) => dispatch(getReviewEntitiesActionCreator(transactionId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfViewer);
