import React from "react";
import { Col, Row, Modal } from "react-bootstrap";
import IconWithButton from "../IconWithButton/IconWithButton";
import "../../components/dataEntryDetails/DataEntryDetails.scss";
import { } from '../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants';
import { qaReviewEntityProps } from "../../components/dataEntryDetails/DataEntryDetailsDucks";
import { Core } from '@pdftron/webviewer';

interface IpropType {
    toggleForm?: any,
    showForm?: any, 
    userAttributes?: any, 
    transactionId?: any
    refreshIssues?: any
    setShowReviewEntityForm?: any,
    sensitivityTypes?: any,
    annotation?: qaReviewEntityProps,
    spanText?: string,
    documentFileName?:string,
    page?: any,
    leftSide?: any,
    topSide?: any,
    rightSide?: any,
    bottomSide?: any,
    userId?: any,
    createQAReviewEntity: Function
    selectedAnnotation: Core.Annotations.Annotation
    getReviewEntities: Function
    reviewEntities?: any
    selectedAnnotationManager: Core.AnnotationManager
}

const AddReviewEntityForm = (props:IpropType) => {
    const onClickCancel = () => {
        props.setShowReviewEntityForm(false);
    }

    function hexToRgb(hex) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : {
          r: 200,
          g: 200,
          b: 200
        };
      }

    const onClickSaveIssue = async (event) => {
        event.preventDefault();
        let element = document.querySelector('#sensitivity-type') as HTMLSelectElement;
        let sensitivityTypeValue = element.options[element.selectedIndex].value;
        
        if (sensitivityTypeValue === ""){
            alert("Please set a sensitivity type.")
        }
        else {      
            let colorValue = element.options[element.selectedIndex].dataset.color;
            var rgbColor = hexToRgb(colorValue);
            props.selectedAnnotation.Color.R = rgbColor?.r ?? 0;
            props.selectedAnnotation.Color.G = rgbColor?.g ?? 0;
            props.selectedAnnotation.Color.B = rgbColor?.b ?? 0;
            
            //gather the data
            let data = {
                typeId: sensitivityTypeValue,
                documentFilename: props.annotation?.documentFilename,
                page: props.annotation?.page,
                x1: props.annotation?.x1,
                y1: props.annotation?.y1,
                x2: props.annotation?.x2,
                y2: props.annotation?.y2,
                highlightText: props.annotation?.highlightText,
                organizationID: props.annotation?.organizationID,
                requestBufferID: props.annotation?.requestBufferID,
                userId: props.annotation?.userId
            }

            //post it
            await Promise.resolve(PostData(data));

            props.setShowReviewEntityForm(false);
            alert("The User Review Sensitive Entity record has been saved.");
            props.getReviewEntities(props.transactionId);
            props.selectedAnnotationManager.deselectAnnotations([props.selectedAnnotation]);
        }
    };

    async function PostData(data){
        await props.createQAReviewEntity(data);
    }

    return (
        <div>
            <Modal className="cancel-dimension information-prompt" show={true}>
                <div className="alert-container">
                    <Modal.Title className="cancel-header">
                        <span className="info-icon"></span>
                        <div className="cancel-header-text">Add Review Sensitive Entity</div>
                    </Modal.Title>
                    <Modal.Body>
                        <div style={{marginLeft:'2em'}}>
                            <Row>
                                <Col md={10} xs={12}>
                                    <p className={"form-label"}>Please choose the Sensitivity Type and then Save.  Otherwise, click Cancel.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10} xs={10}>
                                    {/* <label key="sensitivity-type-label" id="sensitivity-type-label">Sensitivity Type</label> */}
                                    <select
                                        className="input-field form-control"
                                        name="sensitivityType"
                                        id="sensitivity-type"
                                        title="Sensitivity Type"
                                        key={"sensitivity-type-select"}
                                        placeholder="Select Sensitivity Type"
                                    >
                                        <option id="SelectSensitivityType" key="SelectSensitivityType" value="">Select Sensitivity Type</option>
                                        {props.sensitivityTypes && props.sensitivityTypes.map(fb =>
                                            (<>
                                                <option id={fb.docAISensitivityTypeId} key={fb.docAISensitivityTypeId} value={fb.docAISensitivityTypeId} data-color={fb.annotationColorCode}>
                                                    {fb.docAISensitivityType}
                                                </option>
                                            </>)
                                        )}
                                    </select>
                                </Col>
                            </Row>
                            <Row className="button-align">
                                <Col xs={3} md={3}>
                                    <IconWithButton
                                        buttonText={"Save"}
                                        onButtonClick={onClickSaveIssue}
                                        hideArrowIcon={true}
                                        isSave={true}
                                    />
                                </Col>
                                <Col xs={1} md={1} className="button-position-cancel">
                                    <a href="#" onClick={onClickCancel} className="modal-cancel">
                                        Cancel
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
}

export default (AddReviewEntityForm);