import React, { useRef, useState, useEffect } from 'react';
import useEvent from 'react-use-event-hook';
import '../DataEntryDetails.scss'
import IconWithButton from '../../../common/IconWithButton/IconWithButton';
import UploadFileModal from '../../../common/modal/UploadFileModal'
import UploadProgressBars from '../../../common/uploadProgressBars/UploadProgressBars'
import { Link } from 'react-router-dom'
import RecordsTable from '../../../common/table/RecordsTable';
import { setGlobalId } from '../../../common/utils/appUtils';
import { documentURL } from '../../../services/Network';
import '../DataEntryDetails.scss';
import MedicalRecordsRetrieve from '../../../common/table/medicalRecordsRetrieve/MedicalRecordsRetrieve';
import ButtonWithDropdown from '../../../common/buttonWithDropdown/ButtonWithDropdown';
import CopyTransactionModal from '../../../common/modal/CopyTransactionModal';
import { connect } from "react-redux";
import { resetTransactionDocumentsToCopy, postWPSToken, readBarCodeDDSRequest
    , getAuditTrail,  userViewedDocumentRequest, setCurrentFileTypeActionCreator, updateReplaceDocucmentActionCreator } from '../../../components/dataEntryDetails/DataEntryDetailsDucks';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Button, Tooltip } from "react-bootstrap";
import { FormConstants } from './DataEntryFieldsConstants';
import moment from 'moment';
import LoadingSpinner from "../../../common/loadingSpinner/loadingSpinner";
import ErrorMessagePopup from '../../../common/alertPopup/ErrorMessagePopup';
import { API_FAILURE, API_SUCCESS } from '../../../common/constants/ApiConstants';
import PdfViewer from '../../../common/pdfViewer/PdfViewer';
import { access } from 'fs';
import { de } from 'date-fns/locale';
import { FeatureFlagsType } from '../../../common/customTypes/FeatureFlagsType';
import ReleaseDocumentMissingPopUp from '../../../common/alertPopup/ReleaseDocumentMissingPopUp';

const MED = "MED"
const AUX = "AUX"

interface IpropType {
    activeTabId: number,
    documentsList: Array<any>,
    uploadMultipleDocuments: Function,
    transactionId: string,
    transactionDetails?: any,
    getTransactionDocuments: Function,
    getDocumentById: Function,
    updateDocument: Function,
    documentSources: any,
    deleteDocument: Function,
    deleteAuxDocument: Function,
    resetTransactionDocumentsToCopy?: Function,
    copyTransactionComplete?: false,
    postWPSToken?: Function,
    wpsToken?: string,
    formState?: any,
    readBarCodeDDSRequest?: Function,
    deleteDocumentSuccess?: Boolean,
    completeTransactionFinished?: Boolean,
    getAuditTrailData?: Function,
    uploadDocumentAPIStatus?: any,
    uploadDocumentFailure?: any,
    uploadDocumentSuccess?: any,
    featureFlags?: FeatureFlagsType,
    patientDob?: any,
    hasUserMadeAnyChanges: boolean,
    userHasMadeChanged: Function,
    userViewedDocumentRequest?: Function,
    currentFile: string,
    setCurrentFileType: Function,
    currentFileType?: string,
    updateReplaceDocument?: Function
}

function DataEntryDetailsBody(props: IpropType) {
    const usePrevious = (value)  => {
        // The ref object is a generic container whose current property is mutable ...
        // ... and can hold any value, similar to an instance property on a class
        const ref = useRef();
        // Store current value in ref
        useEffect(() => {
          ref.current = value;
        }, [value]); // Only re-run if value changes
        // Return previous value (happens before update in useEffect above)
        return ref.current;
      }
   
    const defaultDocument = props.documentsList.find(row => row.type?.role == "RELEASE") ??
        props.documentsList.find(row => row.type?.role == "REQ" || row.type?.role == "INT");

    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const [showRecordGrid, setShowRecordGrid] = useState(true);
    const {activeTabId } = props;
    const [selectedDocumentId, setSelectedDocumentId] = useState(defaultDocument?.id);
    const [selectedMedDocumentIndex, setSelectedMedDocumentIndex] = useState<any>('');
    const [userId, setUserId] = useState(global.userID)
    const [fileType, setFileType] = useState("");
    const [showCopyTransaction, setShowCopyTransaction] = useState(false);
    const [updatedRecords, setUpdatedRecords] = useState<any[]>([]);
    const [requestFiles, setRequestFiles] = useState<any[]>([]);
    const [internalFiles, setInternalFiles] = useState<any[]>([]);
    const [auxiliaryRecords, setAuxiliaryRecords] = useState<any[]>([]);
    const [notificationFiles, setNotificationFiles] = useState<any[]>([]);
    const [reviewDocuments, setReviewDocuments] = useState<any[]>([]);
    const [otherFiles, setOtherFiles] = useState<any[]>([]);
    const [releaseFiles, setReleaseFiles] = useState<any[]>([]);
    const [coverFiles, setcoverFiles] = useState<any[]>([]);
    const [iframeKey, setIframeKey] = useState(0);
    const [uploadProgress, setUploadProgress] = useState<any>({});
    const [showReleaseMissing, setShowReleaseMissing] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const prevSelectDocument = usePrevious(selectedDocumentId)
    const prevHasUserMadeChanges = usePrevious(props.hasUserMadeAnyChanges)
    
    const showModal = (fileType: string) => {
        setFileType(fileType)
        setRefresh(false);
        setShow(true)
    }

    const docTypes = {
        REQ: "request document",
        INT: "internal records",
        AUX: "auxiliary document",
        MED: "medical records"
    }

    const getIframeKey = () => {
        let newKey;
        do {
            newKey = Math.floor(Math.random() * (999999 - 100000) + 100000);
        } while (newKey === iframeKey)
        return newKey;
    }

    useEffect(() => {
        const types = [MED, "REQ", "INT", AUX, "NOTE", "RECEIPT", "RELEASE", "QA", 'COVER']
        const uris = props.documentsList.filter(doc => types.indexOf(doc.type?.role) !== -1).map(file => file.uri);
        const files = props.documentsList.filter(doc => uris.indexOf(doc.uri) == -1 )
        setOtherFiles(files);
    }, [props.documentsList, requestFiles, internalFiles, auxiliaryRecords, notificationFiles, releaseFiles, reviewDocuments, coverFiles])

    useEffect(() => {
        if(props.currentFile) {
            let record = props.documentsList.find((row => row.description === props.currentFile));
            if (record === undefined) {
                record = props.documentsList.find((row => row.file.name.includes(props.currentFile)));
            }
            setSelectedDocumentId(record?.id);
        }
    }, [props.currentFile])

    useEffect(() => {
        if (selectedDocumentId) {
            let record = props.documentsList.find((row => row.id === selectedDocumentId));
            if (record === undefined) {
                record = props.documentsList.find((row => row.file.name.includes(props.currentFile)));
            }
            props.setCurrentFileType(record?.type?.role);
        }
    }, [selectedDocumentId])

    useEffect(() => {
        setUpdatedRecords(props.documentsList ? props.documentsList.filter((row => row.type?.role === MED)) : [])
        setRequestFiles(props.documentsList ? props.documentsList.filter((row => row.type?.role === "REQ")) : [])
        setInternalFiles(props.documentsList ? props.documentsList.filter((row => row.type?.role === "INT")) : [])
        setAuxiliaryRecords(props.documentsList ? props.documentsList.filter((row => row.type?.role === AUX)) : [])
        setReleaseFiles(props.documentsList ? props.documentsList.filter((row => row.type?.role === "RELEASE")) : [])
        setNotificationFiles(props.documentsList ? props.documentsList.filter((row => row.type?.role === "NOTE" || row.type?.role === "RECEIPT")) : [])
        setcoverFiles(props.documentsList ? props.documentsList.filter((row => row.type?.role === "COVER")) : [])
        console.log(coverFiles)
        const id = props.documentsList.some(d => d.id === selectedDocumentId) ?
            selectedDocumentId :
            defaultDocument?.id;

        if (id) {
            if (id != selectedDocumentId) {
                setIframeKey(getIframeKey())
            }
            setSelectedDocumentId(id)
        }
    }, [props.documentsList])

    useEffect(() =>{
        if(selectedDocumentId && props.hasUserMadeAnyChanges != prevHasUserMadeChanges && selectedDocumentId == prevSelectDocument){
            props.userViewedDocumentRequest && props.userViewedDocumentRequest(selectedDocumentId)
        }
    }, [props.hasUserMadeAnyChanges])


    const refreshDocuments = () => {
        props.getTransactionDocuments(props.transactionId)
    }

    const hideModal = () => {
        setShow(false)
        const id = props.documentsList.filter((row => row.type?.role === "REQ" || row.type?.role === "INT"))[0]?.id
        if (selectedDocumentId === id) {
            setSelectedDocumentId(-1)
            setSelectedDocumentId(selectedDocumentId)
        }
    }
    const attachRecords = () => {
    }

    useEffect(() => {
        if (props.uploadDocumentSuccess && Object.keys(uploadProgress).length && Object.keys(uploadProgress).every(f => uploadProgress[f].loaded === 100)) {
            setUploadProgress(prev => ({}));
            refreshDocuments();
            setRefresh(true);
        }
    }, [props.uploadDocumentSuccess?.fileID])


    const UploadProgressCreator = (fileId, filename) => (progress) => {
        let percentage = Math.floor((progress.loaded * 100) / progress.total)
        setUploadProgress(prev => ({
            ...prev,
            [fileId]: {
                name: filename,
                loaded: percentage
            }
        }));
    }

    const uploadFiles = (files: any, append: boolean = false) => {
        hideModal();
        const requestObjs: any[] = [];
        const initProgress = {};
        Object.keys(files).forEach((fileKey: string, index: any) => {
            let requestObject = {
                fileName: files[fileKey].name,
                fileID: fileKey,
                recordType: files[fileKey]["fileType"],
                file: files[fileKey].file,
                transactionId: props.transactionId,
                append: append,
                uploadProgress: UploadProgressCreator(fileKey, files[fileKey].name)
            }

            initProgress[fileKey] = { name: files[fileKey].name, loaded: 0};

            requestObjs.push({obj: requestObject});
        })

        setUploadProgress(prev => initProgress);
        process.nextTick(() => {
            requestObjs.forEach(o => props.uploadMultipleDocuments(o.obj));
        });
        
    }

    useEffect(() => {
        let reqDoc = props.documentsList?.filter(el => el.id.includes('REQ'))
        if (reqDoc.length === 1 && props.formState?.DATA_ENTRY_FIELDS_FORM?.values && props.formState?.DATA_ENTRY_FIELDS_FORM?.values[FormConstants.REQUEST_TYPE] === '31') {
            props.readBarCodeDDSRequest && props.readBarCodeDDSRequest(reqDoc[0].id);
        }
    }, [props.documentsList])

    useEffect(() => {
        const getData = async () => {
            const id = await setGlobalId()
            setUserId(id)
        }
        getData()
    })

    useEffect(() => {
        if (props.transactionDetails.transactionStatus?.id >= 130 &&
            props.transactionDetails.transactionStatus.id <= 141) {
            const releaseDocs = props.documentsList.some(row => row.type?.role == "RELEASE");
            if (!releaseDocs) {
                setShowReleaseMissing(true);
            }
        }
      }, [props.transactionDetails?.transactionStatus]);
    
    const getFileName = (file: any) => {
        return file.description||  file.file?.name
    }
    const addRecordsSelect = (eventKey) => {
        if (eventKey.id === "a") {
            showModal("MED");
        } else if (eventKey.id === "c") {
            showCopyModal();
        } else if (eventKey.id === "w") {
            props.postWPSToken && props.postWPSToken(props.transactionId, "MED");
        }
    }

    const attachAuxSelect = (eventKey) => {
        if (eventKey.id === "a") {
            showModal("AUX");
        } else if (eventKey.id === "w") {
            props.postWPSToken && props.postWPSToken(props.transactionId, "AUX");
        }
    }

    const attachInternalSelect = (eventKey) => {
        if (eventKey.id === "a") {
            showModal("INT");
        } else if (eventKey.id === "w") {
            props.postWPSToken && props.postWPSToken(props.transactionId, "INT");
        }
    }

    const attachRequestSelect = (eventKey) => {
        if (eventKey.id === "a") {
            showModal("REQ");
        } else if (eventKey.id === "w") {
            props.postWPSToken && props.postWPSToken(props.transactionId, "REQ");
        }
    }

    const [documentRefresh, setDocumentRefresh] = useState<any>(null);

    useEffect(() => {
        if (props.wpsToken && props.wpsToken != "") {
            window.open("verismawps:" + props.wpsToken, '_blank');
            const interval = setInterval(() => {
                refreshDocuments();
            }, 5000)

            setDocumentRefresh(interval);

            return () => clearInterval(interval);
        }
    }, [props.wpsToken]);

    useEffect(() => {
        clearInterval(documentRefresh);
    }, [activeTabId]);

    const showCopyModal = () => {
        props.resetTransactionDocumentsToCopy && props.resetTransactionDocumentsToCopy();
        setShowCopyTransaction(true);
    }

    const hideCopyModal = () => {
        setShowCopyTransaction(false);
        props.resetTransactionDocumentsToCopy && props.resetTransactionDocumentsToCopy();
    }

    const fileLoaded = () => {
        props.getAuditTrailData && props.getAuditTrailData(props.transactionId);
    }

    useEffect(() => {
        if (props.copyTransactionComplete) {
            setShowCopyTransaction(false);
        }
    });

    const medDocumentData = () => {
        if (selectedMedDocumentIndex){
            const ret = { ...props.documentsList.find(d => d.id === selectedMedDocumentIndex) };
            ret.filePath = documentURL + selectedMedDocumentIndex + "/file?userid=" + userId + (ret.type.role === "MED" ? "" : "&addTrailEntry=false")
            return ret;
        } else {
            return {
                filePath: ''
            }
        }
    }

    const documentData = () => {
        if (selectedDocumentId) {
            return {
                ...props.documentsList.find(d => d.id === selectedDocumentId),
                filePath: documentURL + selectedDocumentId + "/file?addTrailEntry=false&userid=" + userId + '&viewedDocument=' + props.hasUserMadeAnyChanges.toString()
            }
        }else{
            return {
                filePath: ''
            }
        }
    }

    const recordGridState = (state) => {
        if(!state){
            setSelectedMedDocumentIndex('')
        }
        setShowRecordGrid(state);
    }

    const [saveTrigger, setSaveTrigger] = useState(false);
    const [mergeTrigger, setMergeTrigger] = useState(false);

    useEffect(() => {
        if(saveTrigger)
            setSaveTrigger(false);
    }, [saveTrigger])

    const onClickSave = () => {
        setSaveTrigger(true);
    }

    useEffect(() => { 
        if (props.deleteDocumentSuccess){
            setSelectedDocumentId(null)
            refreshDocuments();
        }

    }, [props.deleteDocumentSuccess])

    const saveFile = (fileData) => {
        const date = new Date();
        const id = fileData.id.split('-').slice(-1)[0];

        let requestObject = {
            fileName: fileData.file.name,
            fileID: fileData.file.name + "-0-" + (new Date()).getTime() + Math.random(),
            recordType: fileData.type.role,
            file: {
                path: fileData.file.name,
                lastModified: date.getTime(),
                lastModifiedDate: date,
                name: fileData.file.name,
                size: fileData.blobData.size,
                type: fileData.blobData.type,
                webkitRelativePath: "",
            },
            documentId: fileData.id,
            transactionId: props.transactionId,
            blob: fileData.blobData,
            pagesRemoved: fileData.pagesDeleted,
            pagesAppended: fileData.pagesAppended,
            uploadProgress: UploadProgressCreator(fileData.file.name + '.' + date.getTime().toString() + '.' + id, fileData.file.name)
        }

        process.nextTick(() => {
            props.updateReplaceDocument && props.updateReplaceDocument(requestObject)
            recordGridState(true);
        })
        
    }

    const [showReplaceAppend, setShowReplaceAppend] = useState(false);

    useEffect(() => {
        setShowReplaceAppend(props.documentsList.some(d => d.type?.role === fileType))
    }, [props.documentsList, fileType])
    
    const deleteFile = useEvent((deleteAllPages:boolean, removePages:Function) => {
        const fileType = props.documentsList?.find(doc => doc.id == selectedDocumentId)?.type?.role;
        if (deleteAllPages){
            if (fileType === "INT") {
                if (window.confirm("Deleting the last page of the document will delete the file and can not be undone. Are you sure you wish to continue?")) {
                    props.deleteAuxDocument(selectedDocumentId);
                    setSelectedDocumentId(null);
                }
            }else{
                window.alert("You cannot delete all the pages in a Request document.")
            }
        }else{
            removePages();
        }
    })

    useEffect(() => {
        if (props.currentFileType) {
          if (props.currentFileType === "RELEASE") {
            setCanSave(false);
          } else {
            setCanSave(true);
          }
        }
    }, [props.currentFileType]);

    return (
        <div className='records-interface' style= {activeTabId !== 4? {}: {"overflowY": "scroll"}}>
            <UploadProgressBars uploadProgress={uploadProgress} uploadDocumentFailure={props.uploadDocumentFailure} setUploadProgress={setUploadProgress} />
            {activeTabId !== 4 ?
                <div className='request-pdf-wrapper'>
                    <div className="viewer-files-list">
                        <span className='upload-items'>
                            <select value={selectedDocumentId} onChange={(event) => { setSelectedDocumentId(event.target.value); props.userHasMadeChanged(); }} className="form-control">
                            {auxiliaryRecords && auxiliaryRecords.length &&
                                <optgroup label="Auxiliary Records">
                                    {auxiliaryRecords.map((file, index) => (
                                        <option value={file.id} id={index.toString() }>{getFileName(file).length > 0 ? getFileName(file) : "No file name"}</option>
                                    ))}
                                </optgroup>}
                                {coverFiles && coverFiles.length &&
                                    <optgroup label="Cover Sheet">
                                        {coverFiles.map((file, index) => (
                                            <option value={file.id} id={index.toString()}>{getFileName(file).length > 0 ? getFileName(file) : "No file name"}</option>
                                        ))}
                                    </optgroup>}
                                {requestFiles && requestFiles.length &&
                                <optgroup label="Request file">
                                    {requestFiles.map((file, index) =>
                                    (
                                        <option value={file.id} id={index.toString()}>{getFileName(file).length > 0 ? getFileName(file) : "No file name"}</option>
                                    ))}
                                </optgroup>}
                                {internalFiles && internalFiles.length &&
                                <optgroup label="Internal files">
                                    {internalFiles.map((file, index) => (
                                        <option value={file.id} id={index.toString() }>{getFileName(file).length > 0 ? getFileName(file) : "No file name"}</option>
                                    ))}
                                </optgroup>}
                                {updatedRecords && updatedRecords.length &&
                                    <optgroup label="Medical Records">
                                        {updatedRecords.map((file, index) => (
                                            <option value={file.id} id={index.toString() }>{getFileName(file).length > 0 ? getFileName(file) : "No file name"}</option>
                                        ))}
                                    </optgroup>}
                                {notificationFiles && notificationFiles.length &&
                                <optgroup label="Notifications">
                                    {notificationFiles.map((file, index) => (
                                        <option value={file.id} id={index.toString()}>{getFileName(file).length > 0 ? getFileName(file) : "No file name"}</option>
                                    ))}
                                </optgroup>}
                                {otherFiles && otherFiles.length &&
                                <optgroup label="Other">
                                    {otherFiles.map((file, index) => (
                                        <option value={file.id} id={index.toString()}>{getFileName(file).length > 0 ? getFileName(file) : "No file name"}</option>
                                    ))}
                                </optgroup>}
                                {releaseFiles && releaseFiles.length &&
                                <optgroup label="Release files">
                                    {releaseFiles.map((file, index) => (
                                        <option value={file.id} id={index.toString() }>{getFileName(file).length > 0 ? getFileName(file) : "No file name"}</option>
                                    ))}
                                </optgroup>}
                            </select>
                        </span>
                        <div className='attach-dropdown-container'>
                            <IconWithButton
                                buttonText={'Save'}
                                onButtonClick={onClickSave}
                                hideArrowIcon={true}
                                isSave={true}
                                isDisabled={!canSave}
                                title={canSave ? "" : "Changes must be made to original documents\\files"}
                            />
                            <span className='upload attach-records dropup' onClick={attachRecords}>
                                <ButtonWithDropdown
                                    drop='down'
                                    style={{ marginTop: '-.5em' }}
                                    title={"Attach Request"}
                                    onSelect={(event) => {attachRequestSelect(event); props.userHasMadeChanged();}}
                                    items={[
                                        { title: 'Attach Files', id: 'a' },
                                        { title: 'Web print / scan', id: 'w' }]}
                                ></ButtonWithDropdown>
                            </span>

                            <span className='upload attach-records dropup' onClick={attachRecords}>
                                <ButtonWithDropdown
                                    drop='down'
                                    style={{ marginTop: '-.5em' }}
                                    title={"Attach Internal"}
                                    onSelect={(event) => {attachInternalSelect(event); props.userHasMadeChanged();}}
                                    items={[
                                        { title: 'Attach Files', id: 'a' },
                                        { title: 'Web print / scan', id: 'w' }]}
                                ></ButtonWithDropdown>
                            </span>
                        </div>
                    </div>
                    { userId && 
                        <PdfViewer
                            key={iframeKey}
                            saveTrigger={saveTrigger}
                            mergeTrigger={mergeTrigger}
                            fileData={documentData()}
                            getFileData={saveFile}
                            canSave={canSave}
                            setCanSave = {setCanSave}
                            deleteFile={deleteFile}
                            transactionId={props.transactionId}
                            refresh={refresh}
                        />}
                    {show ? <UploadFileModal onClickSave={uploadFiles} type={docTypes[fileType]} handleClose={hideModal} showModal={show} allowAppend={showReplaceAppend} /> : null}
                </div>
                :
                showRecordGrid ?
                    <div className='tab-records'>
                        <div className='refresh-button-container'>
                            <Button style={{ width: 'fit-content', display:'flex',}} onClick={refreshDocuments}>
                                <RefreshIcon style={{ color: 'white', marginRight: 4 }} />
                                Refresh Document Lists 
                            </Button>
                        </div>
                        <div className='aux-document-container record-container'>
                            <div className="aux-document-header-container record-header-container">
                                <span className='aux-document-header'>Auxiliary Document</span>

                                <span className='aux-attach-dropdown-container record-dropdown-container' onClick={attachRecords}>
                                    <ButtonWithDropdown
                                        title={"Attach Auxiliary"}
                                        drop='down'
                                        onSelect={(event) => attachAuxSelect(event)}
                                        items={[
                                            { title: 'Attach Files', id: 'a' },
                                            { title: 'Web print / scan', id: 'w' }]}
                                    ></ButtonWithDropdown>
                                    {show ? <UploadFileModal tab='record' onClickSave={uploadFiles} handleClose={hideModal} type={docTypes[fileType]} showModal={show} allowAppend={showReplaceAppend} /> : null}
                                </span>

                            </div>
                            <RecordsTable 
                                transactionId={props.transactionId} 
                                getTransactionDocuments={refreshDocuments} 
                                updateDocument={props.updateDocument} 
                                deleteDocument={props.deleteAuxDocument} 
                                hideSource={true} 
                                rows={auxiliaryRecords} 
                                getDocumentById={props.getDocumentById} 
                                setShowRecordGrid={setShowRecordGrid} 
                                setSelectedMedDocumentIndex={setSelectedMedDocumentIndex} 
                                auditOnShowDoc={false}
                            />
                        </div>


                        <div className='medrec-container record-container'>
                            <div className="medrec-header-container record-header-container">
                                <span className='medrec-header record-header'>{updatedRecords.length} Medical Record{updatedRecords.length === 1 ? "" : "s"} Retrieved</span>
                                
                                <span className='medrec-dropdown-container record-dropdown-container' onClick={attachRecords}>
                                    <ButtonWithDropdown
                                        title={"Add Records"}
                                        drop='down'
                                        onSelect={(event) => addRecordsSelect(event)}
                                        items={[
                                            { title: 'Attach Files', id: 'a' },
                                            { title: 'Copy from Transaction', id: 'c' },
                                            { title: 'Web print / scan', id: 'w' }]}
                                    ></ButtonWithDropdown>
                                    {show ? <UploadFileModal tab='record' onClickSave={uploadFiles} handleClose={hideModal} type={docTypes[fileType]} showModal={show} allowAppend={showReplaceAppend} /> : null}
                                    {showCopyTransaction ? <CopyTransactionModal show={showCopyModal} handleClose={hideCopyModal} transactionId={props.transactionId} showCopyTransaction={showCopyTransaction} /> : null}
                                </span>
                                

                            </div>
                        </div>


                        <MedicalRecordsRetrieve documentSources={props.documentSources}
                            getTransactionDocuments={refreshDocuments}
                            deleteDocument={props.deleteDocument} transactionId={props.transactionId}
                            updateDocument={props.updateDocument} rows={updatedRecords} getDocumentById={props.getDocumentById}
                            setShowRecordGrid={() => recordGridState(false)} setSelectedMedDocumentIndex={setSelectedMedDocumentIndex}
                            deleteDocumentSuccess={props.deleteDocumentSuccess}
                            minDate={moment(props.patientDob && props.patientDob).toDate()} maxDate={new Date()} 
                            auditOnShowDoc={false}
                            />
                    </div> :
                    <div className='viewer-files-list-outer' >
                        <div className="viewer-files-list">
                            <span className='upload-items'>
                                <select value={selectedMedDocumentIndex} onChange={(event) => { setSelectedMedDocumentIndex(event.target.value) }} className="form-control">
                                    <optgroup label="Auxillary">
                                        {auxiliaryRecords.map((file, index) =>
                                        (
                                            <option value={file.id} id={index.toString() }>{file.description ? file.description : file.file.name ? file.file.name : "No file name"}</option>
                                        ))}
                                    </optgroup>
                                    <optgroup label="Medical">
                                        {updatedRecords.map((file, index) => (
                                            <option value={file.id} id={index.toString()}>{file.description ? file.description : file.file.name ? file.file.name : "No file name"}</option>
                                        ))}
                                    </optgroup>
                                </select>
                            </span>
                            <span className='close-Btn'>
                                    <IconWithButton
                                        buttonText={'Save'}
                                        onButtonClick={onClickSave}
                                        hideArrowIcon={true}
                                        isSave={true}
                                        isDisabled={!canSave}
                                    />
                                    <IconWithButton
                                        buttonText={'Close'}
                                        onButtonClick={() => recordGridState(true) }
                                        hideArrowIcon={true}
                                        isCloseIcon={true} />
                            </span>
                        </div>
                        <PdfViewer 
                            key={iframeKey}
                            saveTrigger={saveTrigger} 
                            mergeTrigger={mergeTrigger}
                            fileData={medDocumentData()} 
                            getFileData={saveFile}
                            canSave={canSave}
                            transactionId={props.transactionId}
                            />
                    </div>
            }
            {props.completeTransactionFinished && props.completeTransactionFinished ? <LoadingSpinner /> : null}
            {showReleaseMissing && <ReleaseDocumentMissingPopUp/>}
        </div>
    )

}
function mapStateToProps(state: any) {
    return {
        copyTransactionComplete: state.dataEntryState.copyTransactionComplete,
        wpsToken: state.dataEntryState.wpsToken,
        formState: state.form,
        completeTransactionFinished: state.dataEntryState.completeTransactionFinished,
        uploadDocumentAPIStatus: state.dataEntryState.uploadDocumentAPIStatus,
        uploadDocumentFailure: state.dataEntryState.uploadDocumentFailure,
        uploadDocumentSuccess: state.dataEntryState.uploadDocumentSuccess,
        featureFlags: state.loginState.featureFlags,
        patientDob: state?.dataEntryState?.patientDetailsAttributes?.dob,
        currentFile: state.dataEntryState.currentFile,
        transactionDetails: state.dataEntryState.transactionDetails,
        currentFileType: state.dataEntryState.currentFileType,
    }
}
export function mapDispatchToProps(dispatch: Function) {
    return {
        resetTransactionDocumentsToCopy: () => dispatch(resetTransactionDocumentsToCopy()),
        postWPSToken: (transactionId, docType) => dispatch(postWPSToken(transactionId, docType)),
        readBarCodeDDSRequest: (data) => dispatch(readBarCodeDDSRequest(data)),
        getAuditTrailData: (transactionId) => dispatch(getAuditTrail(transactionId)),
        userViewedDocumentRequest: (documentId) => dispatch(userViewedDocumentRequest(documentId)),
        setCurrentFileType: (fileType) => dispatch(setCurrentFileTypeActionCreator(fileType)),
        updateReplaceDocument: (data) => dispatch(updateReplaceDocucmentActionCreator(data))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(DataEntryDetailsBody);
