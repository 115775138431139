import React from "react";
import { Row, Col } from "react-bootstrap";
import "./auditItem.scss";
import moment from "moment";

interface AuditData {
    lastUpdate: Date,
    createdDate: Date,
    task: string,
    userFirstName: string,
    userLastName: string,
    results: string,
    userID: number,
    applicationID: number
}

interface AuditItemProps {
    auditData: AuditData,
    selected?: boolean,
    className?: string,
    onClick?: Function
}

const DisplayAuditItem = (props: AuditItemProps) => {
    const isValidDate = (date) => {
        return (Object.prototype.toString.call(date) === "[object String]" && !isNaN(Date.parse(date))) || (Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date.getTime()));
    }

    const determineAppOrigins = () => {
        if(props.auditData.applicationID === 100){
            return "A"
        }else if(props.auditData.applicationID === null && props.auditData.userID < 2){
            return "S"
        }
        else{
            return "V"
        }

    } 

    return (
        <div className="auditBlock">
            <Row>
                <Col>
                    {isValidDate(props.auditData.createdDate) ? moment(props.auditData.createdDate).format("M/D/YYYY h:mm a") : null} 
                    &nbsp;[{determineAppOrigins()}] &nbsp; 
                </Col>
                <Col>{props.auditData.task}</Col>
            </Row>
            <Row>
                <Col>
                    {props.auditData.userLastName}, {props.auditData.userFirstName}
                </Col>
            </Row>
           
            {props.auditData.results.trim() !== "" && props.auditData.results.trim().toLowerCase() !== props.auditData.task.trim().toLowerCase() ?
                <Row>
                    <Col className="reasonorresult">{props.auditData.results.trim()}</Col>
                </Row> :
                <></>
            }
        </div >
    )
}

export default DisplayAuditItem;

