import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import * as Redux from 'redux'
import { push } from "../../redux/navigation/navigationDucks";
import { onChangeDataEntryTab, resetDataEntryState } from '../dataEntryDetails/DataEntryDetailsDucks';
import { getTokenLoginRequest, getUserAttributesRequest, getFeatureFlagsRequest } from './LoginPageDucks';
import { FeatureFlagsType } from "../../common/customTypes/FeatureFlagsType";

interface TokenLoginProps {
    getTokenLoginRequest: Function,
    getTokenLoginAPIStatus: number,
    redirectToDataDetails: Function,
    redirectToLoginPage: Function,
    getFeatureFlagsRequest: Function,
    resetDataEntryState: Function,
    featureFlags?: FeatureFlagsType,
    onChangeDataEntryTab?: Function,
    match: any,
}

class TokenLogin extends React.Component<TokenLoginProps> {
    componentDidMount() {
        this.props.resetDataEntryState();
        if(global.userID === undefined){
            const id = sessionStorage.getItem("userID");
            global.userID = id ? Number(id) : 0;
        }

        if(Object.keys(this.props.featureFlags!).length == 0){
            this.props.getFeatureFlagsRequest();
        }

        this.props.onChangeDataEntryTab && this.props.onChangeDataEntryTab(1);

        this.props.getTokenLoginRequest(this.props.match.params.token);
    }

    render() {
        return (<div></div>);
    }
}

export function mapDispatchToProps(dispatch: Redux.Dispatch<Redux.Action>) {
    return {
        getTokenLoginRequest: (
        token: string
        ) => dispatch(getTokenLoginRequest(token)),
        redirectToLoginPage: () => dispatch(push("/")),
        onChangeDataEntryTab: (data: number) => dispatch(onChangeDataEntryTab(data, false)),
        getFeatureFlagsRequest: () => dispatch(getFeatureFlagsRequest()),
        resetDataEntryState: () => dispatch(resetDataEntryState()),
    };
}

export function mapStateToProps(state: any) {
    return {
        getTokenLoginAPIStatus: state.loginState.getTokenLoginAPIStatus,
        featureFlags: state.loginState.featureFlags,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenLogin);
