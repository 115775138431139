import React, { useEffect } from "react";
import { connect } from "react-redux";
import "../../../common/tabComponents/TabComponents.scss";
import { Row, Col, Container } from "react-bootstrap";
import { submit, stopSubmit, touch } from 'redux-form'
import Header from "../../../common/header/Header";
import "../DataEntryDetails.scss";
import DataEntryDetailsLeftPane from "./DataEntryDetailsLeftPane";
import DataEntryDetailsRightPane from "./DataEntryDetailsRightPane";
import DataEntryDetailsBody from "./DataEntryDetailsBody";
import { tabItems } from "../DataEntryTabConstants";
import {
  onChangeDataEntryTab,
  getTransactionAttributesActionCreator,
  assignTransactionIDActionCreator,
  unassignTransactionIDActionCreator,
  getFeeScheduleData,
  getTransactionNotesRequest,
  getAdditionalChargeType,
  getAdditionalChargeRecordsCreator,
  getTransactionDocuments,
  getRejectOptions,
  getShippingServicesData,
  getReleaseMethodOptionsData,
  getTransactionNoteTypes,
  uploadMultipleDocumentsActionCreator,
  getDocumentByIdActionCreator,
  resetDataEntryState,
  updateDocumentActionCreqtor,
  getDoucmentSourcesActionCreator,
  deleteDocumentActionCreator,
  getRetrieveProtocolActionCreator,
  getCancellationReasons,
  getSelectedPHICodesActionCreator,
  getPHICodesRequest,
  getUserGroupsActionCreator,
  getDisclosureReasonActionCreator,
  updateFormSyncErrorsActionCreator,
  getUserTasksActionCreator,
  getRequestTypesAndPurposesRequest,
  getFacilityDiscloseInstitutionsRequest,
  requestorVerificationEmailSent,
  RequestorVerificationEmailSentReset,
  setLastUsedRequestorInfo,
  requestorCompleteActionCreator,
  billingCompleteActionCreator,
  getFacilityAdditionalFieldsActionCreator,
  getWaitAndHoldReasons,
  getQAErrorsActionCreator,
  getQAIssuesActionCreator,
  getQAPDIssuesActionCreator,
  updateBillingPaneFields,
  getAssessmentResultsActionCreator,
  getAIFeedbackActionCreator,
  getReviewEntitiesActionCreator
} from "../DataEntryDetailsDucks";
import {
  getOutboundTemplateRequest,
  getNextQATransaction
} from "../../landingPage/LandingPageDucks";
import { getRoutePathName } from "../../landingPage/LandingPage";
import { reset } from 'redux-form';
import {FormConstants, BillingFormconstants} from "./DataEntryFieldsConstants";
import localValidations, { ignoreFields, DISCLOSURE_EVENT_ID } from './validations/localValidations';
import TimeoutWarning from "../../../common/timeoutWarning/timeoutWarning";
import { RequestorVerificationNotificationPopup } from '../../../common/alertPopup/RequestorVerificationNotificationPopup';
import { postCancellationReasonsActionCreators } from "../DataEntryDetailsDucks";
import { delay } from "@redux-saga/core/effects";
import { wait } from "@testing-library/dom";
import LoadingSpinner from "../../../common/loadingSpinner/loadingSpinner";
import toast, { Toaster } from "react-hot-toast";

export interface HealthSystemCountsModal {
  "model": string,
  "total": number,
  "dataEntry": number,
  "recordAttachment": number,
  "recordValidation": number,
  "userTasks": number,
  "qaReview": number,
  "facilityActionRequired": number
}

export interface FacilitiesCountsModal {
  "model": string,
  "uri": null,
  "facility": FacitiesModal,
  "transactionCounts": HealthSystemCountsModal,
  "userTasks": HealthSystemCountsModal
}

export interface FacitiesModal {
  userTaskCounts: any;
  model: string,
  id: number,
  name: string,
  uri: string
}

export interface HealthSystemModal {
  model: string,
  id: number,
  name: string,
  uri: string,
  facilities: FacilitiesCountsModal[]
}

export interface OrganizationDetailsModal {
  "model": string,
  "healthSystem": HealthSystemModal,
  "transactionCounts": HealthSystemCountsModal,
  "facilityCounts": FacilitiesCountsModal[]
}


interface Props {
  onChangeDataEntryTab: Function;
  activeTabId: number;
  proceedBtnClick: false;
  selectedHealthOrganizationDetails: OrganizationDetailsModal,
  selectedFacilityDetails: FacilitiesCountsModal,
  resetForm: Function,
  transactionId: string,
  getRequestsAndPurposes: Function,
  requestTypes: any,
  purposeOfRequests: any,
  transactionIDAttributes: any,
  getTransactionAttributes: Function,
  assignTransactionID: Function,
  unassignTransactionID: Function,
  resetDataEntryState: Function,
  patientDetails?: any,
  transactionDetails?: any,
  leftPaneTransactionDetails: any,
  getFacilityFeeSchedules: Function,
  getTransactionNotesRequest: Function,
  transactionNotes: any,
  leftPaneRequestorDetails: any,
  getAdditionalChargeType: Function,
  getAdditionalChargeRecords: Function,
  getTransactionDocuments: Function,
  documentsList: any,
  getRejectOptions: Function,
  getFacilityShippingServices: Function,
  getFacilityReleaseMethods: Function,
  getTransactionNoteTypes: Function,
  uploadMultipleDocuments: Function,
  getDocumentById: Function,
  updateDocument: Function,
  getDocumentSources: Function,
  documentSources: any,
  getRejectionLetter: Function,
  getAssessmentResults: any,
  getReviewEntities: Function,
  reviewEntities: any,
  deleteDocument: Function,
  deleteAuxDocument: Function,
  deleteDocumentSuccess?: Boolean,
  getRetrieveProtocol: Function,
  retrieveProtocol: any,
  transactionCancellationReasons: Function,
  formState: any,
  getSelectedPHICodes: Function,
  getPHICodesRequest: any,
  getUserGroups: Function,
  getDisclosureReasons: Function,
  submitForm: Function,
  stopSubmit: Function,
  updateFormSyncErrors: Function,
  getUserTasks: Function,
  userTasks: any,
  userTaskComments: any,
  touch: Function,
  getFacilityDiscloseInstitutionsRequest: Function,
  feeSchedules?: any,
  history: any,
  logoutUser: Function,
  userState: any,
  additionalChargeRecords: any,
  isRequestorVerificationEmailSent: boolean,
  requestorVerificationEmailPopupTriggered: boolean,
  requestorVerificationEmailSentReset: Function,
  requestorVerificationEmailSent: Function,
  setLastUsedRequestorInfo: any,
  requestorDetails: any,
  patchRequestorComplete?: Function,
  patchBillingComplete?: Function,
  postCancellationReasonsDispatch?: any
  intiaTxlSagaCallCount: number,
  getFacilityAdditionalFieldsActionCreator?: Function,
  transactionWaitAndHoldReasons: Function,
  requestContext: any,
  getOutboundTemplateRequest?: Function,
  uploadDocumentSuccess: any,
  getQAErrors: any,
  getQAIssues: Function,
  QAIssues: Array<any>,
  getQAPDIssues: Function,
  qaPDIssues: any,
  statusIndicatorID?:any,
  updateBillingPaneFields?:Function,
  getAIFeedback: any,
  getNextQATransaction: Function,
  finalQAFeatureFlags?: any,
}

interface State {
  test: string;
  active: boolean[];
  tabClick: boolean,
  showLoadingSpinner: boolean,
  showRequestorPopUp: boolean,
  hasUserMadeAnyChanges: boolean
}
interface tabItemModal {
  id: number,
  label: string
}
class DataEntryDetails extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.props.resetForm()
    this.props.onChangeDataEntryTab(1, false)
  }
  state: State = {
    test: "",
    active: [false, false, false, false],
    tabClick: false,
    showLoadingSpinner: false,
    showRequestorPopUp: false,
    hasUserMadeAnyChanges: false
  };

  componentDidMount() {
    if(global.userID === undefined){
      const id = sessionStorage.getItem("userID");
      global.userID = id ? Number(id) : 0;
    }
    this.setState({ showLoadingSpinner: true })
    if(this.props.requestContext == 3 || this.props.requestContext == 4){
      this.props.onChangeDataEntryTab(4, true)
      const id: number = 4;
      this.handleSelectedTab(4 - 1);
    }else{
      const id: number = this.props.activeTabId;
      this.handleSelectedTab(id - 1);
    }
   
    this.initialAPICalls(this.props.transactionId)

    // window.addEventListener("beforeunload", (e) => {
    //   if (!this.txOkToExit()) {
    //     this.props.transactionId && this.props.transactionId.length > 0 && this.props.postCancellationReasonsDispatch(this.props.transactionId, 0, 'Empty Transaction');
    //   }
    // });

    window.addEventListener("unload", async (e) => {
      if (!this.txOkToExit()) {
        this.props.logoutUser({ userId: this.props.userState.userID })
        await (ms => new Promise(res => setTimeout(res, ms)))(1000);
      }
    });
    this.setState({ showLoadingSpinner: false });

    // TODO: Use this instead of the code in componentWillUnmount once we update from this ancient Node version
//  const leftPanel = document.getElementById("leftPanel")!;
//  const centerPanel = document.getElementById("centerPanel")!;
//  const resizeObserver = new ResizeObserver((entries) => {
//    localStorage.setItem("leftWidth", leftPanel.offsetWidth + "px");
//    localStorage.setItem("centerWidth", centerPanel.offsetWidth + "px");
//  });
//  resizeObserver.observe(leftPanel);
//  resizeObserver.observe(centerPanel);
  }

  componentWillUnmount = () => {
    this.props.onChangeDataEntryTab(1, false)
    this.props.resetForm()
    this.props.unassignTransactionID(this.props.transactionId)
    this.props.resetDataEntryState()

    const leftPanel = document.getElementById("leftPanel");
    const centerPanel = document.getElementById("centerPanel");
    if (leftPanel != null && centerPanel != null) {
      localStorage.setItem("leftWidth", leftPanel.offsetWidth + "px");
      localStorage.setItem("centerWidth", centerPanel.offsetWidth + "px");
    }
  }



  renderTabItems = () => {
    const values = this.props.formState?.values || { values: {} }
    return (
      <div className="breadcrumDiv">
        <ul className="steps">
          {tabItems.map((item: tabItemModal, index: number) => {
            return this.state.active[index] ? (
              <li className="activeSelection"
                id={index.toString()}
                key={index}
                onClick={() => this.handleClick(item)}
              >
                <a href="#!" style={{ color: "#5F9EC5" }}>{item.label}</a>
              </li>
            ) : (
              <li id={index.toString()} key={index} onClick={() => this.handleClick(item)}
                className={(values[FormConstants.REQUEST_TYPE] === DISCLOSURE_EVENT_ID && (item.id === 3 || item.id === 4)) || (item.id > this.props.activeTabId + 1  && this.props.activeTabId != 2) ? 'disabled-tab' : ''} >
                <a href="#!" className={(values[FormConstants.REQUEST_TYPE] === DISCLOSURE_EVENT_ID && (item.id === 3 || item.id === 4)) || (item.id > this.props.activeTabId + 1 && this.props.activeTabId != 2)? 'disabled-tab-a' : ''}>{item.label}</a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  handleSelectedTab = (selectedId: number) => {
    let active = this.state.active;
    active.forEach((element, inx) => {
      return selectedId === inx ? (active[inx] = true) : (active[inx] = false);
    });
    this.setState({ active: active });
  };
  handleNavigation = () => {
    this.setState({ tabClick: false });
    const id: number = this.props.activeTabId;
    this.handleSelectedTab(id - 1);
  };
  handleClick = (tabItem: tabItemModal) => {
    const values = this.props.formState?.values || { values: {} }
    sessionStorage.setItem("formValues", JSON.stringify(values))
      document.getElementsByClassName('right-pane')[0].scrollTo(0,0)
    if (tabItem.id < this.props.activeTabId) {
      this.setState({ tabClick: true })
      this.props.onChangeDataEntryTab(tabItem.id, true)
      this.handleSelectedTab(tabItem.id - 1)
      return null
    }

    if(this.props.activeTabId + 1 < tabItem.id){
      return null;
    }

    if (this.props.activeTabId === 1) {
      this.props.getFacilityFeeSchedules(this.props.selectedFacilityDetails?.facility?.id, this.props.transactionId)
      this.props.getFacilityShippingServices(this.props.selectedFacilityDetails?.facility?.id);
      this.props.getFacilityReleaseMethods(this.props.selectedFacilityDetails?.facility?.id, this.props.transactionId);

    }else if (this.props.activeTabId === 2) {
      this.props.setLastUsedRequestorInfo(
        {
          requestorName: this.props.requestorDetails?.fullName,
          address1: this.props.requestorDetails?.requestorAddress?.address1,
          address2: this.props.requestorDetails?.requestorAddress?.address2,
          city: this.props.requestorDetails?.requestorAddress?.city,
          state: this.props.requestorDetails?.requestorAddress?.state,
          zip: this.props.requestorDetails?.requestorAddress?.zipCode,
          firstName: this.props.requestorDetails?.requestorInfo?.firstName,
          lastName: this.props.requestorDetails?.requestorInfo?.lastName,
          email: this.props.requestorDetails?.requestorInfo?.email,
          phone: this.props.requestorDetails?.requestorInfo?.phone,
          fax: this.props.requestorDetails?.requestorInfo?.fax,
          requestorContactId: this.props.requestorDetails?.requestorInfo?.id,
          requestorId: this.props.requestorDetails?.requestorID,
          requestPurpose: this.props.transactionDetails.requestPurpose,
          org: this.props.selectedFacilityDetails.facility.id,
          transactionId: this.props.transactionId
        });
      this.props.patchRequestorComplete && this.props.patchRequestorComplete(this.props.transactionId);
      this.props.getTransactionAttributes && this.props.getTransactionAttributes(this.props.transactionId); //if this is only being done to refresh the status, please delete
    }else if (this.props.activeTabId === 3) {

      this.props.patchBillingComplete && this.props.patchBillingComplete(this.props.transactionId);
    }

    if (this.props.activeTabId === 2 && values[FormConstants.REQUEST_TYPE] === DISCLOSURE_EVENT_ID) { } else {
      this.props.submitForm()
    }
    let validations = localValidations(values, { activeTabId: this.props.activeTabId, transactionDetails: this.props.transactionDetails, feeSchedules: this.props.feeSchedules })
    ignoreFields.map(item => delete validations[item])
    this.props.stopSubmit(validations)
    const fieldErrorsCount = Object.keys(validations).length
    this.props.updateFormSyncErrors(validations)
    if (!fieldErrorsCount && values[FormConstants.REQUEST_TYPE] !== DISCLOSURE_EVENT_ID) {
      this.setState({ tabClick: true });
      this.props.onChangeDataEntryTab(tabItem.id, true);
      this.handleSelectedTab(tabItem.id - 1);
    }
    else if (!fieldErrorsCount && values[FormConstants.REQUEST_TYPE] === DISCLOSURE_EVENT_ID && (tabItem.id !== 3 && tabItem.id !== 4)) {
      this.setState({ tabClick: true });
      this.props.onChangeDataEntryTab(tabItem.id, true);
      this.handleSelectedTab(tabItem.id - 1);
    }
  };

  initialAPICalls = (transactionId: string) => {
    if (transactionId && this.props.selectedFacilityDetails && global.userID) {
      this.props.getFacilityAdditionalFieldsActionCreator && this.props.getFacilityAdditionalFieldsActionCreator(this.props.selectedFacilityDetails?.facility?.id) 
      this.props.assignTransactionID(this.props.transactionId)
      this.props.getTransactionAttributes(this.props.transactionId);
      this.props.getFacilityFeeSchedules(this.props.selectedFacilityDetails?.facility?.id, this.props.transactionId)
      this.props.getTransactionNotesRequest(this.props.transactionId)
      this.props.getRetrieveProtocol(this.props.transactionId)
      this.props.getAdditionalChargeType(this.props.selectedFacilityDetails?.facility?.id)
      this.props.getRejectOptions(this.props.selectedFacilityDetails?.facility?.id)
      this.props.getTransactionNoteTypes(this.props.selectedFacilityDetails?.facility?.id)
      this.props.getDocumentSources(this.props.selectedFacilityDetails?.facility?.id)
      this.props.getAdditionalChargeRecords(this.props.transactionId)
      this.props.getTransactionDocuments(this.props.transactionId)
      this.props.getFacilityShippingServices(this.props.selectedFacilityDetails?.facility?.id)
      this.props.getFacilityReleaseMethods(this.props.selectedFacilityDetails?.facility?.id, this.props.transactionId)
      this.props.getPHICodesRequest(this.props.selectedFacilityDetails?.facility?.id)
      this.props.transactionCancellationReasons(this.props.selectedFacilityDetails?.facility?.id)
      this.props.getSelectedPHICodes(this.props.transactionId)
      this.props.getUserGroups(this.props.selectedFacilityDetails?.facility?.id)
      this.props.getDisclosureReasons(this.props.selectedFacilityDetails?.facility?.id)
      this.props.getUserTasks(this.props.transactionId)
      if (this.props.finalQAFeatureFlags?.finalQAAccordions) {
        this.props.getReviewEntities(this.props.transactionId)
        this.props.getAssessmentResults(this.props.transactionId)
        this.props.getAIFeedback()
      }
      this.props.getRequestsAndPurposes(this.props.selectedFacilityDetails?.facility?.id)
      this.props.getQAErrors()
      this.props.getQAIssues(this.props.transactionId)
      this.props.getQAPDIssues(this.props.transactionId)
      this.props.getOutboundTemplateRequest && this.props.getOutboundTemplateRequest()

      this.setState({ hasUserMadeAnyChanges: false })
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.intiaTxlSagaCallCount >= 19 && this.state.showLoadingSpinner) {
      this.setState({ showLoadingSpinner: false })
    }

    if (prevProps.activeTabId !== this.props.activeTabId && this.props.proceedBtnClick) {
      this.handleNavigation();
    }
    if (prevProps.transactionId !== this.props.transactionId) {
      this.setState({ showLoadingSpinner: true })
      this.initialAPICalls(this.props.transactionId)

    } 
    // if(this.props.uploadDocumentSuccess && this.props.uploadDocumentSuccess.arePageizesValid && this.props.uploadDocumentSuccess.arePageizesValid === "False" 
    // && this.props.uploadDocumentSuccess.fileID !== prevProps?.uploadDocumentSuccess?.fileID && this.props.formState?.values[BillingFormconstants.RELEASE_METHODS] == 5){
    //       toast(`Pages: ${this.props.uploadDocumentSuccess.invalidPagesNumbers} uploaded contain an invalid page size. Pages were modified to fit letter size please review the document`, {id: "baddoc", duration: 5000, position: 'top-center'});
    //    }
  }

  txOkToExit = () => {
    
    //const values = this.props.formState?.values || { values: {} }
    return true;
    // return (this.props.transactionId && this.props.transactionId.length > 0 && (this.props.documentsList.some(d => d.type?.role === "REQ") ||
    //   (values[FormConstants.PATIENT_NAME])) && this.props.intiaTxlSagaCallCount > 18 );
  }

  checkTxOkToExit = () => {
    return true;
  }

  requestorToast = () =>{
    toast("Requestor verification email has been sent", {id: "reqPopUp", duration: 5000})
    this.props.requestorVerificationEmailSentReset()

  }

  setIsEscalated = (state) => {
    this.props.updateBillingPaneFields && this.props.updateBillingPaneFields(
      {
        transactionId: this.props.transactionId,
        patientDataid: this.props.patientDetails?.id,
        userID: global.userID,
        isEscalated: state
      });

      this.props.leftPaneTransactionDetails.isEscalated = state;
  }

  userHasMadeChanged = () =>  {this.setState({ hasUserMadeAnyChanges: true })}

  render() {
    const { selectedHealthOrganizationDetails, selectedFacilityDetails, transactionId } = this.props
    const homeText = getRoutePathName(selectedHealthOrganizationDetails, selectedFacilityDetails)

    homeText.push("Data Entry")
    return (
      <div>
        {this.state.showLoadingSpinner ? <LoadingSpinner /> :




          <Container fluid className="app-container-custom-style" style={{"maxHeight": "100vh", "overflow": "hidden"}}>
            <div className="landing-page-header pb-0">
              <Header name="Data Entry" homeText={homeText} onExitTransaction={this.checkTxOkToExit} getNextQATransaction={getNextQATransaction} />
            </div>
            <Row className='mr-0 data-entry-wrapper'>
              <div id="leftPanel" className='pr-0 h-100 d-flex flex-column' style={{"position": "relative", "overflow": "hidden", "minWidth": "15%", "maxWidth": "50%", "width": localStorage.getItem("leftWidth") ?? "25%", "resize": "horizontal"}}>
                <DataEntryDetailsLeftPane
                  transactionNotes={this.props.transactionNotes}
                  transactionDetails={this.props.leftPaneTransactionDetails}
                  requestorDetails={this.props.leftPaneRequestorDetails}
                  transactionId={transactionId}
                  patientDetails={this.props.patientDetails}
                  userTasks={this.props.userTasks}
                  userTaskComments={this.props.userTaskComments}
                  resetForm={this.props.resetForm}
                  onExitTransaction={this.checkTxOkToExit} 
                  setEscalation={this.setIsEscalated}
                  assessmentResults={this.props.getAssessmentResults}
                  reviewEntities={this.props.reviewEntities}
                  getQAErrors={this.props.getQAErrors}
                  getQAIssues={this.props.QAIssues}
                  getQAPDIssues={this.props.getQAPDIssues}
                  getAIFeedback={this.props.getAIFeedback} />
              </div>
              <div className='pr-0 d-flex flex-column h-100 flex-grow-1 flex-shrink-1' style={{"flexBasis": "auto", "position": "relative"}}>
                <Row className='mx-0 w-100'>
                  <Col lg={12} className='pr-0 pl-lg-0'>
                    <div className="breadcrum-wrapper">
                      {this.renderTabItems()}
                    </div>
                  </Col>
                </Row>
                <Row className='request-content-wrapper mr-0' style={{ zIndex: 2 }}>
                  <div id="centerPanel" className='request-pdf-wrapper' style={{ "minWidth": "15%", "maxWidth": "85%", "width": localStorage.getItem("centerWidth") ?? "50%", "resize": "horizontal", overflow: this.props.activeTabId === 4 ? 'visible' : 'hidden' }}>
                    <DataEntryDetailsBody
                      documentsList={this.props.documentsList}
                      uploadMultipleDocuments={this.props.uploadMultipleDocuments}
                      transactionId={transactionId}
                      activeTabId={this.props.activeTabId}
                      getDocumentById={this.props.getDocumentById}
                      getTransactionDocuments={this.props.getTransactionDocuments}
                      updateDocument={this.props.updateDocument}
                      documentSources={this.props.documentSources}
                      deleteDocument={this.props.deleteDocument}
                      deleteAuxDocument={this.props.deleteAuxDocument}
                      deleteDocumentSuccess={this.props.deleteDocumentSuccess}
                      hasUserMadeAnyChanges={this.state.hasUserMadeAnyChanges}
                      userHasMadeChanged={this.userHasMadeChanged}
                    />
                  </div>
                  <div className='pr-0 data-entry-form-wrapper flex-grow-1 flex-shrink-1' style={{"flexBasis": "auto", "position": "relative"}}>
                    <DataEntryDetailsRightPane
                      onChangeDataEntryTab={this.props.onChangeDataEntryTab}
                      activeTabId={this.props.activeTabId}
                      proceedBtnClick={this.props.proceedBtnClick}
                      retrieveProtocol={this.props.retrieveProtocol}
                      transactionDetails={this.props.transactionDetails}
                      additionalChargeRecords={this.props.additionalChargeRecords}
                      getFacilityFeeSchedules={this.props.getFacilityFeeSchedules}
                      getFacilityReleaseMethods={this.props.getFacilityReleaseMethods}
                      getFacilityShippingServices={this.props.getFacilityShippingServices}
                      userHasMadeChanged={this.userHasMadeChanged}
                      getQAPDIssues={this.props.getQAPDIssues}
                      qaPDIssues={this.props.qaPDIssues}
                    />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        }
        {this.props.isRequestorVerificationEmailSent? this.requestorToast() : null}

        <Toaster toastOptions={{
                  className: 'toast-notification',
                }}
         position="top-right" />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  //These two lines assume that the landingPageState.getHealthOrganizationsSearch will contain the entire health org list that the user will have access to.
  //If we move to a back end paging/searching system (paging and searching controlled by the db stored procedure) this will have to change
  //and it will require either the transaction search receiving the Health Org Id, or a way to search health orgs/facilities using only the facility id

  // const healthOrgDetails = state.landingPageState.selectedHealthOrganizationDetails ??
  //   state.landingPageState.getHealthOrganizationsSearch?.filter(h => h.healthSystem.facilities
  //     .some(f => f.facility.id == state.landingPageState.selectedFacilityDetails?.facility.id))[0]

  // const facilityDetails = state.landingPageState.selectedFacilityDetails?.facility?.name
  //   ? state.landingPageState.selectedFacilityDetails
  //   : healthOrgDetails?.facilityCounts?.find(f => f.facility.id === state.landingPageState.selectedFacilityDetails.facility.id);

  return {
    activeTabId: state.dataEntryState.activeTabId,
    proceedBtnClick: state.dataEntryState.proceedBtnClick,
    selectedHealthOrganizationDetails: state.landingPageState.selectedHealthOrganizationDetails,
    selectedFacilityDetails: state.landingPageState.selectedFacilityDetails,
    transactionId: state.landingPageState.transactionId,
    transactionIDAttributes: state.dataEntryState.transactionIDAttributes,
    patientDetails: state.dataEntryState.leftPanePatientDetailsAttributes,
    leftPaneTransactionDetails: state.dataEntryState.leftPaneTransactionDetails,
    transactionNotes: state.dataEntryState.transactionNotes,
    leftPaneRequestorDetails: state.dataEntryState.requestorDetails,
    documentsList: state.dataEntryState.documentsList,
    documentSources: state.dataEntryState.documentSources,
    retrieveProtocol: state.dataEntryState.retrieveProtocol,
    formState: state.form && state.form[FormConstants.DATA_ENTRY_FIELDS_FORM],
    transactionDetails: state.dataEntryState.transactionDetails,
    userTasks: state.dataEntryState.userTasks,
    userTaskComments: state.dataEntryState.userTaskComments,
    feeSchedules: state.dataEntryState.feeSchedule,
    additionalChargeRecords: state.dataEntryState.additionalChargeRecords,
    isRequestorVerificationEmailSent: state.dataEntryState.requestorVerificationEmailSent,
    requestorVerificationEmailPopupTriggered: state.dataEntryState.requestorVerificationEmailPopupTriggered,
    requestorDetails: state.dataEntryState.requestorDetails,
    deleteDocumentSuccess: state.dataEntryState.deleteDocumentAPISuccess,
    intiaTxlSagaCallCount: state.dataEntryState.intiaTxlSagaCallCount,
    requestContext: state.landingPageState.requestContext,
    uploadDocumentSuccess: state.dataEntryState.uploadDocumentSuccess,
    getAssessmentResults: state.dataEntryState.getAssessmentResults,
    reviewEntities: state.dataEntryState.reviewEntities,
    getQAErrors: state.dataEntryState.getQAErrors,
    QAIssues: state.dataEntryState.getQAIssues,
    getQAPDIssues: state.dataEntryState.getQAPDIssus,
    qaPDIssues: state.dataEntryState.qaPDIssues,
    statusIndicatorID: state.dataEntryState?.transactionDetails?.transactionStatus?.id,
    finalQAFeatureFlags: state.loginState.QAFeatureFlags,
  };
}

function mapDispatchToProps(dispatch: Function) {
  return {
    onChangeDataEntryTab: (data: number, tabClick: boolean) =>
      dispatch(onChangeDataEntryTab(data, tabClick)),
    resetForm: () => dispatch(reset(FormConstants.DATA_ENTRY_FIELDS_FORM)),
    getTransactionAttributes: (transactionId: string) =>
      dispatch(getTransactionAttributesActionCreator(transactionId)),
    assignTransactionID: (transactionId: string) =>
      dispatch(assignTransactionIDActionCreator(transactionId)),
    getDocumentById: (documentId: string, auditOnShowDoc: boolean) =>
      dispatch(getDocumentByIdActionCreator(documentId, auditOnShowDoc)),
    unassignTransactionID: (transactionId: string) =>
      dispatch(unassignTransactionIDActionCreator(transactionId)),
    getFacilityFeeSchedules: (facilityId: string, transactionId: string, setDefault: boolean) => dispatch(getFeeScheduleData(facilityId, transactionId, setDefault)),
    getFacilityShippingServices: (facilityId: string) => dispatch(getShippingServicesData(facilityId)),
    getFacilityReleaseMethods: (facilityId: string, transactionId: string) => dispatch(getReleaseMethodOptionsData(facilityId, transactionId)),

    getTransactionNotesRequest: (transactionId: string) =>
      dispatch(getTransactionNotesRequest(transactionId)),
    getAdditionalChargeType: (facilityId: string) =>
      dispatch(getAdditionalChargeType(facilityId)),
    getAdditionalChargeRecords: (transactionId: string) =>
      dispatch(getAdditionalChargeRecordsCreator(transactionId)),
    getTransactionDocuments: (transactionId: string) =>
      dispatch(getTransactionDocuments(transactionId)),
    getRejectOptions: (facilityId: string) => dispatch(getRejectOptions(facilityId)),
    getTransactionNoteTypes: (facilityId: string) => dispatch(getTransactionNoteTypes(facilityId)),
    uploadMultipleDocuments: (data: any) => dispatch(uploadMultipleDocumentsActionCreator(data)),
    resetDataEntryState: () => dispatch(resetDataEntryState()),
    updateDocument: (data: any) => dispatch(updateDocumentActionCreqtor(data)),
    getDocumentSources: (data: any) => dispatch(getDoucmentSourcesActionCreator(data)),
    deleteAuxDocument: (transactionId: string) => dispatch(deleteDocumentActionCreator("AUX", transactionId)),
    deleteIntDocument: (transactionId: string) => dispatch(deleteDocumentActionCreator("INT", transactionId)),
    deleteDocument: (documentId: any) => dispatch(deleteDocumentActionCreator("MED", documentId)),
    getRetrieveProtocol: (data: any) => dispatch(getRetrieveProtocolActionCreator(data)),
    transactionCancellationReasons: (facilityId: string) => dispatch(getCancellationReasons(facilityId)),
    getSelectedPHICodes: (transactionId: string) =>
      dispatch(getSelectedPHICodesActionCreator(transactionId)),
    getPHICodesRequest: (facilityId: string) => dispatch(getPHICodesRequest(facilityId)),
    getUserGroups: (facilityId: string) => dispatch(getUserGroupsActionCreator(facilityId)),
    getDisclosureReasons: (facilityId: string) => dispatch(getDisclosureReasonActionCreator(facilityId)),
    stopSubmit: (validations: any) => dispatch(stopSubmit(FormConstants.DATA_ENTRY_FIELDS_FORM, validations)),
    updateFormSyncErrors: (data: any) => dispatch(updateFormSyncErrorsActionCreator(data)),
    getUserTasks: (data: any) => dispatch(getUserTasksActionCreator(data)),
    submitForm: () => dispatch(submit(FormConstants.DATA_ENTRY_FIELDS_FORM)),
    touch: (fields: any) => dispatch(touch(FormConstants.DATA_ENTRY_FIELDS_FORM, fields)),
    getRequestsAndPurposes: (healthSystemId: number) => dispatch(getRequestTypesAndPurposesRequest(healthSystemId)),
    getFacilityDiscloseInstitutionsRequest: (facilityId: string) => dispatch(getFacilityDiscloseInstitutionsRequest(facilityId)),
    requestorVerificationEmailSent: () => dispatch(requestorVerificationEmailSent()),
    requestorVerificationEmailSentReset: () => dispatch(RequestorVerificationEmailSentReset()),
    setLastUsedRequestorInfo: (data: any) => dispatch(setLastUsedRequestorInfo(data)),
    patchRequestorComplete: (data: string) => dispatch(requestorCompleteActionCreator(data)),
    patchBillingComplete: (data: string) => dispatch(billingCompleteActionCreator(data)),
    postCancellationReasonsDispatch: (transactionId: string, id: any, cancelReasonText: string) => dispatch(postCancellationReasonsActionCreators(transactionId, id, cancelReasonText)),
    getFacilityAdditionalFieldsActionCreator: (data) => dispatch(getFacilityAdditionalFieldsActionCreator(data)), 
    transactionWaitAndHoldReasons: (data) => dispatch(getWaitAndHoldReasons(data)),
    getOutboundTemplateRequest: () => dispatch(getOutboundTemplateRequest()),
    getAssessmentResults:(transactionId: string) => dispatch(getAssessmentResultsActionCreator(transactionId)),
    getReviewEntities: (transactionId: string) => dispatch(getReviewEntitiesActionCreator(transactionId)),
    getQAErrors:() => dispatch(getQAErrorsActionCreator()),
    getQAIssues: (transactionId: string) => dispatch(getQAIssuesActionCreator(transactionId)),
    getQAPDIssues:(transactionId: string) => dispatch(getQAPDIssuesActionCreator(transactionId)),
    updateBillingPaneFields: (data: any, updateData) => {return dispatch(updateBillingPaneFields(data, updateData))},
    getAIFeedback: () => dispatch(getAIFeedbackActionCreator()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataEntryDetails);

function doctype(doctype: any, arg1: string, data: any, transactionId: string): any {
  throw new Error("Function not implemented.");
}
