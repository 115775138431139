import React from "react";
import { Col, Modal, Row, Container } from "react-bootstrap";
import './alertPopup.scss';
import { useHistory } from "react-router";


function ReleaseDocumentMissingPopUp(props: any) {
    const history = useHistory();
    const onClickClose = () => {
        history.push("/home");
    }

    return(
        <div>
            <Modal className="cancel-dimension complete-transaction-modal" show={true} >
                <div className="alert-container">
                    <Modal.Title className="cancel-header">
                        <span className="info-icon"></span>
                        <div className="cancel-header-text">Review Error</div>
                    </Modal.Title>
                    <Modal.Body>
                        <Container style={{display: "flex", flexDirection: "column"}}>
                            <Row>
                                <p>The release copy for this transaction is not available.</p>
                            </Row>
                            <Row className="button-align">
                                <Col className="d-flex justify-content-center align-items-center">
                                    <a href="#" onClick={onClickClose} className="modal-cancel" style={{marginLeft: "0"}}>
                                        Close
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}

export default ReleaseDocumentMissingPopUp;
