import React from "react"
import "./copyToClipboard.scss";

interface CopyToClipboardProps {
    copyText: string
}
function CopyToClipboard(props: CopyToClipboardProps) {
    const onCLick = () => {
        navigator.clipboard.writeText(props.copyText);
        var popup = document.getElementById("copyPopup") as HTMLElement;
        if(popup) {
            popup.classList.add("visible")
        }
        setTimeout(() => {
          if(popup) {
            popup.classList.remove("visible")
          }
        }, 3000)
    }


  return (
    <>
      <span onClick={onCLick} title="Copy Transaction ID" className="copyIcon">
          <svg height={"20px"} width={"20px"} viewBox="0 0 30 30"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2m0 16H8V7h11z"></path></svg>
      </span>
      <div id="copyPopup">"{props.copyText}" copied to clipboard</div>
    </>
  )
}

export default CopyToClipboard