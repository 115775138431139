import React from 'react'; 
import DateField from '../formFields/DateField'
import { mirrorData } from '../utils/appUtils';

/**
 * to be wrapped with redux-form Field component
 */
export default function ReduxFormDateField(props) {

  const { disabled, input, meta, label,onChangeRTRDateRange, placeholder, maxDate=null, minDate=null, required, errorMessage, setErrMsgPresent, onChange, onBlur } = props

  const customOnBlur = (e) => {
    mirrorData(input.name, input.value)
    onBlur && onBlur(input.value, input.name, e)
  }

  // do not display warning if the field has not been touched or if it's currently being edited
  let error = errorMessage || ""
  if (meta.touched && !meta.active) {
    if (meta.valid) {
    } else {
      error = meta.error
    }
  }

  if(error && setErrMsgPresent) {
    setErrMsgPresent(true)
  }
  
  return (
    <DateField 
    label={label}
    placeholder={placeholder}
    maxDate={maxDate}
    minDate={ minDate } 
    required = {required}
    errMsg={error}
    onChange={input.onChange}
    value={input.value}
    name={input.name}
    disabled={disabled}
    onChangeVal={onChange}
    onBlur={customOnBlur}
    onChangeRTRDateRange={onChangeRTRDateRange}
  />
  );
}