import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import DropdownField from "./../formFields/DropdownField";
import editSmallIcon from '../../assets/images/editIcons/editSmallIcon.png';
import RemoveIcon from "../../assets/images/tabImages/RemoveIcon.png"
import './CustomTable.scss';
import { areValuesIdentical } from '../utils/appUtils';
import TextField from '../formFields/TextField';
import {getComparator} from './AccordionTable'
interface Data {
    record_name: string;
    source: string;
    sab: string;
    size: string;
    pages: number;
    actions: string;
}

type Order = 'asc' | 'desc';


function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {return order};
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

const headCells: HeadCell[] = [
  { id: 'record_name', label: 'RECORD NAME' },
  { id: 'source', label: 'SOURCE' },
  { id: 'sab', label: 'S/A/B' },
  { id: 'size', label: 'SIZE' },
  { id: 'pages', label: 'PAGES' },
  { id: 'actions', label: 'ACTIONS' },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  hideSource?:Boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  let updatedHeadCells = [...headCells]
  if(props.hideSource){
    updatedHeadCells.splice(1,1)
  }
  return (
    <TableHead>
      <TableRow>    
        {updatedHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}           
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
interface IpropTypes {
  rows: any,
  getDocumentById:Function,
  setShowRecordGrid : any
  hideSource?: Boolean,
  setSelectedMedDocumentIndex? : any,
  updateDocument: Function,
  transactionId: string,
  documentSources?:any,
  deleteDocument:Function,
  getTransactionDocuments : any,
  auditOnShowDoc: boolean
}
export default function RecordsTable(props:IpropTypes) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('pages');
  const [documentsList, updateDocumentsList] = React.useState(props.rows)

  const onClickEditButton = (index: number) => {
    let updatedListData = [...documentsList]
    let updatedItem = updatedListData[index]
    const isEditing = !updatedItem.isEditing
    updatedItem = {
      ...updatedItem,
      isEditing
    }
    updatedListData[index] = updatedItem
    updateDocumentsList(updatedListData)
    if(!isEditing){
      updateDocumentDetails(updatedItem)
    }
  }

  const updateDocumentDetails = (data: any) => {
    const requestObject = {
      documentId: data.id,
      recordSourceId: data.sourceID || 3,
      fileDescription: data.description,
      transactionId:props.transactionId
    }
    props.updateDocument(requestObject)
  }

  const onEditFileName = (index: number, editedValue:string, sourceID) => {
    let updatedListData = [...documentsList]
    let updatedItem = updatedListData[index]
    updatedItem = {
      ...updatedItem,
      description: editedValue,
      sourceID
    }
    if(updatedListData[index].sourceID !== sourceID){
      updateDocumentDetails(updatedItem)
    }
    updatedListData[index] = updatedItem
    updateDocumentsList(updatedListData)
  }

  useEffect(() => {
    const currentListSorted = documentsList.slice((a, b) => (a.id > b.id) ? 1 : -1).sort();
    const updatedListSorted = props.rows.slice((a,b) => (a.id > b.id) ? 1 : -1).sort();

    if (!areValuesIdentical(currentListSorted, updatedListSorted)){
      updateDocumentsList(props.rows)
    } 
  },[props.rows])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

   const removeDocument = (id: any) => {
     if (window.confirm("Are you certain you wish to delete this file?")) {
       props.deleteDocument(id)
       props.getTransactionDocuments(props.transactionId)
     }
  }
  const medAPIFetch = (documentId) => {
    props.setShowRecordGrid(false)
    props.setSelectedMedDocumentIndex(documentId)
    props.getDocumentById(documentId, props.auditOnShowDoc)
    return ""
  }
  const getFileName = (file:any) => {
      return  file.description || file.file?.name
  }
  return (
    <div className="records-table-main" style={props.hideSource ? {width: "100%"} : {}}>
          <Table
            size="small" stickyHeader className='records-table'
          >
          <div className="records-table-inner">                
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              hideSource={props.hideSource}
            />
            <TableBody>
              {stableSort(documentsList, getComparator(order, orderBy))
                .map((row: any, index) => {
                  const fileSize =  row.file?.size?.bytes
                  const fileName =  getFileName(row)
                  return (
                    <TableRow
                      hover
                      key={row[index]}
                    >                    
                      <TableCell className='record_name'>
                        {row.isEditing ? <TextField className="edit-textinput" label="" value={fileName}
                         onBlur={() => {onClickEditButton(index)}} onChange={(value) => {onEditFileName(index,value,row.sourceID)}} errMsg="" placeholder=""  /> 
                        : <>
                          <a href="#!"  onClick={() => medAPIFetch(row.id)} className="records-table-file" style={fileName && fileName.length > 0 ? {} : {opacity: 0.5}}>{fileName && fileName.length > 0 ? fileName : "No file name"}</a>
                            <a href="#!" onClick={() => {onClickEditButton(index)}}>
                            <img className="cancelButton record-edit-icon" src={editSmallIcon} alt="Edit"/> 
                        </a></>
                        }
                      </TableCell> 
                      {props.hideSource ? null : <TableCell className='record_source'>
                          <DropdownField 
                            required= {true}
                            options= {props.documentSources}
                            onChange= {(e) => {onEditFileName(index, fileName, e.target.value)}}
                            input={{value: row.sourceID || "34000"}}
                            meta= 'abc'
                          />
                      </TableCell>}
                      <TableCell className='record_sab'>{row.sab}</TableCell>
                      <TableCell className='record_size'>{isNaN(fileSize) ? fileSize : ((Number(fileSize)* 0.00000095367432).toFixed(2) + "MB")}</TableCell>
                      <TableCell className='record_pages'>{row.pageCount}</TableCell>
                      <TableCell className='record_remove'>
                        <div className='record_options_items'>
                          <span className='remove_doc' onClick={() =>removeDocument(row.id)} style={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                            <svg width="15" height="15" viewBox="0 0 1200 1200" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M600 0C268.629 0 0 268.629 0 600C0 931.371 268.629 1200 600 1200C931.371 1200 1200 931.371 1200 600C1200 268.629 931.371 0 600 0ZM411.475 262.5L600 451.025L788.525 262.5L937.5 411.475L748.975 600L937.5 788.525L788.525 937.5L600 748.975L411.475 937.5L262.5 788.525L451.025 600L262.5 411.475L411.475 262.5Z" fill="#CC5967" />
                            </svg>
                            <span className='record_remove_span'>Remove</span>
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}         
            </TableBody>
            </div>
          </Table>
    </div>
  );
}
