import React from 'react';
import TextField from '../formFields/TextField'
import { RequestorFormConstants, BillingFormconstants } from '../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants';
import { FormConstants } from '../../components/dataEntryDetails/dataEntryComponents/DataEntryFieldsConstants';
import { normalizePhone, numericAmount, mirrorData, normalizeCharacters } from '../utils/appUtils';
/**
 * to be wrapped with redux-form Field component
 */

const TEXT_FIELDS = [RequestorFormConstants.CITY, RequestorFormConstants.STATE] //, RequestorFormConstants.FIRST_NAME, RequestorFormConstants.LAST_NAME
const PHONE_FIELDS = [RequestorFormConstants.PHONE_FAX_NUMBER, RequestorFormConstants.PHONE_NUMBER, RequestorFormConstants.FAX_NUMBER || BillingFormconstants.SEND_TO_PHONE, BillingFormconstants.SEND_TO_FAX, BillingFormconstants.BILL_TO_PHONE, BillingFormconstants.BILL_TO_FAX, BillingFormconstants.DIGITAL_FAX]
const NUM_FIELDS = [FormConstants.PAGES_DISCLOSED, RequestorFormConstants.ZIP, FormConstants.PROTOCOL_NUMBER]
export default function ReduxFormTextField(props) {

  const { disabled, maxLength, input, meta, multiline, className, label, placeholder, onChange, required, errorMessage, onBlur, suppressEnterSubmit } = props


  const customOnBlur = (e) => {
    mirrorData(input.name, input.value)
    onBlur && onBlur(input.value, input.name, e)
  }

  
  

  /* do not display warning if the field has not been touched or if it's currently being edited */
  const onChangeValue = (value: any) => {

    let updatedValue = value

    if (PHONE_FIELDS.includes(input.name)) {
      updatedValue = normalizePhone(value)
    }
    if (NUM_FIELDS.includes(input.name)) {
      updatedValue = numericAmount(value);
    }
    if (TEXT_FIELDS.includes(input.name)) {
      updatedValue = normalizeCharacters(value, input.name)
    }
    input.onChange(updatedValue)
    onChange && onChange(updatedValue)

  }
  let error = errorMessage || ""
  if (meta.touched && !meta.active) {
    if (meta.valid) {
    } else {
      error = meta.error
    }

  }
  return (

    <TextField
      label={label}
      placeholder={placeholder}
      maxLength={maxLength}
      disabled={disabled}
      errMsg={error}
      onChange={onChangeValue}
      value={input.value}
      required={required}
      onBlur={customOnBlur}
      className={className}
      multiline={multiline}
      suppressEnterSubmit={suppressEnterSubmit}
    />
  );
}