import React, { Component, Fragment, ReactChild } from 'react';
import { connect } from 'react-redux'
import { Row, Col, CardGroup, Container } from 'react-bootstrap';
import '../landingPage/LandingPage.scss';
import Header from '../../common/header/Header';
import DataEntry from '../../common/tabComponents/DataEntry';
import RecordAttachment from '../../common/tabComponents/RecordAttachment';
import RecordValidation from '../../common/tabComponents/RecordValidation';
import QAReview from "../../common/tabComponents/QAReview";
import FacilityActionRequired from "../../common/tabComponents/FacilityActionRequired";
import { getHealthOrganizationsSearchRequest, getSystemBulletinRequestActionCreator, userAcknowledgeBulletinRequestActionCreator, getTrackingLinkRequest, getNextQATransaction } from './LandingPageDucks';
import { getUserAttributesRequest, getFeatureFlagsRequest, getQAFeatureFlagsRequest } from '../login/LoginPageDucks';
import { OrganizationDetailsModal, FacilitiesCountsModal }
  from '../dataEntryDetails/dataEntryComponents/DataEntryDetails';
import MyTasks from '../../common/tabComponents/MyTasks';
import toast, { Toaster} from "react-hot-toast";
import IconWithButton from "../../common/IconWithButton/IconWithButton";
import { FeatureFlagsType } from '../../common/customTypes/FeatureFlagsType';


export function getRoutePathName(
  selectedHealthOrganizationDetails: OrganizationDetailsModal,
  selectedFacilityDetails?: FacilitiesCountsModal
) {
  let name: string[] = ["Home"];
  if (selectedHealthOrganizationDetails?.healthSystem?.name) {
    name.push(selectedHealthOrganizationDetails.healthSystem.name);
  }
  if (selectedFacilityDetails?.facility?.name) {
    name.push(selectedFacilityDetails.facility.name);
  }
  return name;
}

interface LandingPageProps {
  selectedHealthOrganizationDetails: any,
  getHealthOrganizationsSearch: Function,
  getUserAttributesRequest: Function,
  children?: ReactChild,
  name: string,
  searchPlaceholder?: string,
  searchChanged?: ((term: string) => void),
  getQAFeatureFlagsRequest?: Function,
  QAFeatureFlags?: FeatureFlagsType,
  getFeatureFlagsRequest: Function,
  featureFlags?: FeatureFlagsType
  getSystemBulletinRequest?: Function,
  systemBulletinMessages: any,
  userAcknowledgeBulletinRequest?: Function,
  getTrackingLinkRequest: Function,
  getNextQATransaction: Function,
}

interface LandPageState {
  showToast: boolean
}


export function renderHealthOrganziationTransactionsData(item: OrganizationDetailsModal, finalQa?: boolean, onClick?: Function) {
  return (
    <CardGroup onClick={() => onClick && onClick()}>
      <DataEntry
        dataEntry={item?.transactionCounts?.dataEntry ?? 0}
      />
      <div className="vertical-line"></div>
      <RecordAttachment
        recordAttachment={item?.transactionCounts?.recordAttachment ?? 0}
      />
      <div className="vertical-line"></div>
      <RecordValidation
        recordValidation={item?.transactionCounts?.recordValidation ?? 0}
      />
      <div className="vertical-line"></div>
       <FacilityActionRequired 
        facilityActionRequired ={item?.transactionCounts?.facilityActionRequired ?? 0} 
      />
      <div className="vertical-line"></div>
      <MyTasks
        dataEntry={item?.transactionCounts?.userTasks ?? 0}
      />
      {finalQa && (<>
        <div className="vertical-line"></div>
        <QAReview
          qaReview={item?.transactionCounts?.qaReview ?? 0}
        />
      </>)}
      
     
    </CardGroup>
  )
}

class LandingPage extends Component<LandingPageProps, LandPageState> {
  constructor(props){
    super(props);
    this.state = {showToast : true}
  }

  componentDidMount() {
    if(global.userID === undefined){
      const id = sessionStorage.getItem("userID");
      global.userID = id ? Number(id) : 0;
    }
    if (this.props.getHealthOrganizationsSearch != null) {
      const loadFirst = 10;
      // this.props.getHealthOrganizationsSearch(loadFirst);
      this.props.getHealthOrganizationsSearch(null, null, null, false);
    }
    this.props.getUserAttributesRequest();
    this.props.getTrackingLinkRequest();
    if(Object.keys(this.props.featureFlags!).length == 0){
      this.props.getFeatureFlagsRequest();
    }
    if (this.props.getQAFeatureFlagsRequest) {
      this.props.getQAFeatureFlagsRequest();
  }

    this.props.getSystemBulletinRequest && this.props.getSystemBulletinRequest(0)
  };

  searchChanged = (term) => {
    if (this.props.searchChanged != null)
      this.props.searchChanged(term)
  }

  componentDidUpdate(){
    if(this.props?.systemBulletinMessages?.length > 0 && this.state.showToast){
      this.setState({showToast: false})
      return toast(
        (t) => (
          <div>
            <h5 className="alert-heading">{this.props?.systemBulletinMessages[0]?.headerText} &nbsp;
              
              
            </h5>
            <p className="mb-0 pb-2" style={{fontSize: "16px"}}>{this.props?.systemBulletinMessages[0]?.textBody}</p>
            <div>
            {/* <span className='close-icon alert-close-icon'  style={{fontSize: "10px"}} onClick={(e) => { e.preventDefault(); this.props.userAcknowledgeBulletinRequest && this.props.userAcknowledgeBulletinRequest(this.props?.systemBulletinMessages[0]?.systemBulletinId);
                  toast.dismiss(t.id);
                  }}></span> */}
              <IconWithButton
                    buttonText={"OK"}
                    onButtonClick={(e) => { e.preventDefault(); this.props.userAcknowledgeBulletinRequest && this.props.userAcknowledgeBulletinRequest(this.props?.systemBulletinMessages[0]?.systemBulletinId);
                      toast.dismiss(t.id);
                      }}
                    isSave={true}
                    hideArrowIcon={true}
              />          
            </div>
          </div>
        ),
        {duration: 30000}
      );      
    }
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  

  render() {
    const { selectedHealthOrganizationDetails, name } = this.props;
    return (
      <Fragment>
        <Container fluid className='app-container-custom-style'>
          <div className='landing-page-header'>
            <Header headerClassName={"header-style"} name={name} searchChanged={this.searchChanged} getNextQATransaction={getNextQATransaction}
              homeText={getRoutePathName(selectedHealthOrganizationDetails)}
              searchPlaceholder={this.props.searchPlaceholder} />
          </div>
          <div className='landing-page-content'>
            <Row className='common-row-level-margin'>
              <Col lg={12}>
                <div className='lg-3 pl-0 healthOrgWrapper'>
                  <div className='individual-org'>
                    {this.props.children}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Toaster toastOptions={{
                  className: 'toast-notification',
                }}
          position="top-right" />
      </Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    hltOrgTransactionsData: state.landingPageState.hltOrgTransactionsData,
    selectedHealthOrganizationDetails: state.landingPageState.selectedHealthOrganizationDetails,
    featureFlags: state.loginState.featureFlags,
    systemBulletinMessages: state.landingPageState.systemBulletinMessages,
  }
}

function mapDispatchToProps(dispatch: Function) {
  return {
    getHealthOrganizationsSearch: (offset: number, limit: number, searchTerm: string, returnEmpty: boolean) => dispatch(getHealthOrganizationsSearchRequest(offset, limit, searchTerm, returnEmpty)),
    getUserAttributesRequest: () => dispatch(getUserAttributesRequest()),
    getFeatureFlagsRequest: () => dispatch(getFeatureFlagsRequest()),
    getQAFeatureFlagsRequest: () => dispatch(getQAFeatureFlagsRequest()),
    getSystemBulletinRequest: (data) => dispatch(getSystemBulletinRequestActionCreator(data)),
    userAcknowledgeBulletinRequest: (data) => dispatch(userAcknowledgeBulletinRequestActionCreator(data)),
    getTrackingLinkRequest: () => dispatch(getTrackingLinkRequest())

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);