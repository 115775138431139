export default {
    requestOtpOrLogin: "auth/User",
    getUserAttributes: "auth/user",
    getTokenLogin: "auth/user/token/",
    //getHealthOrganizationsList: "auth/user/healthsystems",
    getHealthOrganizationsSearch: "auth/user/healthsystemssearch",
    getHealthSystemByFacility: "auth/user/healthsystembyfacility",
    getHealthOrganizationTransactionsCount: "transactions/healthsystems",
    generateOrGetTransactionId: "transactions/facilities/",
    getRequestTypesAndPurposes: "transactions/healthsystems/",
    getTransactionAttributes: "transactions/",
    assignTransactionId: "transactions/",
    getTransactionPatientAttributes: "patients/",
    getDisclosingInstitutions: "transactions/facilities/",
    getRequestorDetails: "requestors/",
    getRequestorBillingDetails: "requestor/billtorequestor/",
    getRequestorBillingDetailsByTx: "requestor/billtorequestorbytx/",
    getFacilitiesAttributes: 'facility/',
    getTransactionNotes: "transactions/",
    getAdditionalChargeType: "facilities/",
    putSendToRequestor: "requestor/",
    getResendTransaction: "transactions/",
    updateBillToRequestorDetails: "requestor/",
    getRequestorSearchDetails: "requestor/facilities/",
    updateBillingPane: "transactions/",
    getTransactionDocuments: "transactions/",
    getRejectOptions: "facility/",
    patchPatientsNotes: "patients/",
    patchRequestorsNotes: "requestor/",
    uploadDocument: "documents/Request?transactionId=",
    createTransaction: "transactions/",
    getDocument: "documents/",
    updateReplaceDocument: "documents/update/",
    getTransactionNoteTypes: "facilities/",
    updateTransactionNotes: "transactions/",
    getSelectedPHIodes: "transactions/",
    postCancellationReasons: "transactions/",
    sentToRequestorsContact: "requestor/",
    rejectionContactOverride: "transactions/",
    updateDocument: "documents/",
    getDocumentSources: "facility/",
    getRejectionLetter: "transactions/",
    getPreviousTransaction: "transactions/",
    getPatientDetails: "patients/facilities/",
    getPHICodes: "facilities/",
    createPatientRecord: "patients/",
    patchPHIdisclosed: "transactions/",
    getTransactionSearchResults: "transactions/search",
    postRejectionAction: "transactions/",
    getUserGroups: "transactions/usertasks/Assignment/UserGroups/",
    getDisclosureReason: "facilities/",
    updateDisclosureInformation: 'transactions/',
    getUserTasks: "transactions/",
    getUserTaskComments: "usertasks/",
    createUserTaskComment: "usertasks/",
    addUserTask: "transactions/",
    deleteTransactionTask: "transactions/",
    completeTransaction: "transactions/",
    buildNumber: "verify/buildnumber",
    detailscomplete: "transactions/",
    requestorcomplete: "transactions/",
    billingcomplete: "transactions/",
    putHeartbeat: "auth/user/heartbeat",
    clearUserLocks: "auth/user/clearuserlocks/",
    patchPatientDetails: "patients/",
    getAuditTypes: "transactions/RequestForAuditType",
    getOBOs: "transactions/OnBehalfOf",
    getValidate: "transactions/",
    getAuditTrail: "transactions/",
    getDuplicates: "transactions/",
    patchTransactionOnFile: "transactions/",
    getWebPrintScan: "wps/e/",
    getDocumentsForTransactionOnFile: "transactions/",
    getWaitAndHoldReasons: "transactions/",
    postWaitAndHoldReasons: "transactions/",
    patchTransactionContinue: "transactions/",
    getFeatureFlags: "auth/featureFlags",
    getQAFeatureFlags: "auth/QAFeatureFlags",
    getTrackingLink: "auth/trackinglink",
    systemBulletin: "systembulletin/",
    digitalFax: "auditTrail/",
    transactionSensitiveInfo: "auditTrail/",
    getStatusIndicator: "transactions/",
    outboundTemplate: "OutboundTemplate/",
    getFacilityCount: "transactions/",
    GetFacilitySpotlightDetails: "facilities/",
    getDefaultFeeSchedule: "transactions/",
    getAssessment: "ai/fetch",
    getReviewEntities: "ai/reviewentities/",
    getQAErrors: "qaError/getQAError",
    getQAIssues: "qaError/getIssues",
    postQATransactionIssue: "qaError/submitIssue",
    resolveQATransactionIssue: "qaError/resolveIssue",
    removeQATransactionIssue: "qaError/removeIssue",
    getQAPDIssues: "qaError/getPDIssues",
    getAIFeedback: "ai/feedback",
    submitAIFeedback: "ai/review",
    returnToSearch: "auth/advancedsearch",
    getAISensitivityTypes: "ai/sensitivityTypes",
    createQAReviewEntity:"ai/createQAReviewEntity",
    getNextQATransaction: "transactions/QA",
}
