
import React from "react";
import { Col, Modal, Row, Container } from "react-bootstrap";
import IconWithButton from "../IconWithButton/IconWithButton";
import './alertPopup.scss';


function CompleteReviewPopUp(props: any) {
    const { setShowCompleteReviewPopUp, transactionId } = props;

    const onClickCancel = () => {
        setShowCompleteReviewPopUp(false);
    }

    const onClickComplete = () => {
        setShowCompleteReviewPopUp(false);
        props.completeTransaction(transactionId);
        props.setWait(true);
        setTimeout(() => {
            props.setWait(false);
            props.clearPreviousTransactionOnComplete();
            props.goToNextTransaction();
            props.onChangeDataEntryTab(1, false)
        }, 1000);
    }

    return(
        <div>
            <Modal className="cancel-dimension complete-transaction-modal" show={true} >
                <div className="alert-container">
                    <Modal.Title className="cancel-header">
                        <span className="info-icon"></span>
                        <div className="cancel-header-text">Complete review - {transactionId}</div>
                    </Modal.Title>
                    <Modal.Body>
                        <Container style={{display: "flex", flexDirection: "column"}}>
                            <Row>
                                <p>If the transaction has been reviewed and is ready for completion, please complete.</p>
                            </Row>
                            <Row className="button-align ">
                                <Col xs={6} className="d-flex justify-content-center align-items-center">
                                    <IconWithButton
                                        buttonText={"Complete"}
                                        onButtonClick={onClickComplete}
                                        isSave={true}
                                        hideArrowIcon={true}
                                    />
                                </Col>
                                <Col xs={6} className="d-flex justify-content-center align-items-center">
                                    <a href="#" onClick={onClickCancel} className="modal-cancel" style={{marginLeft: "0"}}>
                                        Cancel
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}

export default CompleteReviewPopUp;
