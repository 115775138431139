import React, { useState, useEffect, useRef } from "react";
import "./autoLogoutTimeOut.scss";

interface AutoLogoutProps {
  logoutUser?: () => void;
}

const AutoLogoutTimeOut: React.FC<AutoLogoutProps> = ({ logoutUser }) => {
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [inactive, setInactive] = useState(false);
  const [countdown, setCountdown] = useState(20); // 60 seconds countdown
  const timerInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const lastTimeRef = useRef(Date.now());
  const inactivityThreshold = 25 * 60; // 25 minutes in seconds
  const countdownInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const hiddenStartRef = useRef<number | null>(null); // Track when the tab was hidden

 // Function to handle session logout
 const handleLogout = () => {
  if (logoutUser) {
    logoutUser();
  }
};

// Function to reset inactivity timer explicitly
const resetInactivity = () => {
  setSecondsElapsed(0);
  setInactive(false);
  setCountdown(20);
  lastTimeRef.current = Date.now();

  if (countdownInterval.current) {
    clearInterval(countdownInterval.current);
  }
};

// Timer logic to check inactivity
useEffect(() => {
  function startTimer() {
    timerInterval.current = setInterval(() => {
      const now = Date.now();
      const elapsed = Math.floor((now - lastTimeRef.current) / 1000); 

      setSecondsElapsed((prevSeconds) => {
        const newTime = prevSeconds + elapsed;

        if (newTime >= inactivityThreshold && !inactive) {
          setInactive(true); 
        }

        return newTime;
      });

      lastTimeRef.current = now; 
    }, 1000); 
  }

  startTimer();

  return () => {
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
    }
  };
}, []); // Empty dependency array

// Countdown logic when popup is active
useEffect(() => {
  // Start countdown if inactive and 60 seconds remain
  if (inactive && countdown > 0) {
    countdownInterval.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          if(countdownInterval.current)
            clearInterval(countdownInterval.current); // Stop countdown at 0
          handleLogout(); // Log out the user after countdown reaches 0
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000); // Decrease countdown every second
  }

  return () => {
    if (countdownInterval.current) {
      clearInterval(countdownInterval.current);
    }
  };
}, [inactive, countdown]);

// Handle visibility change
useEffect(() => {
  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      hiddenStartRef.current = Date.now(); // Start tracking when the tab was hidden
    } else if (document.visibilityState === "visible" && hiddenStartRef.current) {
      const hiddenDuration = Math.floor((Date.now() - hiddenStartRef.current) / 1000);
      hiddenStartRef.current = null; // Reset the hidden tracker

      // If the tab was hidden for more than the inactivity threshold, logout
      if (hiddenDuration >= inactivityThreshold) {
        handleLogout();
      }
    }
  };

  document.addEventListener("visibilitychange", handleVisibilityChange);

  return () => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  };
}, []);

// Event listeners for user activity (but don't close the popup)
useEffect(() => {
  const events = ['click', 'keypress', 'scroll', 'mousemove'];
  const activityHandler = () => {
    if (!inactive) {
      resetInactivity();
    }
  };

  events.forEach((event) => window.addEventListener(event, activityHandler));

        return () => {
            events.forEach((event) => window.removeEventListener(event, activityHandler));
        };
}, [inactive]);

  return (
    <div>
      {inactive && (
        <div id="autoLogoutOverlay">
          <div id="autoLogoutModal">
            <p id="autoLogoutHeader">Your session is about to expire</p>
            <p id="autoLogoutCountdown">
              You will be logged out in <span id="autoLogoutDigits">{countdown}</span> seconds
            </p>
            <div id="autoLogoutButtons">
              <button id="autoLogoutContinueButton" onClick={resetInactivity}>
                Continue Session
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AutoLogoutTimeOut;